import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getAuthenticatedUserFromCookies } from '../../../../../utils/cookies';
import { BASE_URL } from '../../../../../config-global';
import {
  changeCallState,
  changeNumber,
  changePhoneState,
} from '../../../../../redux/slices/virtualPhone';

const Contact = ({ data, makeCall, setContacts, sendMessage }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isChanging, setIsChanging] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const makeContactFavorite = useCallback(async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    setIsChanging(true);
    axios
      .post(
        `${BASE_URL}:9000/callcenter/contact/favorite`,
        { contactId: data.id, favorite: data.favorite },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        const storedContacts = JSON.parse(Cookies.get('virtual_phone_contacts'));
        console.log(storedContacts);
        const modifiedContacts = storedContacts.map((c) => {
          if (c.id === data.id) {
            c.favorite = !data.favorite;
          }
          return c;
        });
        setIsChanging(false);

        setContacts(modifiedContacts);
        Cookies.set('virtual_phone_contacts', JSON.stringify(modifiedContacts));
      })
      .catch((e) => {
        console.log(e);
        setIsChanging(false);
      });
  }, [data.favorite, data.id, setContacts]);

  const deleteContact = useCallback(async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    setIsChanging(true);
    axios
      .delete(`${BASE_URL}:9000/callcenter/contact/${data.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const storedContacts = JSON.parse(Cookies.get('virtual_phone_contacts'));
        const modifiedContacts = storedContacts.filter((c) => c.id !== data.id);
        setIsChanging(false);

        setContacts(modifiedContacts);
        Cookies.set('virtual_phone_contacts', JSON.stringify(modifiedContacts));
      })
      .catch((e) => {
        console.log(e);
        setIsChanging(false);
      });
  }, [data.id, setContacts]);

  const call = () => {
    dispatch(changeCallState('calling'));
    dispatch(changePhoneState('formatNumber'));
    dispatch(changeNumber(data.phoneNumber));
    makeCall(data.phoneNumber);
  };

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemText primary={data?.contactName} secondary={data?.phoneNumber} />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '40%',
            margin: '10px auto',
            pointerEvents: isChanging ? 'none' : 'auto',
          }}
        >
          <IconButton onClick={call} size="medium" sx={{ border: '1px solid green' }}>
            <CallIcon fontSize="medium" sx={{ color: 'green' }} />
          </IconButton>
          <IconButton
            size="medium"
            sx={{ border: '1px solid orange' }}
            onClick={() => makeContactFavorite(data.id, !data.favorite)}
          >
            {data.favorite ? (
              <StarIcon fontSize="medium" sx={{ color: 'orange' }} />
            ) : (
              <StarBorderIcon fontSize="medium" sx={{ color: 'orange' }} />
            )}
          </IconButton>
          {/* <IconButton
            size="medium"
            sx={{ border: '1px solid royalblue' }}
            onClick={() => sendMessage('0773742716', 'salut')}
          >
            <MessageIcon fontSize="medium" sx={{ color: 'royalblue' }} />
          </IconButton> */}
          <IconButton size="medium" sx={{ border: '1px solid red' }} onClick={deleteContact}>
            <DeleteIcon fontSize="medium" sx={{ color: 'red' }} />
          </IconButton>
        </div>
      </Collapse>
      <Divider />
    </>
  );
};

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  makeCall: PropTypes.func.isRequired,
  setContacts: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

export default Contact;
