import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { FILES_ARCHIVE_URL } from '../../config-global';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  list: {},
};

const slice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TABLES
    setPersons(state, action) {
      state.list = action.payload;
    },

    // ADD PERSON
    addPerson(state, action) {
      const { tip } = action.payload;
      if (!state.list[tip]) {
        state.list[tip] = [];
      }
      state.list[tip].push(action.payload);
    },

    // DELETE PERSON
    deletePerson(state, action) {
      const personId = action.payload;
      // Iterăm prin toate tipurile de persoane
      Object.keys(state.list).forEach((tip) => {
        // Filtrăm lista pentru fiecare tip, eliminând persoana cu ID-ul specificat
        state.list[tip] = state.list[tip].filter((person) => person.id !== personId);
        // Dacă lista pentru un anumit tip devine goală, o eliminăm
        if (state.list[tip].length === 0) {
          delete state.list[tip];
        }
      });
    },

    updatePerson(state, action) {
      const { id, tip, ...updatedData } = action.payload;

      // Găsim tipul curent al persoanei
      const currentTypeEntry = Object.entries(state.list).find(([_, persons]) =>
        persons.some((person) => person.id === id)
      );

      if (currentTypeEntry) {
        const [currentType, persons] = currentTypeEntry;

        // Dacă tipul s-a schimbat
        if (currentType !== tip) {
          // Eliminăm persoana din vechiul tip
          state.list[currentType] = persons.filter((person) => person.id !== id);

          // Adăugăm persoana la noul tip
          state.list[tip] = state.list[tip] || [];
          state.list[tip].push({ id, tip, ...updatedData });
        } else {
          // Dacă tipul nu s-a schimbat, actualizăm persoana în lista curentă
          state.list[currentType] = persons.map((person) =>
            person.id === id ? { id, tip, ...updatedData } : person
          );
        }
      }
    },
  },
});

export const { addPerson } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setReduxPersons(data) {
  return (dispatch) => {
    dispatch(slice.actions.setPersons(data));
  };
}

// ----------------------------------------------------------------------

export function addReduxPerson(data) {
  return (dispatch) => {
    const request = axios
      .post(`${FILES_ARCHIVE_URL}/persoane`, data)
      .then((response) => {
        dispatch(slice.actions.addPerson(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function deleteReduxPerson(id) {
  return (dispatch) => {
    const request = axios
      .delete(`${FILES_ARCHIVE_URL}/persoane/${id}`)
      .then((response) => {
        dispatch(slice.actions.deletePerson(id));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function updateReduxPerson(data) {
  return (dispatch) => {
    const request = axios
      .patch(`${FILES_ARCHIVE_URL}/persoane/${data.id}`, data)
      .then((response) => {
        dispatch(slice.actions.updatePerson(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}
