import { Box } from '@mui/material';
import * as React from 'react';

const FileIllustration = ({ ...other }) => (
  <Box {...other}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        d="M98 388.25c87.46 45.69 229.27 45.69 316.73 0s87.46-119.77 0-165.47-229.23-45.69-316.73 0-87.42 119.78 0 165.47Z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m73.62 313.32-28.43-16.41v-60.98l28.43 16.42v60.97z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m73.62 252.35 40.62-23.45v60.97l-40.62 23.45v-60.97z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m114.24 228.9-40.62 23.45-28.43-16.42 40.62-23.45 28.43 16.42z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m77.69 254.69 32.49-18.76.75.43v22.59l-32.5 18.76-.75-.43.01-22.59z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m78.43 255.13 32.5-18.77v22.59l-32.5 18.76v-22.58z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m91.17 251.6 5.53-3.19c.71-.42 1.29-.23 1.29.42a2.59 2.59 0 0 1-1.29 1.92l-5.53 3.19c-.72.42-1.3.23-1.3-.42a2.58 2.58 0 0 1 1.3-1.92ZM76.94 283.27l32.49-18.76v22.59l.75.43-32.5 18.76-.74-.43v-22.59z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m77.68 283.7 32.5-18.76v22.59l-32.5 18.76V283.7z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="M90.42 280.18 96 277c.72-.42 1.3-.23 1.3.42a2.58 2.58 0 0 1-1.3 1.92l-5.53 3.19c-.72.42-1.3.23-1.3-.42a2.58 2.58 0 0 1 1.25-1.93ZM73.62 248.83l-28.43-16.42v-60.97l28.43 16.41v60.98z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m73.62 187.85 40.62-23.45v60.98l-40.62 23.45v-60.98z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m114.24 164.4-40.62 23.45-28.43-16.41 40.62-23.45 28.43 16.41z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m77.68 190.2 32.5-18.76.75.43v22.59l-32.5 18.76-.75-.44V190.2z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m91.17 187.1 5.52-3.19c.72-.41 1.3-.22 1.3.43a2.6 2.6 0 0 1-1.3 1.92l-5.52 3.19c-.72.41-1.3.23-1.3-.42a2.61 2.61 0 0 1 1.3-1.93ZM76.94 218.78l32.49-18.76v22.58l.75.43-32.5 18.76-.74-.43v-22.58z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m77.68 219.21 32.5-18.76v22.58l-32.5 18.76v-22.58z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m90.42 215.68 5.58-3.19c.71-.41 1.3-.22 1.3.43a2.64 2.64 0 0 1-1.3 1.92L90.42 218c-.72.41-1.3.22-1.3-.42a2.61 2.61 0 0 1 1.3-1.9ZM77.68 190.2l32.5-18.76v22.58l-32.5 18.76V190.2z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m126.43 182.6-17.79-10.27v19.9l17.79 10.26V182.6z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M89.87 216v-28.15l6.97-4.04 1.15 1.7 20.31-11.73v25.8L89.87 216z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m89.87 216 2.03-24.63 28.43-16.41-2.03 24.62L89.87 216z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m93.93 201.36-15.5-8.95v19.9l15.5 8.94v-19.89z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m93.93 199.58 32.5-18.76v22.58l-32.5 18.76v-22.58z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m106.67 196.05 5.52-3.19c.72-.41 1.3-.22 1.3.43a2.61 2.61 0 0 1-1.3 1.92l-5.52 3.19c-.72.41-1.3.22-1.3-.42a2.61 2.61 0 0 1 1.3-1.93ZM93.93 199.58l-1.08-.62v22.58l1.08.62v-22.58z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m92.85 198.96 32.49-18.77 1.09.63-32.5 18.76-1.08-.62z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="m409.5 276.38-28.44-16.42v-60.97l28.44 16.41v60.98z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m409.5 215.4 40.62-23.45v60.98l-40.62 23.45V215.4z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M450.12 191.95 409.5 215.4l-28.44-16.41 40.63-23.46 28.43 16.42z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m413.56 217.75 32.5-18.76.75.43-.01 22.58-32.49 18.76-.75-.43v-22.58z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m414.31 218.18 32.5-18.76-.01 22.58-32.49 18.76v-22.58z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m427.05 214.65 5.52-3.19c.72-.41 1.3-.22 1.3.42a2.61 2.61 0 0 1-1.3 1.93l-5.52 3.19c-.72.41-1.3.22-1.3-.42a2.61 2.61 0 0 1 1.3-1.93ZM412.81 246.33l32.5-18.77v22.59l.75.43-32.5 18.76-.75-.43v-22.58z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m413.56 246.76 32.5-18.76v22.58l-32.5 18.76v-22.58z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="m426.3 243.23 5.53-3.19c.71-.41 1.29-.22 1.29.42a2.63 2.63 0 0 1-1.29 1.93l-5.53 3.19c-.72.41-1.3.22-1.3-.43a2.61 2.61 0 0 1 1.3-1.92Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M139 340.11s.11-15.86-9.89-27.75c-8.85-10.53-13.56-17.21-10.77-20.29s8.79-3.82 16.88 4.7 8.72 32.12 7.6 37.46Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M139 340.11s.11-15.86-9.89-27.75c-8.85-10.53-13.56-17.21-10.77-20.29s8.79-3.82 16.88 4.7 8.72 32.12 7.6 37.46Z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M140.68 323.06a.5.5 0 0 1-.49-.39c-3.5-16.37-12.48-25.42-17.53-29.36a.5.5 0 0 1-.09-.7.51.51 0 0 1 .71-.09c5.15 4 14.32 13.26 17.89 29.94a.49.49 0 0 1-.38.59Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="m138.62 346-4.07 2.35a7.36 7.36 0 0 0-5.48-4.69c-4.45-1-9.07-.83-12.19-4.69s.68-8.06 0-10.9-1.12-3.66-6.7-6.5-7.78-7.55-3.58-10.74 11-.85 15.12 2c3.66 2.5 7.14 3.16 11.23 3.67s10.75 2.78 9.73 12.28a74.86 74.86 0 0 1-4.06 17.22Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M123 333.52a.51.51 0 0 1-.48-.35.51.51 0 0 1 .33-.63c.36-.11 9-2.85 12.67.09a.5.5 0 0 1-.62.78c-3.24-2.6-11.66.06-11.75.09Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M139.07 345.24a.49.49 0 0 1-.49-.44c-2.23-21.06-27.6-31.05-27.85-31.15a.5.5 0 0 1-.29-.65.51.51 0 0 1 .64-.29c.27.11 26.2 10.31 28.49 32a.5.5 0 0 1-.44.55Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M387.8 332.17c.11-.35 4.4-22.43 0-38.09-4-14.36-9.22-19.66-16.66-20.83s-10.67 4.19-6.72 11.45 13.46 16.89 15.79 33.11c1 6.68 1.76 12.48 1.76 12.48Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M387.8 332.17c.11-.35 4.4-22.43 0-38.09-4-14.36-9.22-19.66-16.66-20.83s-10.67 4.19-6.72 11.45 13.46 16.89 15.79 33.11c1 6.68 1.76 12.48 1.76 12.48Z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M385.29 319.49a.5.5 0 0 1-.47-.53c1-17.88-4.84-33.84-15.31-41.67a.5.5 0 0 1-.1-.7.51.51 0 0 1 .7-.1c10.73 8 16.75 24.32 15.7 42.53a.5.5 0 0 1-.52.47Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M399.31 343.67c-.57-4.11 1.79-10.27 7.4-11.73s8.11-4.68 6.09-8.2-1.55-6.75 3.54-8.21 9.55-4 6.62-9.38c-2.13-3.89-2.6-6.41 2.62-14.07 4.33-6.36.79-10.09-4.63-9.39s-8.77 7.16-12.16 8.24-4.17-4.68-8.64-2.1-4.2 9.19-4.34 13.8-.44 5.65-4.48 5.09-8 .1-7.91 9.87c.07 8.38 4.54 18.76 4.54 18.76Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M392.22 337.42h-.05a.51.51 0 0 1-.45-.56c2.82-25.19 24.44-46.44 30.08-50.75a.5.5 0 0 1 .61.8c-5.57 4.25-26.91 25.22-29.69 50.06a.51.51 0 0 1-.5.45Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M402.08 310.49a.51.51 0 0 1-.47-.32.52.52 0 0 1 .3-.65c8.06-3 17.2-1.58 17.59-1.52a.5.5 0 0 1 .41.57.52.52 0 0 1-.57.42c-.09 0-9.27-1.44-17.08 1.47a.57.57 0 0 1-.18.03ZM394.9 325.63a.5.5 0 0 1-.37-.17 29.48 29.48 0 0 1-6.59-13.67.5.5 0 0 1 .42-.57.5.5 0 0 1 .57.42 28.9 28.9 0 0 0 6.35 13.15.51.51 0 0 1-.38.84Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M138.62 191.23v154.78l158.42-91.46-.01-140.71-117.79 68.01-4.07-11.73-36.55 21.11z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M138.62 191.23v154.78l158.42-91.46-.01-140.71-117.79 68.01-4.07-11.73-36.55 21.11z"
        style={{
          opacity: 0.7000000000000001,
        }}
      />
      <path
        d="m138.62 346.01 8.12-140.71 158.42-91.46-8.12 140.71-158.42 91.46z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m138.62 346.01 12.18-138.36 158.42-91.47-12.18 138.37-158.42 91.46z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="m138.62 346.01 12.18-138.36 158.42-91.47-12.18 138.37-158.42 91.46z"
        style={{
          opacity: 0.7000000000000001,
        }}
      />
      <path
        d="M162.99 205.3v154.79l158.42-91.47V127.91l-117.8 68.01-4.06-11.73-36.56 21.11z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M162.99 205.3v154.79l158.42-91.47V127.91l-117.8 68.01-4.06-11.73-36.56 21.11z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="m162.99 360.09 8.12-140.72 158.42-91.46-8.12 140.71-158.42 91.47z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="m162.99 360.09 12.18-138.37 158.42-91.47-12.18 138.37-158.42 91.47z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="m162.99 360.09 12.18-138.37 158.42-91.47-12.18 138.37-158.42 91.47z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="M297 409.34c-28.44-16.42-54.82-34.27-66.86-55.21l36.6-21.13Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M191.42 221.72V376.5l158.42-91.46V144.33l-117.8 68.01-4.06-11.73-36.56 21.11z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M191.42 221.72V376.5l158.42-91.46V144.33l-117.8 68.01-4.06-11.73-36.56 21.11z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M237.06 267.48c-.4 0-.46-.46-.46-.73v-132a3.32 3.32 0 0 1 1.48-2.56l114.34-66a1.06 1.06 0 0 1 .52-.16c.4 0 .46.46.46.73v132a3.33 3.33 0 0 1-1.48 2.57l-114.34 66a1.09 1.09 0 0 1-.52.15Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M352.88 66.49a1 1 0 0 1 0 .22v132a2.87 2.87 0 0 1-1.23 2.14l-114.34 66-.2.09a.61.61 0 0 1 0-.22v-132a2.87 2.87 0 0 1 1.23-2.13l114.34-66a.56.56 0 0 1 .21-.09m.06-1a1.56 1.56 0 0 0-.77.23l-114.34 66a3.85 3.85 0 0 0-1.73 3v132c0 .78.39 1.23 1 1.23a1.54 1.54 0 0 0 .77-.23l114.34-66a3.81 3.81 0 0 0 1.73-3v-132c0-.78-.39-1.24-1-1.23Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M352.88 66.49a1 1 0 0 1 0 .22v132a2.87 2.87 0 0 1-1.23 2.14l-114.34 66-.2.09a.61.61 0 0 1 0-.22v-132a2.87 2.87 0 0 1 1.23-2.13l114.34-66a.56.56 0 0 1 .21-.09m.06-1a1.56 1.56 0 0 0-.77.23l-114.34 66a3.85 3.85 0 0 0-1.73 3v132c0 .78.39 1.23 1 1.23a1.54 1.54 0 0 0 .77-.23l114.34-66a3.81 3.81 0 0 0 1.73-3v-132c0-.78-.39-1.24-1-1.23Z"
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <path
        d="m248.29 139.76 40.62-23.45v46.9l-40.62 23.45v-46.9z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="m248.29 139.76 40.62-23.45v46.9l-40.62 23.45v-46.9z"
        style={{
          fill: '#fff',
          opacity: 0.8,
        }}
      />
      <path
        d="M282.82 128v14.1l-1.28.71v-4.31c0-2.38-1.93-3.19-4.33-1.81L260 146.64c-2.39 1.38-4.33 4.43-4.33 6.81v4.31l-1.27.76v-14.07Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M275 163.9c-2.08 1.2-3.77-.06-3.77-2.83V138.5l-5.23 2.84v22.75c0 2.76-1.69 6-3.77 7.17l-3.78 2.18v1.5l20.31-11.73v-1.49Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M297 114c0 1.3 1.17 1.67 2.6.85L339.12 92a5.22 5.22 0 0 0 2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-39.49 22.8A5.2 5.2 0 0 0 297 114ZM297 128c0 1.3 1.17 1.68 2.6.85l39.49-22.8a5.22 5.22 0 0 0 2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-39.49 22.8A5.22 5.22 0 0 0 297 128ZM297 142.1c0 1.3 1.17 1.68 2.6.85l39.49-22.8a5.22 5.22 0 0 0 2.6-3.84c0-1.3-1.17-1.68-2.6-.85l-39.49 22.8a5.22 5.22 0 0 0-2.6 3.84ZM297 156.17c0 1.3 1.17 1.68 2.6.85l39.49-22.8a5.22 5.22 0 0 0 2.6-3.84c0-1.3-1.17-1.68-2.6-.85l-39.49 22.8a5.22 5.22 0 0 0-2.6 3.84ZM248.29 198.39c0 1.3 1.16 1.68 2.6.85l88.23-51a5.2 5.2 0 0 0 2.6-3.84c0-1.3-1.17-1.67-2.6-.85l-88.23 51a5.19 5.19 0 0 0-2.6 3.84ZM248.29 212.46c0 1.3 1.16 1.68 2.6.85l88.23-50.94a5.22 5.22 0 0 0 2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-88.23 50.94a5.19 5.19 0 0 0-2.6 3.84ZM248.29 226.54c0 1.29 1.16 1.67 2.6.84l88.23-50.94a5.22 5.22 0 0 0 2.6-3.85c0-1.29-1.17-1.67-2.6-.84l-88.23 50.94a5.19 5.19 0 0 0-2.6 3.85Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="m191.42 376.5 28.43-124.29 158.42-91.47-28.43 124.3-158.42 91.46z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M230.18 261.94v92.19l36.56-21.11v-92.18l-36.56 21.1z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M230.18 261.94v92.19l36.56-21.11v-92.18l-36.56 21.1z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M297.07 408.74c-3.11-2.32-33.09-25.23-48.33-58-16.22-34.84-16.2-75.48-16.2-90.77v-.48l108.68-62.74c0 15.43 0 56.13 16.29 91.09 14.89 32 43.72 54.62 48.29 58.08Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M340.72 197.65c0 16.08.31 56 16.34 90.44 14.39 30.9 41.75 53.12 47.84 57.81l-107.8 62.24c-4.21-3.17-33.06-25.7-47.9-57.57C233 315.83 233 275.27 233 260v-.19l107.68-62.17m1-1.73L232 259.24c0 14.39-.3 56.2 16.25 91.75 16.29 34.89 48.75 58.35 48.75 58.35L406.71 346s-32.5-23.46-48.74-58.35c-16.56-35.55-16.25-77.36-16.25-91.75Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M340.72 197.65c0 16.08.31 56 16.34 90.44 14.39 30.9 41.75 53.12 47.84 57.81l-107.8 62.24c-4.21-3.17-33.06-25.7-47.9-57.57C233 315.83 233 275.27 233 260v-.19l107.68-62.17m1-1.73L232 259.24c0 14.39-.3 56.2 16.25 91.75 16.29 34.89 48.75 58.35 48.75 58.35L406.71 346s-32.5-23.46-48.74-58.35c-16.56-35.55-16.25-77.36-16.25-91.75Z"
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <path
        d="m329.31 217.15-34.68 20a4.92 4.92 0 0 0-2.52 3.79c.05 1.27 1.26 1.63 2.69.8l34.69-20A4.92 4.92 0 0 0 332 218c-.06-1.32-1.25-1.68-2.69-.85ZM330.05 230.8l-34.68 20a4.62 4.62 0 0 0-2.42 3.74c.11 1.23 1.37 1.56 2.8.73l34.69-20a4.56 4.56 0 0 0 2.39-3.73c-.1-1.24-1.34-1.54-2.78-.74ZM331.44 244.07l-34.69 20c-1.43.83-2.47 2.48-2.3 3.67s1.48 1.5 2.92.67l34.69-20c1.43-.83 2.45-2.47 2.27-3.66s-1.46-1.51-2.89-.68ZM333.52 256.94l-34.69 20c-1.43.83-2.42 2.45-2.18 3.61s1.6 1.42 3 .59l34.68-20c1.44-.83 2.4-2.44 2.16-3.6s-1.49-1.43-2.97-.6ZM336.34 269.38l-34.69 20c-1.43.83-2.36 2.41-2 3.52s1.73 1.36 3.16.53l34.69-20c1.44-.83 2.34-2.4 2-3.52s-1.73-1.36-3.16-.53ZM340 281.37l-84.54 48.81c-1.43.83-2.3 2.37-1.91 3.44s1.88 1.27 3.31.44l84.54-48.81c1.44-.83 2.27-2.36 1.88-3.44s-1.9-1.27-3.28-.44ZM344.45 292.82l-84.53 48.81c-1.44.83-2.23 2.33-1.75 3.35s2 1.18 3.46.35l84.54-48.81c1.44-.83 2.21-2.33 1.73-3.35s-2.01-1.17-3.45-.35ZM350 303.73l-84.54 48.81c-1.44.83-2.15 2.29-1.58 3.26s2.2 1.07 3.64.24L352 307.23c1.43-.82 2.12-2.27 1.55-3.24s-2.15-1.09-3.55-.26ZM356.47 314.05l-84.53 48.8c-1.44.83-2.08 2.26-1.43 3.17s2.36 1 3.8.16l84.53-48.81c1.44-.83 2.06-2.24 1.41-3.16s-2.34-.99-3.78-.16ZM363.85 323.86l-84.54 48.8c-1.43.83-2 2.22-1.28 3.09s2.49.91 3.93.08L366.5 327c1.43-.83 2-2.2 1.26-3.07s-2.47-.93-3.91-.07ZM372 333.2 287.49 382c-1.44.83-2 2.19-1.16 3s2.62.83 4.05 0l84.54-48.81c1.44-.83 1.94-2.17 1.14-3s-2.6-.81-4.06.01ZM381 342.13l-84.54 48.8c-1.43.83-1.9 2.16-1 2.95s2.74.76 4.18-.07L384.11 345c1.43-.83 1.88-2.13 1-2.93s-2.71-.77-4.11.06Z"
        style={{
          fill: '#455a64',
        }}
      />
      <path
        d="m282.06 244.44-39.88 23c.44 14.45 2.09 35.29 8.13 56.28l39.89-23c-6.05-20.99-7.7-41.84-8.14-56.28Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="m282.06 244.44-39.88 23c.44 14.45 2.09 35.29 8.13 56.28l39.89-23c-6.05-20.99-7.7-41.84-8.14-56.28Z"
        style={{
          fill: '#fff',
          opacity: 0.8,
        }}
      />
      <path
        d="M258.44 264.26a6.59 6.59 0 0 0-1.94-5.06l-14.32 8.26c.12 3.82.32 8.09.67 12.69a9.39 9.39 0 0 0 5.41-1c5.63-2.58 10.18-9.27 10.18-14.89Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M258.44 264.26a6.59 6.59 0 0 0-1.94-5.06l-14.32 8.26c.12 3.82.32 8.09.67 12.69a9.39 9.39 0 0 0 5.41-1c5.63-2.58 10.18-9.27 10.18-14.89Z"
        style={{
          fill: '#fff',
          opacity: 0.6000000000000001,
        }}
      />
      <path
        d="M286.81 286.92c-2.66-3.35-8.07-13.7-10.38-18.21a1 1 0 0 0-1.85.17 109.75 109.75 0 0 0-4.32 26.25 38 38 0 0 1-8.47-9.42.89.89 0 0 0-1.59.28c-4.36 16.79-2.17 33.31-2.17 33.31l32.17-18.58c-1.34-4.64-2.45-9.26-3.39-13.8Z"
        style={{
          fill: '#ba68c8',
        }}
      />
      <path
        d="M286.81 286.92c-2.66-3.35-8.07-13.7-10.38-18.21a1 1 0 0 0-1.85.17 109.75 109.75 0 0 0-4.32 26.25 38 38 0 0 1-8.47-9.42.89.89 0 0 0-1.59.28c-4.36 16.79-2.17 33.31-2.17 33.31l32.17-18.58c-1.34-4.64-2.45-9.26-3.39-13.8Z"
        style={{
          fill: '#fff',
          opacity: 0.6000000000000001,
        }}
      />
    </svg>
  </Box>
);
export default React.memo(FileIllustration);
