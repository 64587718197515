import * as React from 'react';
import { Box } from '@mui/material';
import BackgroundIllustration from './BackgroundIllustration';

const SuccessfullPaymentIllustration = ({ ...other }) => (
  <Box {...other}>
    <svg width="100%" height="100%" viewBox="0 0 480 360" xmlns="http://www.w3.org/2000/svg">
      <BackgroundIllustration />
      <path
        d="M0 327.4h500v.25H0zM401.33 340.46h28.39v.25h-28.39zM368.63 340.58H394v.25h-25.37zM242.47 334.79h34.15v.25h-34.15zM293.56 342.54h47.46v.25h-47.46zM96.89 334.5h45.95v.25H96.89zM148.39 334.5H164v.25h-15.61zM62.46 339h40.21v.25H62.46zM237 282.8H43.91a5.71 5.71 0 0 1-5.7-5.71V5.66A5.71 5.71 0 0 1 43.91 0H237a5.71 5.71 0 0 1 5.71 5.71v271.38a5.71 5.71 0 0 1-5.71 5.71ZM43.91.2a5.46 5.46 0 0 0-5.45 5.46v271.43a5.46 5.46 0 0 0 5.45 5.46H237a5.48 5.48 0 0 0 5.46-5.46V5.66A5.48 5.48 0 0 0 237 .2ZM453.31 282.8h-193.1a5.73 5.73 0 0 1-5.71-5.71V5.66A5.72 5.72 0 0 1 260.21 0h193.1A5.71 5.71 0 0 1 459 5.66v271.43a5.7 5.7 0 0 1-5.69 5.71ZM260.21.2a5.48 5.48 0 0 0-5.46 5.46v271.43a5.48 5.48 0 0 0 5.46 5.46h193.1a5.48 5.48 0 0 0 5.46-5.46V5.66A5.48 5.48 0 0 0 453.31.2Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M71.36 24.27h257v129.56h-257z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M74.71 24.27h257v129.56h-257z"
        style={{
          fill: '#efefef',
        }}
      />
      <path
        d="M80.73 30.34H325.7v117.4H80.73z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="m98.87 147.75 66.44-117.4h31.13l-66.43 117.4H98.87zM140.46 147.75l66.44-117.4h31.13l-66.43 117.4h-31.14zM227 147.75l66.43-117.4h12.82l-66.43 117.4H227z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M236.89 30.34h9.06v117.4h-9.06zM153.79 30.34h9.06v117.4h-9.06z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M240.07 25.83h9.06v125.78h-9.06zM156.97 25.83h9.06v125.78h-9.06z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M323.17 30.34h2.53v117.4h-2.53zM391.84 192.08s-18.34-31.61 7.1-56.77c0 0 6.64 4.32 6.54 10.14s-7.11 7.29-5.61 12.15 8.13 9.62 3.79 13.63.69 7.59.4 12.5-12.22 8.35-12.22 8.35Z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M394.72 197.25s7.43-35.78-24.65-51.64c0 0-4.93 6.2-3 11.69s9.05 4.68 9.15 9.76-4.68 11.69.71 14.13 1.73 7.42 3.56 12 14.23 4.06 14.23 4.06Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M378.39 188.16s.32 14.92 12.66 14.92 12.67-14.92 12.67-14.92Z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M292.76 191.46s.42 11.62 16.65 11.62 16.66-11.62 16.66-11.62ZM330.43 194.34s.32 8.74 12.52 8.74 12.51-8.74 12.51-8.74ZM276.87 210.33h27.32V319.9h-27.32z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M282.11 319.89h134.72v7.51H282.11z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M304.19 210.32h117.89v109.57H304.19z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M314.93 220.92h96.41v38.86h-96.41zM314.93 266.86h96.41v38.86h-96.41z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M374 225.18h-21.69a2.67 2.67 0 0 1-2.67-2.66 2.69 2.69 0 0 1 2.67-2.67H374a2.69 2.69 0 0 1 2.67 2.67 2.67 2.67 0 0 1-2.67 2.66ZM374 271.12h-21.69a2.67 2.67 0 0 1-2.67-2.66 2.69 2.69 0 0 1 2.67-2.67H374a2.69 2.69 0 0 1 2.67 2.67 2.67 2.67 0 0 1-2.67 2.66Z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M304.18 203.08h121.53v7.25H304.18z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M273.04 203.07h31.14v7.25h-31.14z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M360.44 42.51h59.18v68.18h-59.18z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M362.21 42.51h64.13v68.18h-64.13z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M367.58 47.88h53.39v57.43h-53.39z"
        style={{
          fill: '#fff',
        }}
      />
      <rect
        width={27.51}
        height={29.59}
        x={380.52}
        y={61.8}
        rx={4.71}
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M262.22 263.92h6.87v63.48h-6.87z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M265.65 263.92h3.44v63.48h-3.44z"
        style={{
          fill: '#f4f4f4',
          isolation: 'isolate',
          opacity: 0.5,
        }}
      />
      <path
        d="M239 281.7h-4.72a4.66 4.66 0 0 0 1.65-3.56H180a4.66 4.66 0 0 0 1.66 3.56H177a6.3 6.3 0 0 0-6.3 6.3v18.37a6.3 6.3 0 0 0 6.3 6.3h62a6.3 6.3 0 0 0 6.3-6.3V288a6.3 6.3 0 0 0-6.3-6.3Z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <rect
        width={46.09}
        height={17.4}
        x={145.42}
        y={295.27}
        rx={4.24}
        style={{
          fill: '#ebebeb',
        }}
      />
      <rect
        width={46.09}
        height={4.46}
        x={145.42}
        y={291.43}
        rx={1.42}
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M195.76 235.57s.65 21 25.25 21 25.25-21 25.25-21Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M77.11 256.58h30.68v7.34H77.11z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M107.8 256.58h185.1v7.34H107.8z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M77.11 312.67h30.68v7.34H77.11z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M77.12 263.92h6.87v63.48h-6.87z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M80.55 263.92h3.44v63.48h-3.44z"
        style={{
          fill: '#f4f4f4',
          isolation: 'isolate',
          opacity: 0.5,
        }}
      />
      <path
        d="M104.36 263.92h6.87v63.48h-6.87z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M104.36 263.92h3.44v63.48h-3.44z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M286.02 263.92h6.87v63.48h-6.87z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M286.02 263.92h3.44v63.48h-3.44z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M107.8 312.67h185.1v7.34H107.8z"
        style={{
          fill: '#f4f4f4',
        }}
      />
      <path
        d="M119.84 252.25h37.32v4.33h-37.32z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M157.16 252.24h13.37v4.33h-13.37z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <ellipse
        cx={250}
        cy={361.24}
        rx={193.89}
        ry={11.32}
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="M217.87 95.7c.07 11.85-8.42 11.73-8.25 8s-1.93-12.49 0-11.64 8.22-1.6 8.25 3.64ZM212.53 117.16c1.17-.09 3.52 1 3.73 4.09a5.81 5.81 0 0 0 2.59 4.49s-3.89.47-6.44-1.55c-2.08-1.63-3-5.2-1.91-6a5.58 5.58 0 0 1 2.03-1.03ZM211 108.74c2 1.77 3.83 6.45-.68 8.67s-11.6-3-11.6-3l7.79-3.61Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M213.22 117.59a8.87 8.87 0 0 0-.54-1.89.41.41 0 0 0-.53-.24h-.08l-2.77 1.85a.41.41 0 0 0-.11.57.57.57 0 0 0 .11.11 7.1 7.1 0 0 0 1.28.74c.65.26 2-.48 2.46-.8a.38.38 0 0 0 .18-.34Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M213.22 117.59a8.87 8.87 0 0 0-.54-1.89.41.41 0 0 0-.53-.24h-.08l-2.77 1.85a.41.41 0 0 0-.11.57.57.57 0 0 0 .11.11 7.1 7.1 0 0 0 1.28.74c.65.26 2-.48 2.46-.8a.38.38 0 0 0 .18-.34Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="M183.41 112.68c-1.11-.37-3.65.13-4.59 3.09a5.84 5.84 0 0 1-3.58 3.75s3.67 1.38 6.63 0c2.4-1.09 4.1-4.35 3.27-5.39a5.5 5.5 0 0 0-1.73-1.45ZM194.43 92.33c-4.05 1.1-9.5 5.9-7.08 11.79-2.39 1.63-5.45 6.32-1.87 9.32 3.85 3.23 13.32-1.3 13.32-1.3l.07-1.54L200 99.14Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M182.64 112.94a8.6 8.6 0 0 1 1-1.71.42.42 0 0 1 .59-.06l.06.06 2.24 2.45a.41.41 0 0 1 0 .58.25.25 0 0 1-.12.08 6.9 6.9 0 0 1-1.41.41c-.7.1-1.78-.93-2.2-1.35a.43.43 0 0 1-.16-.46Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M182.64 112.94a8.6 8.6 0 0 1 1-1.71.42.42 0 0 1 .59-.06l.06.06 2.24 2.45a.41.41 0 0 1 0 .58.25.25 0 0 1-.12.08 6.9 6.9 0 0 1-1.41.41c-.7.1-1.78-.93-2.2-1.35a.43.43 0 0 1-.16-.46Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="M219.08 134.07c1.91 1.48 4 3.06 6.1 4.5s4.21 2.89 6.38 4.22 4.37 2.57 6.59 3.75l1.67.85 1.7.79c.56.27 1.13.51 1.71.73l.85.35c.29.11.6.25.78.3a19.63 19.63 0 0 0 3.2.69c1.18.17 2.42.29 3.68.38 2.51.16 5.09.23 7.68.24s5.21 0 7.82-.08l7.85-.22.49 3.93a154.85 154.85 0 0 1-15.89 2.35c-2.69.24-5.39.42-8.15.44-1.38 0-2.78 0-4.22-.13a22.23 22.23 0 0 1-4.58-.75c-.46-.12-.75-.24-1.09-.35l-1-.35c-.67-.22-1.33-.46-2-.73l-1.94-.78-1.9-.84a74.77 74.77 0 0 1-7.33-3.8c-2.36-1.39-4.68-2.83-6.92-4.37a78.31 78.31 0 0 1-6.6-5Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M208.7 129.58c2.49-1.94 12.74 4.41 15.86 7.06l-2.27 13.73s-14.75-6.18-15.64-10.4c-.92-4.41.17-8.91 2.05-10.39Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="m274 150.91 6.18-4.79.49 7.41s-4.26 2.64-7.07 1Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="m285.69 145.39 3.16 3.52-8.22 4.62-.49-7.41 5.55-.73zM168.28 351.47l7.79.58 2.84-17.93-7.78-.58-2.85 17.93zM113.17 326.41l6.61 4.16 11.77-13.96-6.61-4.17-11.77 13.97z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="m121.87 329.08-6.19-6.23a.63.63 0 0 0-.82-.06l-5.63 4.21a1.26 1.26 0 0 0-.19 1.77l.09.1c2.19 2.13 3.36 3.05 6.08 5.79 1.67 1.69 3.94 4.32 6.25 6.65s4.73.16 4-1c-3.38-5.27-2.77-7.24-3-9.92a2 2 0 0 0-.59-1.31ZM176.59 350.93h-8.78a.66.66 0 0 0-.65.54l-1.39 6.94a1.18 1.18 0 0 0 .93 1.37 1.31 1.31 0 0 0 .28 0c3.06 0 4.55-.23 8.41-.23 2.37 0 9 .24 12.31.24s3.64-3.24 2.29-3.53c-6-1.32-10.13-3.13-12.1-4.87a1.94 1.94 0 0 0-1.3-.46Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="m178.91 334.12-1.58 9.96-7.68-1.29 1.47-9.24 7.79.57zM124.94 312.45l6.61 4.16-4.87 5.78-6.76-3.99 5.02-5.95z"
        style={{
          fill: '#e77064',
        }}
      />
      <path
        d="m174.2 171.13 31.14 6.07c16.09-43.57 12.59-45 12.59-45a62.06 62.06 0 0 0-7-3.3l-.42-.16c-2-.73-2.4-.84-5.06-1.62-4.58-1.09-8.85-2-14.14-2.75l-1-.14c-1-.12-1.91-.22-2.83-.31-.62-.07-1.24-.11-1.83-.15l-2-.11c-3.42-.17-5.87-.12-5.87-.12.95 27.21-.59 35.02-3.58 47.59Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M195.81 108.74c0 4.72-.73 13.23-4.51 15.65 0 0 .35 4.86 8.8 6.5 9.3 1.81 5.34-3.75 5.34-3.75-4.83-2.2-4-5.94-2.4-9.3Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="m198.76 112.45 4.28 5.39a16.07 16.07 0 0 0-.92 2.42c-1.89-.66-4.14-3.3-4-5.37a6.89 6.89 0 0 1 .64-2.44Z"
        style={{
          fill: '#e77064',
        }}
      />
      <path
        d="M195.36 97.9c-1 7.69-1.61 10.92 1.47 15.57 4.63 7 14.29 6.55 17.58-.68 3-6.51 4.12-18-2.73-22.45a10.57 10.57 0 0 0-14.65 3 10.67 10.67 0 0 0-1.67 4.56Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M220.39 85.72a9.72 9.72 0 0 1-.27 3.64 9.83 9.83 0 0 0-.29-1.19c-2.74-9.06-15.08-6.9-18.8-3-6-1.72-11.71-.35-10.95 7a9.14 9.14 0 0 0 1.95 5.2c-.93 2.58 0 6.69 3.07 6.81 4.32.17 8.42-5.55 10.07-8.69 3 .51 7.32 1 10.6.2.55.33 1 .56 1 .56 3.49-1.87 6.15-3.68 3.62-10.53Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M192.7 103.15a5.71 5.71 0 0 0 1.31 4.12c1.3 1.51 3 .62 3.61-1.17.51-1.62.44-4.35-1.3-5.2a2.54 2.54 0 0 0-3.38 1.22 2.59 2.59 0 0 0-.24 1.03Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M204.87 177.2s.12 61.82-4.94 85.1c-5.26 24.22-20.73 79.32-20.73 79.32l-11.2-1.45s8.77-55.66 12.5-79.73c4.07-26.23 1.58-87.68 1.58-87.68Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M204.87 177.2s.12 61.82-4.94 85.1c-5.26 24.22-20.73 79.32-20.73 79.32l-11.2-1.45s8.77-55.66 12.5-79.73c4.07-26.23 1.58-87.68 1.58-87.68Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="m181.11 342.59-15.03-1.94.64-5.32 17.06 2-2.67 5.26z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M197.12 175.6s-16.77 51.09-25.1 80.52c-8.78 31.05-42.44 65.39-42.44 65.39l-9.65-5.58s23-32.6 31.45-61.57c17.21-58.81 10.14-71.27 22.82-83.23Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M197.12 175.6s-16.77 51.09-25.1 80.52c-8.78 31.05-42.44 65.39-42.44 65.39l-9.65-5.58s23-32.6 31.45-61.57c17.21-58.81 10.14-71.27 22.82-83.23Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="m131.15 323.06-13.41-7.74 3.34-4.78 14.89 8.21-4.82 4.31zM206.46 102.27c-.12.62.11 1.18.52 1.26s.83-.36 1-1-.11-1.19-.52-1.27-.88.39-1 1.01ZM213.45 103.63c-.12.62.11 1.19.52 1.27s.83-.36.95-1-.11-1.19-.52-1.26-.83.36-.95.99Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="m214.22 102.65 1.57-.13s-.99 1.01-1.57.13Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M211.4 104.34a16.73 16.73 0 0 0 1.44 4.37 2.7 2.7 0 0 1-2.29 0Z"
        style={{
          fill: '#fd5652',
        }}
      />
      <path
        d="M207.93 110.71a5.25 5.25 0 0 1-3.89-2.92.18.18 0 0 1 .09-.24.19.19 0 0 1 .24.1 5 5 0 0 0 3.62 2.7.18.18 0 0 1 .15.21.19.19 0 0 1-.21.15ZM204.87 99.61a.39.39 0 0 1-.37-.37.4.4 0 0 1 .12-.27 3.69 3.69 0 0 1 3.25-.93.38.38 0 0 1 .26.46.36.36 0 0 1-.44.26 3 3 0 0 0-2.57.76.36.36 0 0 1-.25.09ZM217.33 101a.36.36 0 0 1-.34-.23A3 3 0 0 0 215 99a.37.37 0 0 1-.3-.43.38.38 0 0 1 .43-.3 3.69 3.69 0 0 1 2.54 2.23.37.37 0 0 1-.19.49Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M207.3 107.75a2 2 0 0 1-1.6-.79 6.33 6.33 0 0 1-.8-5c.57-3.16 2.55-5.73 4.42-5.73a2.08 2.08 0 0 1 1.61.8 6.33 6.33 0 0 1 .8 5c-.57 3.16-2.56 5.72-4.43 5.72Zm2-11.24c-1.75 0-3.63 2.48-4.18 5.52-.35 2-.07 3.8.75 4.77a1.79 1.79 0 0 0 1.41.7c1.76 0 3.63-2.47 4.18-5.51a6 6 0 0 0-.75-4.77 1.77 1.77 0 0 0-1.39-.71ZM215.07 108.75a2.14 2.14 0 0 1-1.67-.84 5.83 5.83 0 0 1-.81-4.63c.51-2.94 2.43-5.32 4.28-5.32a2.18 2.18 0 0 1 1.67.85 5.88 5.88 0 0 1 .81 4.62c-.5 2.95-2.42 5.32-4.28 5.32Zm1.77-10.54c-1.74 0-3.54 2.29-4 5.12a5.69 5.69 0 0 0 .76 4.42 2 2 0 0 0 1.48.75c1.74 0 3.55-2.28 4-5.11a5.74 5.74 0 0 0-.75-4.42 2 2 0 0 0-1.48-.76Z"
        style={{
          fill: '#f9f9f9',
        }}
      />
      <path
        d="M124.17 332.71c-1 0-2-1.54-2.59-2.43a.18.18 0 0 1 0-.2.2.2 0 0 1 .19-.08c.32.07 3.07.67 3.42 1.61a.61.61 0 0 1-.11.62 1 1 0 0 1-.91.48Zm-2.09-2.25c.77 1.16 1.52 1.85 2.06 1.88a.7.7 0 0 0 .6-.33.25.25 0 0 0 .05-.27c-.18-.5-1.61-1-2.71-1.28Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M122.53 330.46a3.94 3.94 0 0 1-.9-.1.21.21 0 0 1-.13-.13.28.28 0 0 1 0-.18c.07-.06 1.6-1.57 2.73-1.57h.08a1 1 0 0 1 .72.38.66.66 0 0 1 .08.81c-.32.54-1.6.79-2.58.79Zm-.45-.39c.94.11 2.46-.16 2.77-.61.05-.07.09-.18-.06-.37a.57.57 0 0 0-.46-.24 4.17 4.17 0 0 0-2.25 1.22ZM179.46 352.3a12.73 12.73 0 0 1-2.46-.3.19.19 0 0 1-.15-.16.19.19 0 0 1 .1-.19c.37-.18 3.58-1.78 4.51-1.24a.61.61 0 0 1 .28.5 1.06 1.06 0 0 1-.39.89 3 3 0 0 1-1.89.5Zm-1.83-.54c1.71.28 3 .21 3.5-.22a.72.72 0 0 0 .25-.6.21.21 0 0 0-.1-.19c-.5-.29-2.28.39-3.65 1Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M177.05 352h-.11a.18.18 0 0 1-.08-.18c0-.14.48-3.32 2.22-3.32h.15c.5.05.64.3.67.51.14.88-1.8 2.58-2.78 3Zm2-3.16c-1.13 0-1.63 1.84-1.8 2.64 1-.59 2.33-1.92 2.25-2.45 0 0 0-.16-.35-.19Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m207.23 101.29 1.57-.14s-.99 1.02-1.57.14Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="M200.36 137.54c-13-4.93-17.06-12.31-16.75-13.86l2 .12h.39a12.31 12.31 0 0 1 1.49.13c.92.08 1.87.18 2.83.31l1 .14a101.47 101.47 0 0 1 14.14 2.75c2.12.62 2.82.82 4 1.24l1 .38.42.16c1.04 2.24.81 6.82-10.52 8.63Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M200.36 137.54c-13-4.93-17.06-12.31-16.75-13.86l2 .12h.39c2.64 3.75 8.42 8.93 14.31 9.64 4.74-1 9.21-2.14 9.17-5.07l1 .38.42.16c1.02 2.24.79 6.82-10.54 8.63Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M200.36 137.54c-13-4.93-17.06-12.31-16.75-13.86l2 .12h.39c2.64 3.75 8.42 8.93 14.31 9.64 4.74-1 9.21-2.14 9.17-5.07l1 .38.42.16c1.02 2.24.79 6.82-10.54 8.63Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="m174.09 169.21-2 2.52c-.15.19.07.46.43.53l32.66 6.36c.28.06.54 0 .59-.21l.82-2.73c.06-.19-.15-.4-.46-.46l-31.54-6.14a.57.57 0 0 0-.5.13Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="m177.64 173.56-.84-.16c-.17 0-.28-.14-.25-.25l1.1-3.54c0-.11.2-.16.36-.13l.85.16c.17 0 .28.15.25.25l-1.1 3.55a.31.31 0 0 1-.37.12Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m177.64 173.56-.84-.16c-.17 0-.28-.14-.25-.25l1.1-3.54c0-.11.2-.16.36-.13l.85.16c.17 0 .28.15.25.25l-1.1 3.55a.31.31 0 0 1-.37.12Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="M179.86 135.61c-.38 2.37-.74 5-1 7.46s-.49 5-.61 7.56q-.1 1.89-.12 3.78v3.75a64.07 64.07 0 0 0 .57 7.32 7.09 7.09 0 0 0 1 2.33 24.76 24.76 0 0 0 2 2.78 62.11 62.11 0 0 0 5.12 5.39c1.83 1.76 3.75 3.46 5.72 5.12s4 3.31 6 4.9l-2.15 3.32a104 104 0 0 1-13.46-8.79 56.81 56.81 0 0 1-6.2-5.5 28 28 0 0 1-2.83-3.41 12.93 12.93 0 0 1-2.21-4.79l-.37-2.12c-.13-.69-.22-1.38-.3-2.06-.2-1.38-.31-2.75-.42-4.12a77.15 77.15 0 0 1-.19-8.19c.08-2.71.23-5.41.5-8.1s.61-5.33 1.15-8.11Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M173.22 125.63c-4.28 3.46-3.26 13.74-3.26 13.74l14.4 4.74S183.7 132.26 183 128c-.86-5.07-5.58-5.78-9.78-2.37Z"
        style={{
          fill: '#1a2e35',
        }}
      />
      <path
        d="m196.78 185.36 6.51-2.42-1 6.19s-4.72 1.7-7.14-.48Z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="M211.04 183.86v4.78l-8.75.49 1-6.19 7.75.92z"
        style={{
          fill: '#fd897b',
        }}
      />
      <path
        d="m202.79 178.46-.84-.16c-.17 0-.28-.14-.25-.25l1.1-3.54c0-.11.2-.16.37-.13l.84.16c.17 0 .28.15.25.25l-1.1 3.55c-.03.1-.16.16-.37.12Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m202.79 178.46-.84-.16c-.17 0-.28-.14-.25-.25l1.1-3.54c0-.11.2-.16.37-.13l.84.16c.17 0 .28.15.25.25l-1.1 3.55c-.03.1-.16.16-.37.12Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="m190.22 176-.85-.16c-.17 0-.28-.14-.24-.25l1.1-3.54c0-.11.19-.16.36-.13l.85.16c.16 0 .28.15.24.25l-1.1 3.55c-.03.12-.19.17-.36.12Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m190.22 176-.85-.16c-.17 0-.28-.14-.24-.25l1.1-3.54c0-.11.19-.16.36-.13l.85.16c.16 0 .28.15.24.25l-1.1 3.55c-.03.12-.19.17-.36.12Z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
      />
      <path
        d="m126.53 234.31 51.84 113.22a10.73 10.73 0 0 0 14.18 5.27l8.23-3.76 190.57-87.25a10.72 10.72 0 0 0 5.28-14.17l-45.81-100-6-13.21a10.75 10.75 0 0 0-14.18-5.27l-198.81 91a10.74 10.74 0 0 0-5.3 14.17Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m126.53 234.31 51.84 113.22a10.73 10.73 0 0 0 14.18 5.27l8.23-3.76 190.57-87.25a10.72 10.72 0 0 0 5.28-14.17l-45.81-100-6-13.21a10.75 10.75 0 0 0-14.18-5.27l-198.81 91a10.74 10.74 0 0 0-5.3 14.17Z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.10000000149011612,
        }}
      />
      <path
        d="M149.45 227.51h240.04v61.36H149.45z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-24.6 269.472 258.187)"
      />
      <path
        d="M149.45 227.51h240.04v61.36H149.45z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
        transform="rotate(-24.6 269.472 258.187)"
      />
      <path
        d="M216.55 236.72h151.69v21.96H216.55z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-24.6 292.395 247.69)"
      />
      <path
        d="M216.55 236.72h151.69v21.96H216.55z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
        transform="rotate(-24.6 292.395 247.69)"
      />
      <path
        d="M135.72 208.94H376.3v32.81H135.72z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-24.78 255.942 225.312)"
      />
      <path
        d="M135.72 208.94H376.3v32.81H135.72z"
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
        transform="rotate(-24.78 255.942 225.312)"
      />
      <path
        d="M156.17 235.73 200.78 349l190.57-87.25a10.72 10.72 0 0 0 5.28-14.17l-45.81-100-188.61 74.28a10.74 10.74 0 0 0-6.04 13.87Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M408.22 199.96h1v4.71h-1z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.36 408.713 202.324)"
      />
      <path
        d="M408.22 199.96h1v4.71h-1z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.10000000149011612,
        }}
        transform="rotate(-19.36 408.713 202.324)"
      />
      <path
        d="M402.63 177.23h1v18.56h-1z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 403.14 186.494)"
      />
      <path
        d="M402.63 177.23h1v18.56h-1z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.10000000149011612,
        }}
        transform="rotate(-19.35 403.14 186.494)"
      />
      <path
        d="m169.44 222.25 206.3-72.45a10.72 10.72 0 0 1 13.63 6.55l41.26 117.49a10.73 10.73 0 0 1-6.54 13.64l-206.3 72.45a10.75 10.75 0 0 1-13.64-6.55l-41.26-117.49a10.73 10.73 0 0 1 6.55-13.64Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m169.44 222.25 206.3-72.45a10.72 10.72 0 0 1 13.63 6.55l41.26 117.49a10.73 10.73 0 0 1-6.54 13.64l-206.3 72.45a10.75 10.75 0 0 1-13.64-6.55l-41.26-117.49a10.73 10.73 0 0 1 6.55-13.64Z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.10000000149011612,
        }}
      />
      <path
        d="M180.88 239.55h240.04v54.17H180.88z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 300.896 266.637)"
      />
      <path
        d="M180.88 239.55h240.04v54.17H180.88z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
        transform="rotate(-19.35 300.896 266.637)"
      />
      <path
        d="M331.12 184.44h49.62v7.73h-49.62z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 355.928 188.314)"
      />
      <path
        d="M331.12 184.44h49.62v7.73h-49.62z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
        transform="rotate(-19.35 355.928 188.314)"
      />
      <path
        d="M371.4 272.23h39.81v4.04H371.4z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 391.32 274.236)"
      />
      <path
        d="M371.4 272.23h39.81v4.04H371.4z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
        transform="rotate(-19.35 391.32 274.236)"
      />
      <path
        d="M362.8 281.43h51.3v4.04h-51.3z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 388.448 283.465)"
      />
      <path
        d="M362.8 281.43h51.3v4.04h-51.3z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
        transform="rotate(-19.35 388.448 283.465)"
      />
      <path
        d="M215.91 332.73h25.65v8.69h-25.65z"
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 228.735 337.09)"
      />
      <path
        d="M215.91 332.73h25.65v8.69h-25.65z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
        transform="rotate(-19.35 228.735 337.09)"
      />
      <path
        d="m368.79 258.5 28.26-9.92a6 6 0 0 0 3.66-7.61 6 6 0 0 0-7.61-3.66l-28.27 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.62 3.65ZM320.38 275.5l28.27-9.93a6 6 0 0 0 3.65-7.57 6 6 0 0 0-7.61-3.65l-28.26 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.61 3.61ZM272 292.5l28.27-9.93a6 6 0 0 0 3.63-7.57 6 6 0 0 0-7.61-3.65L268 281.24a6 6 0 0 0-3.65 7.61 6 6 0 0 0 7.65 3.65ZM223.57 309.5l28.27-9.93a6 6 0 0 0 3.66-7.57 6 6 0 0 0-7.61-3.65l-28.27 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.61 3.61Z"
        style={{
          fill: '#407bff',
        }}
      />
      <g
        style={{
          opacity: 0.800000011920929,
        }}
      >
        <path
          d="m368.79 258.5 28.26-9.92a6 6 0 0 0 3.66-7.61 6 6 0 0 0-7.61-3.66l-28.27 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.62 3.65ZM320.38 275.5l28.27-9.93a6 6 0 0 0 3.65-7.57 6 6 0 0 0-7.61-3.65l-28.26 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.61 3.61ZM272 292.5l28.27-9.93a6 6 0 0 0 3.63-7.57 6 6 0 0 0-7.61-3.65L268 281.24a6 6 0 0 0-3.65 7.61 6 6 0 0 0 7.65 3.65ZM223.57 309.5l28.27-9.93a6 6 0 0 0 3.66-7.57 6 6 0 0 0-7.61-3.65l-28.27 9.93a6 6 0 0 0-3.66 7.61 6 6 0 0 0 7.61 3.61Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <rect
        width={34.86}
        height={24.02}
        x={357.45}
        y={208}
        rx={4.1}
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-19.35 374.881 220.006)"
      />
      <rect
        width={34.86}
        height={24.02}
        x={357.45}
        y={208}
        rx={4.1}
        style={{
          isolation: 'isolate',
          opacity: 0.30000001192092896,
        }}
        transform="rotate(-19.35 374.881 220.006)"
      />
      <path
        d="M231.68 232a14.45 14.45 0 0 0-18.43-8.86l-20.32 7.63-.26.07-.6.25-.37.14a14 14 0 0 0 10.31 26l19.24-6.4a16 16 0 0 0 1.64-.46 12 12 0 0 0 1.17-.48 14.44 14.44 0 0 0 7.62-17.89Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M231.68 232a14.45 14.45 0 0 0-18.43-8.86l-20.32 7.63-.26.07-.6.25-.37.14a14 14 0 0 0 10.31 26l19.24-6.4a16 16 0 0 0 1.64-.46 12 12 0 0 0 1.17-.48 14.44 14.44 0 0 0 7.62-17.89Z"
        style={{
          fill: '#fff',
          isolation: 'isolate',
          opacity: 0.800000011920929,
        }}
      />
    </svg>
  </Box>
);
export default React.memo(SuccessfullPaymentIllustration);
