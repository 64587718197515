import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { getAuthenticatedUserFromCookies } from '../utils/cookies';
import { BASE_URL } from '../config-global';

class WebSocketService {
  constructor(route, onMessageReceivedCallback) {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const port = 9000;
    this.username = cookies.username;

    this.route = route;
    this.client = new Client({
      webSocketFactory: () => new SockJS(`${BASE_URL}:${port}/ws?token=${token}`),
      onConnect: () => {
        const onMessageReceived = (message) => {
          if (onMessageReceivedCallback) {
            onMessageReceivedCallback(JSON.parse(message.body));
          }
        };
    
          this.client.subscribe(`/user/${this.username}${this.route}`, onMessageReceived);
          console.log(`Connected to WebSocket on route: ${this.route}`);
    
      },
      onDisconnect: () => {
        console.log(`Disconnected from WebSocket on route: ${this.route}`);
      },
    });
  }

  connect() {
    // Removed 'username' parameter
    this.client.activate();
  }

  disconnect() {
    this.client.deactivate();
  }
}

export default WebSocketService;
