import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  isLoadingDownload: false,
  error: null,
  files: [],
};

const slice = createSlice({
  name: 'fileManager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SET LOADING DELETE
    setLoadingDelete(state, action) {
      state.isLoadingDelete = action.payload;
    },

    // SET LOADING DOWNLOAD
    setLoadingDownload(state, action) {
      state.isLoadingDownload = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FILES AND SET THEM TO THE STATE
    setFiles(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // ADD FOLDER
    addFolder(state, action) {
      state.files.folders.push(action.payload);
    },

    // ADD FILE
    addFile(state, action) {
      const arr = action.payload;
      arr.forEach((file) => state.files.files.push(file));
    },

    // DELETE FILE/FOLDER
    deleteFile(state, action) {
      state.isLoadingDelete = false;
      const { fileName, type } = action.payload;
      if (type === 'folder') {
        state.files.folders = state.files.folders.filter(
          (folder) => folder.folderName !== fileName
        );
      } else {
        state.files.files = state.files.files.filter((file) => file.fileName !== fileName);
      }
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFilesRedux(path) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = await axios
      .get(`${BASE_URL}:9000/fileManager?path=Mastergaz/${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setFiles(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function addFolderRedux(path, folderName) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const res = await axios
      .post(
        `${BASE_URL}:9000/fileManager/folder?path=Mastergaz/${
          path !== '' ? `${path}/${folderName}` : folderName
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addFolder({ folderName }));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return res;
  };
}

// ----------------------------------------------------------------------

export function addFileRedux(path, formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(
        `${BASE_URL}:9000/fileManager/files?path=Mastergaz/${path ? `${path}/` : ''}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json', // Update the Accept header to the desired media type
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addFile(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function deleteFileRedux(path, fileName, type) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoadingDelete(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const encodedFileName = encodeURIComponent(fileName);
    const folderUrl = path ? `${path}/${encodedFileName}/` : `${encodedFileName}/`;
    const fileUrl = path ? `${path}/${encodedFileName}` : `${encodedFileName}`;

    const res = axios
      .delete(
        `${BASE_URL}:9000/fileManager/${type}?path=Mastergaz/${
          type === 'folder' ? folderUrl : fileUrl
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.deleteFile({ fileName, type }));
        dispatch(slice.actions.setLoadingDelete(false));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.setLoadingDelete(false));
        return error;
      });

    return res;
  };
}

// ----------------------------------------------------------------------

export async function getFilesForEmail(ticketNumber) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = await axios
    .get(`${BASE_URL}:9000/fileManager/folders-with-files?ticketNumber=${ticketNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}
