import { createAction, createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import Cookies from 'js-cookie';
import emailAxiosInstance from '../../utils/axios';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  mails: [],
  mailsCount: 0,
  labels: [],
  searchValue: '',
};

export const resetState = createAction('mail/resetState');

const url = `${BASE_URL}:9000/mail`;

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;
      state.isLoading = false;
      state.mails = mails.emails;
      state.mailsCount = mails.count;
    },

    // GET MAIL BY TITLE
    returnMailByTitle(state, action) {
      const title = action.payload;
      const foundMailByTitle = state.mails.find((mail) => mail.title === title);
      return foundMailByTitle;
    },

    // DELETE MAIL BY ID
    deleteMailById(state, action) {
      const id = action.payload;
      const index = state.mails.findIndex((mail) => mail.id === id);
      state.mails.splice(index, 1);
      state.isLoading = false;
    },

    // SET THE SEARCH VALUE
    searchValue(state, action) {
      const value = action.payload;
      state.searchValue = value;
      state.isLoading = false;
    },

    // MARK EMAIL SEEN
    markAndSeen(state, action) {
      const id = action.payload;
      const updatedMails = state.mails.map((e) => {
        if (e.id === id) {
          return {
            ...e,
            seen: true,
          };
        }
        return e;
      });
      state.mails = updatedMails;
    },

    // SEND REPLY
    sendReply(state, action) {
      const values = action.payload;
      const findMailById = state.mails.find((mail) => mail.messageID === values.messageID);
      findMailById.replies.push(values);
    },

    // RESET EMAILS COUNT
    resetState(state) {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getMails(folder, page = 0) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = Cookies.get('authenticatedUser')
      ? JSON.parse(Cookies.get('authenticatedUser'))
      : '';

    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      const response = await axiosCall.get(`/${folder}?page=${page}`);
      dispatch(slice.actions.getMailsSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      const status = JSON.stringify(error.response.status);
      return status;
    }
  };
}

// ----------------------------------------------------------------------

export function getMailByTitle(array, id) {
  return array.find((mail) => mail.id === id);
}

// ----------------------------------------------------------------------

export function deleteEmail(emailId, email, folderName) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await emailAxiosInstance.post('/delete', {
        emailId,
        userId: emailAxiosInstance.defaults.headers.userId,
        email,
        folderName,
      });
      dispatch(slice.actions.deleteMailById(emailId));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function setSearchValue(value) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = Cookies.get('authenticatedUser')
      ? JSON.parse(Cookies.get('authenticatedUser'))
      : '';

    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      const response = await axiosCall.get(`/searchQuery=${value}`);
      console.log(`search ${response}`);
      dispatch(slice.actions.getMailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
    // dispatch(slice.actions.searchValue(value));
  };
}

// ----------------------------------------------------------------------

export async function sendEmail(formData) {
  try {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await axiosCall.post('/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

// ----------------------------------------------------------------------

export function markAsSeen(objectId, folderName, email) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      dispatch(slice.actions.markAndSeen(objectId));
      const response = await axiosCall.post('/markAsSeen', {
        objectId,
        userId: emailAxiosInstance.defaults.headers.userId,
        folderName,
        email,
      });
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function sendReply(formData, object) {
  return async (dispatch) => {
    try {
      const response = await emailAxiosInstance.post('/reply', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(slice.actions.sendReply(object));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function addMail(type, host, port, username, password, smtpAuth, smtpTtls) {
  return async (dispatch) => {
    try {
      const emailType = type.toLowerCase();
      const response = await emailAxiosInstance.post(`/${emailType}/add`, {
        host,
        port,
        username,
        password,
        smtpAuth,
        smtpTtls,
      });
      return response;
    } catch (e) {
      dispatch(slice.actions.hasError(e));
      console.log(`eroare in mail slice: ${e.response.data.message}`);
      console.log(`eroare in mail slice: ${e.response.status}`);
      throw e;
    }
  };
}
