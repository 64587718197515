import { Box } from '@mui/material';
import * as React from 'react';

const NoteIllustration = (props) => (
  <Box {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="a">
          <path
            d="M79.63 287.15s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7.3-30.57 8.66-38.56 6.85-93.12 6.85-93.12.21-1.81-3.44-3.42c-3-1.35-17.72-2-22.48-2.16a5 5 0 0 0-5.05 3.68s0 .08 0 .11c-.91 3.67-14.58 94.22-14.58 94.22Z"
            style={{
              fill: '#92e3a9',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="b">
          <path
            d="M107.57 290.14c-4.27 0-22.66-1.94-25.51-2.55a22.36 22.36 0 0 1-2.4-.64v.2s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7-29 8.54-37.86c-3.6 1.87-7.16 1.57-10.67 1.6Z"
            style={{
              fill: '#263238',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="m361.16 449.91-49.52-85.6s-5.54-38.89-17.35-54.54-28.37-27.07-18.76-35.07 34-23.3 39-22.32 38.66 18 48.17 20.75 12.84 6.79 11.42 15-2 65.78.81 73.73S409 426.47 409 426.47"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
          />
        </clipPath>
        <clipPath id="d">
          <path
            d="M333.94 306.44s-11.34 2.66-16.41 1.46-15.42-15.76-22.53-20.55-11.44-5.93-18.88-7.05-12.58 2.47-11 9.28 10.23 15.06 16.54 20.52 12.33 14.8 16.07 25.65 6.08 20.82 14.4 31.78 18.7 15.47 22.19 16.08"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
          />
        </clipPath>
        <clipPath id="e">
          <path
            d="m374 285.55 1.36-4.39c1.36-4.38.33-10.84-3.4-14.51s-17-10.13-20.88-13.52S318.16 229 313 230.27s-11.37 8.65-16.63 12.75-24.12 19.54-24.12 19.54S259 268.3 259.6 271s3.35 6.15 11.66 5.88 22.16-8.55 24.26-9.77 15.17-9.78 15.17-9.78a45.78 45.78 0 0 0 11.52 8.3c6.17 2.86 7 3.15 7 3.15s1.61 5.85 6.14 8.85"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
            }}
          />
        </clipPath>
      </defs>
      <path
        d="M377.7 228.66s3.47-11.75 15.24-36.61c16.11-34-7.05-72.26-52.94-92.21-53.8-23.37-123-14.66-172.91-.38-41.26 11.82-58.31 42.68-65.73 73.44-3.77 15.59-4.48 31.53-6.86 47.33-3.16 21.06-10.06 42.45-16.1 63.08-7.29 24.92-14.53 53.06-4.23 80 31.73 82.86 206.09 86.44 274.7 48.09 38.79-21.67 53.08-53.54 40-90.73-9.71-27.55-19.13-65.15-11.17-92.01Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M377.7 228.66s3.47-11.75 15.24-36.61c16.11-34-7.05-72.26-52.94-92.21-53.8-23.37-123-14.66-172.91-.38-41.26 11.82-58.31 42.68-65.73 73.44-3.77 15.59-4.48 31.53-6.86 47.33-3.16 21.06-10.06 42.45-16.1 63.08-7.29 24.92-14.53 53.06-4.23 80 31.73 82.86 206.09 86.44 274.7 48.09 38.79-21.67 53.08-53.54 40-90.73-9.71-27.55-19.13-65.15-11.17-92.01Z"
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <path
        d="M334.92 156.19a1 1 0 1 0-.29-1.89l-3.79.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.8.6-.81-5.23-11.38-10.28-149.07 274 11.42 7.72 186.74-29.1-2.08-13.4 3.79-.59a1 1 0 1 0-.29-1.88l-3.8.59-.11-.73 3.8-.59a.94.94 0 0 0 .79-1.08 1 1 0 0 0-1.09-.8l-3.79.59-1.1-7.05 3.8-.59A1 1 0 0 0 367 362l-3.79.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.8.59-1.09-7 3.79-.6a.93.93 0 0 0 .79-1.08 1 1 0 0 0-1.08-.8l-3.8.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.79.59-1.1-7 3.79-.59a1 1 0 0 0-.29-1.89l-3.79.59-.12-.72 3.8-.59a1 1 0 0 0 .79-1.09.93.93 0 0 0-1.08-.79l-3.8.59-1.09-7 3.79-.59a1 1 0 0 0 .79-1.09.93.93 0 0 0-1.08-.79l-3.8.59-.11-.73 3.79-.59a1 1 0 1 0-.29-1.88l-3.79.59-1.1-7.05 3.79-.59a1 1 0 1 0-.29-1.88l-3.79.59-.12-.73 3.8-.59a1 1 0 1 0-.3-1.88l-3.79.59-1.1-7.05 3.8-.59a1 1 0 1 0-.3-1.88l-3.79.59-.11-.73 3.79-.59a1 1 0 0 0 .8-1.08 1 1 0 0 0-1.09-.8l-3.8.59-1.09-7 3.79-.59a1 1 0 0 0 .8-1.08 1 1 0 0 0-1.09-.8l-3.79.59-.12-.72 3.8-.59a1 1 0 0 0-.3-1.89l-3.79.59-1.1-7 3.8-.59a1 1 0 0 0-.3-1.89l-3.79.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.8.6-1.09-7 3.79-.59a1 1 0 0 0-.29-1.89l-3.8.59-.11-.72 3.79-.59a1 1 0 1 0-.29-1.88l-3.79.59-1.1-7.05 3.79-.59a1 1 0 1 0-.29-1.88l-3.79.59-.11-.73 3.79-.59a1 1 0 0 0-.29-1.88l-3.8.59-1.09-7 3.79-.59a1 1 0 0 0-.29-1.88l-3.8.59-.11-.73 3.79-.59a1 1 0 0 0 .8-1.08 1 1 0 0 0-1.09-.8l-3.79.59-1.1-7.05 3.79-.59a1 1 0 1 0-.29-1.88l-3.79.59-.12-.72 3.8-.59a1 1 0 0 0-.3-1.89l-3.79.59-1.1-7 3.8-.6a1 1 0 0 0-.29-1.88l-3.8.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.79.59-1.1-7 3.79-.59a1 1 0 1 0-.29-1.89l-3.79.59-.12-.72 3.8-.59a1 1 0 1 0-.3-1.88l-3.79.59-1.1-7 3.8-.59a1 1 0 0 0-.3-1.88l-3.79.59-.11-.73 3.79-.59a1 1 0 1 0-.29-1.88l-3.8.59-1.09-7.05 3.79-.59a1 1 0 1 0-.29-1.88l-3.8.59-.11-.73 3.8-.59a1 1 0 0 0-.3-1.88l-3.79.59-1.1-7.05 3.8-.59a1 1 0 0 0-.3-1.88l-3.79.59-.11-.73 3.79-.59a1 1 0 0 0 .8-1.08 1 1 0 0 0-1.09-.8l-3.8.59-1.09-7.05 3.79-.59a1 1 0 0 0 .8-1.08 1 1 0 0 0-1.09-.8l-3.8.59-.11-.72 3.79-.59a1 1 0 0 0-.29-1.89l-3.79.59-1.1-7Z"
        style={{
          opacity: 0.30000000000000004,
        }}
      />
      <path
        d="m175.2 413.39 173.15-26.94-38.64-248.35-173.16 27a8.09 8.09 0 0 0-6.75 9.24l36.2 232.3a8.08 8.08 0 0 0 9.2 6.75Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M163.73 377.93h10a2.65 2.65 0 0 1 2.65 2.65v22.6a2.65 2.65 0 0 1-2.65 2.65h-10 0v-27.9h0Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        transform="rotate(171.15 170.037 391.888)"
      />
      <path
        d="M159.22 348.94h10a2.65 2.65 0 0 1 2.65 2.65v22.6a2.65 2.65 0 0 1-2.65 2.65h-10 0v-27.9h0Z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        transform="rotate(171.15 165.53 362.903)"
      />
      <path
        d="M154.71 320h10a2.65 2.65 0 0 1 2.65 2.65v22.6a2.65 2.65 0 0 1-2.65 2.65h-10 0V320h0Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        transform="rotate(171.15 161.017 333.919)"
      />
      <path
        d="M150.2 291h10a2.65 2.65 0 0 1 2.65 2.65v22.6a2.65 2.65 0 0 1-2.65 2.65h-10 0V291h0Z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
        transform="rotate(171.15 156.51 304.935)"
      />
      <path
        d="m146.24 262.72 5.91-.92-15.07-96.8 181.16-28.19 38.64 248.34-181.15 28.16-19.29-123.94-5.91.92a2.64 2.64 0 0 1-3-2.21L144 265.75a2.65 2.65 0 0 1 2.24-3.03Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m165.61 217.88.6 13.57 67.6-11.03-1.77-11.36-66.43 8.82z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M308.17 152a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m312 150.9 10.58-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.79L311.74 149a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.06.81ZM312.44 153.5l10.56-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.1.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M310 163.53a3.54 3.54 0 1 0 3-4 3.54 3.54 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m313.83 162.43 10.57-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.79ZM314.23 165l10.58-1.64a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M311.76 175.07a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m315.62 174 10.58-1.64a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79ZM316 176.58l10.57-1.65a1 1 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.08h0a1 1 0 0 0 1.09.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M313.55 186.61a3.54 3.54 0 1 0 3-4 3.54 3.54 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m317.42 185.51 10.58-1.65a1 1 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.58 1.65a.94.94 0 0 0-.79 1.08h0a1 1 0 0 0 1.08.8ZM317.82 188.12l10.58-1.65a.94.94 0 0 0 .79-1.09h0a.94.94 0 0 0-1.08-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.08.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M315.35 198.15a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m319.21 197.05 10.58-1.65a1 1 0 0 0 .79-1.09h0a.94.94 0 0 0-1.08-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.08.8ZM319.62 199.66l10.57-1.66a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.57 1.64a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.81Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M317.14 209.69a3.54 3.54 0 1 0 3-4.05 3.54 3.54 0 0 0-3 4.05Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m321 208.59 10.57-1.65a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.57 1.64a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.8ZM321.42 211.19l10.58-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.08.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M318.94 221.22a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m322.8 220.12 10.58-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a.93.93 0 0 0 1.08.79ZM323.21 222.73l10.58-1.64a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M320.74 232.76a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m324.6 231.66 10.58-1.66a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.81ZM325 234.27l10.57-1.65a1 1 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M322.53 244.3a3.54 3.54 0 1 0 3-4 3.54 3.54 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m326.4 243.2 10.6-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.06.79ZM326.8 245.81l10.58-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.79l-10.57 1.65a1 1 0 0 0-.8 1.08h0a1 1 0 0 0 1.09.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M324.33 255.84a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m328.19 254.74 10.58-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.79l-10.57 1.65a1 1 0 0 0-.8 1.08h0a1 1 0 0 0 1.09.8ZM328.6 257.35l10.57-1.65a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M326.12 267.38a3.54 3.54 0 1 0 3-4.05 3.54 3.54 0 0 0-3 4.05Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m330 266.28 10.57-1.65a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.8ZM330.39 268.88l10.61-1.64a1 1 0 0 0 .79-1.09h0a.93.93 0 0 0-1.08-.79L330.1 267a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M327.92 278.91a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m331.78 277.82 10.58-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.08-.79l-10.58 1.64a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.8ZM332.19 280.42l10.57-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M329.71 290.45a3.54 3.54 0 1 0 3-4 3.55 3.55 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m333.58 289.35 10.57-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79ZM334 292l10.58-1.64a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.08-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a.94.94 0 0 0 1.08.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M331.51 302a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m335.37 300.89 10.63-1.64a.94.94 0 0 0 .79-1.09h0a1 1 0 0 0-1.08-.8L335.08 299a.94.94 0 0 0-.79 1.09h0a.94.94 0 0 0 1.08.8ZM335.78 303.5l10.57-1.65a.94.94 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.08h0a1 1 0 0 0 1.09.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M333.3 313.53a3.54 3.54 0 1 0 3-4 3.55 3.55 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m337.17 312.43 10.57-1.65a1 1 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.08h0a1 1 0 0 0 1.09.8ZM337.58 315l10.57-1.65a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M335.1 325.07a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m339 324 10.57-1.65a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.8ZM339.37 326.58l10.63-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.79l-10.57 1.64a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.04.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M336.9 336.61a3.54 3.54 0 1 0 3-4.05 3.54 3.54 0 0 0-3 4.05Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m340.76 335.51 10.58-1.65a1 1 0 0 0 .79-1.09h0A1 1 0 0 0 351 332l-10.57 1.64a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.13.78ZM341.17 338.11l10.57-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M338.69 348.14a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m342.56 347 10.57-1.64a1 1 0 0 0 .8-1.09h0a.94.94 0 0 0-1.09-.79l-10.58 1.64a1 1 0 0 0-.79 1.09h0a.94.94 0 0 0 1.09.79ZM343 349.65l10.54-1.65a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.13.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M340.49 359.68a3.54 3.54 0 1 0 2.95-4 3.54 3.54 0 0 0-2.95 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m344.35 358.58 10.58-1.64a1 1 0 0 0 .79-1.09h0a1 1 0 0 0-1.09-.8l-10.57 1.65a1 1 0 0 0-.8 1.09h0a1 1 0 0 0 1.09.79ZM344.76 361.19l10.57-1.65a1 1 0 0 0 .8-1.08h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.79Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M342.28 371.22a3.54 3.54 0 1 0 3-4 3.54 3.54 0 0 0-3 4Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m346.15 370.12 10.57-1.64a1 1 0 0 0 .8-1.09h0a1 1 0 0 0-1.09-.8l-10.58 1.65a1 1 0 0 0-.79 1.09h0a1 1 0 0 0 1.09.79ZM346.55 372.73l10.58-1.65a1 1 0 0 0 .79-1.09h0a.94.94 0 0 0-1.08-.79l-10.58 1.65a.93.93 0 0 0-.79 1.08h0a1 1 0 0 0 1.08.8Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <g
        style={{
          opacity: 0.30000000000000004,
        }}
      >
        <path
          d="m191.08 175.51 2.63-.4M196.34 174.7l2.63-.41M165.38 183.22l2.62-.41M170.63 182.4l2.63-.41M175.89 181.59l2.62-.41M181.14 180.77l2.63-.41M186.39 179.95l2.63-.41M191.65 179.13l2.62-.41M196.9 178.31l2.63-.4M165.94 186.84l2.63-.41M171.19 186.02l2.63-.41M176.45 185.2l2.63-.4M181.7 184.39l2.63-.41M186.96 183.57l2.62-.41M192.21 182.75l2.63-.41M197.46 181.93l2.63-.41M166.5 190.46l2.63-.41M171.76 189.64l2.62-.41M177.01 188.82l2.63-.41M182.27 188.01l2.62-.41M187.52 187.19l2.63-.41M192.77 186.37l2.63-.41M198.03 185.55l2.62-.41M167.07 194.08l2.62-.41M172.32 193.26l2.63-.41M177.57 192.44l2.63-.41M182.83 191.62l2.63-.4M188.08 190.81l2.63-.41M193.34 189.99l2.62-.41M198.59 189.17l2.63-.41M164.07 174.85l2.63-.41M169.33 174.03l2.63-.4M174.58 173.22l2.63-.41M179.84 172.4l2.62-.41M185.09 171.58l2.63-.41M190.35 170.76l2.62-.4M195.6 169.95l2.63-.41M198.77 171.54l-34.6 5.38M173.77 168.71l13.35-2.08M157.19 206.03l147.23-22.91M159.04 217.89l147.22-22.91M160.88 229.74l147.23-22.91M162.73 241.6l147.22-22.91M164.57 253.46l147.23-22.91M166.42 265.32l147.22-22.92M168.26 277.17l147.23-22.91M170.11 289.03l147.22-22.91M171.95 300.89l147.23-22.91M173.8 312.75l147.22-22.92M175.64 324.6l147.23-22.91M177.49 336.46l147.23-22.91M179.33 348.32l147.23-22.91M183.72 376.48l147.22-22.91M185.18 385.87l147.22-22.92M186.64 395.25l147.22-22.91"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'round',
            strokeMiterlimit: 10,
          }}
        />
      </g>
      <path
        d="M178.19 217.44s-12.56 13.59-5.3 11.45 17.19-16.34 15.08-10.44-2 9.92 1.41 5.85 5.32-4.88 4.63-2.75 2.13.68 2.13.68 7-4.12 6.27-2-1.17 2.21.81 1.9l6.42-1s-.42.58 5.51-.35 8.24-2.29 11.21-2.75M172.49 236s9.68-6.06 10.9-4.73-.45 3.61 3.92 2.43 8.35-4.84 8.66-2.87 3.12.53 3.12.53l2.47-.39s4.52-.19 5.93-.92 2.87-7.53 1.79 1.75-4.87 1.26 4.14-2.67 10.68.36 10.68.36a13.81 13.81 0 0 0 2.89-1c1.89-.81 3.56-3.09 5.35-1.34s5-3.81 5-3.81a29.32 29.32 0 0 1 3.69.94 8.64 8.64 0 0 0 3.61.45 27.42 27.42 0 0 0 4.52-.2c2-.31 3.65-2.59 5.28-1.83s4.1-6.21 2.86 2.08-9.26 12.07-6 7a41.51 41.51 0 0 1 10.89-11.31c3.65-2.59 4.71-2.25 5.51-.35s1.79 1.74 3.69.94.27-1.56 1.9-.8a4.75 4.75 0 0 0 3.61.45c1.49-.23-5.46 3.89 1.49-.23s9.43-20.7 5.79-11.53-2.26 11.49 2.61 10.22"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M173.54 252.57s11.88-11.46 11.15-12.87-4.52.2-7.29 5.18-.82 7.72 6.63 3.53-3.39 1 4.26 1.36 9.91-4.58 10.14-3.1-2.32 1.37 3.69 1 3.07-3 9.23-2.45 8.9-1.39 8.9-1.39l3.95-.61s-8 .73 2.39-.88 14.95-14.47 9.95-7.62-2.65 9 2.48 6.19l5.13-2.82s6.48-7.08 3.42 2.5-6.68 2.56 4.71-2.25 11.1-.21 14.9-1.81 3.18-5.56 5.7-2.41 2.36 2.17 6.31 1.55 2.89-1 6.35-1.49L293 233M175.54 288.19s8.28-5.34 9.6-13.14-5.21 2.33-4.5 13.35-3.17-4.06 8.38-7.88-1 3.2.28 5 10.75-5.72 10.64-3.18 2 3.23 7.82-1.72-3.65-4-4.1-.37 2.36 2.16 9.54-.48.15 1 4.6.3 14.24-9.3 12.31-5.46-.07 6.09 2.09 3.73 6.76-8.65 7.14-6.18a6 6 0 0 1-.89 4.19"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M129.13 194.09a6 6 0 0 0-.62-.57 9.32 9.32 0 0 0-2.07-1.22l-.44-.16h-.11c-3.94-1.22-17.41-1.8-21.93-2a5 5 0 0 0-5 3.69v.11c-.96 3.67-14.66 94.21-14.66 94.21s-.1 2-.24 5.13c-.06 1.37-.12 3-.2 4.65v1.3c0 .88-.07 1.78-.11 2.69-.13 3.21-.25 6.59-.34 9.68-.07 2.2-.12 4.26-.15 6v3.47a51.27 51.27 0 0 0 .35 6.27c.39 2.58 1.29 3.77 3.51 4.16.55.1 1.35.22 2.32.34l.6.07 2.75.3.76.07 2.35.19 1.62.1 1.63.08c1.07.05 2.13.07 3.14.07a30.85 30.85 0 0 0 4.4-.25h.13l.37-.07.41-.1h.08l.41-.13.69-.26.63-.26a12.34 12.34 0 0 0 2.41-1.29 8.17 8.17 0 0 0 .7-.56q.32-.28.6-.6l.26-.31a13.41 13.41 0 0 0 1-1.44 1.17 1.17 0 0 0 .12-.25l.06-.15.1-.26a2.09 2.09 0 0 1 .08-.23c0-.11.06-.2.1-.32l.09-.28c0-.13.07-.25.11-.39s.07-.22.1-.33.08-.3.13-.46.07-.27.11-.4.08-.32.13-.5.08-.3.12-.45.09-.36.14-.56l.12-.47.15-.61c0-.17.09-.36.14-.54l.15-.64c.05-.18.09-.38.14-.57s.11-.45.16-.68l.15-.61c0-.23.11-.46.16-.7s.1-.44.16-.66.11-.48.16-.73a1.6 1.6 0 0 0 0-.21c0-.14.07-.28.1-.42s.12-.54.19-.82.09-.42.14-.64.12-.55.19-.83l.15-.66.18-.83c0-.24.11-.49.16-.73l.18-.79c0-.25.11-.51.16-.76l.18-.79c0-.12 0-.24.07-.36s.07-.28.1-.42.11-.52.16-.78.11-.51.17-.77.11-.54.17-.8.11-.52.17-.78.1-.5.16-.75.11-.55.17-.83l.15-.72c.06-.26.11-.51.16-.76s.11-.53.17-.79l.15-.75c0-.23.1-.46.14-.69s.07-.34.11-.51l.06-.29c0-.22.09-.44.13-.66s.11-.5.16-.75.08-.43.13-.64l.15-.77c0-.17.07-.35.1-.52l.12-.58v-.22c0-.08.07-.33.1-.5l.15-.73.09-.5c.05-.23.09-.46.13-.67l.09-.44.12-.67c0-.12 0-.23.07-.34l.12-.65.06-.32c0-.2.07-.39.1-.57 0-.24.08-.48.12-.69.17-1 .4-3.21.68-6.3.11-1.16.22-2.44.34-3.84.43-5.1.93-11.66 1.46-18.86 0-.65.09-1.31.14-2 2-27.22 4.22-62.15 4.22-62.15a2.35 2.35 0 0 0-.65-1.72Z"
        style={{
          opacity: 0.30000000000000004,
        }}
      />
      <path
        d="M79.63 287.15s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7.3-30.57 8.66-38.56 6.85-93.12 6.85-93.12.21-1.81-3.44-3.42c-3-1.35-17.72-2-22.48-2.16a5 5 0 0 0-5.05 3.68s0 .08 0 .11c-.91 3.67-14.58 94.22-14.58 94.22Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <g
        style={{
          clipPath: 'url(#a)',
        }}
      >
        <path
          d="M101.37 331.53a9 9 0 0 0 2-.42c3.84-1.38 5-2.6 6.28-4.71s7.3-30.57 8.66-38.56 6.85-93.12 6.85-93.12.21-1.81-3.44-3.42a6.7 6.7 0 0 0-1.09-.35c-.79 19.75-3.46 43.2-8.15 71.06-4.38 26.36-9.35 58.22-11.11 69.52Z"
          style={{
            opacity: 0.5,
          }}
        />
      </g>
      <path
        d="M79.63 287.15s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7.3-30.57 8.66-38.56 6.85-93.12 6.85-93.12.21-1.81-3.44-3.42c-3-1.35-17.72-2-22.48-2.16a5 5 0 0 0-5.05 3.68s0 .08 0 .11c-.91 3.67-14.58 94.22-14.58 94.22Z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M107.57 290.14c-4.27 0-22.66-1.94-25.51-2.55a22.36 22.36 0 0 1-2.4-.64v.2s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7-29 8.54-37.86c-3.6 1.87-7.16 1.57-10.67 1.6Z"
        style={{
          fill: '#263238',
        }}
      />
      <g
        style={{
          clipPath: 'url(#b)',
        }}
      >
        <path
          d="M101.37 331.53a9 9 0 0 0 2-.42c3.84-1.38 5-2.6 6.28-4.71s7.3-30.57 8.66-38.56 6.85-93.12 6.85-93.12.21-1.81-3.44-3.42a6.7 6.7 0 0 0-1.09-.35c-.79 19.75-3.46 43.2-8.15 71.06-4.38 26.36-9.35 58.22-11.11 69.52Z"
          style={{
            opacity: 0.5,
          }}
        />
        <path
          d="M107.61 292.52a38.22 38.22 0 0 0 8.31-1.28"
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            opacity: 0.26,
          }}
        />
        <path
          d="M81.09 289.57s22.57 3.09 26.52 2.95"
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
      <path
        d="M107.57 290.14c-4.27 0-22.66-1.94-25.51-2.55a22.36 22.36 0 0 1-2.4-.64v.2s-1.23 25.76-1.09 32.9.31 9.81 3.85 10.44 17.2 2 21 .62 5-2.6 6.28-4.71 7-29 8.54-37.86c-3.6 1.87-7.16 1.57-10.67 1.6Z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <circle
        cx={381.86}
        cy={146.47}
        r={42.91}
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="rotate(-27.51 381.831 146.467)"
      />
      <circle
        cx={378.66}
        cy={142.2}
        r={42.91}
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="rotate(-82.91 378.653 142.204)"
      />
      <circle
        cx={378.66}
        cy={142.2}
        r={38.65}
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        transform="rotate(-80.67 378.66 142.203)"
      />
      <path
        d="M374.13 113.15S369.87 102.49 362.4 95s-37.84-12.26-37.84-12.26a111.22 111.22 0 0 1 13.32 15.46c6.93 9.59 29.86 31.45 29.86 31.45s-11.2-9.6-21.33-10.66-35.71 4.26-37.31 4.26 9.59 4.27 17.59 8 38.91 6.93 38.91 6.93-13.32 3.73-22.39 6.93-17.59 26.65-17.59 26.65 11.73-10.66 21.86-13.33S372 151 372 151s-9.06 10.13-11.19 17.06-.54 22.92-.54 22.92 4.8-14.93 10.66-20.79 12.26-16 12.26-16a145.88 145.88 0 0 1 8 14.92c4.26 9.07 6.93 22.39 6.93 22.39s6.93-14.92 6.4-22.39-8.52-20.78-8.52-20.78 5.33-3.73 13.32-.53 21.86 16.53 21.86 16.53-3.2-17.59-8.53-24.52-28.25-9.07-28.25-9.07 12.79-7.46 20.79-9.06 18.66 2.13 18.66 2.13-12.27-17-21.86-18.12-24 13.86-24 13.86 3.2-16 4.27-21.32-1.07-18.66-1.07-18.66-5.86 3.2-12.79 13.33a26.51 26.51 0 0 0-4.27 20.25Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M374.13 113.15S369.87 102.49 362.4 95s-37.84-12.26-37.84-12.26a111.22 111.22 0 0 1 13.32 15.46c6.93 9.59 29.86 31.45 29.86 31.45s-11.2-9.6-21.33-10.66-35.71 4.26-37.31 4.26 9.59 4.27 17.59 8 38.91 6.93 38.91 6.93-13.32 3.73-22.39 6.93-17.59 26.65-17.59 26.65 11.73-10.66 21.86-13.33S372 151 372 151s-9.06 10.13-11.19 17.06-.54 22.92-.54 22.92 4.8-14.93 10.66-20.79 12.26-16 12.26-16a145.88 145.88 0 0 1 8 14.92c4.26 9.07 6.93 22.39 6.93 22.39s6.93-14.92 6.4-22.39-8.52-20.78-8.52-20.78 5.33-3.73 13.32-.53 21.86 16.53 21.86 16.53-3.2-17.59-8.53-24.52-28.25-9.07-28.25-9.07 12.79-7.46 20.79-9.06 18.66 2.13 18.66 2.13-12.27-17-21.86-18.12-24 13.86-24 13.86 3.2-16 4.27-21.32-1.07-18.66-1.07-18.66-5.86 3.2-12.79 13.33a26.51 26.51 0 0 0-4.27 20.25Z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="M384.63 96.93a87.8 87.8 0 0 1 1.23-4.57M349 96.92c5 3.65 11.23 8.66 14.44 13 5.86 8 17.59 25.06 17.59 25.06a158.65 158.65 0 0 1 2.71-34.17M341.62 91.83s1.55 1 3.88 2.59M381.06 135s20.26-20.26 28.79-21.86 16 4.8 16 4.8M344.86 152c-4.93 3.14-5.38 4.91-5.38 4.91M425.31 153.67s-3.2-8-14.4-11.73-28.78-4.27-28.78-4.27-24.67 8.08-33.69 12.29M371.36 134.63c6 1.73 10.77 3 10.77 3M333.81 125.16c5.79.35 13.25 1.14 17.4 2.92 3.38 1.45 10.05 3.55 16.33 5.42M325.62 124.88s1.95 0 4.83.11"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.5px',
        }}
      />
      <path
        d="M381.06 140.87s-14.92 22.39-16 27.72a54.25 54.25 0 0 0-1.07 9.6M387.7 147.92c2.87 3.71 6.32 8.65 7.75 12.68a86.8 86.8 0 0 1 3.74 14.92M383.19 142.47s1.19 1.32 2.84 3.35"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.5px',
        }}
      />
      <path
        d="M367.36 117.75s-2.76-11.15-1.05-21.57 23.6-32 23.6-32a110.83 110.83 0 0 0-1.81 20.33c0 11.84-5.91 43-5.91 43s3.5-14.33 11.11-21.1S424.79 89 426.09 88s-5.3 9.06-9.62 16.76-27.57 28.32-27.57 28.32 13-4.74 22.23-7.43S441 137.08 441 137.08s-15.74-1.82-25.52 1.92-24.27 8.24-24.27 8.24 13.27 2.95 19 7.33S424 172.88 424 172.88s-12.6-9.33-20.78-10.67-19.29-5.84-19.29-5.84a148.18 148.18 0 0 0 2.21 16.79c1.82 9.85 7.43 22.23 7.43 22.23s-14.33-8.08-18.25-14.46-5.2-21.86-5.2-21.86-6.51.08-11.14 7.34-8.11 26.17-8.11 26.17-7.67-16.15-7.38-24.89 17.67-23.84 17.67-23.84-14.75 1.4-22.18 4.76-13.91 12.62-13.91 12.62 0-21 7.18-27.47 27.58-2.76 27.58-2.76l-15.9-14.84c-4-3.7-10-15.77-10-15.77s6.62-.83 18.16 3.36a26.49 26.49 0 0 1 15.27 14Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M349.37 110.69q-1.8-1.55-3.66-3M378.28 89.92c-1.93 5.87-4.07 13.58-4.13 19-.1 9.91.33 30.61.33 30.61a158.31 158.31 0 0 0-22.13-26.18M381.34 81.46s-.7 1.71-1.65 4.37M374.48 139.54s-28.27-4.64-36.13-1-10.19 13.22-10.19 13.22M413.77 132.18c5.84-.32 7.23.86 7.23.86M349.42 180.5s-2.06-8.36 4.85-17.92 20.9-20.25 20.9-20.25 24.74-7.83 34.53-9.67M382.14 133.58c-3.83 4.88-7 8.75-7 8.75M407.12 104c-4.51 3.66-10.1 8.65-12.44 12.52-1.9 3.15-6.08 8.75-10.09 13.93M413.6 99s-1.58 1.13-3.85 2.9"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.5px',
        }}
      />
      <path
        d="M377.9 144.3s25.18 9.48 29.16 13.19a54.94 54.94 0 0 1 6.46 7.17M376.62 153.89c-.17 4.7-.09 10.73 1.09 14.83a86.67 86.67 0 0 0 5.68 14.3M377.1 146.85s-.2 1.75-.35 4.37"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.5px',
        }}
      />
      <path
        d="M387.21 363.33c-1.92-5.41-2.26-33.79-1.83-54l11.23 2.43a8.21 8.21 0 0 0 5.73-.85 8 8 0 0 0 1.2-.82 7 7 0 0 0 1-1 8.11 8.11 0 0 0 2-4.21 8 8 0 0 0 .08-.84 8.19 8.19 0 0 0-1.17-4.62 8.34 8.34 0 0 0-.94-1.27 8.22 8.22 0 0 0-4.91-2.63l-13.56-2c.12-1.76.26-3.13.4-4a18.76 18.76 0 0 0 .3-4l.93-3c1.37-4.39.33-10.85-3.4-14.52s-17-10.13-20.88-13.52-32.93-24.08-38.14-22.86-11.37 8.65-16.62 12.76S284.47 264 284.47 264c.06-.65-9.72 3.3-12.1 7.13a1.67 1.67 0 0 0-.51 1.34 6.91 6.91 0 0 0 4.34 4.78l-8.48-1.25a2.55 2.55 0 0 0-2.5 1.12c-3-.45-9.12-1.12-13.54.09 0 0 7.21 3.53 12.6 4.71a2.55 2.55 0 0 0 1.88 1.67l11.25 2.41a12.84 12.84 0 0 0-.46 2 3.51 3.51 0 0 0 .25 1.82c.05.38.12.77.21 1.18 1.6 6.8 10.24 15.06 16.54 20.52s12.33 14.81 16.07 25.65S316.1 358 324.42 369a51.58 51.58 0 0 0 4.61 5.27l77.57 49s-16.6-51.99-19.39-59.94ZM286 278.05c-.13.2-.26.4-.37.61l-2.14-.32a17.92 17.92 0 0 0 2.51-.29Z"
        style={{
          opacity: 0.30000000000000004,
        }}
      />
      <path
        d="m361.16 449.91-49.52-85.6s-5.54-38.89-17.35-54.54-28.37-27.07-18.76-35.07 34-23.3 39-22.32 38.66 18 48.17 20.75 12.84 6.79 11.42 15-2 65.78.81 73.73S409 426.47 409 426.47"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#c)',
        }}
      >
        <path
          d="M375 361.88c-2.83-7.95-2.23-65.5-.81-73.73.87-5-.05-8.49-3.08-11a36.71 36.71 0 0 1-2.19 13.39c-2.67 7.57-4 12.91-4 12.91s-.44 27.15 1.34 41 10.68 38.28 19.14 51.64c6.26 9.88 15.19 18.78 19.42 22.75-8.7-15.84-27.7-50.84-29.82-56.96Z"
          style={{
            fill: '#92e3a9',
          }}
        />
        <path
          d="M375 361.88c-2.83-7.95-2.23-65.5-.81-73.73.87-5-.05-8.49-3.08-11a36.71 36.71 0 0 1-2.19 13.39c-2.67 7.57-4 12.91-4 12.91s-.44 27.15 1.34 41 10.68 38.28 19.14 51.64c6.26 9.88 15.19 18.78 19.42 22.75-8.7-15.84-27.7-50.84-29.82-56.96Z"
          style={{
            fill: '#fff',
            opacity: 0.5,
          }}
        />
      </g>
      <path
        d="m361.16 449.91-49.52-85.6s-5.54-38.89-17.35-54.54-28.37-27.07-18.76-35.07 34-23.3 39-22.32 38.66 18 48.17 20.75 12.84 6.79 11.42 15-2 65.78.81 73.73S409 426.47 409 426.47"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M262.48 276.24a44.56 44.56 0 0 0 21.81-7.12c11.13-7.12 7.57-8.46 7.57-8.46s5.78-1.78 9.34-3.11a46.52 46.52 0 0 0 8.46-4.9s14.25 9.35 20.92 12a71.72 71.72 0 0 0 9.84 3.36l9.31 12.22a31.86 31.86 0 0 0-12.91 11.13c-5.34 8-12 18.69-15.14 20s-1.78 2.67-15.58-2.67-44.51-24.43-43.62-32.45Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M262.48 276.24a44.56 44.56 0 0 0 21.81-7.12c11.13-7.12 7.57-8.46 7.57-8.46s5.78-1.78 9.34-3.11a46.52 46.52 0 0 0 8.46-4.9s14.25 9.35 20.92 12a71.72 71.72 0 0 0 9.84 3.36l9.31 12.22a31.86 31.86 0 0 0-12.91 11.13c-5.34 8-12 18.69-15.14 20s-1.78 2.67-15.58-2.67-44.51-24.43-43.62-32.45Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <path
        d="M287.14 280.35s10.7-1.32 18.24-15.05c0 0 8.88 3.76 18.12 1.35M316.15 270.42s-5.16 13.66 5.72 31.73"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M304.72 271.67s5.39 6.63 6.27 12.41M314.81 308.54s9-5.69 13.79-11.61 6.95-12.16 14.5-14.65M305.38 265.3l-2.19-2.59"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M254.78 276s-9.3-1.88-15.36-.22c0 0 8.92 4.37 14.33 5Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m387.3 294.14-131.84-19.59a2.56 2.56 0 0 0-2.86 1.91L252 279a2.55 2.55 0 0 0 1.94 3.13l130.45 28.2a8.24 8.24 0 0 0 9.89-6.84h0a8.24 8.24 0 0 0-6.98-9.35Z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m387.3 294.14-19.93-3a10 10 0 0 0-4.53 10.93 10.12 10.12 0 0 0 1.92 4l19.59 4.23a8.24 8.24 0 0 0 9.89-6.84h0a8.24 8.24 0 0 0-6.94-9.32Z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M333.94 306.44s-11.34 2.66-16.41 1.46-15.42-15.76-22.53-20.55-11.44-5.93-18.88-7.05-12.58 2.47-11 9.28 10.23 15.06 16.54 20.52 12.33 14.8 16.07 25.65 6.08 20.82 14.4 31.78 18.7 15.47 22.19 16.08"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#d)',
        }}
      >
        <path
          d="m328.31 308.05-.8-.41c-3.44.5-7.47.85-10 .26-5.06-1.2-15.42-15.76-22.52-20.55s-11.44-5.93-18.88-7.05a16.42 16.42 0 0 0-3.59-.16 48.43 48.43 0 0 1 16 7.31c7.92 5.72 13.64 20.23 22.43 22.87s17.36-2.27 17.36-2.27Z"
          style={{
            fill: '#92e3a9',
          }}
        />
        <path
          d="m328.31 308.05-.8-.41c-3.44.5-7.47.85-10 .26-5.06-1.2-15.42-15.76-22.52-20.55s-11.44-5.93-18.88-7.05a16.42 16.42 0 0 0-3.59-.16 48.43 48.43 0 0 1 16 7.31c7.92 5.72 13.64 20.23 22.43 22.87s17.36-2.27 17.36-2.27Z"
          style={{
            fill: '#fff',
            opacity: 0.5,
          }}
        />
      </g>
      <path
        d="M333.94 306.44s-11.34 2.66-16.41 1.46-15.42-15.76-22.53-20.55-11.44-5.93-18.88-7.05-12.58 2.47-11 9.28 10.23 15.06 16.54 20.52 12.33 14.8 16.07 25.65 6.08 20.82 14.4 31.78 18.7 15.47 22.19 16.08"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M270.84 294.46s.54-5.87 8.58-11.34c0 0-5.37-3.53-9.05-2.9s-5.13 2.64-5.69 6.36 6.16 7.88 6.16 7.88Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M276.12 300.69a10.7 10.7 0 0 0 2.06 1.62M282 286.82s-8.54 4.4-7.17 11.26"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m374 285.55 1.36-4.39c1.36-4.38.33-10.84-3.4-14.51s-17-10.13-20.88-13.52S318.16 229 313 230.27s-11.37 8.65-16.63 12.75-24.12 19.54-24.12 19.54S259 268.3 259.6 271s3.35 6.15 11.66 5.88 22.16-8.55 24.26-9.77 15.17-9.78 15.17-9.78a45.78 45.78 0 0 0 11.52 8.3c6.17 2.86 7 3.15 7 3.15s1.61 5.85 6.14 8.85"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#e)',
        }}
      >
        <path
          d="M366.64 266.89c4.8 3.77 5.11 11.24 3.78 17.93l3.59.73 1.36-4.39c1.36-4.38.33-10.84-3.4-14.51s-17-10.13-20.88-13.52c-1-.88-3.76-2.95-7.33-5.49l-.71.11s17.36 14.25 23.59 19.14Z"
          style={{
            fill: '#92e3a9',
          }}
        />
        <path
          d="M366.64 266.89c4.8 3.77 5.11 11.24 3.78 17.93l3.59.73 1.36-4.39c1.36-4.38.33-10.84-3.4-14.51s-17-10.13-20.88-13.52c-1-.88-3.76-2.95-7.33-5.49l-.71.11s17.36 14.25 23.59 19.14Z"
          style={{
            fill: '#fff',
            opacity: 0.5,
          }}
        />
        <path
          d="M262.48 276.24a44.56 44.56 0 0 0 21.81-7.12c11.13-7.12 7.57-8.46 7.57-8.46s5.78-1.78 9.34-3.11a46.52 46.52 0 0 0 8.46-4.9s14.25 9.35 20.92 12a71.72 71.72 0 0 0 9.84 3.36l9.31 12.22a31.86 31.86 0 0 0-12.91 11.13c-5.34 8-12 18.69-15.14 20s-1.78 2.67-15.58-2.67-44.51-24.43-43.62-32.45Z"
          style={{
            fill: '#92e3a9',
          }}
        />
        <path
          d="M262.48 276.24a44.56 44.56 0 0 0 21.81-7.12c11.13-7.12 7.57-8.46 7.57-8.46s5.78-1.78 9.34-3.11a46.52 46.52 0 0 0 8.46-4.9s14.25 9.35 20.92 12a71.72 71.72 0 0 0 9.84 3.36l9.31 12.22a31.86 31.86 0 0 0-12.91 11.13c-5.34 8-12 18.69-15.14 20s-1.78 2.67-15.58-2.67-44.51-24.43-43.62-32.45Z"
          style={{
            fill: '#fff',
            opacity: 0.5,
          }}
        />
      </g>
      <path
        d="m374 285.55 1.36-4.39c1.36-4.38.33-10.84-3.4-14.51s-17-10.13-20.88-13.52S318.16 229 313 230.27s-11.37 8.65-16.63 12.75-24.12 19.54-24.12 19.54S259 268.3 259.6 271s3.35 6.15 11.66 5.88 22.16-8.55 24.26-9.77 15.17-9.78 15.17-9.78a45.78 45.78 0 0 0 11.52 8.3c6.17 2.86 7 3.15 7 3.15s1.61 5.85 6.14 8.85"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M259.6 271s1.34.64 4.18-.5 8.36-7.22 8.42-8-12.15 4.35-12.6 8.5Z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M310.16 247.65a17.89 17.89 0 0 1 .47-1.77M310.69 257.35a19.46 19.46 0 0 1-.85-7.44"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  </Box>
);
export default React.memo(NoteIllustration);
