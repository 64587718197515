import { Helmet } from 'react-helmet-async';
// @mui
import { Typography, Stack } from '@mui/material';
// assets
import { SuccessfullPaymentIllustration } from '../assets/illustrations';

// ----------------------------------------------------------------------

export default function SuccessfullPaymentPage() {
  return (
    <>
      <Helmet>
        <title>Plată Aprobată | Master Gaz</title>
      </Helmet>

      <Stack sx={{ alignItems: 'center', mt: 5 }}>
        <Typography variant="h3" paragraph>
          Plată Efectuată cu Succes!
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 2 }}>
          Vă mulțumim pentru plata efectuată. Tranzacția dvs. a fost procesată cu succes.
        </Typography>

        <SuccessfullPaymentIllustration sx={{ height: 500, width: 500 }} />
      </Stack>
    </>
  );
}
