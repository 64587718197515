import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, MenuItem, Divider, Chip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import Cookies from 'js-cookie';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { BASE_URL } from '../../../config-global';
import { LEGAL_QUALITY, PERSON_TYPE, RECOMMEND_SOURCE } from '../../../_mock/arrays/_client';
import LegalQualityForm from '../tickets/LegalQualityForm';
import { addClientRedux, editClientRedux } from '../../../redux/slices/clients';
import { useDispatch } from '../../../redux/store';
import { getAuthenticatedUserFromCookies } from '../../../utils/cookies';

// ----------------------------------------------------------------------

NewClientForm.propTypes = {
  isEdit: PropTypes.bool,
  currentClient: PropTypes.object,
};

export default function NewClientForm({ isEdit = false, currentClient }) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const [legalQuality, setLegalQuality] = useState('naturalPerson'); // natural person or legal person
  const city = {
    nume: `${currentClient?.contactAddress?.city || ''}`,
    judet: `${currentClient?.contactAddress?.county || ''}`,
  };

  const [selectedOption, setSelectedOption] = useState(
    isEdit && city.nume !== '' && city.judet !== '' ? city : null
  );
  const [searchValue, setSearchValue] = useState('');

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('Prenumele este obligatoriu!'),
    lastName: Yup.string().max(255).required('Numele este obligatoriu!'),
    cui: Yup.string().when('legalQuality', {
      is: 'legalPerson',
      then: Yup.string().required('CUI is required'),
      otherwise: Yup.string(),
    }),
    tradeRegisterNumber: Yup.string().when('legalQuality', {
      is: 'legalPerson',
      then: Yup.string().required('Trade register number is required'),
      otherwise: Yup.string(),
    }),
  });

  const defaultValues = useMemo(
    () => ({
      firstName: currentClient?.firstName,
      lastName: currentClient?.lastName || '',
      clientPhoneNumber: currentClient?.phoneNumber || '',
      personType: currentClient?.type !== null ? currentClient?.type : '',
      legalQuality:
        currentClient?.company !== true
          ? setLegalQuality('naturalPerson')
          : setLegalQuality('legalPerson'),
      clientEmailAddress: currentClient?.email || '',
      clientRecommendSource: currentClient?.clientRecommendSource || '',
      street: currentClient?.contactAddress?.streetName || '',
      number: currentClient?.contactAddress?.streetNumber || '',
      building: currentClient?.contactAddress?.building || '',
      stair: currentClient?.contactAddress?.stair || '',
      floor: currentClient?.contactAddress?.floor || '',
      apartment: currentClient?.contactAddress?.apartment || '',
      vila: currentClient?.contactAddress?.vila || '',
      corp: currentClient?.contactAddress?.corp || '',
      tradeRegisterNumber: currentClient?.clientCompanyDetails?.tradeRegisterNumber || '',
      companyName: currentClient?.clientCompanyDetails?.companyName || '',
      companyAddress: currentClient?.clientCompanyDetails?.address || '',
      CUI: currentClient?.clientCompanyDetails?.cui || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentClient]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentClient) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentClient]);

  const addressSubmit = async (data) => {
    let sendData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.clientPhoneNumber,
      type: data.personType,
      email: data.clientEmailAddress,
      clientRecommendSource: data.clientRecommendSource,
      isCompany: legalQuality === 'legalPerson',
      contactAddress: {
        county: selectedOption.judet,
        city: selectedOption.nume,
        building: data.building,
        floor: data.floor,
        apartment: data.apartment,
        stair: data.stair,
        postalCode: '06812',
        streetName: data.street,
        streetNumber: data.number,
        vila: data.vila,
        corp: data.corp,
      },
      workAddress: {
        county: selectedOption.judet,
        city: selectedOption.nume,
        building: data.building,
        floor: data.floor,
        apartment: data.apartment,
        stair: data.stair,
        postalCode: '06812',
        streetName: data.street,
        streetNumber: data.number,
        vila: data.vila,
        corp: data.corp,
      },
    };
    if (legalQuality === 'legalPerson') {
      const companyData = {
        companyName: data.companyName,
        cui: data.CUI,
        tradeRegisterNumber: data.tradeRegisterNumber,
        address: data.companyAddress,
      };
      sendData = {
        ...sendData,
        clientCompanyDetails: companyData,
      };
    }

    const res = await dispatch(addClientRedux(sendData));

    if (res.status === 200) {
      enqueueSnackbar('Clientul a fost adaugat cu succes!', { variant: 'success' });
      reset();
    } else {
      enqueueSnackbar('Clientul nu a fost adaugat!', { variant: 'error' });
    }
  };

  const editSubmit = async (data) => {
    let sendData = {
      clientId: currentClient?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.clientPhoneNumber,
      type: data.personType,
      email: data.clientEmailAddress,
      isCompany: legalQuality === 'legalPerson',
      clientRecommendSource: data.clientRecommendSource,
      contactAddress: {
        county: selectedOption.judet,
        city: selectedOption.nume,
        building: data.building,
        floor: data.floor,
        apartment: data.apartment,
        stair: data.stair,
        postalCode: '06812',
        streetName: data.street,
        streetNumber: data.number,
        vila: data.vila,
        corp: data.corp,
      },
      workAddress: {
        county: selectedOption.judet,
        city: selectedOption.nume,
        building: data.building,
        floor: data.floor,
        apartment: data.apartment,
        stair: data.stair,
        postalCode: '06812',
        streetName: data.street,
        streetNumber: data.number,
        vila: data.vila,
        corp: data.corp,
      },
    };
    if (legalQuality === 'legalPerson') {
      const companyData = {
        companyName: data.companyName,
        cui: data.CUI,
        tradeRegisterNumber: data.tradeRegisterNumber,
        address: data.companyAddress,
      };
      sendData = {
        ...sendData,
        clientCompanyDetails: companyData,
      };
    }

    const res = await dispatch(editClientRedux(sendData));

    if (res.status === 200) {
      enqueueSnackbar('Clientul a fost editat cu succes!', { variant: 'success' });
    } else {
      enqueueSnackbar('Clientul nu a putut fi editat!', { variant: 'error' });
    }
  };

  //! ------------------------------- SEARCH CITY --------------------------- !//
  const loadOptions = async (inputValue, callback) => {
    try {
      setSearchValue(inputValue);

      const cookies = getAuthenticatedUserFromCookies();
      const token = cookies.accessToken;

      const response = await axios.get(`${BASE_URL}:9000/ticket/cities`, {
        params: { search: inputValue },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      callback(response.data);
    } catch (error) {
      console.error(error);
      callback([]);
    }
  };

  const handleCityChange = (select) => {
    setSelectedOption(select);
  };

  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const handleSearchDebounced = debounce(loadOptions, 200);

  // ------------------------------------------------------------------------ //
  // set the values from select-options
  const handleChange = (e) => {
    const el = e.target;
    setLegalQuality(el.value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(!isEdit ? addressSubmit : editSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={10} sx={{ mx: 'auto' }}>
          <Card sx={{ px: 8, py: 12 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Chip color="primary" label="DATE CLIENT" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="lastName" label="Nume" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="firstName" label="Prenume" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="clientPhoneNumber" label="Numar telefon" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="clientEmailAddress" label="Adresa de email" />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <RHFSelect
                  fullWidth
                  required
                  name="legalQuality"
                  label="Calitate legala"
                  inputlabelprops={{ shrink: true }}
                  onChange={handleChange}
                  value={legalQuality}
                >
                  {LEGAL_QUALITY.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label.charAt(0) + option.label.substring(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <RHFSelect
                  required
                  name="personType"
                  label="Tip"
                  inputlabelprops={{ shrink: true }}
                >
                  {PERSON_TYPE.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label.charAt(0) + option.label.substring(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <RHFSelect
                  fullWidth
                  name="clientRecommendSource"
                  label="Sursa recomandare"
                  inputlabelprops={{ shrink: true }}
                >
                  {RECOMMEND_SOURCE.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label.charAt(0) + option.label.substring(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Divider />

              {/* <Grid item xs={12} sx={{ my: 2 }}>
                <Divider textAlign="center">
                  <Typography variant="subtitle2">Adresa</Typography>
                </Divider>
              </Grid> */}

              <Grid item xs={12} sx={{ my: 2 }}>
                <Chip color="primary" label="ADRESA" sx={{ mx: 'auto' }} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="street" label="Strada" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="number" label="Numar" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="vila" label="Vila" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="corp" label="Corp" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="building" label="Bloc" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="stair" label="Scara" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="floor" label="Etaj" />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <RHFTextField name="apartment" label="Apartament" />
              </Grid>

              <Grid item xs={12}>
                <AsyncSelect
                  cacheOptions
                  value={selectedOption}
                  onChange={handleCityChange}
                  loadOptions={handleSearchDebounced}
                  getOptionLabel={(option) => option && `${option.nume}, ${option.judet}`}
                  getOptionValue={(option) => option.id}
                  noOptionsMessage={({ inputValue }) => {
                    if (!inputValue) {
                      return 'Introdu numele localitatii!';
                    }
                    return 'Nu s-au gasit rezultate.';
                  }}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '100px',
                      overflowY: 'auto',
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderColor: 'rgb(33,43,54)',
                      zIndex: 1000,
                    }),
                    control: (provided) => ({
                      ...provided,
                      padding: '0.55rem',
                      borderRadius: '8px',
                    }),
                  }}
                />
              </Grid>

              {isEdit && legalQuality === 'legalPerson' && currentClient?.company ? (
                <>
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <Chip color="primary" label="ADRESA" sx={{ mx: 'auto' }} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <RHFTextField
                      inputlabelprops={{ shrink: true }}
                      name="companyName"
                      label="Nume companie"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <RHFTextField
                      inputlabelprops={{ shrink: true }}
                      name="tradeRegisterNumber"
                      label="Nr. inregistrare ONRC"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <RHFTextField inputlabelprops={{ shrink: true }} name="CUI" label="CUI" />
                  </Grid>

                  <Grid item xs={12}>
                    <RHFTextField
                      inputlabelprops={{ shrink: true }}
                      name="companyAddress"
                      label="Adresa"
                    />
                  </Grid>
                </>
              ) : (
                isEdit &&
                legalQuality === 'legalPerson' && (
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <LegalQualityForm setValue={setValue} title="Detaliile companiei" />
                  </Grid>
                )
              )}

              {!isEdit && legalQuality === 'legalPerson' && (
                <Grid item xs={12} sx={{ my: 2 }}>
                  <LegalQualityForm setValue={setValue} title="Detaliile companiei" />
                </Grid>
              )}
            </Grid>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Adauga client' : 'Salveaza modificarile'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
