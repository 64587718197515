import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

RHFDate.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date), // Add minDate prop
};

export default function RHFDate({ name, helperText, label, minDate, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          label={label}
          value={field.value}
          onChange={field.onChange}
          inputFormat="dd-MM-yyyy"
          minDate={minDate || null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={!!error}
              helperText={error ? error.message : ''}
              {...other}
            />
          )}
        />
      )}
    />
  );
}
