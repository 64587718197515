export const RECOMMEND_SOURCE = [
  { label: 'Presa', value: 'Presa' },
  { label: 'TV', value: 'TV' },
  { label: 'Radio', value: 'Radio' },
  {
    label: 'Panotaj stradal',
    value: 'Panotaj stradal',
  },
  { label: 'Online-Google', value: 'Online-Google' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Partener', value: 'Partener' },
  { label: 'Alta sursa', value: 'Alta sursa' },
];

export const LEGAL_QUALITY = [
  { label: 'Persoana fizica', value: 'naturalPerson' },
  { label: 'Persoana juridica', value: 'legalPerson' },
];

export const PERSON_TYPE = [
  { label: 'Beneficiar', value: 'Beneficiar' },
  { label: 'Imputernicit', value: 'Imputernicit' },
];
