import { createSlice } from '@reduxjs/toolkit';
import outboundAudio from '../../components/sip/VirtualPhone/outbound-sound.mp3';
import inboundAudio from '../../components/sip/VirtualPhone/inbound-sound.mp3';

const initialState = {
  unusable: false,
  number: '',
  clientName: '',
  phoneState: 'closed',
  callState: 'formatting',
  isCalling: false,
  session: {},
  selectedTab: 0,
  outboundAudio: new Audio(outboundAudio),
  inboundAudio: new Audio(inboundAudio),
  recentCalls: [],
  onHold: false,
  onMute: false,
  disabledTabs: false,
  phoneSettings: { iphoneLook: false },
  isConnected: false,
};

const slice = createSlice({
  name: 'virtualPhone',
  initialState,
  reducers: {
    setClientName(state, action) {
      state.clientName = action.payload;
    },
    setNumber(state, action) {
      state.number = action.payload;
    },
    setPhoneState(state, action) {
      state.phoneState = action.payload;
    },
    setCallState(state, action) {
      state.callState = action.payload;
    },
    setIsCalling(state, action) {
      state.isCalling = action.payload;
    },
    setSession(state, action) {
      state.session = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setRecentCalls(state, action) {
      state.recentCalls = action.payload;
    },
    addRecentCall(state, action) {
      state.recentCalls.push(action.payload);
    },
    setCallMinutes(state, action) {
      state.callMinutes = action.payload;
    },
    setCallSeconds(state, action) {
      state.callSeconds = action.payload;
    },
    setOnMute(state, action) {
      state.onMute = action.payload;
    },
    setOnHold(state, action) {
      state.onHold = action.payload;
    },
    setDisabledTabs(state, action) {
      state.disabledTabs = action.payload;
    },
    setUnusable(state, action) {
      state.unusable = action.payload;
    },
    setSettings(state, action) {
      state.phoneSettings = { ...state.phoneSettings, ...action.payload };
    },
    setConnected(state, action) {
      state.isConnected = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// --------------------------------------------------------------------------------
export function changeClientName(value) {
  return (dispatch) => {
    dispatch(slice.actions.setClientName(value));
  };
}

export function changeNumber(value) {
  return (dispatch) => {
    dispatch(slice.actions.setNumber(value));
  };
}
export function changePhoneState(value) {
  return (dispatch) => {
    dispatch(slice.actions.setPhoneState(value));
  };
}
export function changeCallState(value) {
  return (dispatch) => {
    dispatch(slice.actions.setCallState(value));
  };
}
export function changeIsCalling(value) {
  return (dispatch) => {
    dispatch(slice.actions.setIsCalling(value));
  };
}

export function changeSession(session) {
  return (dispatch) => {
    dispatch(slice.actions.setSession(session));
  };
}

export function changeSelectedTab(tab) {
  return (dispatch) => {
    dispatch(slice.actions.setSelectedTab(tab));
  };
}
export function changeRecentCalls(recentCalls) {
  return (dispatch) => {
    dispatch(slice.actions.setRecentCalls(recentCalls));
  };
}
export function addRecentCall(recentCall) {
  return (dispatch) => {
    dispatch(slice.actions.addRecentCall(recentCall));
  };
}

export function changeOnMute(mute) {
  return (dispatch) => {
    dispatch(slice.actions.setOnMute(mute));
  };
}
export function changeOnHold(hold) {
  return (dispatch) => {
    dispatch(slice.actions.setOnHold(hold));
  };
}

export function changeDisabledTabs(value) {
  return (dispatch) => {
    dispatch(slice.actions.setDisabledTabs(value));
  };
}

export function changeUnusable(value) {
  return (dispatch) => {
    dispatch(slice.actions.setUnusable(value));
  };
}

export function changeSettings(value) {
  return (dispatch) => {
    dispatch(slice.actions.setSettings(value));
  };
}

export function changeConnected(value) {
  return (dispatch) => {
    dispatch(slice.actions.setConnected(value));
  };
}
