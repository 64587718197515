import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Stack,
  Button,
  DialogActions,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Popper,
  Paper,
  Popover,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
//
import AsyncSelect from 'react-select/async';
// axios
import axios from 'axios';
// redux
import { useDispatch } from 'react-redux';
import { parse } from 'date-fns';
import { useSelector } from '../../../redux/store';
// components
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from '../../../locales';
import LegalQualityForm from './LegalQualityForm';
import { BASE_URL } from '../../../config-global';
import { LEGAL_QUALITY, PERSON_TYPE, RECOMMEND_SOURCE } from '../../../_mock/arrays/_client';
import { debounce } from '../../../utils/debounce';
import { getAuthenticatedUserFromCookies } from '../../../utils/cookies';
import { setSearchedClientRedux } from '../../../redux/slices/tickets';
import NewClientConfirm from './dialogs/NewClientConfirmDialog';
import RHFDate from '../../../components/hook-form/RHFDate';
import { fDate } from '../../../utils/formatTime';

const steps = ['Tip persoana', 'Date identificare', 'Date lucrare'];

// ----------------------------------------------------------------------

AddClientAddressDialog.propTypes = {
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  cities: PropTypes.array,
  handleSetClient: PropTypes.func,
  setPlaceholder: PropTypes.func,
  isEdit: PropTypes.bool,
  departmentName: PropTypes.string,
  ticketNumber: PropTypes.string,
  closeSearchClientDialog: PropTypes.func,
};

export default function AddClientAddressDialog({
  onCancel,
  onClose,
  cities,
  handleSetClient,
  setPlaceholder,
  isEdit,
  departmentName,
  ticketNumber,
  closeSearchClientDialog,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { selectedClient } = useSelector((state) => state.tickets);

  const [checkedContact, setCheckedContact] = useState(false);
  const [checkedWorkAddress, setCheckedWorkAddress] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [activeStep, setActiveStep] = useState(0);

  // form validating
  const EventSchema = Yup.object().shape({
    firstName: Yup.string().max(255),
    lastName: Yup.string().max(255),
    cui: Yup.string().max(8),
    tradeRegisterNumber: Yup.string().max(255),
  });

  // default values for the form inputs
  const defaultValues = {
    firstName: '',
    lastName: '',
    cnp: '',
    series_ci: '',
    number_ci: '',
    released_by: '',
    released_date: '',
    expired_date: '',
    personType: PERSON_TYPE[0].value,
    legalQuality: LEGAL_QUALITY[0].value,
    clientPhoneNumber: '',
    clientEmailAddress: '',
    clientRecommendSource: RECOMMEND_SOURCE[0].value,
    street: '',
    number: '',
    building: '',
    stair: '',
    floor: '',
    apartment: '',
    companyName: '',
    companyCounty: '',
    companyCity: '',
    companyAddress: '',
    workStreet: '',
    workNumber: '',
    workBuilding: '',
    workStair: '',
    workFloor: '',
    workApartment: '',
    cui: '',
    tradeRegisterNumber: '',
    contactFullName: '',
    contactPhoneNumber: '',
    contactEmail: '',
  };

  const clientValues = useMemo(
    () => ({
      firstName:
        !selectedClient.company && selectedClient?.firstName ? selectedClient?.firstName : '',
      lastName: selectedClient?.lastName || '',
      clientPhoneNumber:
        !selectedClient.company && selectedClient?.phoneNumber ? selectedClient?.phoneNumber : '',
      personType: selectedClient?.type !== null ? selectedClient?.type : '',
      legalQuality: selectedClient?.company ? 'legalPerson' : 'naturalPerson',
      clientEmailAddress:
        !selectedClient.company && selectedClient?.email ? selectedClient?.email : '',
      clientRecommendSource: selectedClient?.clientRecommendSource || '',
      cnp: selectedClient?.cnp || '',
      series_ci: selectedClient?.series_ci || '',
      number_ci: selectedClient?.number_ci || '',
      released_by: selectedClient?.released_by || '',
      released_date: selectedClient?.released_date
        ? parse(selectedClient?.released_date, 'dd-MM-yyyy', new Date())
        : '',
      expired_date: selectedClient?.expired_date
        ? parse(selectedClient?.expired_date, 'dd-MM-yyyy', new Date())
        : '',
      street: selectedClient?.contactAddress?.streetName || '',
      number: selectedClient?.contactAddress?.streetNumber || '',
      building: selectedClient?.contactAddress?.building || '',
      stair: selectedClient?.contactAddress?.stair || '',
      floor: selectedClient?.contactAddress?.floor || '',
      apartment: selectedClient?.contactAddress?.apartment || '',
      vila: selectedClient?.contactAddress?.vila || '',
      corp: selectedClient?.contactAddress?.corp || '',
      contactFullName: selectedClient?.clientContactPerson?.contactFullName
        ? selectedClient?.clientContactPerson?.contactFullName
        : '',
      contactPhoneNumber: selectedClient?.clientContactPerson?.contactPhoneNumber
        ? selectedClient?.clientContactPerson?.contactPhoneNumber
        : '',
      contactEmail: selectedClient?.clientContactPerson?.contactEmail
        ? selectedClient?.clientContactPerson?.contactEmail
        : '',
      workStreet: selectedClient?.workAddress?.streetName || '',
      workNumber: selectedClient?.workAddress?.streetNumber || '',
      workBuilding: selectedClient?.workAddress?.building || '',
      workStair: selectedClient?.workAddress?.stair || '',
      workFloor: selectedClient?.workAddress?.floor || '',
      workApartment: selectedClient?.workAddress?.apartment || '',
      workVila: selectedClient?.workAddress?.vila || '',
      workCorp: selectedClient?.workAddress?.corp || '',
      cui: selectedClient?.clientCompanyDetails?.cui,
      companyName: selectedClient?.clientCompanyDetails?.companyName,
      tradeRegisterNumber: selectedClient?.clientCompanyDetails?.tradeRegisterNumber,
      companyAddress: selectedClient?.clientCompanyDetails?.address,
    }),
    [selectedClient]
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const values = watch();

  useEffect(() => {
    if (selectedClient && isEdit) {
      setLegalQuality(selectedClient?.company ? 'legalPerson' : 'naturalPerson');
      if (!selectedClient.company)
        setSelectedOption({
          nume: selectedClient?.contactAddress?.city,
          judet: selectedClient?.contactAddress?.county,
        });

      if (selectedClient.clientContactPerson) setCheckedContact(true);
      // if (selectedClient.workAddress) {
      //   setCheckedWorkAddress(true);
      //   setSelectedWorkOption({
      //     nume: selectedClient?.workAddress?.city || '',
      //     judet: selectedClient?.workAddress?.county || '',
      //   });
      // }
      reset(clientValues);
    }
  }, [selectedClient, reset, clientValues, isEdit]);

  const [legalQuality, setLegalQuality] = useState(''); // natural person or legal person
  const [isSubmitted, setIsSubmitted] = useState(false); // set the state of the modal

  //! ------------------------------- ADDRESS SUBMIT --------------------------- !//

  const addressSubmit = async (data) => {
    try {
      setAnchorEl(null);
      const cookies = getAuthenticatedUserFromCookies();
      const token = cookies.accessToken;
      const axiosCall = axios.create({
        baseURL: `${BASE_URL}:9000/ticket`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const contactAddress = {
        county: selectedOption?.judet,
        city: selectedOption?.nume,
        building: data.building,
        floor: data.floor,
        apartment: data.apartment,
        stair: data.stair,
        streetName: data.street,
        streetNumber: data.number,
        vila: data.vila,
        corp: data.corp,
      };

      let sendData;

      if (legalQuality === 'legalPerson') {
        sendData = {
          departmentName: departmentName && departmentName,
          ticketNumber: ticketNumber && ticketNumber,
          firstName: data.companyName,
          lastName: '',
          phoneNumber: data.contactPhoneNumber,
          email: data.contactEmail,
          isCompany: true,
          isClientContactPerson: true,
          clientContactPerson: {
            contactFullName: data.contactFullName,
            contactPhoneNumber: data.contactPhoneNumber,
            contactEmail: data.contactEmail,
          },
          clientCompanyDetails: {
            companyName: data.companyName,
            cui: data.cui,
            tradeRegisterNumber: data.tradeRegisterNumber,
            address: data.companyAddress,
            companyStreet: data.companyStreet,
            companyStreetNumber: data.companyStreetNumber,
            companyCity: data.companyCity,
            companyCounty: data.companyCounty,
            companyBuilding: data.companyBuilding,
            companyFloor: data.companyFloor,
            companyStair: data.companyStair,
            companyApartment: data.companyApartment,
            companyCorp: data.companyCorp,
          },
          workAddress: contactAddress,
          contactAddress,
        };
      } else if (legalQuality === 'naturalPerson') {
        sendData = {
          departmentName: departmentName && departmentName,
          ticketNumber: ticketNumber && ticketNumber,
          clientId: selectedClient.id,
          firstName: data.firstName,
          lastName: data.lastName,
          cnp: data.cnp,
          series_ci: data.series_ci,
          number_ci: data.number_ci,
          released_by: data.released_by,
          released_date: data.released_date ? fDate(data.released_date) : '',
          expired_date: data.expired_date ? fDate(data.expired_date) : '',
          phoneNumber: data.clientPhoneNumber,
          type: data.personType,
          email: data.clientEmailAddress,
          clientRecommendSource: data.clientRecommendSource,
          legalQuality,
          isCompany: legalQuality === 'legalPerson',
          contactAddress,
          workAddress: contactAddress,
          clientContactPerson: checkedContact
            ? {
                contactFullName: data.contactFullName,
                contactPhoneNumber: data.contactPhoneNumber,
                contactEmail: data.contactEmail,
              }
            : null,
        };
      }

      const url = isEdit ? '/edit/client' : '/add/client';

      const response = await axiosCall.post(url, { ...sendData });
      if (response.status === 200) {
        enqueueSnackbar('Clientul a fost salvat cu succes!');
        handleSetClient(response.data);
        dispatch(setSearchedClientRedux(response.data));
        if (!isEdit) closeSearchClientDialog();
      }
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
      if (error && error?.response?.status === 409) {
        enqueueSnackbar(error.response.data, { variant: 'error' });
      } else {
        enqueueSnackbar(
          'A fost o problema la salvarea clientului! Va rugam, incercati mai tarziu!',
          {
            variant: 'error',
          }
        );
      }
    }
  };

  //! ------------------------------- SEARCH CITY --------------------------- !//
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedWorkOption, setSelectedWorkOption] = useState(null); // for work address [not implemented

  const loadOptions = async (inputValue, callback) => {
    try {
      const cookies = getAuthenticatedUserFromCookies();
      const token = cookies.accessToken;

      const response = await axios.get(`${BASE_URL}:9000/ticket/cities`, {
        params: { search: inputValue },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      callback(response.data);
    } catch (error) {
      console.error(error);
      callback([]);
    }
  };

  const handleCityChange = (select) => {
    setSelectedOption(select);
  };

  const handleWorkCityChange = (select) => {
    setSelectedWorkOption(select);
  };

  const handleSearchDebounced = debounce(loadOptions, 200);

  // if response is ok, close the modal //
  useEffect(() => {
    if (isSubmitted) {
      onClose();
      setIsSubmitted(false);
      reset();
    }
  }, [isSubmitted, onClose, reset]);

  // next step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // prev step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const disabled = activeStep === steps.length - 1;

  // open the popper
  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Stack sx={{ p: 4 }}>
      <FormProvider methods={methods}>
        <Stack direction="column" spacing={4}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Stack spacing={2} direction="column">
            <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack direction="column" spacing={2}>
                {activeStep === 0 && (
                  <Box sx={{ mx: 'auto' }}>
                    <ToggleButtonGroup value={legalQuality} color="primary">
                      <ToggleButton
                        value="naturalPerson"
                        onClick={() => setLegalQuality('naturalPerson')}
                      >
                        Persoana fizica
                      </ToggleButton>
                      <ToggleButton
                        value="legalPerson"
                        onClick={() => setLegalQuality('legalPerson')}
                      >
                        Persoana juridica
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                )}
              </Stack>
            </Stack>

            {legalQuality === 'naturalPerson' && activeStep === 1 && (
              <Stack direction="column" spacing={2} sx={{ px: '24px' }}>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="firstName" label="Nume" />
                    <RHFTextField name="lastName" label="Prenume" />
                    <RHFSelect name="personType" label="Tip" inputlabelprops={{ shrink: true }}>
                      {PERSON_TYPE.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label.charAt(0) + option.label.substring(1).toLowerCase()}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <RHFTextField label="CNP" name="cnp" />
                    <RHFTextField label="Serie CI" name="series_ci" />
                    <RHFTextField label="Numar CI" name="number_ci" />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <RHFTextField label="Eliberat de" name="released_by" />
                    <RHFDate label="Data eliberare" name="released_date" />
                    <RHFDate label="Data expirare" name="expired_date" />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="clientPhoneNumber" label="Numar telefon" />
                    <RHFTextField
                      name="clientEmailAddress"
                      label={translate('newTicketForm.client.email')}
                    />
                    <RHFSelect
                      name="clientRecommendSource"
                      label="Sursa recomandare"
                      inputlabelprops={{ shrink: true }}
                    >
                      {RECOMMEND_SOURCE.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label.charAt(0) + option.label.substring(1).toLowerCase()}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Stack>
                </Stack>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedContact}
                        onChange={() => setCheckedContact(!checkedContact)}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                        Persoana contact
                      </Typography>
                    }
                    sx={{ width: '20%' }}
                  />
                </FormGroup>

                {checkedContact && (
                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="contactFullName" label="Nume complet" />
                    <RHFTextField name="contactPhoneNumber" label="Numar telefon" />
                    <RHFTextField
                      name="contactEmail"
                      label={translate('newTicketForm.client.email')}
                    />
                  </Stack>
                )}
              </Stack>
            )}

            {activeStep === 2 && (
              <>
                <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                  {legalQuality === 'naturalPerson' ? 'Adresa client' : 'Adresa persoana contact'}
                </Typography>
                <Stack spacing={2}>
                  {legalQuality === 'legalPerson' && (
                    <Stack direction="row" spacing={2}>
                      <RHFTextField name="contactFullName" label="Nume complet" />
                      <RHFTextField name="contactPhoneNumber" label="Numar telefon" />
                      <RHFTextField name="contactEmail" label="Email" />
                    </Stack>
                  )}

                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="street" label="Strada" />
                    <RHFTextField name="number" label="Numar" />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="building" label="Bloc" />
                    <RHFTextField name="stair" label="Scara" />
                    <RHFTextField name="floor" type="number" label="Etaj" />
                    <RHFTextField name="apartment" type="number" label="Apartament" />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <RHFTextField name="vila" label="Vila" />
                    <RHFTextField name="corp" label="Corp" />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <AsyncSelect
                      value={selectedOption}
                      onChange={handleCityChange}
                      loadOptions={handleSearchDebounced}
                      getOptionLabel={(option) => `${option.nume} ${option.judet}`}
                      getOptionValue={(option) => option.id}
                      placeholder={
                        !isEdit
                          ? 'Localitate'
                          : `${
                              selectedClient?.contactAddress?.city
                                ? selectedClient?.contactAddress?.city
                                : ''
                            } ${
                              selectedClient?.contactAddress?.county
                                ? selectedClient?.contactAddress?.county
                                : ''
                            }`
                      }
                      noOptionsMessage={({ inputValue }) => {
                        if (!inputValue) {
                          return 'Introdu numele localitatii!';
                        }
                        return 'Nu s-au gasit rezultate.';
                      }}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                          borderColor: 'rgb(33,43,54)',
                          zIndex: 1000,
                        }),
                        control: (provided) => ({
                          ...provided,
                          padding: '0.55rem',
                          borderRadius: '8px',
                        }),
                      }}
                    />
                  </Stack>
                </Stack>

                {/* <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedWorkAddress}
                          onChange={() => setCheckedWorkAddress(!checkedWorkAddress)}
                        />
                      }
                      label={
                        <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                          Adresa de executie e diferita de cea de contact
                        </Typography>
                      }
                      sx={{ width: '55%' }}
                    />
                  </FormGroup>
                  {checkedWorkAddress && (
                    <Stack direction="column" spacing={2}>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField name="workStreet" label="Strada" />
                        <RHFTextField name="workNumber" label="Numar" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField name="workBuilding" label="Bloc" />
                        <RHFTextField name="workStair" label="Scara" />
                        <RHFTextField name="workFloor" type="number" label="Etaj" />
                        <RHFTextField name="workApartment" type="number" label="Apartament" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <RHFTextField name="workVila" label="Vila" />
                        <RHFTextField name="workCorp" label="Corp" />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <AsyncSelect
                          value={selectedWorkOption}
                          onChange={handleWorkCityChange}
                          loadOptions={handleSearchDebounced}
                          getOptionLabel={(option) => `${option.nume} ${option.judet}`}
                          getOptionValue={(option) => option.id}
                          placeholder={
                            !isEdit
                              ? 'Cauta...'
                              : `${
                                  selectedClient?.contactAddress?.city
                                    ? selectedClient?.contactAddress?.city
                                    : ''
                                } ${
                                  selectedClient?.contactAddress?.county
                                    ? selectedClient?.contactAddress?.county
                                    : ''
                                }`
                          }
                          noOptionsMessage={({ inputValue }) => {
                            if (!inputValue) {
                              return 'Introdu numele localitatii!';
                            }
                            return 'Nu s-au gasit rezultate.';
                          }}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: '100%',
                              borderColor: 'rgb(33,43,54)',
                              zIndex: 1000,
                            }),
                            control: (provided) => ({
                              ...provided,
                              padding: '0.55rem',
                              borderRadius: '8px',
                            }),
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack> */}
              </>
            )}
          </Stack>

          {legalQuality === 'legalPerson' && activeStep === 1 && (
            <LegalQualityForm
              setPlaceholder={setPlaceholder}
              handleSetClient={handleSetClient}
              onClose={onClose}
              isEdit={isEdit}
              departmentName={departmentName}
              ticketNumber={ticketNumber}
            />
          )}

          <Stack
            direction="row"
            spacing={1}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
              startIcon={<ArrowCircleLeftIcon sx={{ fontSize: '2rem' }} />}
            >
              Inapoi
            </Button>

            <Button
              color="success"
              variant="outlined"
              onClick={handleNext}
              disabled={activeStep === 0 && legalQuality === '' ? 'disabled' : disabled}
              endIcon={<ArrowCircleRightIcon sx={{ fontSize: '2rem' }} />}
            >
              Inainte
            </Button>
          </Stack>
        </Stack>
      </FormProvider>

      <DialogActions sx={{ paddingBottom: '0 !important', px: '0 !important' }}>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="text" color="error" onClick={onClose}>
          Anuleaza
        </Button>

        <LoadingButton
          size="medium"
          loading={isSubmitting}
          variant="contained"
          disabled={!disabled}
          onClick={handleSubmit(addressSubmit)}
        >
          {!isEdit ? 'Adauga client' : 'Salveaza client'}
        </LoadingButton>

        {/* <Popover
          open={open}
          anchorEl={anchorEl}
          placement="top-end" // Alegeți locația potrivită aici
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Paper sx={{ p: 2 }}>
            <Typography>
              {!checkedWorkAddress
                ? `Esti sigur ca adresa de executie este aceeasi cu cea de contact?`
                : `Esti sigur ca adresa de executie este diferita de cea de contact?`}
            </Typography>
            <Stack
              mt={2}
              spacing={1}
              direction="row"
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <Button color="error" onClick={() => setAnchorEl(null)}>
                Nu
              </Button>
              <Button variant="contained" onClick={handleSubmit(addressSubmit)}>
                Da
              </Button>
            </Stack>
          </Paper>
        </Popover> */}
      </DialogActions>
    </Stack>
  );
}
