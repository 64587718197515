import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import axios from 'axios';
import Cookies from 'js-cookie';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Contact from './Contact';
import AddContactView from './AddContactView';

import { getAuthenticatedUserFromCookies } from '../../../../../utils/cookies';
import { BASE_URL } from '../../../../../config-global';

const HomeView = ({ makeCall, sendMessage }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [mustBeFavorite, setMustBeFavorite] = useState(false);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [contacts, setContacts] = useState([]);

  const goBack = () => {
    setIsAddingContact(false);
  };

  const getContacts = useCallback(async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    axios
      .get(`${BASE_URL}:9000/callcenter/contacts`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setContacts(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  useEffect(() => {}, []);

  const filteredContacts = contacts.filter((contact) => {
    const nameMatches = contact.contactName.toLowerCase().includes(searchTerm.toLowerCase());
    const phoneMatches = contact.phoneNumber.includes(searchTerm);

    return contact.favorite === mustBeFavorite && (nameMatches || phoneMatches);
  });
  const sortedContacts = [...filteredContacts].sort((a, b) =>
    a.contactName.localeCompare(b.contactName)
  );

  const groupedContacts = sortedContacts.reduce((acc, contact) => {
    const firstLetter = contact.contactName[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(contact);
    return acc;
  }, {});

  return (
    <>
      {contacts.length > 0 ? (
        <Box>
          {isAddingContact ? (
            <>
              <IconButton size="medium" onClick={goBack} sx={{ ml: 1 }}>
                <ArrowBackIcon fontSize="medium" sx={{ color: 'black' }} />
              </IconButton>
              <AddContactView setContacts={setContacts} />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size="medium"
                  sx={{ border: '2px solid black', mt: 2 }}
                  onClick={() => setIsAddingContact(true)}
                >
                  <PersonAddAlt1Icon fontSize="medium" sx={{ color: 'black' }} />
                </IconButton>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextField
                    label="Cauta contact"
                    variant="standard"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    margin="normal"
                    sx={{ width: '80%', mt: 0 }}
                  />
                  <IconButton onClick={() => setMustBeFavorite((state) => !state)}>
                    {mustBeFavorite ? (
                      <StarIcon sx={{ color: 'orange' }} />
                    ) : (
                      <StarBorderIcon sx={{ color: 'orange' }} />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <List>
                {Object.entries(groupedContacts).map(([letter, groupedContactsList]) => (
                  <React.Fragment key={letter}>
                    <Box sx={{ backgroundColor: '#daeaf7' }}>
                      <ListItemText>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 2, mb: 1 }}>
                          {letter}
                        </Typography>
                      </ListItemText>
                    </Box>
                    {/* "category title" */}
                    {groupedContactsList.map((contact) => (
                      <Contact
                        key={contact.phoneNumber}
                        data={contact}
                        makeCall={makeCall}
                        setContacts={setContacts}
                        sendMessage={sendMessage}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </List>
            </>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="secondary" />
          <p style={{ fontWeight: 'bold' }}>Actualizez contactele..</p>
        </Box>
      )}
    </>
  );
};

HomeView.propTypes = {
  makeCall: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};

export default HomeView;
