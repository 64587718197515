// array gol inseamna access indiferent de rol
// accessul este verificat pentru fiecare submeniu,
// de exemplu la tickets poate sa fie access indiferent de rol ca sectiune, dar lista de tickete poate avea rol admin necesar

import Cookies from 'js-cookie';

export const availableRoles = ['admin', 'user', 'moderator'];

// Updated departmentAccesses object
const departmentAccesses = {
  'call center': {
    canView: [
      'comercial',
      'acces gaz',
      'proiectare',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii',
      'call center', // Moved from canEdit
    ],
    cantView: [],
  },
  comercial: {
    canView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii',
      'comercial', // Moved from canEdit
    ],
    cantView: [],
  },
  'acces gaz': {
    canView: [
      'proiectare',
      'acces gaz', // Moved from canEdit
    ],
    cantView: [
      'call center',
      'comercial',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii',
    ],
  },
  proiectare: {
    canView: [
      'acces gaz',
      'proiectare', // Moved from canEdit
    ],
    cantView: [
      'call center',
      'comercial',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii',
    ],
  },
  avize: {
    canView: [
      'acces gaz',
      'proiectare',
      'tehnic',
      'avize', // Moved from canEdit
    ],
    cantView: ['call center', 'comercial', 'instalatori', 'carte tehnica', 'depozit', 'achizitii'],
  },
  tehnic: {
    canView: [
      'avize',
      'tehnic', // Moved from canEdit
    ],
    cantView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii',
    ],
  },
  instalatori: {
    canView: [
      'avize',
      'instalatori', // Moved from canEdit
    ],
    cantView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'tehnic',
      'carte tehnica',
      'depozit',
      'achizitii',
    ],
  },
  'carte tehnica': {
    canView: [
      'carte tehnica', // Moved from canEdit
    ],
    cantView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'avize',
      'tehnic',
      'instalatori',
      'depozit',
      'achizitii',
    ],
  },
  depozit: {
    canView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'achizitii',
      'depozit', // Moved from canEdit
    ],
    cantView: [],
  },
  achizitii: {
    canView: [
      'call center',
      'comercial',
      'acces gaz',
      'proiectare',
      'avize',
      'tehnic',
      'instalatori',
      'carte tehnica',
      'depozit',
      'achizitii', // Moved from canEdit
    ],
    cantView: [],
  },
};

// Get authenticated user's departments from cookies
const getAuthenticatedUserDepartments = () => {
  const user = Cookies.get('authenticatedUser');
  if (user) {
    const parsedUser = JSON.parse(user);
    return parsedUser.departments.map((department) => department.toLowerCase()) || [];
  }
  return [];
};

// Create specific utility function
const canViewDepartment = (targetDepartment) => () => {
  const departments = getAuthenticatedUserDepartments();
  return departments.some((userDepartment) =>
    departmentAccesses[targetDepartment]?.canView.includes(userDepartment)
  );
};

// Export specific functions
export const canViewComercial = canViewDepartment('comercial');
export const canViewAccesGaz = canViewDepartment('acces gaz');
export const canViewProiectare = canViewDepartment('proiectare');
export const canViewAvize = canViewDepartment('avize');
export const canViewTehnic = canViewDepartment('tehnic');
export const canViewInstalatori = canViewDepartment('instalatori');
export const canViewCarteTehnica = canViewDepartment('carte tehnica');
export const canViewDepozit = canViewDepartment('depozit');
export const canViewAchizitii = canViewDepartment('achizitii');

const routePermissions = {
  // TICKETS
  tickets: {
    roles: ['admin', 'agent_call_center'],
    list: {
      roles: [],
    },
    departmentName: {
      roles: [],
      id: {
        roles: [],
      },
    },
    id: {
      roles: [],
      edit: {
        roles: [],
      },
    },
    new: {
      roles: [],
    },
  },
  // VOICE
  voice: {
    roles: ['admin', 'agent_call_center'],
    general: {
      roles: [],
    },
    queues: {
      roles: [],
    },
    callReports: {
      roles: [],
    },
  },
  // ADMIN
  admin: {
    roles: ['admin'],
    general: {
      roles: [],
    },
    companies: {
      roles: [],
      list: {
        roles: [],
      },
      new: {
        roles: [],
      },
    },
    accounts: {
      roles: [],
      list: {
        roles: [],
      },
      new: {
        roles: [],
      },
    },
    custom: {
      roles: [],
      modules: {
        roles: [],
      },
    },
    predefined: {
      roles: [],
      values: {
        roles: [],
      },
      selects: {
        roles: [],
      },
    },
  },
  // BOOK
  book: {
    roles: ['admin', 'carte_tehnica'],
    list: {
      roles: [],
    },
    new: {
      roles: [],
    },
    id: {
      roles: [],
      edit: {
        roles: [],
      },
    },
  },
  // INVENTORY
  inventory: {
    roles: ['admin', 'gestionar_depozit'],
    list: {
      roles: [],
    },
    teamOrders: {
      roles: [],
    },
  },
  // CLIENTS
  clients: {
    roles: ['admin'],
    list: {
      roles: [],
    },
    add: {
      roles: [],
    },
    id: {
      roles: [],
      edit: {
        roles: {},
      },
    },
  },
  // FILES MANAGER
  filesManager: {
    roles: ['admin', 'manager_fisiere'],
  },
};

export default routePermissions;
