import { Box } from '@mui/material';
import * as React from 'react';

const HouseIllustration = () => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="a">
          <path
            d="M326.65 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="b">
          <path
            d="M353.81 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="c">
          <path
            d="M380.96 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="d">
          <path
            d="M326.65 369.39h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="e">
          <path
            d="M353.81 369.39h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="f">
          <path
            d="M380.96 369.39h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="g">
          <path
            d="M257.89 367.61h42.42v31.41h-42.42z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="h">
          <path
            d="M279.1 367.61h21.21v31.41H279.1z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="i">
          <path
            d="M181.19 172.14h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="j">
          <path
            d="M208.71 172.14h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="k">
          <path
            d="M181.19 205.7h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="l">
          <path
            d="M208.71 205.7h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="m">
          <path
            d="M181.19 239.26h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="n">
          <path
            d="M208.71 239.26h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="o">
          <path
            d="M181.19 272.82h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="p">
          <path
            d="M208.71 272.82h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="q">
          <path
            d="M181.19 306.38h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="r">
          <path
            d="M208.71 306.38h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="s">
          <path
            d="M181.19 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="t">
          <path
            d="M208.71 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="u">
          <path
            d="M242.49 172.14h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="v">
          <path
            d="M270.01 172.14h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="w">
          <path
            d="M297.53 172.14h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="x">
          <path
            d="M242.49 205.7h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="y">
          <path
            d="M270.01 205.7h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="z">
          <path
            d="M297.53 205.7h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="A">
          <path
            d="M242.49 239.26h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="B">
          <path
            d="M270.01 239.26h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="C">
          <path
            d="M297.53 239.26h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="D">
          <path
            d="M242.49 272.82h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="E">
          <path
            d="M270.01 272.82h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="F">
          <path
            d="M297.53 272.82h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="G">
          <path
            d="M242.49 306.38h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="H">
          <path
            d="M270.01 306.38h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="I">
          <path
            d="M297.53 306.38h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="J">
          <path
            d="M242.49 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="K">
          <path
            d="M270.01 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
        <clipPath id="L">
          <path
            d="M297.53 339.94h19.25v17.48h-19.25z"
            style={{
              fill: '#fff',
              stroke: '#263238',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
          />
        </clipPath>
      </defs>
      <path
        d="M441.11 368c-11.43-13-27.44-9.15-16-38.11 7.09-17.95 12.76-39.14 6-58.06-8.06-22.65-32.94-34.62-55.62-36.43-12.25-1-25.24 4.57-37.11 2.1-14.42-3-22.95-17.35-24.63-31.11a.28.28 0 0 0 0-.09h109.36s1.35-4.05-3.6-6.75-7.2-.45-9-2.7 4.95-8.1-8.55-14c0 0-.9-12.6-19.35-13s-13.5 12.6-18.45 15.75-6.3-1.8-11.25 1.35-2.7 9.45-6.75 10.35-9.45-4.05-14.39-.9-4.95 5.85-7.2 5.85-6.75-1.8-9.45 0c-.54.35-1.09.73-1.63 1.1-.81-11.49 1.52-23 3.08-34.5 4.5-33-9.27-58.62-42.23-68.36-33.87-10-72.26-4.62-102.25 13.73-51.09 31.2-19.09 92.17-58.68 109.69s-77.2 53.5-55.63 109c21.34 54.87 66.34 42.95 66.34 42.95l1.49 16.94h321.07s5.86-11.9-5.57-24.8Zm-229-108.74H74s3.82-2.73 7.09-4.92 8.74 0 11.46 0 2.73-3.27 8.74-7.09 12.55 2.18 17.47 1.09 2.18-8.73 8.19-12.55 7.64 2.18 13.64-1.64 0-19.65 22.38-19.11 23.48 15.83 23.48 15.83c16.37 7.1 8.18 14.2 10.37 16.92s4.91 0 10.91 3.28 4.39 8.14 4.39 8.14Zm70.33-131.69h-83.06s2.3-1.64 4.27-3 5.25 0 6.89 0 1.65-2 5.26-4.27 7.55 1.32 10.5.66 1.32-5.25 4.93-7.55 4.6 1.31 8.21-1 0-11.82 13.46-11.49 14.1 9.52 14.1 9.52c9.85 4.27 4.93 8.54 6.24 10.18s2.95 0 6.57 2 2.64 4.9 2.64 4.9ZM368.8 271h-38s1.05-.75 1.95-1.35 2.4 0 3.15 0 .75-.9 2.41-1.95 3.45.6 4.8.3.6-2.4 2.25-3.45 2.1.6 3.75-.45 0-5.41 6.16-5.26 6.46 4.36 6.46 4.36c4.5 1.95 2.25 3.9 2.85 4.65s1.35 0 3 .9a1.94 1.94 0 0 1 1.22 2.25Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <circle
        cx={364.23}
        cy={141.97}
        r={23.2}
        style={{
          fill: '#92e3a9',
        }}
        transform="rotate(-67.13 364.226 141.973)"
      />
      <g
        style={{
          opacity: 0.7000000000000001,
        }}
      >
        <path
          d="M441.11 368c-11.43-13-27.44-9.15-16-38.11 7.09-17.95 12.76-39.14 6-58.06-8.06-22.65-32.94-34.62-55.62-36.43-12.25-1-25.24 4.57-37.11 2.1-14.42-3-22.95-17.35-24.63-31.11a.28.28 0 0 0 0-.09h109.36s1.35-4.05-3.6-6.75-7.2-.45-9-2.7 4.95-8.1-8.55-14c0 0-.9-12.6-19.35-13s-13.5 12.6-18.45 15.75-6.3-1.8-11.25 1.35-2.7 9.45-6.75 10.35-9.45-4.05-14.39-.9-4.95 5.85-7.2 5.85-6.75-1.8-9.45 0c-.54.35-1.09.73-1.63 1.1-.81-11.49 1.52-23 3.08-34.5 4.5-33-9.27-58.62-42.23-68.36-33.87-10-72.26-4.62-102.25 13.73-51.09 31.2-19.09 92.17-58.68 109.69s-77.2 53.5-55.63 109c21.34 54.87 66.34 42.95 66.34 42.95l1.49 16.94h321.07s5.86-11.9-5.57-24.8Zm-229-108.74H74s3.82-2.73 7.09-4.92 8.74 0 11.46 0 2.73-3.27 8.74-7.09 12.55 2.18 17.47 1.09 2.18-8.73 8.19-12.55 7.64 2.18 13.64-1.64 0-19.65 22.38-19.11 23.48 15.83 23.48 15.83c16.37 7.1 8.18 14.2 10.37 16.92s4.91 0 10.91 3.28 4.39 8.14 4.39 8.14Zm70.33-131.69h-83.06s2.3-1.64 4.27-3 5.25 0 6.89 0 1.65-2 5.26-4.27 7.55 1.32 10.5.66 1.32-5.25 4.93-7.55 4.6 1.31 8.21-1 0-11.82 13.46-11.49 14.1 9.52 14.1 9.52c9.85 4.27 4.93 8.54 6.24 10.18s2.95 0 6.57 2 2.64 4.9 2.64 4.9ZM368.8 271h-38s1.05-.75 1.95-1.35 2.4 0 3.15 0 .75-.9 2.41-1.95 3.45.6 4.8.3.6-2.4 2.25-3.45 2.1.6 3.75-.45 0-5.41 6.16-5.26 6.46 4.36 6.46 4.36c4.5 1.95 2.25 3.9 2.85 4.65s1.35 0 3 .9a1.94 1.94 0 0 1 1.22 2.25Z"
          style={{
            fill: '#fff',
          }}
        />
        <circle
          cx={364.23}
          cy={141.97}
          r={23.2}
          style={{
            fill: '#fff',
          }}
          transform="rotate(-67.13 364.226 141.973)"
        />
      </g>
      <path
        d="M339 392.57s8.36-2.76 6-.62a11.18 11.18 0 0 1-1 .83h-24.78l-.75-1.51-.18-.36-.3-.61 3-2-3.17 1.7-.83-1.87.64-6.29-1 5.73L316 386l-2.77-5.3 4.5 10-6.44-2.56 6.71 3.06.78 1.58h-26.7c-1.46-.16-3.51-.58-3.88-1.75-.6-1.83 5.67-1.83 5.67-1.83s-6.57-2.76-4.18-3.37 6.57 1.83 6.57 1.83-2.39-5.51.59-4.29a6.15 6.15 0 0 1 3.44 3.52c-.32-1.08-.92-3.63.74-2.9a6.25 6.25 0 0 1 2.52 2.32c-.8-1.64-1.5-4.08 2-3.24 0 0-.73-7.52 7.16-8.89s7.47 9.5 10.15 11.65 6-.31 7.17 2.14.89 3.68.89 3.68 4.48-11.95 5.08-8.58a9.78 9.78 0 0 1-1.5 6.43s7.17-9.5 5.68-5.82a42.92 42.92 0 0 1-3.29 6.44s9.85-2.46 8.36-.92-6.25 3.37-6.25 3.37Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M327.11 390.84c.07-.55.17-1.34.35-2.28.05-.27.1-.55.17-.85a5.09 5.09 0 0 0 1.55 0 1.6 1.6 0 0 0 1-.8.76.76 0 0 0-.48-1c-.61-.16-1.48.58-1.79 1.06 0 0-.21.41-.3.61 0-.18.08-.35.12-.54s.13-.54.19-.81.09-.28.13-.42.09-.27.13-.42l.24-.61a5.2 5.2 0 0 0 1.46.23 1.57 1.57 0 0 0 1.15-.64.75.75 0 0 0-.32-1.07c-.58-.25-1.55.35-1.93.78l-.16.23.27-.6c.27-.57.57-1.13.86-1.7l.18-.36a4.73 4.73 0 0 0 1.49.24 1.55 1.55 0 0 0 1.16-.63.75.75 0 0 0-.33-1.07c-.58-.25-1.55.35-1.92.77 0 0-.1.13-.19.27.16-.32.31-.63.44-.94a12.43 12.43 0 0 0 .62-1.66 5.22 5.22 0 0 0 1.54.25 1.53 1.53 0 0 0 1.15-.63.75.75 0 0 0-.32-1.07c-.58-.25-1.55.35-1.93.77l-.42.61a11 11 0 0 0 .3-1.71 14.06 14.06 0 0 0 .05-1.56c0-.48-.05-.92-.08-1.3 0-.21 0-.41-.07-.58s.23-.73.24-.77c.1-.56 0-1.69-.6-2a.76.76 0 0 0-1 .42 1.57 1.57 0 0 0 .23 1.29 4.85 4.85 0 0 0 1.13 1c0 .19 0 .39.06.61 0 .39.05.83.05 1.3v.4c0-.22-.1-.53-.11-.56-.17-.54-.82-1.48-1.45-1.48a.74.74 0 0 0-.73.84 1.6 1.6 0 0 0 .8 1.05 4.88 4.88 0 0 0 1.48.38c0 .3 0 .6-.06.91a9.55 9.55 0 0 1-.34 1.69v.09c0-.11-.05-.74-.06-.79-.11-.55-.65-1.56-1.28-1.63a.74.74 0 0 0-.81.76 1.55 1.55 0 0 0 .67 1.13 5.31 5.31 0 0 0 1.47.55 13.12 13.12 0 0 1-.63 1.59c-.18.39-.38.77-.58 1.15 0-.15-.05-.74-.06-.78-.11-.55-.65-1.56-1.27-1.63a.75.75 0 0 0-.82.76 1.54 1.54 0 0 0 .68 1.12 4.94 4.94 0 0 0 1.46.56c-.1.17-.19.34-.28.51-.3.57-.61 1.13-.9 1.69-.09.2-.18.39-.28.59 0-.22 0-.63-.05-.67-.11-.55-.65-1.56-1.28-1.63a.74.74 0 0 0-.81.76 1.53 1.53 0 0 0 .68 1.13 5 5 0 0 0 1.4.54c-.15.33-.29.66-.41 1-.05.15-.11.29-.15.43l-.13.42c-.08.29-.16.55-.22.82s-.07.27-.1.4v-.62c0-.57-.4-1.64-1-1.81a.74.74 0 0 0-.92.63 1.55 1.55 0 0 0 .5 1.22 5.57 5.57 0 0 0 1.35.76c-.08.32-.15.64-.21.94-.2.94-.32 1.74-.4 2.29s-.12.88-.12.88a.22.22 0 0 0 .2.24.22.22 0 0 0 .24-.2s0-.3.11-.85Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M331.62 375.84a5.56 5.56 0 0 0 1.57-.16 1.56 1.56 0 0 0 .94-.92.74.74 0 0 0-.59-.94c-.63-.09-1.41.74-1.65 1.25-.02.05-.28.77-.27.77ZM326.46 390.83c-.25-.49-.59-1.21-1-2.09-.11-.25-.22-.52-.33-.8a5.26 5.26 0 0 0 1.3-.84 1.58 1.58 0 0 0 .43-1.25.75.75 0 0 0-1-.57c-.6.2-.91 1.3-.9 1.86 0 0 0 .47.08.68-.06-.17-.13-.33-.2-.51s-.19-.52-.28-.79l-.13-.41c-.05-.14-.08-.28-.12-.43s-.09-.42-.14-.64a4.79 4.79 0 0 0 1.34-.61 1.59 1.59 0 0 0 .62-1.17.75.75 0 0 0-.86-.71c-.63.11-1.1 1.14-1.18 1.7v.28c0-.21-.08-.42-.11-.64-.08-.62-.15-1.26-.22-1.89 0-.14 0-.27-.05-.4a5.1 5.1 0 0 0 1.39-.62 1.62 1.62 0 0 0 .61-1.17.75.75 0 0 0-.86-.71c-.63.11-1.1 1.14-1.18 1.7v.33c0-.35-.09-.69-.15-1a13.47 13.47 0 0 0-.4-1.72 5.27 5.27 0 0 0 1.42-.64 1.53 1.53 0 0 0 .61-1.17.75.75 0 0 0-.86-.71c-.62.11-1.1 1.15-1.17 1.71v.74a9.53 9.53 0 0 0-.69-1.59 13.14 13.14 0 0 0-.81-1.32c-.27-.4-.55-.74-.79-1.05l-.38-.45s-.21-.73-.23-.78c-.23-.51-1-1.38-1.6-1.32a.75.75 0 0 0-.63.92 1.58 1.58 0 0 0 .9 1 5 5 0 0 0 1.51.22l.38.48c.24.31.5.66.76 1.06.07.1.14.22.21.33l-.4-.4c-.44-.36-1.5-.79-2-.44a.75.75 0 0 0-.14 1.1 1.58 1.58 0 0 0 1.25.44 5.16 5.16 0 0 0 1.45-.5c.15.25.3.51.44.8a9 9 0 0 1 .65 1.58.44.44 0 0 1 0 .1c-.06-.09-.45-.59-.48-.62-.4-.4-1.4-.95-2-.66a.75.75 0 0 0-.26 1.08 1.55 1.55 0 0 0 1.19.57 5 5 0 0 0 1.53-.35 12.81 12.81 0 0 1 .35 1.68c.06.42.11.84.15 1.27l-.48-.61c-.4-.41-1.4-1-2-.66a.75.75 0 0 0-.27 1.08 1.56 1.56 0 0 0 1.19.57 5.06 5.06 0 0 0 1.52-.34l.06.58c.06.63.11 1.27.18 1.9l.09.65c-.13-.18-.39-.5-.41-.53-.4-.4-1.4-.94-2-.66a.77.77 0 0 0-.26 1.09 1.57 1.57 0 0 0 1.19.56 4.78 4.78 0 0 0 1.47-.32c.06.35.12.71.2 1.05 0 .15.07.3.11.44l.12.42.27.81c0 .13.1.25.14.38-.11-.2-.29-.52-.31-.54-.33-.46-1.24-1.15-1.84-.95a.75.75 0 0 0-.42 1 1.57 1.57 0 0 0 1.09.74 4.85 4.85 0 0 0 1.55-.11c.11.32.23.62.34.9.36.9.69 1.63.93 2.14s.39.8.39.8a.22.22 0 0 0 .39-.21s-.01-.34-.26-.84Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M322 375.83a5.15 5.15 0 0 0 1.22-1 1.55 1.55 0 0 0 .28-1.28.74.74 0 0 0-1-.46c-.58.27-.76 1.39-.69 2-.05 0 .13.75.19.74ZM440.34 392.51S451 389 448 391.73c-.53.47-.94.8-1.28 1h-31.56l-1-1.92-.23-.47-.38-.78 3.78-2.6-4 2.16-1.06-2.39.82-8-1.29 7.32-.78-2-3.53-6.76 5.74 12.71-8.22-3.27 8.57 4 1 2h-34.04c-1.85-.2-4.47-.74-5-2.23-.76-2.34 7.24-2.34 7.24-2.34s-8.38-3.52-5.33-4.3 8.38 2.34 8.38 2.34-3.05-7 .76-5.47a7.77 7.77 0 0 1 4.38 4.48c-.4-1.38-1.17-4.63 1-3.7a8.1 8.1 0 0 1 3.21 3c-1-2.09-1.91-5.2 2.5-4.13 0 0-.93-9.59 9.14-11.34s9.53 12.12 12.95 14.86 7.62-.4 9.15 2.73 1.14 4.69 1.14 4.69 5.71-15.24 6.47-10.94-1.9 8.21-1.9 8.21 9.14-12.12 7.24-7.43a54.78 54.78 0 0 1-4.19 8.21s12.57-3.13 10.66-1.17-8 4.31-8 4.31Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M425.23 390.3c.09-.7.23-1.71.45-2.9.06-.35.13-.71.21-1.09a6.61 6.61 0 0 0 2 0 2 2 0 0 0 1.33-1 1 1 0 0 0-.61-1.28c-.78-.21-1.89.74-2.28 1.34 0 0-.27.53-.39.79l.15-.69a73 73 0 0 1 .25-1c.06-.18.11-.36.16-.54s.12-.35.17-.53.2-.53.31-.79a6 6 0 0 0 1.86.3 2 2 0 0 0 1.47-.82 1 1 0 0 0-.41-1.36c-.74-.32-2 .45-2.46 1l-.21.29c.11-.25.22-.51.35-.76.34-.72.72-1.45 1.09-2.17.08-.15.15-.31.23-.46a6.56 6.56 0 0 0 1.91.31 2 2 0 0 0 1.47-.81 1 1 0 0 0-.41-1.36c-.74-.32-2 .44-2.46 1l-.24.34q.3-.6.57-1.2a16.37 16.37 0 0 0 .78-2.11 6.78 6.78 0 0 0 2 .32 2 2 0 0 0 1.47-.81 1 1 0 0 0-.42-1.36c-.74-.32-2 .44-2.45 1l-.54.78a12.47 12.47 0 0 0 .38-2.18 17 17 0 0 0 .07-2c0-.62-.06-1.18-.11-1.67 0-.27-.05-.51-.08-.74s.29-.93.3-1c.13-.72-.05-2.16-.76-2.54a1 1 0 0 0-1.32.53 2 2 0 0 0 .29 1.66 6.36 6.36 0 0 0 1.44 1.3c0 .24.05.49.07.77 0 .5.07 1.06.07 1.67v.51c-.05-.29-.13-.68-.14-.72-.21-.69-1-1.89-1.84-1.9a1 1 0 0 0-.93 1.08 2 2 0 0 0 1 1.34 6.36 6.36 0 0 0 1.89.49c0 .38 0 .76-.09 1.16a11.53 11.53 0 0 1-.43 2.15s0 .08 0 .12c0-.15-.06-.95-.08-1-.14-.7-.82-2-1.62-2.08a1 1 0 0 0-1 1 2 2 0 0 0 .86 1.44 6.78 6.78 0 0 0 1.87.71 17.77 17.77 0 0 1-.8 2c-.23.49-.48 1-.74 1.46 0-.2-.07-.94-.08-1-.14-.7-.82-2-1.62-2.08a1 1 0 0 0-1.05 1 2 2 0 0 0 .87 1.44 6.93 6.93 0 0 0 1.86.71l-.35.66c-.39.71-.78 1.43-1.15 2.15-.12.25-.24.5-.35.75 0-.29-.07-.81-.07-.85-.14-.71-.83-2-1.63-2.09a1 1 0 0 0-1 1 2 2 0 0 0 .86 1.45 6.25 6.25 0 0 0 1.8.68c-.19.42-.37.84-.53 1.26-.07.18-.14.37-.19.55s-.12.35-.17.53c-.1.37-.2.71-.28 1.05 0 .18-.08.34-.12.51 0-.29.05-.76.05-.8 0-.72-.51-2.09-1.29-2.31a1 1 0 0 0-1.18.8 2 2 0 0 0 .64 1.56 6.82 6.82 0 0 0 1.72 1c-.09.41-.19.82-.26 1.2-.25 1.2-.41 2.21-.52 2.92s-.14 1.12-.14 1.12a.29.29 0 0 0 .26.31.28.28 0 0 0 .31-.26s-.13-.55-.04-1.25Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M431 371.17a6.84 6.84 0 0 0 2-.21 2 2 0 0 0 1.2-1.17 1 1 0 0 0-.75-1.21c-.8-.11-1.8 1-2.11 1.6-.04.06-.34.98-.34.99ZM424.41 390.3c-.32-.64-.76-1.55-1.23-2.68q-.21-.48-.42-1a6.74 6.74 0 0 0 1.66-1.07 2 2 0 0 0 .54-1.59 1 1 0 0 0-1.22-.73c-.76.26-1.16 1.66-1.15 2.38 0 0 .07.59.11.86-.09-.21-.17-.42-.26-.65s-.24-.67-.36-1-.11-.36-.16-.53a5.44 5.44 0 0 1-.15-.54c-.07-.27-.12-.55-.18-.83a6.26 6.26 0 0 0 1.71-.78 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.1-.91c-.79.14-1.4 1.47-1.5 2.18v.36c-.05-.28-.1-.55-.14-.83-.1-.79-.19-1.6-.28-2.41l-.06-.51a6.12 6.12 0 0 0 1.76-.79 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.09-.91c-.8.14-1.41 1.46-1.51 2.18v.41c-.06-.44-.12-.88-.19-1.31a18.6 18.6 0 0 0-.51-2.2 6.56 6.56 0 0 0 1.81-.81 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.09-.91c-.8.14-1.41 1.47-1.51 2.18v.95a13 13 0 0 0-.89-2 16.23 16.23 0 0 0-1-1.68c-.34-.51-.69-.95-1-1.34l-.48-.57s-.28-.94-.3-1c-.29-.66-1.23-1.76-2-1.68a.93.93 0 0 0-.8 1.16 2 2 0 0 0 1.15 1.23 6.44 6.44 0 0 0 1.92.29c.15.18.32.38.49.6.3.4.64.84 1 1.36.09.13.18.28.27.43-.2-.22-.48-.5-.51-.53-.56-.45-1.91-1-2.59-.56a1 1 0 0 0-.18 1.41 2 2 0 0 0 1.59.56 6.6 6.6 0 0 0 1.85-.63c.19.32.39.65.57 1a12 12 0 0 1 .82 2s0 .08 0 .12c-.09-.12-.58-.75-.62-.79-.51-.52-1.79-1.21-2.51-.85a1 1 0 0 0-.33 1.39 2 2 0 0 0 1.51.72 6.63 6.63 0 0 0 1.95-.44 15.89 15.89 0 0 1 .45 2.14c.08.53.14 1.07.19 1.62-.11-.16-.57-.75-.61-.78-.51-.52-1.79-1.21-2.51-.85a1 1 0 0 0-.33 1.39 2 2 0 0 0 1.52.72 6.56 6.56 0 0 0 1.94-.44c0 .25 0 .5.07.75.07.81.14 1.62.23 2.43 0 .27.07.54.12.82l-.53-.67c-.51-.51-1.79-1.21-2.51-.84a1 1 0 0 0-.33 1.38 2 2 0 0 0 1.51.73 6.46 6.46 0 0 0 1.88-.42c.07.45.15.9.25 1.34.05.19.09.38.14.56l.16.54c.12.36.23.7.35 1 0 .17.11.33.17.49-.14-.26-.37-.66-.39-.69-.42-.59-1.59-1.47-2.35-1.22a1 1 0 0 0-.54 1.32 2 2 0 0 0 1.39.95 6.71 6.71 0 0 0 2-.14l.44 1.15a49.006 49.006 0 0 0 1.68 3.72.29.29 0 0 0 .38.11.3.3 0 0 0 .12-.39s-.31-.33-.62-.96Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M418.65 371.16a7.2 7.2 0 0 0 1.56-1.28 2 2 0 0 0 .35-1.64 1 1 0 0 0-1.29-.59c-.73.35-1 1.78-.88 2.5.01.06.25 1.01.26 1.01ZM345.64 392.61s-6.59-2.15-4.71-.47c.33.28.59.49.8.64h19.49l.59-1.17.14-.29.24-.48-2.34-1.59 2.47 1.32.65-1.46-.5-4.91.8 4.48.48-1.2 2.18-4.14-3.55 7.78 5.08-2-5.29 2.43-.62 1.23h21.08c1.15-.12 2.76-.45 3.06-1.36.47-1.44-4.47-1.44-4.47-1.44s5.18-2.15 3.29-2.63-5.18 1.44-5.18 1.44 1.89-4.31-.47-3.35a4.79 4.79 0 0 0-2.71 2.74c.25-.84.72-2.83-.59-2.26a5.08 5.08 0 0 0-2 1.81c.63-1.28 1.19-3.18-1.54-2.53a6.44 6.44 0 0 0-5.66-6.94c-6.22-1.07-5.89 7.42-8 9.09s-4.71-.24-5.65 1.68a4.78 4.78 0 0 0-.71 2.87s-3.53-9.33-4-6.7a7.56 7.56 0 0 0 1.18 5s-5.66-7.41-4.48-4.54a33.16 33.16 0 0 0 2.59 5s-7.77-1.91-6.59-.72 4.94 2.67 4.94 2.67Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M378.09 391.27c0-.43-.14-1-.27-1.78 0-.21-.09-.44-.14-.67a3.92 3.92 0 0 1-1.22 0 1.23 1.23 0 0 1-.82-.63.57.57 0 0 1 .38-.78c.48-.13 1.16.45 1.4.82a5.14 5.14 0 0 1 .24.48l-.09-.42-.15-.63-.1-.33c0-.11-.07-.22-.11-.33s-.12-.32-.19-.48a3.91 3.91 0 0 1-1.15.18 1.22 1.22 0 0 1-.91-.5.58.58 0 0 1 .26-.83c.46-.2 1.22.27 1.52.6l.13.18c-.07-.15-.14-.31-.22-.46-.21-.44-.44-.89-.67-1.33l-.15-.28a4.09 4.09 0 0 1-1.18.19 1.25 1.25 0 0 1-.91-.5.59.59 0 0 1 .26-.77c.46-.2 1.22.27 1.52.6 0 0 .07.11.15.21-.13-.24-.24-.49-.35-.73a9.83 9.83 0 0 1-.49-1.3 4.08 4.08 0 0 1-1.21.2 1.2 1.2 0 0 1-.91-.5.58.58 0 0 1 .25-.83c.46-.2 1.23.27 1.52.6l.33.48a7.43 7.43 0 0 1-.24-1.34v-1.21c0-.38 0-.72.06-1l.06-.45s-.18-.57-.19-.61c-.07-.43 0-1.31.48-1.54a.58.58 0 0 1 .81.32 1.21 1.21 0 0 1-.17 1 4 4 0 0 1-.9.8v.47c0 .17-.05.65 0 1v.31c0-.17.08-.42.09-.44.14-.42.65-1.15 1.14-1.16a.59.59 0 0 1 .58.66 1.24 1.24 0 0 1-.63.82 4 4 0 0 1-1.17.3v.71a7.37 7.37 0 0 0 .27 1.32.25.25 0 0 0 0 .07c0-.09 0-.58.05-.62.09-.43.51-1.21 1-1.27a.59.59 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 4.27 4.27 0 0 1-1.16.44 9.19 9.19 0 0 0 .5 1.24c.14.3.29.59.45.89 0-.12 0-.57.05-.61.09-.43.51-1.21 1-1.27a.59.59 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 3.94 3.94 0 0 1-1.16.43l.22.41c.24.44.48.88.71 1.32.08.15.15.3.22.45v-.51c.09-.44.52-1.22 1-1.28a.58.58 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 3.81 3.81 0 0 1-1.11.43c.12.25.23.51.33.76l.12.34.1.32c.06.23.12.44.17.65s.05.21.08.31v-.49c0-.44.32-1.28.8-1.41a.59.59 0 0 1 .73.49 1.21 1.21 0 0 1-.39 1 4.52 4.52 0 0 1-1.07.6c.06.25.12.5.17.73.15.74.25 1.36.31 1.79s.1.69.1.69a.19.19 0 0 1-.16.19.17.17 0 0 1-.19-.16s-.05-.33-.11-.76Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M374.53 379.56a4.43 4.43 0 0 1-1.24-.13 1.23 1.23 0 0 1-.74-.72.58.58 0 0 1 .45-.71c.49-.07 1.1.58 1.3 1 .04 0 .24.56.23.56ZM378.6 391.26c.2-.39.47-.95.76-1.63.09-.2.17-.41.26-.63a4 4 0 0 1-1-.66 1.21 1.21 0 0 1-.33-1 .59.59 0 0 1 .75-.45c.47.16.72 1 .72 1.46 0 0-.05.36-.07.53l.15-.4c.08-.2.16-.41.23-.61l.1-.33c0-.11.06-.22.09-.33s.08-.33.11-.5a4 4 0 0 1-1-.48 1.25 1.25 0 0 1-.49-.91.6.6 0 0 1 .68-.56c.49.09.87.9.93 1.33v.22c0-.16.06-.33.08-.5.07-.49.12-1 .18-1.48v-.31a4 4 0 0 1-1.09-.48 1.25 1.25 0 0 1-.48-.92.59.59 0 0 1 .68-.55c.49.09.87.89.93 1.33v.25c0-.27.07-.53.12-.8a9.68 9.68 0 0 1 .31-1.34 4.06 4.06 0 0 1-1.12-.5 1.2 1.2 0 0 1-.48-.91.59.59 0 0 1 .67-.56c.5.09.87.9.93 1.34v.58a7.38 7.38 0 0 1 .55-1.24 10.78 10.78 0 0 1 .64-1c.21-.31.43-.58.62-.82l.3-.35s.17-.57.18-.61c.18-.4.76-1.08 1.26-1a.58.58 0 0 1 .5.71 1.2 1.2 0 0 1-.71.75 4.09 4.09 0 0 1-1.19.18 4 4 0 0 0-.3.37 9 9 0 0 0-.6.83 2.33 2.33 0 0 0-.17.26l.32-.32c.34-.28 1.18-.61 1.6-.34a.58.58 0 0 1 .11.86 1.26 1.26 0 0 1-1 .34 3.93 3.93 0 0 1-1.15-.39c-.12.2-.24.4-.35.62a7.66 7.66 0 0 0-.51 1.24s0 .05 0 .08l.38-.49c.31-.31 1.11-.73 1.55-.51a.58.58 0 0 1 .21.84 1.25 1.25 0 0 1-.94.45 4.29 4.29 0 0 1-1.21-.27 9.07 9.07 0 0 0-.27 1.3c0 .33-.09.66-.12 1 .07-.1.35-.46.38-.48.31-.32 1.1-.74 1.55-.52a.58.58 0 0 1 .2.85 1.22 1.22 0 0 1-.93.44 3.81 3.81 0 0 1-1.2-.27v.46c0 .5-.09 1-.14 1.48 0 .17 0 .34-.07.51.1-.14.3-.39.32-.41.32-.31 1.11-.74 1.55-.52a.58.58 0 0 1 .21.85 1.24 1.24 0 0 1-.94.44 3.92 3.92 0 0 1-1.16-.25 7.43 7.43 0 0 1-.16.82c0 .11 0 .23-.08.34l-.1.33-.21.63c0 .11-.08.2-.11.3.08-.16.23-.4.24-.42.26-.36 1-.9 1.46-.74a.58.58 0 0 1 .33.8 1.25 1.25 0 0 1-.86.58 4.54 4.54 0 0 1-1.22-.08c-.09.24-.18.48-.27.7-.29.7-.55 1.27-.74 1.67l-.3.62a.16.16 0 0 1-.23.07.17.17 0 0 1-.07-.24s.04-.26.23-.65Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M382.16 379.55a4.21 4.21 0 0 1-1-.78 1.2 1.2 0 0 1-.22-1 .59.59 0 0 1 .8-.36c.45.21.6 1.09.54 1.53.04.06-.11.62-.12.61ZM355.39 391.48c0-.33-.1-.79-.21-1.34 0-.16-.06-.33-.09-.5a3.08 3.08 0 0 1-.93 0 .89.89 0 0 1-.61-.47.43.43 0 0 1 .28-.59c.36-.09.88.34 1.06.62l.18.36c0-.1 0-.21-.07-.31s-.08-.32-.12-.48l-.07-.25a2.25 2.25 0 0 0-.08-.24c0-.12-.09-.24-.14-.37a2.83 2.83 0 0 1-.87.14.92.92 0 0 1-.68-.37.44.44 0 0 1 .19-.63c.35-.15.92.2 1.14.45l.1.14a3.73 3.73 0 0 0-.16-.35c-.16-.33-.33-.67-.51-1l-.1-.21a2.82 2.82 0 0 1-.89.14.92.92 0 0 1-.69-.37.45.45 0 0 1 .19-.63c.35-.15.93.21 1.15.46l.11.15c-.09-.18-.18-.37-.26-.55a7.7 7.7 0 0 1-.37-1 3.59 3.59 0 0 1-.91.15 1 1 0 0 1-.69-.38.44.44 0 0 1 .19-.62c.35-.15.92.2 1.15.45l.25.36a6.27 6.27 0 0 1-.17-1 5.68 5.68 0 0 1 0-.91v-1.11a4.21 4.21 0 0 1-.14-.46c-.06-.33 0-1 .35-1.16a.45.45 0 0 1 .62.24.9.9 0 0 1-.14.76 2.73 2.73 0 0 1-.67.6v.36a6 6 0 0 0 0 .76v.24c0-.14.06-.32.07-.33.1-.32.48-.87.86-.88a.45.45 0 0 1 .43.5 1 1 0 0 1-.47.62 3.11 3.11 0 0 1-.88.22 3.44 3.44 0 0 0 0 .54 5.13 5.13 0 0 0 .19 1 .14.14 0 0 1 0 .06v-.46c.07-.33.38-.92.76-1a.44.44 0 0 1 .48.44.9.9 0 0 1-.4.67 3.17 3.17 0 0 1-.87.32 9.15 9.15 0 0 0 .37.94l.35.67v-.46c.07-.32.39-.92.76-1a.44.44 0 0 1 .48.45.88.88 0 0 1-.4.66 3 3 0 0 1-.86.32 3 3 0 0 0 .16.31c.18.33.36.66.53 1 .06.11.11.23.17.34v-.39c.07-.32.38-.91.76-1a.44.44 0 0 1 .48.45.91.91 0 0 1-.4.66 2.69 2.69 0 0 1-.84.32c.09.19.18.39.25.58a2.5 2.5 0 0 1 .09.25l.08.24c0 .17.09.33.13.49a1.89 1.89 0 0 1 .05.23v-.36c0-.34.24-1 .6-1.07a.45.45 0 0 1 .55.37.94.94 0 0 1-.3.72 3.09 3.09 0 0 1-.8.45c0 .19.09.37.12.55.12.55.19 1 .24 1.34s.07.52.07.52a.14.14 0 0 1-.12.15.13.13 0 0 1-.14-.12s.1-.07.06-.39Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M352.71 382.67a3.33 3.33 0 0 1-.93-.09 1 1 0 0 1-.56-.54.44.44 0 0 1 .36-.55c.37-.06.83.43 1 .73-.01.03.14.45.13.45ZM355.78 391.47c.14-.29.35-.71.57-1.22.06-.15.13-.31.19-.48a3.11 3.11 0 0 1-.77-.49.9.9 0 0 1-.25-.73.44.44 0 0 1 .56-.34c.36.12.55.77.54 1.1 0 0 0 .27-.05.4l.12-.3c.06-.15.11-.31.17-.47l.07-.24a2.33 2.33 0 0 0 .07-.25l.09-.38a2.79 2.79 0 0 1-.8-.36.89.89 0 0 1-.36-.68.44.44 0 0 1 .51-.42c.37.06.65.67.7 1v.17c0-.13.05-.26.06-.38.06-.37.1-.74.14-1.11a1.94 1.94 0 0 0 0-.24 3.06 3.06 0 0 1-.81-.36.94.94 0 0 1-.37-.69.46.46 0 0 1 .51-.42c.37.07.66.68.7 1v.19a6 6 0 0 1 .09-.61 8.68 8.68 0 0 1 .23-1 3.14 3.14 0 0 1-.84-.37.93.93 0 0 1-.36-.69.44.44 0 0 1 .51-.41c.37.06.65.67.7 1v.43a5.67 5.67 0 0 1 .41-.93 7 7 0 0 1 .48-.78c.16-.23.33-.43.47-.61l.22-.27s.12-.43.13-.45c.14-.31.58-.82.95-.78a.45.45 0 0 1 .38.54 1 1 0 0 1-.54.56 2.85 2.85 0 0 1-.89.13l-.23.28c-.14.18-.3.39-.45.62l-.13.2.24-.24c.26-.21.89-.46 1.2-.26a.44.44 0 0 1 .09.65.92.92 0 0 1-.74.26 3.18 3.18 0 0 1-.86-.29c-.09.15-.18.3-.27.47a5.64 5.64 0 0 0-.38.93v.05l.29-.36c.24-.24.83-.56 1.17-.39a.44.44 0 0 1 .15.64.92.92 0 0 1-.7.33 3.19 3.19 0 0 1-.91-.2 7.38 7.38 0 0 0-.21 1l-.09.75a3.42 3.42 0 0 1 .29-.36c.23-.24.83-.56 1.16-.39a.44.44 0 0 1 .16.64.94.94 0 0 1-.71.33 3.48 3.48 0 0 1-.9-.2v.34c0 .37-.07.75-.11 1.12 0 .12 0 .25-.05.37l.24-.3c.24-.24.83-.56 1.17-.39a.44.44 0 0 1 .15.64.92.92 0 0 1-.7.33 3 3 0 0 1-.88-.19c0 .21-.07.41-.11.62a2.33 2.33 0 0 1-.07.25l-.12.22-.16.47c0 .08-.06.16-.08.23.06-.12.17-.3.18-.32.2-.27.74-.67 1.09-.56a.44.44 0 0 1 .25.61.88.88 0 0 1-.64.43 2.71 2.71 0 0 1-.92-.06l-.21.53c-.21.52-.41.95-.55 1.25s-.23.47-.23.47a.14.14 0 0 1-.18.06.13.13 0 0 1 0-.18s.08-.16.23-.46Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M358.45 382.67a3.28 3.28 0 0 1-.72-.59.89.89 0 0 1-.17-.75.44.44 0 0 1 .6-.27c.34.16.46.82.41 1.15 0 .02-.11.46-.12.46Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M323.84 392.21h125.94v6.81H323.84z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M409.62 392.45v3.98M412.22 392.45v2.15M414.82 392.45v2.15M417.42 392.45v5.52M420.54 392.45v2.58"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.75px',
        }}
      />
      <path
        d="M361.03 298.95h29.09v13.97h-29.09z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M380.05 298.95h10.06v13.97h-10.06z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M349.9 320.01v-28.19h-87.31v107.2H407.32v-79.01H349.9z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M395.73 320.01h11.59v79.01h-11.59z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M349.74 313.99h57.49v16.29h-57.49zM292.38 292.59h57.49v16.29h-57.49z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M252.65 288.67h100.89v16.29H252.65zM349.74 310.24h62.78v16.29h-62.78z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M325.44 288.97v6.44M328.04 288.97v6.44M328.04 297.15v4.93M330.65 288.97v6.44M330.65 297.43V302M333.07 297.43v2.88M333.25 288.97v6.44M336.2 293.78v1.63M336.37 288.97v3.66M325.44 296.71v3.82M352.4 310.32v6.45M355 310.32v6.45M355 318.51v4.93M357.6 310.32v6.45M357.6 318.79v4.56M360.03 318.79v2.88M360.2 310.32v6.45M363.15 315.14v1.63M363.32 310.32v3.67M352.4 318.07v3.82"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.75px',
        }}
      />
      <path
        d="M326.65 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#a)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M326.65 353.29v3.46l19.25-15.64v-1.17h-2.82M345.9 348.38v-3.46l-15.39 12.51h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M326.65 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M326.65 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M353.81 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#b)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m370.24 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM361.92 357.43l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M353.81 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M353.81 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M380.96 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#c)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m397.39 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM389.08 357.43l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M380.96 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M380.96 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M326.65 369.39h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#d)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M326.65 382.74v3.45l19.25-15.63v-1.17h-2.82M345.9 377.83v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M326.65 369.39h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M326.65 364.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M353.81 369.39h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#e)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M353.81 382.74v3.45l19.25-15.63v-1.17h-2.82M357.66 386.87h4.26l11.14-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M353.81 369.39h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M353.81 364.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M380.96 369.39h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#f)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m397.39 369.39-16.43 13.35v3.45l19.25-15.63v-1.17h-2.82zM389.08 386.87l11.13-9.04v-3.46l-15.39 12.5h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M380.96 369.39h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M380.96 364.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M95.34 282.1h22.57v20.04H95.34z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M105.56 282.1h22.57v20.04h-22.57zM96.51 308.95h89.01v90.07H96.51z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M90.83 299.11h94.7v16.29h-94.7zM133.22 265.44h27.49v25.66h-27.49z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m146.97 250.93-16.65 15.02h33.3l-16.65-15.02z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="m163.62 265.95-16.65-15.02-9.08 15.02h25.73zM134.11 287.07h1.93v15.47h-1.93zM157.31 287.07h1.93v15.47h-1.93zM144.61 287.07h1.93v15.47h-1.93zM148.2 287.07h1.93v15.47h-1.93z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M99.85 320.01h4.7v18.79h-4.7zM99.85 349.76h4.7v18.79h-4.7z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M171.11 143.47h151.65v255.55H171.11z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M235.44 143.47h87.32v255.55h-87.32z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M245.58 143.47h67.04v255.55h-67.04z"
        style={{
          fill: '#92e3a9',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M235.44 143.47h87.32v17.5h-87.32z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M257.89 367.61h42.42v31.41h-42.42z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#g)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m276.47 377.83-18.16 16.53v4.49h.29l20.53-18.69v-2.33h-2.66zM269.59 398.85l9.54-8.69v-4.76l-14.77 13.45h5.23z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M257.89 367.61h42.42v31.41h-42.42z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M279.1 367.61h21.21v31.41H279.1z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#h)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m297.29 377.83-18.16 16.53v4.49h.29l20.53-18.69v-2.33h-2.66zM290.41 398.85l9.54-8.69v-4.76l-14.77 13.45h5.23z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M279.1 367.61h21.21v31.41H279.1z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 172.14h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#i)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M200.44 173.31v-1.17h-2.82l-16.43 13.34v3.46M200.44 180.58v-3.46l-15.4 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 172.14h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 167.1h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 172.14h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#j)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M208.71 185.48v3.46l19.25-15.63v-1.17h-2.82M227.96 180.58v-3.46l-15.4 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 172.14h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 167.1h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 205.7h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#k)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M181.19 219.05v3.45l19.25-15.63v-1.17h-2.82M200.44 214.14v-3.46l-15.4 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 205.7h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 200.66h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 205.7h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#l)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M227.96 206.87v-1.17h-2.82l-16.43 13.35v3.45M212.56 223.18h4.26l11.14-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 205.7h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 200.66h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 239.26h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#m)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M181.19 252.61v3.46l19.25-15.64v-1.17h-2.82M200.44 247.7v-3.46l-15.4 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 239.26h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 234.23h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 239.26h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#n)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M208.71 252.61v3.46l19.25-15.64v-1.17h-2.82M212.56 256.74h4.26l11.14-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 239.26h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 234.23h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 272.82h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#o)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m197.62 272.82-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM189.3 290.31l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 272.82h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 267.79h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 272.82h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#p)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M208.71 286.17v3.46l19.25-15.64v-1.17h-2.82M212.56 290.31h4.26l11.14-9.05v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 272.82h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 267.79h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 306.38h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#q)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m197.62 306.38-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM189.3 323.87l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 306.38h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 301.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 306.38h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#r)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m225.14 306.38-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM216.82 323.87l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 306.38h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 301.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#s)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m197.62 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM189.3 357.43l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M181.19 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M181.19 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#t)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m225.14 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM216.82 357.43l11.14-9.05v-3.46l-15.4 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M208.71 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M208.71 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 172.14h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#u)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M242.49 185.48v3.46l19.25-15.63v-1.17h-2.82M261.74 180.58v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 172.14h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 167.1h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 172.14h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#v)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M289.26 173.31v-1.17h-2.82l-16.43 13.34v3.46M289.26 180.58v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 172.14h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 167.1h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 172.14h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#w)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M297.53 185.48v3.46l19.25-15.63v-1.17h-2.82M301.39 189.62h4.26l11.13-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 172.14h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 167.1h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 205.7h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#x)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M242.49 219.05v3.45l19.25-15.63v-1.17h-2.82M246.35 223.18h4.26l11.13-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 205.7h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 200.66h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 205.7h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#y)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M270.01 219.05v3.45l19.25-15.63v-1.17h-2.82M273.87 223.18h4.26l11.13-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 205.7h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 200.66h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 205.7h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#z)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M297.53 219.05v3.45l19.25-15.63v-1.17h-2.82M316.78 214.14v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 205.7h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 200.66h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 239.26h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#A)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M242.49 252.61v3.46l19.25-15.64v-1.17h-2.82M261.74 247.7v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 239.26h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 234.23h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 239.26h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#B)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M270.01 252.61v3.46l19.25-15.64v-1.17h-2.82M289.26 247.7v-3.46l-15.39 12.5h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 239.26h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 234.23h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 239.26h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#C)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M297.53 252.61v3.46l19.25-15.64v-1.17h-2.82M301.39 256.74h4.26l11.13-9.04v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 239.26h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 234.23h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 272.82h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#D)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m258.92 272.82-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM250.61 290.31l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 272.82h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 267.79h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 272.82h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#E)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M270.01 286.17v3.46l19.25-15.64v-1.17h-2.82M289.26 281.26v-3.46l-15.39 12.51h4.26"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 272.82h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 267.79h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 272.82h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#F)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M297.53 286.17v3.46l19.25-15.64v-1.17h-2.82M301.39 290.31h4.26l11.13-9.05v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 272.82h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 267.79h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 306.38h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#G)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m258.92 306.38-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM250.61 323.87l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 306.38h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 301.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 306.38h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#H)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m286.44 306.38-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM278.13 323.87l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 306.38h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 301.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 306.38h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#I)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M316.78 307.55v-1.17h-2.82l-16.43 13.35v3.46M301.39 323.87h4.26l11.13-9.05v-3.46"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 306.38h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 301.35h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#J)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m258.92 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM250.61 357.43l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M242.49 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M242.49 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#K)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m286.44 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM278.13 357.43l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M270.01 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M270.01 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 339.94h19.25v17.48h-19.25z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          clipPath: 'url(#L)',
        }}
      >
        <g
          style={{
            opacity: 0.6000000000000001,
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="m313.96 339.94-16.43 13.35v3.46l19.25-15.64v-1.17h-2.82zM305.65 357.43l11.13-9.05v-3.46l-15.39 12.51h4.26z"
            style={{
              fill: '#92e3a9',
            }}
          />
        </g>
      </g>
      <path
        d="M297.53 339.94h19.25v17.48h-19.25z"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M297.53 334.91h19.25v7.6h-19.25z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M241.55 367.56h74.99v9.51h-74.99z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M241.55 364.81h74.99v9.51h-74.99z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M171.03 130.32h40.85v25.18h-40.85z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M197.75 130.32h14.14v25.18h-14.14z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M298.63 136.47h16.79v19.03h-16.79z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M167.42 143.47h159.03v12.04H167.42z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M231.35 143.47h95.1v12.04h-95.1z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M235.44 143.47v5.05M238.04 143.47v5.05M238.04 149.88v3.86M240.65 143.47v5.05M240.65 150.1v3.58M243.07 150.1v2.26M243.25 143.47v5.05M246.2 147.24v1.28M246.37 143.47v2.87M235.44 149.54v2.99"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.75px',
        }}
      />
      <path
        d="M131.45 392.57s8.36-2.76 6-.62a11.18 11.18 0 0 1-1 .83h-24.73l-.75-1.51-.18-.36-.3-.61 3-2-3.17 1.7-.83-1.87.64-6.29-1 5.73-.61-1.53-2.77-5.3 4.5 10-6.44-2.56 6.71 3.11.78 1.58H84.59c-1.46-.16-3.51-.58-3.88-1.75-.6-1.83 5.67-1.83 5.67-1.83s-6.57-2.76-4.18-3.37 6.57 1.83 6.57 1.83-2.39-5.51.59-4.29a6.15 6.15 0 0 1 3.44 3.52c-.32-1.08-.92-3.63.74-2.9a6.25 6.25 0 0 1 2.52 2.32c-.8-1.64-1.5-4.08 2-3.24 0 0-.73-7.52 7.16-8.89s7.47 9.5 10.15 11.65 6-.31 7.17 2.14.89 3.68.89 3.68 4.48-11.95 5.08-8.58a9.78 9.78 0 0 1-1.5 6.43s7.17-9.5 5.68-5.82a42.92 42.92 0 0 1-3.29 6.44s9.85-2.46 8.36-.92-6.31 3.28-6.31 3.28Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M119.61 390.84c.07-.55.17-1.34.35-2.28.05-.27.1-.55.17-.85a5.09 5.09 0 0 0 1.55 0 1.6 1.6 0 0 0 1-.8.76.76 0 0 0-.48-1c-.61-.16-1.48.58-1.79 1.06 0 0-.21.41-.3.61 0-.18.08-.35.12-.54s.13-.54.19-.81.09-.28.13-.42.09-.27.13-.42l.24-.61a5.2 5.2 0 0 0 1.46.23 1.57 1.57 0 0 0 1.15-.64.75.75 0 0 0-.32-1.07c-.58-.25-1.55.35-1.93.78l-.16.23.27-.6c.27-.57.57-1.13.86-1.7l.18-.36a4.73 4.73 0 0 0 1.49.24 1.55 1.55 0 0 0 1.16-.63.75.75 0 0 0-.33-1.07c-.58-.25-1.55.35-1.92.77 0 0-.1.13-.19.27.16-.32.31-.63.44-.94a12.43 12.43 0 0 0 .62-1.66 5.22 5.22 0 0 0 1.54.25 1.53 1.53 0 0 0 1.15-.63.75.75 0 0 0-.32-1.07c-.58-.25-1.55.35-1.93.77l-.42.61a11 11 0 0 0 .3-1.71 14.06 14.06 0 0 0 .05-1.56c0-.48-.05-.92-.08-1.3 0-.21 0-.41-.07-.58s.23-.73.24-.77c.1-.56 0-1.69-.6-2a.76.76 0 0 0-1 .42 1.57 1.57 0 0 0 .23 1.29 4.85 4.85 0 0 0 1.13 1c0 .19 0 .39.06.61V375.66c0-.22-.1-.53-.11-.56-.17-.54-.82-1.48-1.45-1.48a.74.74 0 0 0-.73.84 1.6 1.6 0 0 0 .8 1.05 4.88 4.88 0 0 0 1.48.38c0 .3 0 .6-.06.91a9.55 9.55 0 0 1-.34 1.69v.09c0-.11 0-.74-.06-.79-.11-.55-.65-1.56-1.28-1.63a.74.74 0 0 0-.81.76 1.55 1.55 0 0 0 .67 1.13 5.31 5.31 0 0 0 1.47.55 13.12 13.12 0 0 1-.63 1.59c-.18.39-.38.77-.58 1.15 0-.15 0-.74-.06-.78-.11-.55-.65-1.56-1.27-1.63a.75.75 0 0 0-.82.76 1.54 1.54 0 0 0 .68 1.12 4.94 4.94 0 0 0 1.46.56c-.1.17-.19.34-.28.51-.3.57-.61 1.13-.9 1.69-.09.2-.18.39-.28.59v-.67c-.11-.55-.65-1.56-1.28-1.63a.74.74 0 0 0-.81.76 1.53 1.53 0 0 0 .68 1.13 5 5 0 0 0 1.4.54c-.15.33-.29.66-.41 1 0 .15-.11.29-.15.43l-.13.42c-.08.29-.16.55-.22.82s-.07.27-.1.4v-.62c0-.57-.4-1.64-1-1.81a.74.74 0 0 0-.92.63 1.55 1.55 0 0 0 .5 1.22 5.57 5.57 0 0 0 1.35.76c-.08.32-.15.64-.21.94-.2.94-.32 1.74-.4 2.29s-.12.88-.12.88a.22.22 0 0 0 .2.24.22.22 0 0 0 .24-.2s.04-.3.11-.85Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M124.12 375.84a5.56 5.56 0 0 0 1.57-.16 1.56 1.56 0 0 0 .94-.92.74.74 0 0 0-.59-.94c-.63-.09-1.41.74-1.65 1.25-.02.05-.28.77-.27.77ZM119 390.83c-.25-.49-.59-1.21-1-2.09-.11-.25-.22-.52-.33-.8a5.26 5.26 0 0 0 1.3-.84 1.58 1.58 0 0 0 .43-1.25.75.75 0 0 0-1-.57c-.6.2-.91 1.3-.9 1.86 0 0 0 .47.08.68-.06-.17-.13-.33-.2-.51s-.19-.52-.28-.79l-.13-.41c-.05-.14-.08-.28-.12-.43s-.09-.42-.14-.64a4.79 4.79 0 0 0 1.34-.61 1.59 1.59 0 0 0 .62-1.17.75.75 0 0 0-.86-.71c-.63.11-1.1 1.14-1.18 1.7v.28c0-.21-.08-.42-.11-.64-.08-.62-.15-1.26-.22-1.89v-.4a4.93 4.93 0 0 0 1.38-.62 1.59 1.59 0 0 0 .62-1.17.75.75 0 0 0-.86-.71c-.63.11-1.1 1.14-1.18 1.7v.33c0-.35-.09-.69-.15-1a13.47 13.47 0 0 0-.4-1.72 5.27 5.27 0 0 0 1.42-.64 1.53 1.53 0 0 0 .61-1.17.75.75 0 0 0-.86-.71c-.62.11-1.1 1.15-1.17 1.71v.74a9.53 9.53 0 0 0-.69-1.59 13.14 13.14 0 0 0-.81-1.32c-.27-.4-.55-.74-.79-1.05l-.38-.45s-.21-.73-.23-.78c-.23-.51-1-1.38-1.6-1.32a.75.75 0 0 0-.63.92 1.58 1.58 0 0 0 .9 1 5 5 0 0 0 1.51.22l.38.48c.24.31.5.66.76 1.06.07.1.14.22.21.33l-.4-.4c-.44-.36-1.5-.79-2-.44a.75.75 0 0 0-.14 1.1 1.58 1.58 0 0 0 1.25.44 5.28 5.28 0 0 0 1.45-.5c.15.25.3.51.44.8a9 9 0 0 1 .65 1.58.44.44 0 0 1 0 .1c-.06-.09-.45-.59-.48-.62-.4-.4-1.4-.95-2-.66a.75.75 0 0 0-.26 1.08 1.55 1.55 0 0 0 1.19.57 5 5 0 0 0 1.53-.35 12.81 12.81 0 0 1 .35 1.68c.06.42.11.84.15 1.27l-.48-.61c-.4-.41-1.4-1-2-.66a.75.75 0 0 0-.27 1.08 1.56 1.56 0 0 0 1.19.57 5.06 5.06 0 0 0 1.52-.34l.06.58c.06.63.11 1.27.18 1.9l.09.65c-.13-.18-.39-.5-.41-.53-.4-.4-1.4-.94-2-.66a.77.77 0 0 0-.26 1.09 1.57 1.57 0 0 0 1.19.56 4.78 4.78 0 0 0 1.47-.32c.06.35.12.71.2 1.05 0 .15.07.3.11.44l.12.42.27.81c.05.13.1.25.14.38-.11-.2-.29-.52-.31-.54-.33-.46-1.24-1.15-1.84-.95a.75.75 0 0 0-.42 1 1.57 1.57 0 0 0 1.09.74 4.85 4.85 0 0 0 1.55-.11c.11.32.23.62.34.9.36.9.69 1.63.93 2.14s.39.8.39.8a.22.22 0 1 0 .39-.21s-.1-.34-.31-.84Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M114.45 375.83a5.15 5.15 0 0 0 1.22-1 1.55 1.55 0 0 0 .28-1.28.74.74 0 0 0-1-.46c-.58.27-.76 1.39-.69 2 0 0 .18.75.19.74ZM232.84 392.51s10.67-3.52 7.62-.78a16.09 16.09 0 0 1-1.28 1h-31.52l-.95-1.92-.23-.47-.38-.78 3.78-2.6-4 2.16-1.06-2.39.82-8-1.29 7.32-.78-2-3.57-6.7 5.74 12.71-8.22-3.27 8.57 4 1 2H173c-1.85-.2-4.47-.74-4.95-2.23-.76-2.34 7.24-2.34 7.24-2.34s-8.38-3.52-5.33-4.3 8.38 2.34 8.38 2.34-3-7 .76-5.47a7.77 7.77 0 0 1 4.38 4.48c-.4-1.38-1.17-4.63.95-3.7a8.1 8.1 0 0 1 3.21 3c-1-2.09-1.91-5.2 2.5-4.13 0 0-.93-9.59 9.14-11.34s9.53 12.12 13 14.86 7.62-.4 9.15 2.73 1.14 4.69 1.14 4.69 5.71-15.24 6.47-10.94-1.9 8.21-1.9 8.21 9.14-12.12 7.24-7.43a54.78 54.78 0 0 1-4.19 8.21s12.57-3.13 10.66-1.17-8.01 4.25-8.01 4.25Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M217.73 390.3c.09-.7.23-1.71.45-2.9.06-.35.13-.71.21-1.09a6.61 6.61 0 0 0 2 0 2 2 0 0 0 1.33-1 1 1 0 0 0-.61-1.28c-.78-.21-1.89.74-2.28 1.34 0 0-.27.53-.39.79.05-.23.1-.45.15-.69s.17-.69.25-1c.06-.18.11-.36.16-.54s.12-.35.17-.53.2-.53.31-.79a6 6 0 0 0 1.86.3 2 2 0 0 0 1.47-.82 1 1 0 0 0-.41-1.36c-.74-.32-2 .45-2.46 1l-.21.29c.11-.25.23-.51.35-.76.34-.72.72-1.45 1.09-2.17l.23-.46a6.56 6.56 0 0 0 1.91.31 2 2 0 0 0 1.47-.81 1 1 0 0 0-.41-1.36c-.74-.32-2 .44-2.46 1l-.24.34q.3-.6.57-1.2a16.37 16.37 0 0 0 .78-2.11 6.78 6.78 0 0 0 2 .32 2 2 0 0 0 1.47-.81 1 1 0 0 0-.42-1.36c-.74-.32-2 .44-2.45 1l-.54.78a12.47 12.47 0 0 0 .38-2.18 15 15 0 0 0 .06-2 16.13 16.13 0 0 0-.1-1.67c0-.27 0-.51-.08-.74s.29-.93.3-1c.13-.72-.05-2.16-.76-2.54a1 1 0 0 0-1.32.53 2 2 0 0 0 .28 1.66 6.6 6.6 0 0 0 1.45 1.3c0 .24.05.49.07.77 0 .5.07 1.06.07 1.67v.51c0-.29-.13-.68-.14-.72-.21-.69-1-1.89-1.84-1.9a1 1 0 0 0-.93 1.08 2 2 0 0 0 1 1.34 6.36 6.36 0 0 0 1.89.49c0 .38 0 .76-.09 1.16a11.53 11.53 0 0 1-.43 2.15v.12c0-.15-.06-.95-.08-1-.14-.7-.82-2-1.62-2.08a1 1 0 0 0-1 1 2 2 0 0 0 .86 1.44 6.78 6.78 0 0 0 1.87.71 17.77 17.77 0 0 1-.8 2c-.23.49-.48 1-.74 1.46 0-.2-.07-.94-.08-1-.14-.7-.82-2-1.63-2.08a1 1 0 0 0-1 1 2 2 0 0 0 .87 1.44 6.93 6.93 0 0 0 1.86.71l-.35.66c-.39.71-.78 1.43-1.14 2.15q-.19.38-.36.75c0-.29-.06-.81-.07-.85-.14-.71-.83-2-1.63-2.09a1 1 0 0 0-1 1 2 2 0 0 0 .86 1.45 6.25 6.25 0 0 0 1.8.68c-.19.42-.37.84-.53 1.26-.07.18-.14.37-.19.55s-.12.35-.17.53c-.1.37-.2.71-.28 1.05 0 .18-.08.34-.12.51 0-.29.05-.76.05-.8 0-.72-.51-2.09-1.29-2.31a1 1 0 0 0-1.18.8 2 2 0 0 0 .64 1.56 6.82 6.82 0 0 0 1.72 1c-.09.41-.19.82-.26 1.2-.25 1.2-.41 2.21-.52 2.92s-.14 1.12-.14 1.12a.29.29 0 0 0 .26.31.28.28 0 0 0 .31-.26s-.17-.55-.08-1.25Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M223.49 371.17a6.84 6.84 0 0 0 2-.21 2 2 0 0 0 1.2-1.17 1 1 0 0 0-.75-1.21c-.8-.11-1.8 1-2.11 1.6-.03.06-.36.98-.34.99ZM216.91 390.3c-.32-.64-.76-1.55-1.23-2.68q-.21-.48-.42-1a6.74 6.74 0 0 0 1.66-1.07 2 2 0 0 0 .54-1.59 1 1 0 0 0-1.22-.73c-.76.26-1.16 1.66-1.15 2.38 0 0 .07.59.11.86-.09-.21-.17-.42-.26-.65s-.24-.67-.36-1-.11-.36-.17-.53-.09-.36-.14-.54-.12-.55-.18-.83a6.26 6.26 0 0 0 1.71-.78 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.1-.91c-.79.14-1.4 1.47-1.5 2.18v.36c-.05-.28-.1-.55-.14-.83-.1-.79-.19-1.6-.28-2.41l-.06-.51a6.12 6.12 0 0 0 1.76-.79 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.09-.91c-.8.14-1.41 1.46-1.51 2.18v.41c-.06-.44-.12-.88-.19-1.31a18.6 18.6 0 0 0-.51-2.2 6.56 6.56 0 0 0 1.81-.81 2 2 0 0 0 .78-1.49 1 1 0 0 0-1.09-.91c-.8.14-1.41 1.47-1.51 2.18v.95a13 13 0 0 0-.89-2 16.23 16.23 0 0 0-1-1.68c-.34-.51-.69-.95-1-1.34l-.48-.57s-.28-.94-.3-1c-.29-.66-1.23-1.76-2-1.68a.93.93 0 0 0-.8 1.16 2 2 0 0 0 1.15 1.23 6.44 6.44 0 0 0 1.92.29c.15.18.32.38.49.6.3.4.64.84 1 1.36.09.13.18.28.27.43-.2-.22-.48-.5-.51-.53-.56-.45-1.91-1-2.59-.56a1 1 0 0 0-.18 1.41 2 2 0 0 0 1.59.56 6.6 6.6 0 0 0 1.85-.63c.19.32.39.65.57 1a12 12 0 0 1 .82 2s0 .08 0 .12c-.09-.12-.58-.75-.62-.79-.51-.52-1.79-1.21-2.51-.85a1 1 0 0 0-.33 1.39 2 2 0 0 0 1.51.72 6.63 6.63 0 0 0 2-.44 15.89 15.89 0 0 1 .45 2.14c.08.53.14 1.07.19 1.62-.11-.16-.57-.75-.61-.78-.51-.52-1.79-1.21-2.51-.85a1 1 0 0 0-.33 1.39 2 2 0 0 0 1.52.72 6.56 6.56 0 0 0 1.94-.44c0 .25 0 .5.07.75.07.81.14 1.62.23 2.43 0 .27.07.54.12.82l-.53-.67c-.51-.51-1.79-1.21-2.51-.84a1 1 0 0 0-.33 1.38 2 2 0 0 0 1.51.73 6.46 6.46 0 0 0 1.88-.42c.07.45.15.9.25 1.34.05.19.09.38.14.56l.16.54c.12.36.23.7.35 1 .05.17.11.33.17.49-.14-.26-.37-.66-.39-.69-.42-.59-1.59-1.47-2.35-1.22a1 1 0 0 0-.54 1.32 2 2 0 0 0 1.39.95 6.71 6.71 0 0 0 2-.14l.44 1.15a49.006 49.006 0 0 0 1.68 3.72.29.29 0 0 0 .38.11.3.3 0 0 0 .12-.39s-.36-.33-.67-.96Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M211.15 371.16a7.2 7.2 0 0 0 1.56-1.28 2 2 0 0 0 .35-1.64 1 1 0 0 0-1.29-.59c-.73.35-1 1.78-.88 2.5.01.06.25 1.01.26 1.01ZM138.14 392.61s-6.59-2.15-4.71-.47c.33.28.59.49.8.64h19.49l.59-1.17.14-.29.24-.48-2.34-1.59 2.47 1.32.65-1.46-.5-4.91.8 4.48.48-1.2 2.18-4.14-3.55 7.78 5.08-2-5.29 2.43-.62 1.23h21.08c1.15-.12 2.76-.45 3.06-1.36.47-1.44-4.47-1.44-4.47-1.44s5.18-2.15 3.29-2.63-5.18 1.44-5.18 1.44 1.89-4.31-.47-3.35a4.79 4.79 0 0 0-2.71 2.74c.25-.84.72-2.83-.59-2.26a5.08 5.08 0 0 0-2 1.81c.63-1.28 1.19-3.18-1.54-2.53a6.44 6.44 0 0 0-5.66-6.94c-6.22-1.07-5.89 7.42-8 9.09s-4.71-.24-5.65 1.68a4.78 4.78 0 0 0-.71 2.87s-3.53-9.33-4-6.7a7.56 7.56 0 0 0 1.18 5s-5.66-7.41-4.48-4.54a33.16 33.16 0 0 0 2.59 5s-7.77-1.91-6.59-.72 4.94 2.67 4.94 2.67Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M170.59 391.27c-.05-.43-.14-1-.27-1.78 0-.21-.09-.44-.14-.67a3.92 3.92 0 0 1-1.22 0 1.23 1.23 0 0 1-.82-.63.57.57 0 0 1 .38-.78c.48-.13 1.16.45 1.4.82a5.14 5.14 0 0 1 .24.48l-.09-.42c0-.21-.1-.42-.16-.63l-.09-.33c0-.11-.07-.22-.11-.33s-.12-.32-.19-.48a3.91 3.91 0 0 1-1.15.18 1.22 1.22 0 0 1-.91-.5.58.58 0 0 1 .26-.83c.46-.2 1.22.27 1.52.6l.13.18c-.07-.15-.14-.31-.22-.46-.21-.44-.44-.89-.67-1.33l-.15-.28a4.09 4.09 0 0 1-1.18.19 1.25 1.25 0 0 1-.91-.5.59.59 0 0 1 .26-.83c.46-.2 1.22.27 1.52.6 0 0 .07.11.15.21-.13-.24-.24-.49-.35-.73a9.83 9.83 0 0 1-.49-1.3 4.08 4.08 0 0 1-1.21.2 1.2 1.2 0 0 1-.91-.5.58.58 0 0 1 .25-.83c.46-.2 1.23.27 1.52.6l.33.48a7.43 7.43 0 0 1-.24-1.34v-1.21c0-.38 0-.72.06-1s0-.31.06-.45-.18-.57-.19-.61c-.07-.43 0-1.31.48-1.54a.58.58 0 0 1 .81.32 1.21 1.21 0 0 1-.17 1 4 4 0 0 1-.9.8v1.78c0-.17.09-.42.09-.44.14-.42.65-1.15 1.14-1.16a.59.59 0 0 1 .58.66 1.24 1.24 0 0 1-.63.82 4 4 0 0 1-1.17.3c0 .23 0 .47.05.71a7.37 7.37 0 0 0 .27 1.32.25.25 0 0 0 0 .07c0-.09 0-.58.05-.62.09-.43.51-1.21 1-1.27a.59.59 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 4.27 4.27 0 0 1-1.16.44A9.19 9.19 0 0 0 168 383c.14.3.29.59.45.89v-.61c.09-.43.51-1.21 1-1.27a.59.59 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 3.94 3.94 0 0 1-1.16.43l.22.41c.24.44.48.88.71 1.32.08.15.15.3.22.45v-.51c.09-.44.52-1.22 1-1.28a.58.58 0 0 1 .64.59 1.2 1.2 0 0 1-.53.88 3.81 3.81 0 0 1-1.11.43c.12.25.23.51.33.76l.12.34.1.32c.06.23.12.44.17.65s0 .21.08.31v-.49c0-.44.32-1.28.8-1.41a.59.59 0 0 1 .73.49 1.21 1.21 0 0 1-.39 1 4.34 4.34 0 0 1-1.07.6c.06.25.12.5.17.73.15.74.25 1.36.31 1.79s.1.69.1.69a.19.19 0 0 1-.16.19.17.17 0 0 1-.19-.16s0-.31-.06-.74Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M167 379.56a4.43 4.43 0 0 1-1.24-.13 1.23 1.23 0 0 1-.74-.72.58.58 0 0 1 .47-.73c.49-.07 1.1.58 1.3 1 .05.02.21.58.21.58ZM171.1 391.26c.2-.39.47-.95.76-1.63.09-.2.17-.41.26-.63a4 4 0 0 1-1-.66 1.21 1.21 0 0 1-.33-1 .59.59 0 0 1 .75-.45c.47.16.72 1 .72 1.46 0 0 0 .36-.07.53l.15-.4c.08-.2.16-.41.23-.61l.1-.33c0-.11.06-.22.09-.33s.08-.33.11-.5a4 4 0 0 1-1.05-.48 1.25 1.25 0 0 1-.49-.91.6.6 0 0 1 .68-.56c.49.09.87.9.93 1.33v.22c0-.16.06-.33.08-.5.07-.49.12-1 .18-1.48v-.31a4 4 0 0 1-1.09-.48 1.25 1.25 0 0 1-.48-.92.59.59 0 0 1 .68-.55c.49.09.87.89.93 1.33v.25c0-.27.07-.53.12-.8a9.68 9.68 0 0 1 .31-1.34 4.06 4.06 0 0 1-1.12-.5 1.2 1.2 0 0 1-.48-.91.59.59 0 0 1 .67-.56c.5.09.87.9.93 1.34v.58a7.38 7.38 0 0 1 .55-1.24 10.78 10.78 0 0 1 .64-1c.21-.31.43-.58.62-.82l.3-.35s.17-.57.18-.61c.18-.4.76-1.08 1.26-1a.58.58 0 0 1 .5.71 1.2 1.2 0 0 1-.71.75 4.09 4.09 0 0 1-1.19.18 4 4 0 0 0-.3.37 9 9 0 0 0-.6.83 2.33 2.33 0 0 0-.17.26l.32-.32c.34-.28 1.18-.61 1.6-.34a.58.58 0 0 1 .11.86 1.25 1.25 0 0 1-1 .34 3.93 3.93 0 0 1-1.15-.39c-.12.2-.24.4-.35.62a7.66 7.66 0 0 0-.51 1.24s0 .05 0 .08l.38-.49c.31-.31 1.11-.73 1.55-.51a.58.58 0 0 1 .21.84 1.25 1.25 0 0 1-.94.45 4.29 4.29 0 0 1-1.21-.27 9.07 9.07 0 0 0-.27 1.3c-.05.33-.09.66-.12 1 .07-.1.35-.46.38-.48.31-.32 1.1-.74 1.55-.52a.58.58 0 0 1 .2.85 1.22 1.22 0 0 1-.93.44 3.81 3.81 0 0 1-1.2-.27c0 .16 0 .31-.05.46 0 .5-.09 1-.14 1.48 0 .17-.05.34-.07.51.1-.14.3-.39.32-.41.32-.31 1.11-.74 1.55-.52a.58.58 0 0 1 .21.85 1.24 1.24 0 0 1-.94.44 3.92 3.92 0 0 1-1.16-.25 7.43 7.43 0 0 1-.16.82c0 .11-.05.23-.08.34l-.1.33-.21.63c0 .11-.08.2-.11.3.08-.16.23-.4.24-.42.26-.36 1-.9 1.46-.74a.58.58 0 0 1 .33.8 1.25 1.25 0 0 1-.86.58 4.54 4.54 0 0 1-1.22-.08c-.09.24-.18.48-.27.7-.29.7-.55 1.27-.74 1.67l-.3.62a.16.16 0 0 1-.23.07.17.17 0 0 1-.07-.24s.14-.26.33-.65Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M174.66 379.55a4.21 4.21 0 0 1-1-.78 1.2 1.2 0 0 1-.22-1 .59.59 0 0 1 .8-.36c.45.21.6 1.09.54 1.53.03.06-.11.62-.12.61ZM147.89 391.48c0-.33-.1-.79-.21-1.34 0-.16-.06-.33-.09-.5a3.08 3.08 0 0 1-.93 0 .89.89 0 0 1-.61-.47.43.43 0 0 1 .28-.59c.36-.09.88.34 1.06.62l.18.36c0-.1 0-.21-.07-.31s-.08-.32-.12-.48l-.07-.25a2.25 2.25 0 0 0-.08-.24c0-.12-.09-.24-.14-.37a2.83 2.83 0 0 1-.87.14.92.92 0 0 1-.68-.37.44.44 0 0 1 .19-.63c.35-.15.92.2 1.14.45l.1.14a3.73 3.73 0 0 0-.16-.35c-.16-.33-.33-.67-.51-1l-.1-.21a2.82 2.82 0 0 1-.89.14.92.92 0 0 1-.69-.37.45.45 0 0 1 .19-.63c.35-.15.93.21 1.15.46l.11.15c-.09-.18-.18-.37-.26-.55a7.7 7.7 0 0 1-.37-1 3.59 3.59 0 0 1-.91.15 1 1 0 0 1-.69-.38.44.44 0 0 1 .19-.62c.35-.15.92.2 1.15.45l.25.36a7.68 7.68 0 0 1-.18-1 7.06 7.06 0 0 1 0-.91v-1.11a4.21 4.21 0 0 1-.14-.46c-.06-.33 0-1 .35-1.16a.45.45 0 0 1 .62.24.9.9 0 0 1-.14.76 2.73 2.73 0 0 1-.67.6v.36a6 6 0 0 0 0 .76v.24c0-.14.06-.32.07-.33.1-.32.48-.87.86-.88a.45.45 0 0 1 .43.5 1 1 0 0 1-.47.62 3.11 3.11 0 0 1-.88.22 3.44 3.44 0 0 0 0 .54 5.13 5.13 0 0 0 .19 1 .14.14 0 0 1 0 .06v-.46c.07-.33.38-.92.76-1a.44.44 0 0 1 .48.44.9.9 0 0 1-.4.67 3.17 3.17 0 0 1-.87.32 9.15 9.15 0 0 0 .37.94l.35.67v-.46c.07-.32.39-.92.76-1a.44.44 0 0 1 .48.45.88.88 0 0 1-.4.66 3 3 0 0 1-.86.32 3 3 0 0 0 .16.31c.18.33.36.66.53 1 .06.11.11.23.17.34v-.39c.07-.32.38-.91.76-1a.44.44 0 0 1 .48.45.91.91 0 0 1-.4.66 2.69 2.69 0 0 1-.84.32c.09.19.18.39.25.58a2.5 2.5 0 0 1 .09.25l.08.24c0 .17.09.33.13.49a1.89 1.89 0 0 1 .05.23v-.36c0-.34.24-1 .6-1.07a.45.45 0 0 1 .55.37.94.94 0 0 1-.3.72 3.09 3.09 0 0 1-.8.45c0 .19.09.37.12.55.12.55.19 1 .24 1.34s.07.52.07.52a.14.14 0 0 1-.12.15.13.13 0 0 1 .04.01s-.07-.2-.11-.52Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M145.21 382.67a3.33 3.33 0 0 1-.93-.09 1 1 0 0 1-.56-.54.44.44 0 0 1 .36-.55c.37-.06.83.43 1 .73-.01.03.14.45.13.45ZM148.28 391.47c.14-.29.35-.71.57-1.22.06-.15.13-.31.19-.48a3.11 3.11 0 0 1-.77-.49.9.9 0 0 1-.25-.73.44.44 0 0 1 .56-.34c.36.12.55.77.54 1.1 0 0 0 .27-.05.4l.12-.3c.06-.15.11-.31.17-.47l.07-.24a2.33 2.33 0 0 0 .07-.25l.09-.38a2.79 2.79 0 0 1-.8-.36.89.89 0 0 1-.36-.68.44.44 0 0 1 .51-.42c.37.06.65.67.7 1v.17c0-.13.05-.26.06-.38.06-.37.1-.74.14-1.11a1.94 1.94 0 0 0 0-.24 3.06 3.06 0 0 1-.81-.36.94.94 0 0 1-.37-.69.46.46 0 0 1 .51-.42c.37.07.66.68.7 1v.19a6 6 0 0 1 .09-.61 8.68 8.68 0 0 1 .23-1 3.14 3.14 0 0 1-.84-.37.93.93 0 0 1-.36-.69.44.44 0 0 1 .51-.41c.37.06.65.67.7 1v.43a5.67 5.67 0 0 1 .41-.93 7 7 0 0 1 .48-.78c.16-.23.33-.43.47-.61l.22-.27s.13-.43.14-.45c.14-.31.58-.82 1-.78a.45.45 0 0 1 .38.54 1 1 0 0 1-.54.56 2.85 2.85 0 0 1-.89.13l-.23.28c-.14.18-.3.39-.45.62l-.13.2.24-.24c.26-.21.89-.46 1.2-.26a.44.44 0 0 1 .09.65.92.92 0 0 1-.74.26 3.06 3.06 0 0 1-.86-.29c-.09.15-.18.3-.27.47a5.64 5.64 0 0 0-.38.93v.05l.29-.36c.24-.24.83-.56 1.17-.39a.44.44 0 0 1 .15.64.92.92 0 0 1-.7.33 3.19 3.19 0 0 1-.91-.2 7.38 7.38 0 0 0-.21 1l-.09.75a3.42 3.42 0 0 1 .29-.36c.23-.24.83-.56 1.16-.39a.44.44 0 0 1 .16.64.94.94 0 0 1-.71.33 3.48 3.48 0 0 1-.9-.2v.34c0 .37-.07.75-.11 1.12 0 .12 0 .25-.05.37l.24-.3c.24-.24.83-.56 1.17-.39a.44.44 0 0 1 .15.64.92.92 0 0 1-.7.33 3 3 0 0 1-.88-.19c0 .21-.07.41-.11.62a2.33 2.33 0 0 1-.07.25l-.07.25-.16.47c0 .08-.06.16-.08.23.06-.12.17-.3.18-.32.2-.27.74-.67 1.09-.56a.44.44 0 0 1 .25.61.88.88 0 0 1-.64.43 2.71 2.71 0 0 1-.92-.06l-.21.53c-.21.52-.41.95-.55 1.25s-.23.47-.23.47a.14.14 0 0 1-.18.06.13.13 0 0 1-.05-.18s.02-.19.17-.49Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M151 382.67a3.28 3.28 0 0 1-.72-.59.89.89 0 0 1-.17-.75.44.44 0 0 1 .6-.27c.34.16.46.82.41 1.15-.05.02-.12.46-.12.46Z"
        style={{
          fill: '#92e3a9',
        }}
      />
      <path
        d="M66.45 392.21h174.54v6.81H66.45z"
        style={{
          fill: '#263238',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M138.74 392.21h102.25v6.81H138.74z"
        style={{
          fill: '#fff',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        d="M148.29 396.43v-3.98M150.89 392.45v2.15M153.49 392.45v2.15M156.09 392.45v5.52M159.21 392.45v2.58M221.12 392.45v3.98M223.72 392.45v2.15M226.32 392.45v2.15M228.93 392.45v5.52M232.05 392.45v2.58"
        style={{
          fill: 'none',
          stroke: '#263238',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '.75px',
        }}
      />
    </svg>
  </Box>
);
export default React.memo(HouseIllustration);
