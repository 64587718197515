import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';
import { companii } from '../../_mock/arrays/companii';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingOfferStatus: false,
  isLoadingChangeStatus: false,
  isLoadingFiles: false,
  isLoadingNewFolder: false,
  isLoadingUploadFiles: false,
  isLoadingDocument: false,
  isLoadingDepartments: false,
  error: null,
  department: '64187ffab840d578632893aa',
  allDepartments: [],
  notes: [],
  replies: [],
  tickets: [],
  client: [],
  attachments: [],
  currentTicket: [],
  generateOffer: { company: companii[0] },
  requestTypes: [],
  propertyAttachments: [],
  clientAttachments: [],
  clientOffer: '',
  generateDocuments: [],
  selectedClient: '',
  ticketFolders: [],
  ticketFiles: [],
  selectedTicketFiles: [],
  folderName: '',
  clientDocuments: [],
};

const slice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SET LOADING OFFER STATUS
    isLoadingOfferStatus(state, action) {
      state.isLoadingOfferStatus = action.payload;
    },

    // SET LOADING CHANGE STATUS
    isLoadingChangeStatus(state, action) {
      state.isLoadingChangeStatus = action.payload;
    },

    // SET LOADING FILES
    isLoadingFiles(state, action) {
      state.isLoadingFiles = action.payload;
    },

    // SET LOADING NEW FOLDER
    isLoadingNewFolder(state, action) {
      state.isLoadingNewFolder = action.payload;
    },

    // SET LOADING UPLOAD FILES
    isLoadingUploadFiles(state, action) {
      state.isLoadingUploadFiles = action.payload;
    },

    // SET LOADING DOCUMENT
    isLoadingDocument(state, action) {
      state.isLoadingDocument = action.payload;
    },

    // SET LOADING WHEN FETCHING ALL THE DEPARTMENTS DATA
    isLoadingDepartments(state, action) {
      state.isLoadingDepartments = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NOTES
    setTicketNotes(state, action) {
      state.notes.length = 0;
      state.notes = action.payload;
      state.isLoading = false;
    },

    setTicketAWB(state, action) {
      state.AWBS = [...action.payload];
    },

    // SET REPLIES
    setReplies(state, action) {
      state.replies = action.payload;
    },

    // SET DEPARTMENT
    setReduxDepartment(state, action) {
      state.department = action.payload;
      state.isLoading = false;
    },

    // ADD NOTE
    addNote(state, action) {
      state.notes.push(action.payload);
      state.isLoading = false;
    },

    // SEND REPLY
    sendReply(state, action) {
      state.replies.push(action.payload);
      state.isLoading = false;
    },

    getTickets(state, action) {
      state.tickets = action.payload;
    },

    setClient(state, action) {
      state.client = action.payload;
    },

    // SET ATTACHMENTS
    setAttachments(state, action) {
      state.attachments = action.payload;
    },

    // REMOVE ATTACHMENTS
    removeAttachment(state, action) {
      let arr = state.attachments;
      arr = arr.filter((e) => e.fileName !== action.payload);
      state.attachments = arr;
    },

    // SET TICKET
    setTicket(state, action) {
      state.currentTicket = action.payload;
    },

    // SET GENERATE OFFER
    setGenerateOffer(state, action) {
      const { createDate, dueDate, invoiceNumber, items, totalPrice, totalPriceWithoutVAT } =
        action.payload;
      state.generateOffer = {
        ...state.generateOffer,
        createDate,
        dueDate,
        invoiceNumber,
        items,
        totalPrice,
        totalPriceWithoutVAT,
      };
    },

    // GET REQUEST TYPES
    setRequestTypes(state, action) {
      state.requestTypes = action.payload;
    },

    // SET DEPARTMENTS
    setDepartments(state, action) {
      state.allDepartments = action.payload;
    },

    // ADD REQUEST TYPE:
    addRequestType(state, action) {
      state.isLoading = false;
      state.requestTypes.push(action.payload);
    },

    // UPLOAD COMERCIAL ATTACHMENTS
    uploadComercialAttachments(state, action) {
      state.isLoading = false;
      const { att, category } = action.payload;
      if (category === 'Proprietate') {
        state.currentTicket.comercialVanzari.propertyAttachments = att;
      } else if (category === 'Client') {
        state.currentTicket.comercialVanzari.clientAttachments = att;
      }
    },

    // REMOVE COMERCIAL ATTACHMENTS
    deleteComercialAttachments(state, action) {
      const { fileName, category } = action.payload;
      if (category === 'Property') {
        let property = state.currentTicket.comercialVanzari.propertyAttachments;
        property = property.filter((e) => e.fileName !== fileName);
        state.currentTicket.comercialVanzari.propertyAttachments = property;
      } else if (category === 'Client') {
        let client = state.currentTicket.comercialVanzari.clientAttachments;
        client = client.filter((e) => e.fileName !== fileName);
        state.currentTicket.comercialVanzari.clientAttachments = client;
      }
    },

    // ADD CONTACT CLIENT
    addContactClient(state, action) {
      state.currentTicket.comercialVanzari.contactHistories.push(action.payload);
    },

    // SET CLIENT FOR OFFER
    setClientForOffer(state, action) {
      state.clientOffer = action.payload;
    },

    // SET THE OFFER STATUS
    setOfferStatus(state, action) {
      const { offerStatus, refusedOfferReason } = action.payload;
      state.isLoadingOfferStatus = false;
      state.currentTicket.comercialVanzari.offerStatus = offerStatus;
      state.currentTicket.comercialVanzari.refusedOfferReason = refusedOfferReason;
    },

    // SET COMPANY OFFER
    setCompanyOffer(state, action) {
      state.generateOffer = { company: action.payload[0] };
    },

    // SET DOCUMENTS TO BE GENERATED
    setGenerateDocuments(state, action) {
      state.generateDocuments = action.payload;
    },

    // SET SEARCHED CLIENT
    setSearchedClient(state, action) {
      state.selectedClient = action.payload;
    },

    // SET TICKET STATUS
    setTicketStatus(state, action) {
      const { status, closeReason, date, user } = action.payload;
      state.isLoadingChangeStatus = false;
      state.currentTicket.state = status;
      if (closeReason) state.currentTicket.closeReason = { closeReason, date, user };
    },

    // SET TICKET FOLDERS
    setTicketFolders(state, action) {
      state.ticketFolders = action.payload;
      state.ticketFiles = [];
      state.selectedTicketFiles = [];
    },

    // SET TICKET files
    setTicketFiles(state, action) {
      state.ticketFiles = action.payload;
    },

    // SET FOLDER NAME
    setFolderName(state, action) {
      state.folderName = action.payload;
    },

    // SET SELECTED TICKET files
    setSelectedTicketFiles(state, action) {
      const { file, fileAction } = action.payload;

      if (fileAction === 'add') state.selectedTicketFiles.push(file);
      else if (fileAction === 'remove')
        state.selectedTicketFiles = state.selectedTicketFiles.filter(
          (e) => e.fileName !== file.fileName
        );
      else if (fileAction === 'selectAll') state.selectedTicketFiles = file;
      else if (fileAction === 'removeAll') state.selectedTicketFiles = [];
    },

    // CREATE NEW FOLDER
    createNewFolder(state, action) {
      state.ticketFolders.push(action.payload);
    },

    // ADD A TICKET FILE
    addTicketFile(state, action) {
      const arr = action.payload;
      arr.forEach((file) => state.ticketFiles.push(file));
    },

    // SET CLIENT DOCUMENTS
    setClientDocuments(state, action) {
      state.clientDocuments = action.payload;
    },

    // CREATE NEW CLIENT DOCUMENT
    createNewClientDocument(state, action) {
      state.clientDocuments.push(action.payload);
    },

    // ADD TICKET NUMBER FOR ATR
    createTicketNumberForATR(state, action) {
      state.currentTicket.comercialOSD.atr = action.payload;
    },

    // ADD PIF
    createPif(state, action) {
      state.currentTicket.comercialOSD.pif = action.payload;
    },

    // ADD CNP ON CLIENT
    addCNP(state, action) {
      state.currentTicket.client = action.payload;
    },

    // SET ENTIRE COMERCIAL VANZARI object
    setComercialVanzari(state, action) {
      state.currentTicket.comercialVanzari = action.payload;
    },

    // SET PROIECTANT OBJECT ON CURRENTTICKET
    setProiectant(state, action) {
      state.currentTicket.proiectant = action.payload;
    },

    addTicketAWB(state, action) {
      state.currentTicket.awbs = [...state.currentTicket.awbs, { ...action.payload }];
    },
    addTicketCommand(state, action) {
      state.currentTicket.awbs = state.currentTicket.awbs.map((a) => {
        if (a.awb === action.payload.awb) {
          return { ...a, commandId: action.payload.commandId };
        }
        return a;
      });
    },

    addTeamOrderStatus(state, action) {
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          o.statuses.push(action.payload.status);
        }
        return o;
      });
    },

    addTeamOrderDepozitStatus(state, action) {
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          o.depozitStatuses.push(action.payload.status);
        }
        return o;
      });
    },

    addTeamOrderCompletedStep(state, action) {
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          o.completedSteps.push(action.payload.step);
          if (action.payload.isPhotoUpload)
            o.uploadedImages.push({
              observations: action.payload.photoObservation,
              step: action.payload.step,
              downloadURLS: action.payload.downloadURLS,
            });
        }
        return o;
      });
    },

    changeOrdinLucrariStatus(state, action) {
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          console.log(action.payload);
          o.lucrariInstalatori[action.payload.index].efectuat = action.payload.efectuat;
          o.lucrariInstalatori[action.payload.index].reason = action.payload.reason;
        }
        return o;
      });
    },

    changeNotDoingWorkReason(state, action) {
      console.log(action.payload);
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          o.notDoingWorkReason = action.payload.reason;
          o.doingWork = action.doingWork;
        }
        return o;
      });
    },

    changeCantitateFolositaMateriale(state, action) {
      state.currentTicket.tehnic.teamOrders = state.currentTicket.tehnic.teamOrders.map((o) => {
        if (o.number === action.payload.order) {
          o.materials[action.payload.index].cantitateFolosita = action.payload.cantitate;
        }
        return o;
      });
    },

    // SET TEHNIC ON ticket
    setTehnic(state, action) {
      state.currentTicket.tehnic = action.payload;
    },

    // SET INSTALATORS
    setInstalators(state, action) {
      state.currentTicket.instalatorsList = action.payload;
    },

    // ADD INSTALATOR
    addInstalator(state, action) {
      state.currentTicket.instalatorsList.push(action.payload);
    },

    // CHANGE RESPONSIBLE USER ON TICKET
    changeResponsibleUser(state, action) {
      state.currentTicket.responsibleUser = action.payload;
    },

    // SET AVIZE
    setAvize(state, action) {
      state.currentTicket.avize = action.payload;
    },

    // PUSH AN OFFER IN THE OFFER ARRAY
    addOffer(state, action) {
      state.currentTicket.comercialVanzari.offers.push(action.payload);
    },

    changeOfferStatus(state, action) {
      const { offerNumber, status } = action.payload;

      const index = state.currentTicket.comercialVanzari.offers.findIndex(
        (e) => e.number === offerNumber
      );

      state.currentTicket.comercialVanzari.offers[index].status = status;

      if (status === 'Refuzata') {
        state.currentTicket.comercialVanzari.offers[index].reason = action.payload.reason;
      }
    },

    setUploadContractServicii(state, action) {
      state.currentTicket.comercialVanzari.contractServicii.isContractServiciiUploaded = true;
    },

    setUploadContractMandat(state, action) {
      state.currentTicket.comercialVanzari.isContractMandatUploaded = true;
    },

    setInvoiceStatus(state, action) {
      const { offerNumber, status } = action.payload;
      const index = state.currentTicket.comercialVanzari.offers.findIndex(
        (e) => e.number === offerNumber
      );
      state.currentTicket.comercialVanzari.offers[index].invoiceData.status = status;
    },

    setDepartmentsData(state, action) {
      const data = action.payload;

      console.log('data', data);

      if (data.avize) state.currentTicket.avize = data.avize;
      if (data.proiectant) state.currentTicket.proiectant = data.proiectant;
      if (data.tehnic) state.currentTicket.tehnic = data.tehnic;
      if (data.instalatori) state.currentTicket.instalatori = data.instalatori;
    },

    setInstalatoriDep(state, action) {
      state.currentTicket.instalatori = action.payload;
    },

    addToProiectant(state, action) {
      // add some data to the proiectant
      state.currentTicket.proiectant = {
        ...state.currentTicket.proiectant, // Păstrează datele existente
        ...action.payload, // Adaugă noile date din payload
      };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setTicketNotes(department, ticketNumber) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: `${BASE_URL}:9000`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      const response = await axiosCall.get(`/ticket/${department}/${ticketNumber}/notes`);
      if (response.status === 200) {
        dispatch(slice.actions.setTicketNotes(response.data, ticketNumber));
      }
      return response;
    } catch (e) {
      return null;
    }
  };
}

// ----------------------------------------------------------------------

export function setReduxDepartmentId(id) {
  return (dispatch) => {
    dispatch(slice.actions.setReduxDepartment(id));
  };
}

export function addTeamOrder(obj) {
  return (dispatch) => {
    dispatch(slice.actions.addTeamOrderStatus(obj));
  };
}

export function addTeamOrderDepozitStatus(obj) {
  return (dispatch) => {
    dispatch(slice.actions.addTeamOrderDepozitStatus(obj));
  };
}

export function addTeamOrderCompletedStep(obj) {
  return (dispatch) => {
    dispatch(slice.actions.addTeamOrderCompletedStep(obj));
  };
}

export function changeOrdinLucrariStatus(obj) {
  return (dispatch) => {
    dispatch(slice.actions.changeOrdinLucrariStatus(obj));
  };
}

export function changeNotDoingWorkReason(obj) {
  return (dispatch) => {
    dispatch(slice.actions.changeNotDoingWorkReason(obj));
  };
}

export function changeCantitateFolositaMateriale(obj) {
  return (dispatch) => {
    dispatch(slice.actions.changeCantitateFolositaMateriale(obj));
  };
}

// ----------------------------------------------------------------------

export function addReduxNote(object) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: `${BASE_URL}:9000`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      const response = await axiosCall.post(`/ticket/add/note`, { ...object });
      if (response.status === 200) {
        dispatch(slice.actions.addNote(response.data));
      }
      return response;
    } catch (e) {
      return e;
    }
  };
}

// ----------------------------------------------------------------------

export function setReplies(replies) {
  return (dispatch) => {
    dispatch(slice.actions.setReplies(replies));
  };
}

// ----------------------------------------------------------------------

export function sendReply(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const axiosCall = axios.create({
      baseURL: `${BASE_URL}:9000`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    try {
      const response = await axiosCall.post(`/ticket/reply`, formData);

      if (response.status === 200) {
        dispatch(slice.actions.sendReply(response.data));
      }
      return response;
    } catch (e) {
      return e;
    }
  };
}

// ----------------------------------------------------------------------

export function getTickets(department) {
  return async (dispatch) => {
    try {
      const cookies = getAuthenticatedUserFromCookies();
      const token = cookies.accessToken;

      const axiosCall = axios.create({
        baseURL: `${BASE_URL}:9000/ticket`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const response = await axiosCall.get(`/${department}/tickets/info`);
      if (response.status === 200) {
        dispatch(slice.actions.getTickets(response.data));
        return response;
      }
      return response;
    } catch (e) {
      return e;
    }
  };
}

// ----------------------------------------------------------------------

export function getAllTickets(data) {
  return async (dispatch) => {
    dispatch(slice.actions.getTickets(data));
  };
}

// ----------------------------------------------------------------------

export function setClientRedux(client) {
  return async (dispatch) => {
    dispatch(slice.actions.setClient(client));
  };
}

// ----------------------------------------------------------------------

export function setAttachmentsRedux(attachments) {
  return (dispatch) => {
    dispatch(slice.actions.setAttachments(attachments));
  };
}

export function deleteAttachmentRedux(fileName) {
  return (dispatch) => {
    dispatch(slice.actions.removeAttachment(fileName));
  };
}

// ----------------------------------------------------------------------

export function setTicketRedux(ticket) {
  return (dispatch) => {
    dispatch(slice.actions.setTicket(ticket));
  };
}

// ----------------------------------------------------------------------

export function setGenerateOffer(array) {
  return (dispatch) => {
    dispatch(slice.actions.setGenerateOffer(array));
  };
}

// ----------------------------------------------------------------------

export function setRequestTypes() {
  return (dispatch) => {
    const authenticatedUser = Cookies.get('authenticatedUser');
    const cookies = authenticatedUser ? JSON.parse(authenticatedUser) : null;
    const token = cookies?.accessToken;
    axios
      .get(`${BASE_URL}:9000/ticket/requestTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setRequestTypes(response.data));
      })
      .catch((error) => error);
  };
}

// ----------------------------------------------------------------------

export function setAllDepartments(departments) {
  return (dispatch) => {
    dispatch(slice.actions.setDepartments(departments));
  };
}

// ----------------------------------------------------------------------

export function addRequestType(requestName, departmentId) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(
        `${BASE_URL}:9000/ticket/add/requestType`,
        { requestName, departmentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addRequestType(response.data));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError());
        return error;
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadComercialAttachments(formData, category) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/attachments`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.uploadComercialAttachments({ att: response.data, category }));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function deleteComercialAttachments(fileName, category, departmentName, id) {
  return (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .delete(
        `${BASE_URL}:9000/ticket/comercial/attachments/${departmentName}/${id}/${category}/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.deleteComercialAttachments({ fileName, category }));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------
export function addContactClient(data) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/contact`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addContactClient(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function setClientForOffer(client) {
  return (dispatch) => {
    dispatch(slice.actions.setClientForOffer(client));
  };
}

// ----------------------------------------------------------------------

export function setOfferStatus(offerStatus, ticketId, departmentName, refusedOfferReason) {
  return (dispatch) => {
    dispatch(slice.actions.isLoadingOfferStatus(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(
        `${BASE_URL}:9000/ticket/comercial/offerStatus`,
        { ticketId, offerStatus, departmentName, refusedOfferReason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.setOfferStatus({ offerStatus, refusedOfferReason }));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.isLoadingOfferStatus(false));
        return error;
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function setCompanyOffer(value) {
  return (dispatch) => {
    dispatch(slice.actions.setCompanyOffer(value));
  };
}

// ----------------------------------------------------------------------

export function getGenerateDocuments(path) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .get(`${BASE_URL}:9000/fileManager?path=${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setGenerateDocuments(response.data));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.isLoadingOfferStatus(false));
        return error;
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function setSearchedClientRedux(client) {
  return (dispatch) => {
    dispatch(slice.actions.setSearchedClient(client));
  };
}

// ----------------------------------------------------------------------

export function changeTicketStatusRedux(data) {
  return (dispatch) => {
    dispatch(slice.actions.isLoadingChangeStatus(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const { fullName } = cookies;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/change/status`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const date = new Date();

        const closeReason = {
          status: data.status,
          date,
          user: fullName,
          closeReason: data.closeReason,
        };
        dispatch(slice.actions.setTicketStatus(closeReason));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.isLoadingChangeStatus(false));
        return error;
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function changeDepartment(data) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/ticket/change/department`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function getTicketFolders(ticketNumber, folderName) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .get(`${BASE_URL}:9000/ticket/folders/${ticketNumber}?folderName=${folderName || ''}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTicketFolders(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function setSelectedTicketFiles(file, action) {
  return (dispatch) => {
    dispatch(slice.actions.setSelectedTicketFiles({ file, fileAction: action }));
  };
}

// ----------------------------------------------------------------------

export function getTicketFiles(ticketNumber, folderName) {
  return (dispatch) => {
    dispatch(slice.actions.isLoadingFiles(true));
    dispatch(slice.actions.setFolderName(folderName));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .get(`${BASE_URL}:9000/ticket/folders/${ticketNumber}?folderName=${folderName || ''}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTicketFiles(response.data));
        return response;
      })
      .catch((error) => error)
      .finally(() => {
        dispatch(slice.actions.isLoadingFiles(false));
      });

    return request;
  };
}

// ----------------------------------------------------------------------

export function handleSendTicketFiles(ticketNumber, folderName, email, files, editorValue) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(
        `${BASE_URL}:9000/ticket/files/send`,
        { ticketNumber, folderName, email, files, desc: editorValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);
    return request;
  };
}

// ----------------------------------------------------------------------

export function handleCreateNewFolderRedux(ticketNumber, folderName) {
  return (dispatch) => {
    dispatch(slice.actions.isLoadingNewFolder(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(
        `${BASE_URL}:9000/ticket/create/folder`,
        { ticketNumber, folderName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.createNewFolder(folderName));
        return response;
      })
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingNewFolder(false)));

    return request;
  };
}

// ----------------------------------------------------------------------

export function handleUploadTicketFile(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.isLoadingUploadFiles(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/ticket/upload/files`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json', // Update the Accept header to the desired media type
        },
      })
      .then((response) => {
        dispatch(slice.actions.addTicketFile(response.data));
        return response;
      })
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingUploadFiles(false)));

    return res;
  };
}

// ----------------------------------------------------------------------

export function getClientDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.isLoadingDocument(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .get(`${BASE_URL}:9000/ticket/client/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const arr = response.data.map((e) => {
          const obj = { value: e.id, label: e.documentName };
          return obj;
        });
        dispatch(slice.actions.setClientDocuments(arr));
        return response;
      })
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingDocument(false)));

    return res;
  };
}

// ----------------------------------------------------------------------

export function createClientDocumentRedux(documentName) {
  return async (dispatch) => {
    dispatch(slice.actions.isLoadingDocument(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(
        `${BASE_URL}:9000/ticket/add/client/document`,
        { documentName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const obj = { value: response.data.id, label: response.data.documentName };
        dispatch(slice.actions.createNewClientDocument(obj));
        return response;
      })
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingDocument(false)));

    return res;
  };
}

// ----------------------------------------------------------------------

export function handleSendClientDocuments(formData) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/send/client/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response)
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function handleSendClientDocumentsBySMS(phoneNumber, desc) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(
        `${BASE_URL}:9000/clients/sms`,
        { to: phoneNumber, content: desc },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function createTicketNumberForATR(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/request/atr`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function createPif(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/request/pif`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createPif(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function punereInFunctiune(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial-osd/punere-in-functiune`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createPif(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function saveFileAndSendEmail(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/send/files/email`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadFacturaAnalizaDosar(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/factura/dosar`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addInstiintare(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/instiintare`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addCNP(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/clients/add/cnp`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addCNP(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addReanaliza(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/reanaliza`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function editAtrValues(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercialOsd/edit/atr`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.createTicketNumberForATR(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadReleveu(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/upload/releveu`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function programareReleveu(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/programare/releveu`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function getMentorInvoice(number) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .get(`${BASE_URL}:9000/mentor/invoice/${number}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function uploadDovadaPlata(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/upload/dovada`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function getDocumentsToGenerate(path, fileName) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .get(`${BASE_URL}:9000/fileManager/file/download?path=${path}&fileName=${fileName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function uploadContractDeMandat(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/upload/contractMandat`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', // Important for handling the PDF data
      })
      .then((response) => {
        dispatch(slice.actions.setUploadContractMandat());
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadContractServicii(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/upload/contractServicii`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', // Important for handling the PDF data
      })
      .then((response) => {
        dispatch(slice.actions.setUploadContractServicii(true));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addContractServicii(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/comercial/contractServicii`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data.comercialVanzari));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addWorkAddress(obj, isEdit = false) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const url = isEdit
      ? `${BASE_URL}:9000/clients/edit/workAddress`
      : `${BASE_URL}:9000/clients/add/workAddress`;

    const request = axios
      .post(url, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setSearchedClient(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function saveProiectantValues(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/proiectant/saveValues`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function setProiectant(data) {
  return (dispatch) => {
    dispatch(slice.actions.setProiectant(data));
  };
}

// ----------------------------------------------------------------------

export function uploadMemoriu(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/proiectant/upload/memoriu`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadReferatVgd(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/proiectant/upload-referat-vgd`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadPvla(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/proiectant/upload/pvla`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadReceptie(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/proiectant/upload/receptie`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function addTicketAWB(obj) {
  return (dispatch) => {
    dispatch(slice.actions.addTicketAWB(obj));
  };
}

export function addTicketCommand(obj) {
  return (dispatch) => {
    dispatch(slice.actions.addTicketCommand(obj));
  };
}

// ----------------------------------------------------------------------

export function getPvlaNumber(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/proiectant/pvlaNumber`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function anuntLucrare(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/tehnic/anunt-lucrare`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data.tehnic));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function setTehnic(obj) {
  return (dispatch) => {
    dispatch(slice.actions.setTehnic(obj));
  };
}

// ----------------------------------------------------------------------

export function getInstalators(data) {
  return (dispatch) => {
    dispatch(slice.actions.setInstalators(data));
  };
}

// ----------------------------------------------------------------------

export function addInstalator(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/instalators/add`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addInstalator(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function createTeamOrder(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/tehnic/team-order`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function editTeamOrder(obj, number) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/tehnic/edit/team-order?orderNumber=${number}`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function changeResponsibleUser(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/ticket/change/responsible-user`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.changeResponsibleUser(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function setAvize(id) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .get(`${BASE_URL}:9000/avize?ticketNumber=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

// the endpoint here to save the values from the avize form based on the step the client are
export function saveAvizeValues(obj, endpoint) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/${endpoint}`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function saveSixthStepAvize(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/sixth-step`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function modificareProiect(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/modificare-proiect`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function rezervareValidare(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/rezervare-validare`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadAviz(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/upload-aviz`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function solicitareDiriginte(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/solicitare-diriginte`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function solicitariTehnic(obj, type) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    let endpoint;

    if (type === 'topo') endpoint = 'solicitare-topo';
    else if (type === 'gis') endpoint = 'solicitare-gis';
    else if (type === 'convocare') endpoint = 'convocare-participare';

    const request = axios
      .post(`${BASE_URL}:9000/tehnic/${endpoint}`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function convocareParticipareMulti(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const request = axios
      .post(`${BASE_URL}:9000/tehnic/convocare-participare-multi`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function sendProjectToOsd(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/send-project-to-osd`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadFazaDeterminanta(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/upload-faza-determinanta`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadCerereAc(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/upload-cerere-ac`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function convocareFazaDeterminanta(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/convocare-faza-determinanta`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function anuntIncepereISC(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/incepere-isc`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadCuAc(obj, type) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/avize/upload-${type}`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function generateOrdinDeServiciu(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/tehnic/semnatura-client`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob', // Important for handling the PDF data
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function createOffer(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/offer/create`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addOffer(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function changeOfferStatus(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/offer/change-status`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.changeOfferStatus(obj));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function createInvoice(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/mentor/iesiri-clienti`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function uploadOferta(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/offer/upload-oferta`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function uploadFactura(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/offer/upload-invoice`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function createPaymentLink(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/api/payment/invoice/create-payment-link`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setComercialVanzari(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function getInvoiceStatus(paymentId, offerNumber) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .get(`${BASE_URL}:9000/api/payment/invoice/status?id=${paymentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const object = { status: response.data, offerNumber };
        dispatch(slice.actions.setInvoiceStatus(object));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function incasareMentor(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/mentor/casa-banca`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function deleteInstalator(id) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .delete(`${BASE_URL}:9000/instalators/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setInstalators(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function getAvizeFoldersAndFiles() {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .get(`${BASE_URL}:9000/avize/folders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function uploadAvize(formData) {
  return async (dispatch) => {
    dispatch(slice.actions.isLoadingUploadFiles(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/upload-avize`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response)
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingUploadFiles(false)));

    return res;
  };
}

// ----------------------------------------------------------------------

export function getAllDepartmentsData(ticketNumber) {
  return async (dispatch) => {
    dispatch(slice.actions.isLoadingDepartments(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .get(`${BASE_URL}:9000/ticket/get/departments-data?ticketNumber=${ticketNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setDepartmentsData(response.data));
        return response;
      })
      .catch((error) => error)
      .finally(() => dispatch(slice.actions.isLoadingDepartments(false)));

    return res;
  };
}

// ----------------------------------------------------------------------

export function getProjectFromSpaces(ticketNumber, folderName) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .get(`${BASE_URL}:9000/ticket/folders/${ticketNumber}?folderName=${folderName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function anunturiLucrariAvize(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/anunturi-lucrari`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function uploadPlanCirculatieRutiera(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/upload-circulatie-rutiera`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function dateAnuntIsc(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/date-anunt-isc`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function contravaloarePlataAmplasamentReq(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/plata-amplasament`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function uploadDovadaPlataIsc(formData, type) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    let endpoint;

    if (type === 'anunt') endpoint = `upload-anunt-isc`;
    else if (type === 'referat') endpoint = `upload-referat-verificator`;
    else if (type === 'proiect') endpoint = 'upload-proiect-pth';
    else endpoint = `upload-dovada-plata-isc`;

    const res = axios
      .post(`${BASE_URL}:9000/avize/${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function dateFazeDeterminante(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/date-faze-determinante`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function datePrimarieADPReq(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/date-primarie-adp`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function dateAvizPolitie(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/date-depunere-aviz-politie`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function uploadSevenStepFiles(formData, type) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    let endpoint;

    if (type === 'calitate') endpoint = `upload-control-calitate`;
    else if (type === 'faze') endpoint = 'upload-faze-determinante';
    else if (type === 'convocare-faza') endpoint = 'upload-convocare-faza';
    else if (type === 'predare-amplasament') endpoint = 'upload-predare-amplasament';
    else if (type === 'dovada-amplasament') endpoint = 'upload-dovada-amplasament';
    else if (type === 'cerere-amplasament') endpoint = 'upload-cerere-amplasament';
    else if (type === 'primarie-adp') endpoint = 'upload-primarie-adp';
    else if (type === 'cerere-politie') endpoint = 'upload-cerere-politie';
    else if (type === 'plata-aviz-politie') endpoint = 'upload-plata-aviz-politie';
    else if (type === 'aviz-politie') endpoint = 'upload-aviz-politie';
    else if (type === 'cerere-cu') endpoint = 'upload-cerere-cu';

    const res = axios
      .post(`${BASE_URL}:9000/avize/${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function fazaParticipare(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/faza-cu-participare`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function datePredareAmplasament(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/avize/date-predare-amplasament`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setAvize(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function getPrimAjutorNumber(payload) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = axios
    .post(`${BASE_URL}:9000/tehnic/numar-prim-ajutor`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}

// ----------------------------------------------------------------------

export function uploadTehnicFiles(formData, type) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    let endpoint;

    if (type === 'prim-ajutor') endpoint = `upload-decizie-prim-ajutor`;
    else if (type === 'decizie-sudor') endpoint = 'upload-decizie-sudor';
    else if (type === 'decizie-egd') endpoint = 'upload-decizie-egd';
    else if (type === 'decizie-masinist') endpoint = 'upload-decizie-masinist';
    else if (type === 'plan-ssm') endpoint = 'upload-plan-ssm';
    else if (type === 'topo') endpoint = 'upload-topo';
    else if (type === 'gis') endpoint = 'upload-gis';
    else if (type === 'diagrama') endpoint = 'upload-diagrama';

    const res = axios
      .post(`${BASE_URL}:9000/tehnic/${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function getNumireSudorNumber(payload) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = axios
    .get(`${BASE_URL}:9000/tehnic/numar-decizie-sudor`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}

// ----------------------------------------------------------------------

export function getDecizieEgdNumber(payload) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = axios
    .get(`${BASE_URL}:9000/tehnic/numar-decizie-egd`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}

// ----------------------------------------------------------------------

export function getDecizieMasinistNumber(payload) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = axios
    .get(`${BASE_URL}:9000/tehnic/numar-decizie-masinist`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}

// ----------------------------------------------------------------------

export function programareDiagrama(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const request = axios
      .post(`${BASE_URL}:9000/tehnic/programare-diagrama`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setTehnic(response.data));
        return response;
      })
      .catch((error) => error);

    return request;
  };
}

// ----------------------------------------------------------------------

export function sendNotification(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/notifications/notify`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function setInstalatoriDep(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/instalators/upload-proba-rezistenta`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(slice.actions.setInstalatoriDep(response.data));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function saveConsumatori(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/consumatori`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data.proiectant));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function createBonConsum(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/mentor/bon-consum`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response)
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function createNotaDeSantier(payload) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;

  const res = axios
    .post(`${BASE_URL}:9000/proiectant/nota-de-santier`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return res;
}

// ----------------------------------------------------------------------

export function uploadNotaDeSantier(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/upload-nota-santier`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data.proiectant));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function uploadDispozitieDeSantier(formData) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/upload-dispozitie-santier`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data.proiectant));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

export function savePvlaData(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/pvla-data`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setProiectant(response.data.proiectant));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function getReceptieNumber(obj) {
  const cookies = getAuthenticatedUserFromCookies();
  const token = cookies.accessToken;
  const request = axios
    .post(`${BASE_URL}:9000/proiectant/receptie-number`, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response)
    .catch((error) => error);

  return request;
}

// ----------------------------------------------------------------------

export function saveReceptieData(payload) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/proiectant/receptie-data`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addToProiectant({ ...payload }));
        return response;
      })
      .catch((error) => error);

    return res;
  };
}
