import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';

import { getAuthenticatedUserFromCookies } from '../../../../../utils/cookies';
import { BASE_URL } from '../../../../../config-global';

const AddContactView = ({ setContacts }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');

  const handleAddContact = async () => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    axios
      .post(
        `${BASE_URL}:9000/callcenter/contact`,
        {
          firstName,
          lastName,
          phoneNumber,
          address,
          notes,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setContacts((state) => {
          const newData = [...state, response.data];
          Cookies.set('virtual_phone_contacts', JSON.stringify(newData));
          return newData;
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <Box sx={{ p: '20px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <TextField
        label="Nume"
        variant="standard"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      <TextField
        label="Prenume"
        variant="standard"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Număr de telefon"
        variant="standard"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Adaugă adresă (optional)"
        variant="standard"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Adaugă notițe (opțional)"
        variant="standard"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Button
        disabled={!firstName || phoneNumber.length <= 5}
        variant="contained"
        color="primary"
        sx={{ mt: 5 }}
        onClick={handleAddContact}
      >
        Adaugă Contact
      </Button>
    </Box>
  );
};
AddContactView.propTypes = { setContacts: PropTypes.func.isRequired };

export default AddContactView;
