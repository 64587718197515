import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';
// utils
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  deleteLoading: false,
  searchLoading: false,
  error: null,
  list: [],
  clientSearch: [],
  isClientSearched: false,
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // DELETE LOADING
    deleteLoading(state, action) {
      state.deleteLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENTS
    getClientsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    // DELETE CLIENTS
    deleteClientsSuccess(state, action) {
      const clientsToDelete = action.payload;

      // Filter out the clients that have IDs present in clientsToDelete array
      const updatedList = state.list.filter(
        (client) => !clientsToDelete.some((id) => id === client.id)
      );

      // Return the updated state with the modified list
      return {
        ...state,
        list: updatedList,
      };
    },

    // ADD CLIENT
    addClientSuccess(state, action) {
      state.list.push(action.payload);
    },

    // EDIT CLIENT
    editClientSuccess(state, action) {
      const editedClient = action.payload;

      // Find the index of the client with the same ID as the edited client
      const index = state.list.findIndex((client) => client.id === editedClient.clientId);
      console.log(editedClient, index);

      // Update the client with the new data
      state.list[index] = editedClient;
    },

    // SEARCH CLIENT
    searchClient(state, action) {
      state.clientSearch = action.payload;
      state.isClientSearched = true;
    },

    // set search loading
    searchLoading(state, action) {
      state.searchLoading = action.payload;
    },

    // clear search
    clearSearch(state) {
      state.clientSearch = [];
      state.isClientSearched = false;
    },
  },
});

// Reducer
export default slice.reducer;

// --------------------------------------------------------------------------------

export function getClientsRedux() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const res = await axios
      .get(`${BASE_URL}:9000/clients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.getClientsSuccess(response.data));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return res;
  };
}

// --------------------------------------------------------------------------------

export function deleteClientsRedux(clientIds) {
  return async (dispatch) => {
    dispatch(slice.actions.deleteLoading(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const payload = {
      clientIds,
    };

    const res = await axios
      .delete(`${BASE_URL}:9000/clients`, {
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.deleteLoading(false));
        dispatch(slice.actions.deleteClientsSuccess(clientIds));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.deleteLoading(false));
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return res;
  };
}

// --------------------------------------------------------------------------------

export function addClientRedux(client) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = await axios
      .post(`${BASE_URL}:9000/ticket/add/client`, client, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addClientSuccess(client));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return res;
  };
}

// --------------------------------------------------------------------------------

export function editClientRedux(client) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const res = await axios
      .post(`${BASE_URL}:9000/ticket/edit/client`, client, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.editClientSuccess(client));
        return response;
      })
      .catch((error) => {
        dispatch(slice.actions.hasError(error));
        return error;
      });

    return res;
  };
}

// --------------------------------------------------------------------------------

export function searchClient(inputValue) {
  return async (dispatch) => {
    dispatch(slice.actions.searchLoading(true));
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .get(`${BASE_URL}:9000/ticket/search/client`, {
        params: { value: inputValue },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.searchClient(res.data));
        return res;
      })
      .catch((error) => error);

    dispatch(slice.actions.searchLoading(false));
    return response;
  };
}

// --------------------------------------------------------------------------------

export function clearSearchClient() {
  return async (dispatch) => {
    dispatch(slice.actions.clearSearch());
  };
}
