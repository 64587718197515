import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect } from 'react';
import { paramCase } from 'change-case';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
import { _userList } from '../../_mock/arrays';
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';
import { useSelector } from '../../redux/store';
import NewClientForm from '../../sections/@dashboard/clients/NewClientForm';
import AddClientAddressDialog from '../../sections/@dashboard/tickets/AddClientAddressDialog';

// ----------------------------------------------------------------------

export default function ClientEditPage() {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const { list } = useSelector((state) => state.clients);

  const { id } = useParams();

  const currentClient = list.find((user) => user.id === id);

  const redirect = useCallback(() => {
    if (!currentClient) {
      navigate(PATH_DASHBOARD.clients.list);
    }
  }, [currentClient, navigate]);

  useEffect(() => {
    redirect();
  }, [currentClient, redirect]);
  return (
    <>
      <Helmet>
        <title> Editare client | Mastergaz</title>
      </Helmet>

      <Container maxWidth="false">
        <CustomBreadcrumbs
          heading="Editare client"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Clienti',
              href: PATH_DASHBOARD.clients.list,
            },
            { name: `${currentClient?.firstName} ${currentClient?.lastName}` },
          ]}
        />

        {/* <NewClientForm isEdit currentClient={currentClient} /> */}
        <AddClientAddressDialog client={currentClient} />
      </Container>
    </>
  );
}
