import * as React from 'react';

const FileUploadIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path
      d="m52.93 237.07 1.89 34.72a2.34 2.34 0 0 0 2.32 2.21l62.48.46a2.34 2.34 0 0 0 2.36-2.33l.2-27a2.34 2.34 0 0 0-2.34-2.36H81.41a2.31 2.31 0 0 1-1.65-.69l-6.63-6.62a2.33 2.33 0 0 0-1.64-.69l-16.2-.12a2.34 2.34 0 0 0-2.36 2.42Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M53.81 238.42h68.27v40.79H53.81z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(.42 88.257 258.874)"
    />
    <path
      d="m48.5 241.79 4.9 42.58a1.51 1.51 0 0 0 1.49 1.33l66.53.49a1.49 1.49 0 0 0 1.51-1.38l3.66-42.51a1.51 1.51 0 0 0-1.49-1.64l-75.1-.55a1.51 1.51 0 0 0-1.5 1.68ZM336.6 243.07l1.63 29.9a2 2 0 0 0 2 1.9l53.8.4a2 2 0 0 0 2-2l.17-23.27a2 2 0 0 0-2-2h-33.08a2 2 0 0 1-1.43-.59l-5.69-5.78a2 2 0 0 0-1.41-.59l-13.95-.1a2 2 0 0 0-2.04 2.13Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M337.36 244.23h58.78v35.13h-58.78z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(.42 366.565 261.947)"
    />
    <path
      d="m332.79 247.13 4.21 36.66a1.29 1.29 0 0 0 1.28 1.15l57.29.42a1.3 1.3 0 0 0 1.3-1.19l3.15-36.6a1.29 1.29 0 0 0-1.28-1.41l-64.66-.48a1.3 1.3 0 0 0-1.29 1.45Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M348.41 47.14h95.61V205.4h-95.61z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M345.23 47.14h95.61V205.4h-95.61z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M349.3 50.35h87.47v151.84H349.3z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M365.95 70.08h53.78v112.38h-53.78z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M366 142.7c-.12-10.5 9.32-20.75 19.71-22.22 5-.69 10.23.29 14.86-1.61 8.27-3.39 10.9-14.37 19.22-17.63l.38 81.22H366Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M438.49 249.9s-7.92-.57-7.19 17.2 2.54 27.22 2.54 27.22-.23 3.94-3.28 3.17-8.79-4.61-11.62-1.74-1.9 7.53 2 12.9 12 17.44 12.74 18.54 1 3.09-1.16 2.89-16.76-5.14-18.69 1.93 11.39 20.24 12.82 21.7 1.5 2.4.92 3.29-5.33-1.24-9.54-2.48-9.3-1.93-10.08 2.24 5.1 16.37 27.07 29.2l10.57 5.28 10-6.38c20.58-15 25.19-27.69 24-31.76s-6.19-2.87-10.26-1.22-8.57 4.24-9.24 3.41-.68-1.75.59-3.36 13.21-16 10.58-22.87-16.65-.46-18.79-.05-2-1.63-1.44-2.77 7.48-14 10.82-19.72 3.93-10.37.7-13-8.43 1.82-11.39 2.89-3.58-2.82-3.58-2.82.86-9.59-.19-27.34-9.07-16.32-9.07-16.32"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M439.12 265.81v.37c0 .24 0 .63.09 1.09.06 1 .16 2.39.28 4.2.24 3.64.55 8.92.91 15.44.73 13 1.58 31 2.44 50.95s1.68 37.93 2.33 51c.33 6.51.6 11.78.78 15.44l.21 4.2c0 .47 0 .83.05 1.09v.38s0-.13-.05-.38 0-.62-.08-1.09c-.07-1-.16-2.38-.28-4.19-.25-3.64-.56-8.92-.92-15.44-.72-13-1.57-31-2.44-51s-1.67-37.92-2.32-51c-.33-6.51-.6-11.78-.79-15.44l-.21-4.2v-1.09a3.64 3.64 0 0 1 0-.33Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M426.75 301.43a3.81 3.81 0 0 1 .66.55c.4.37 1 .92 1.67 1.61 1.4 1.38 3.27 3.34 5.3 5.54l5.17 5.66 1.58 1.7a3.81 3.81 0 0 1 .55.66 3.55 3.55 0 0 1-.66-.54c-.41-.37-1-.93-1.67-1.62-1.39-1.4-3.24-3.38-5.26-5.58l-5.21-5.62-1.58-1.7a3.41 3.41 0 0 1-.55-.66ZM442.73 316.8c-.08-.08 3.78-4.13 8.63-9s8.84-8.84 8.92-8.76-3.79 4.13-8.63 9-8.84 8.84-8.92 8.76ZM442.68 347.43a3.49 3.49 0 0 1 .74-.47l2.07-1.16c1.74-1 4.14-2.34 6.74-3.94s4.91-3.11 6.58-4.23l2-1.31a3.85 3.85 0 0 1 .75-.45 3.36 3.36 0 0 1-.66.57c-.44.35-1.09.84-1.9 1.43-1.62 1.17-3.91 2.74-6.52 4.33s-5.05 2.93-6.83 3.85c-.89.46-1.62.82-2.13 1.05a4.6 4.6 0 0 1-.84.33ZM425.17 336.71a2.69 2.69 0 0 1 .73.34c.46.25 1.12.61 1.92 1.07 1.61.91 3.82 2.2 6.24 3.64s4.62 2.77 6.19 3.75l1.85 1.17a3.82 3.82 0 0 1 .65.48 2.85 2.85 0 0 1-.73-.34c-.46-.24-1.11-.61-1.92-1.07-1.61-.91-3.82-2.2-6.24-3.64s-4.61-2.77-6.19-3.75l-1.85-1.17a3.82 3.82 0 0 1-.65-.48ZM421.7 363.74a6.15 6.15 0 0 1 .93.45l2.46 1.36c2.07 1.16 4.91 2.79 8 4.61s5.95 3.49 8 4.71l2.39 1.47a5 5 0 0 1 .85.58 4.35 4.35 0 0 1-.93-.45l-2.4-1.35c-2.06-1.16-4.91-2.79-8-4.61s-6-3.51-8-4.71l-2.4-1.47a5.14 5.14 0 0 1-.9-.59ZM443.9 376.46a4.94 4.94 0 0 1 .92-.62l2.57-1.56a301.083 301.083 0 0 0 16.62-10.83l2.47-1.71a5.37 5.37 0 0 1 .93-.59 6.11 6.11 0 0 1-.84.72c-.55.44-1.37 1.06-2.39 1.82-2.05 1.51-4.92 3.54-8.17 5.65s-6.27 3.92-8.48 5.18c-1.1.63-2 1.13-2.63 1.46a6.69 6.69 0 0 1-1 .48ZM459.79 394.7h-29.24l9.54 72.24h10.15l9.55-72.24z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M432.06 403.58a.78.78 0 0 0 .16.25 1.73 1.73 0 0 0 .7.51A3.53 3.53 0 0 0 436 404c1.18-.65 2.21-1.93 3.79-2.5a8.29 8.29 0 0 1 5.38.11 22.85 22.85 0 0 1 5 2.43 34.82 34.82 0 0 0 4.07 2.18 11.47 11.47 0 0 0 3 .87 6.34 6.34 0 0 0 .85.06h.3a14.81 14.81 0 0 1-4-1.21 39.81 39.81 0 0 1-4-2.25 22.16 22.16 0 0 0-5.09-2.51 8.62 8.62 0 0 0-5.67-.06 7.68 7.68 0 0 0-2.14 1.32 16.67 16.67 0 0 1-1.67 1.3 3.47 3.47 0 0 1-2.85.5 3.17 3.17 0 0 1-.91-.66ZM433.13 416.88s.23-.3.69-.77a6.4 6.4 0 0 1 2.33-1.48 10.26 10.26 0 0 1 4.07-.62 43.16 43.16 0 0 1 5.09.7 17.46 17.46 0 0 0 5.21.21 14 14 0 0 0 4.1-1.17 13.42 13.42 0 0 0 2.46-1.46 3.24 3.24 0 0 0 .78-.71 23.19 23.19 0 0 1-3.38 1.86 14.79 14.79 0 0 1-4 1.05 18.19 18.19 0 0 1-5.09-.25 38.33 38.33 0 0 0-5.15-.67 10.42 10.42 0 0 0-4.2.74 6.17 6.17 0 0 0-2.35 1.67 4.36 4.36 0 0 0-.44.65c-.1.16-.13.24-.12.25ZM456.47 424a2.22 2.22 0 0 0-.8-.42 8.41 8.41 0 0 0-2.43-.52 14.86 14.86 0 0 0-3.66.21c-1.36.22-2.82.59-4.36.92a18.48 18.48 0 0 1-4.35.49 9.51 9.51 0 0 1-3.46-.75 7.79 7.79 0 0 1-2.62-1.95 2.23 2.23 0 0 0 .49.77 6.26 6.26 0 0 0 2 1.49 9.19 9.19 0 0 0 3.58.88 17.69 17.69 0 0 0 4.47-.46c1.55-.33 3-.72 4.33-1a17.75 17.75 0 0 1 3.57-.31 12.74 12.74 0 0 1 3.24.65ZM435.71 434a4.79 4.79 0 0 1 2.84-.28 13.33 13.33 0 0 1 3 1.11 11.36 11.36 0 0 0 3.86 1.06 11.58 11.58 0 0 0 7-1.62 9.91 9.91 0 0 0 1.74-1.34 2.82 2.82 0 0 0 .52-.62 27.42 27.42 0 0 1-2.47 1.69 12 12 0 0 1-6.78 1.43c-2.77-.16-4.89-1.81-6.81-2a4.33 4.33 0 0 0-2.21.19 1.6 1.6 0 0 0-.69.38ZM437.34 447.89a5.7 5.7 0 0 0 2.44-.48c1.47-.5 3.29-1.62 5.41-2.47a9.12 9.12 0 0 1 3.11-.65 5.8 5.8 0 0 1 2.5.57 5.71 5.71 0 0 1 1.81 1.56 1.31 1.31 0 0 0-.28-.61 4 4 0 0 0-1.37-1.24 5.66 5.66 0 0 0-2.65-.72 8.79 8.79 0 0 0-3.29.65c-2.2.88-4 2-5.36 2.59s-2.33.72-2.32.8ZM438.78 454.34a6.71 6.71 0 0 0 1.85.6 34.17 34.17 0 0 0 9.32.92 7 7 0 0 0 1.93-.23c0-.15-3 0-6.58-.38s-6.49-1.05-6.52-.91Z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M37.95 64.46h95.57v138.52H37.95z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M33.16 64.46h95.57v138.52H33.16z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M45.03 79.46h71.82v108.51H45.03z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M116.85 188v-8.07c0-5.2 0-12.77-.06-22.35 0-19.13-.07-46.23-.12-78.14l.24.24-71.91.04.26-.26v108.52l-.21-.21 52.28.11 14.44.05h5.1-19.41l-52.46.25h-.21V79.2H117.05v.24c0 32-.09 59.12-.12 78.3 0 9.55 0 17.11-.06 22.3v7.41c0 .35-.02.55-.02.55Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M129 64.46a16 16 0 0 1-1.7 2.47c-1.11 1.49-2.69 3.51-4.48 5.69s-3.48 4.12-4.73 5.49a16 16 0 0 1-2.1 2.15 17.38 17.38 0 0 1 1.82-2.39l4.6-5.58c1.75-2.11 3.32-4 4.6-5.58a17.65 17.65 0 0 1 1.99-2.25Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M117.22 187.63a88.74 88.74 0 0 0 12.52 15.66"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M129.74 203.29a14.4 14.4 0 0 1-2.18-2 70.87 70.87 0 0 1-8.89-11.12 13.73 13.73 0 0 1-1.45-2.56A19.8 19.8 0 0 1 119 190c1 1.48 2.53 3.5 4.26 5.66s3.38 4 4.59 5.4a19.51 19.51 0 0 1 1.89 2.23Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="m33.14 202.87 12.52-15.45"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M45.66 187.42a93.18 93.18 0 0 1-6 7.89 92.11 92.11 0 0 1-6.47 7.56A96 96 0 0 1 39.2 195a98 98 0 0 1 6.46-7.58ZM33.16 64.46a86.47 86.47 0 0 1 6.07 7.26 82.74 82.74 0 0 1 5.67 7.59A85.73 85.73 0 0 1 38.83 72a85.84 85.84 0 0 1-5.67-7.54ZM70.43 101.49h24.05v68.28H70.43zM178.18 41.29h75.11v78.3h-75.11z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M173.09 41.29h75.11v78.3h-75.11z"
      style={{
        fill: '#fafafa',
      }}
    />
    <path
      d="M248.23 119.62h-75.16V41.27h75.16Zm-75.11-.05h75.06V41.32h-75.06Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M183.19 51.82h54.92v57.25h-54.92z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M183.19 109.45a9.28 9.28 0 0 1-1.35 1.57c-.88.93-2.11 2.18-3.5 3.54s-2.65 2.58-3.58 3.44a8.57 8.57 0 0 1-1.59 1.32 8.6 8.6 0 0 1 1.35-1.57c.87-.92 2.11-2.18 3.5-3.54s2.65-2.58 3.59-3.45a8.76 8.76 0 0 1 1.58-1.31ZM238.11 109.31a9.53 9.53 0 0 1 1.58 1.35c.92.88 2.17 2.11 3.54 3.49s2.57 2.66 3.43 3.59a8.57 8.57 0 0 1 1.32 1.59 8.6 8.6 0 0 1-1.57-1.35c-.92-.88-2.18-2.11-3.54-3.5s-2.58-2.65-3.44-3.59a8.61 8.61 0 0 1-1.32-1.58ZM238 52a9.11 9.11 0 0 1 1.36-1.57c.87-.93 2.1-2.18 3.49-3.54s2.65-2.58 3.58-3.44A8.57 8.57 0 0 1 248 42.1a8.6 8.6 0 0 1-1.35 1.57c-.87.93-2.11 2.18-3.5 3.55s-2.65 2.58-3.59 3.44A8.76 8.76 0 0 1 238 52ZM183 52.11a8.43 8.43 0 0 1-1.58-1.35c-.92-.88-2.17-2.11-3.53-3.49s-2.58-2.65-3.44-3.59a8.57 8.57 0 0 1-1.32-1.59 8.6 8.6 0 0 1 1.57 1.35c.92.88 2.18 2.11 3.54 3.5s2.58 2.66 3.45 3.59a8.76 8.76 0 0 1 1.31 1.58Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M238 109.07V93.23c0-10-.07-24.31-.11-41.41l.11.18h-54.76l.24-.24v57.25l-.21-.21 39.66.11L234 109h3.7H223l-39.79.11H183v-57.7h55.22v.21c0 17.16-.08 31.49-.11 41.55 0 5 0 8.92-.05 11.62v3.82a2.39 2.39 0 0 1-.06.46Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M201.49 71.28h18.33v18.33h-18.33zM138.94 357.9l1.88 34.53a2.33 2.33 0 0 0 2.31 2.21l62.13.45a2.33 2.33 0 0 0 2.35-2.31l.2-26.88a2.34 2.34 0 0 0-2.33-2.35h-38.22a2.31 2.31 0 0 1-1.64-.69l-6.6-6.59a2.36 2.36 0 0 0-1.63-.68l-16.11-.12a2.33 2.33 0 0 0-2.34 2.43Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M139.81 359.24h67.89v40.57h-67.89z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(.42 173.282 379.976)"
    />
    <path
      d="m134.54 362.59 4.87 42.35a1.49 1.49 0 0 0 1.48 1.32l66.16.49a1.51 1.51 0 0 0 1.51-1.37l3.64-42.28a1.51 1.51 0 0 0-1.49-1.63l-74.71-.55a1.5 1.5 0 0 0-1.46 1.67ZM246.89 377.35l1.88 34.65a2.33 2.33 0 0 0 2.31 2.21l62.27.46a2.34 2.34 0 0 0 2.36-2.32l.2-26.94a2.34 2.34 0 0 0-2.34-2.35h-38.3a2.32 2.32 0 0 1-1.65-.68l-6.6-6.61a2.36 2.36 0 0 0-1.64-.68l-16.14-.12a2.33 2.33 0 0 0-2.35 2.38Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M247.76 378.7h68.04v40.66h-68.04z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(.42 281.122 398.68)"
    />
    <path
      d="m242.47 382.05 4.89 42.44a1.5 1.5 0 0 0 1.48 1.33l66.31.49a1.52 1.52 0 0 0 1.51-1.38l3.65-42.37a1.51 1.51 0 0 0-1.49-1.63l-74.82-.55a1.51 1.51 0 0 0-1.53 1.67Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M93.56 318.85C83.3 303.17 84.8 280.77 97 266.59c3.8-4.4 9.11-8.23 14.91-7.73s10.71 5.59 12.52 11.27 1.17 11.82.11 17.68c-1.48 8.2-4 16.59-9.66 22.69s-14 12.35-21.36 8.35M96.66 394.33a23.43 23.43 0 0 1 6.53-26.61c2.36-1.93 5.44-3.42 8.38-2.64s5 3.86 5.43 6.94-.47 6.2-1.54 9.12c-1.51 4.1-3.55 8.2-7 10.82s-8.36 5.09-11.76 2.37M74.5 388.94C77.32 375 70 359.7 57.41 353.16c-3.91-2-8.71-3.24-12.65-1.26s-6 6.93-5.65 11.43S41.59 372 44 375.8c3.34 5.35 7.44 10.56 13.15 13.25s13.35 4.76 17.37-.11M76.28 351.35c4.91-15.84-3.41-35-17.29-44.13-4.3-2.82-9.75-4.78-14.56-3s-7.76 7.37-7.91 12.64 1.87 10.34 4.2 15.07c3.26 6.62 7.42 13.16 13.74 17s16.57 7.6 21.82 2.41"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M83.57 428.77a3.29 3.29 0 0 1-.16-.76 170.8 170.8 0 0 1-.55-3.81c-.08-.6-.24-1.21-.37-1.88-.25-1.35-.52-2.9-1-4.57-.21-.85-.42-1.73-.64-2.64s-.54-1.85-.83-2.83c-.53-2-1.31-4-2-6.17a119.8 119.8 0 0 0-6.11-13.55A120.32 120.32 0 0 0 64 380c-1.4-1.8-2.66-3.58-4-5.11l-1.89-2.25-1.85-2c-1.15-1.3-2.29-2.37-3.28-3.33l-1.36-1.35-1.21-1.06-1.66-1.5c-.38-.35-.57-.54-.55-.56a4.23 4.23 0 0 1 .63.46l1.75 1.4 1.25 1 1.41 1.3c1 .94 2.18 2 3.36 3.28l1.89 2 1.94 2.24c1.37 1.52 2.66 3.29 4.08 5.1a114.45 114.45 0 0 1 8 12.64 113.41 113.41 0 0 1 6.1 13.66c.72 2.19 1.49 4.24 2 6.22.28 1 .54 1.94.8 2.86s.41 1.81.6 2.66c.42 1.69.65 3.25.87 4.61.11.68.22 1.31.31 1.9s.12 1.12.17 1.61c.08.91.15 1.65.2 2.23a3.83 3.83 0 0 1 .01.76Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M83.21 431.62a1.3 1.3 0 0 1 0-.32c0-.24 0-.54.06-.91.08-.86.19-2 .33-3.52.08-.77.16-1.62.25-2.54s.1-1.95.15-3c.08-2.18.29-4.66.21-7.4v-4.3c0-1.5-.15-3.05-.23-4.66-.11-3.22-.54-6.63-.93-10.24a161.53 161.53 0 0 0-4.86-23 161.81 161.81 0 0 0-8.48-22c-1.64-3.23-3.14-6.34-4.8-9.1-.82-1.39-1.55-2.77-2.35-4S61 334.12 60.21 333c-1.43-2.35-3-4.31-4.2-6.09-.64-.88-1.22-1.71-1.78-2.46s-1.12-1.38-1.6-2l-2.19-2.77c-.22-.3-.41-.54-.56-.73s-.18-.26-.17-.27.09.07.23.22l.6.69 2.29 2.7 1.65 2c.58.73 1.17 1.56 1.83 2.43 1.28 1.76 2.84 3.71 4.3 6l2.39 3.59c.82 1.26 1.56 2.64 2.39 4 1.69 2.76 3.21 5.86 4.87 9.1a155.61 155.61 0 0 1 8.57 22 156 156 0 0 1 4.83 23.15c.37 3.62.79 7.05.88 10.28.06 1.62.19 3.18.19 4.68v4.32c0 2.75-.2 5.23-.31 7.41-.08 1.09-.12 2.1-.21 3s-.22 1.78-.31 2.54l-.45 3.51c0 .37-.1.67-.13.91a1.49 1.49 0 0 1-.11.41Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M109.9 277.8a1.86 1.86 0 0 1-.13.29l-.45.82-1.75 3.09c-.37.68-.83 1.42-1.26 2.26l-1.37 2.76-1.58 3.16c-.58 1.16-1 2.36-1.61 3.63s-1.17 2.6-1.74 4-1.11 2.89-1.7 4.41c-.29.77-.59 1.54-.89 2.33l-.83 2.45c-.55 1.65-1.13 3.35-1.71 5.09-2.18 7-4.24 14.85-6 23.16s-2.88 16.37-3.71 23.68-1.3 13.93-1.45 19.49c-.14 2.78-.17 5.3-.18 7.51s-.05 4.11 0 5.67 0 2.71 0 3.59v.93a1.14 1.14 0 0 1 0 .32 1.19 1.19 0 0 1 0-.32c0-.25 0-.55-.06-.93 0-.87-.1-2.07-.17-3.58s-.13-3.47-.13-5.68 0-4.74.08-7.53c.09-5.57.6-12.2 1.31-19.54s2-15.36 3.7-23.7 3.82-16.18 6-23.22c.59-1.74 1.18-3.44 1.74-5.1.29-.82.57-1.64.85-2.44l.91-2.33 1.75-4.41c.58-1.41 1.21-2.73 1.77-4s1.11-2.49 1.66-3.62l1.63-3.15 1.43-2.74c.45-.84.92-1.57 1.31-2.24l1.87-3.07c.2-.32.36-.58.5-.79s.2-.26.21-.25ZM106.41 374a13.1 13.1 0 0 1-1.09 1.92c-.75 1.19-1.78 3-3 5.18s-2.54 4.92-3.89 8-2.62 6.5-3.77 10.17-2 7.26-2.62 10.53-1 6.26-1.29 8.77-.4 4.56-.46 6a13 13 0 0 1-.18 2.19 13.55 13.55 0 0 1-.08-2.2c0-1.42 0-3.47.25-6a86.22 86.22 0 0 1 1.15-8.84 90.71 90.71 0 0 1 6.44-20.84c1.38-3.06 2.76-5.75 4-7.95s2.36-3.94 3.18-5.1a15 15 0 0 1 1.36-1.83Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M63.43 421.72h45.04l-5.45 45.99H69.04l-5.61-45.99z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="m65.08 462.75 3.82 4.96h34.12l3.93-4.81-41.87-.15z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M106.71 435.24c0 .19-9.34.34-20.87.34S65 435.43 65 435.24s9.34-.33 20.87-.33 20.84.15 20.84.33ZM106.71 438.31c0 .18-9.34.33-20.87.33S65 438.49 65 438.31s9.34-.34 20.87-.34 20.84.15 20.84.34ZM106.15 441.27c0 .19-9 .34-20.14.34s-20.14-.15-20.14-.34 9-.34 20.14-.34 20.14.16 20.14.34Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M461.82 467.76c0 .15-97.53.26-217.81.26s-217.83-.11-217.83-.26 97.51-.26 217.83-.26 217.81.12 217.81.26ZM443.94 149.85c-3.36-4.25-3.43-12-3.32-14.07.26-4.71-10.24-32.2-36.26-20.85-9.33 0-9.48 9.21-11.25 11.06s-3.74 3.78-4.21 6.3c-.82 4.47 3.42 8.81 2.34 13.22-.4 1.65-1.51 3.06-2.29 4.57-.84 1.35-1.5 3.55-1.14 4.77a15.72 15.72 0 0 0 1.77 3.37 5.47 5.47 0 0 1 .95 3.62c-.41 2.26-3 3.52-3.74 5.71s.66 4.37 1.89 6.26 2.41 4.28 1.42 6.31a11.21 11.21 0 0 1-2.41 2.65 9.3 9.3 0 0 0-1.84 10.11l15-3 2-21.4c4.38 1.09 9.17-1.71 11.25-2.24l2.55-1.38c5.58 1 11.6.63 16.49-2.22s12.14.8 11.19-4.79c-.53-2.68 1.32-5.85-.39-8Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m426.88 123.84-20.37-6c-6.56-1-11.26 7-11.95 13.56-.76 7.33-1.39 16.31-.74 21.82 1.3 11.1 9.69 12.84 9.69 12.84s-1.47 15.71-1.6 19.64L427 172.82l3.17-44.91a3.88 3.88 0 0 0-3.29-4.07Z"
      style={{
        fill: '#ffd3c1',
      }}
    />
    <path
      d="M395.83 140.41a1.41 1.41 0 0 0 1.3 1.48 1.35 1.35 0 0 0 1.51-1.21 1.41 1.41 0 0 0-1.3-1.48 1.34 1.34 0 0 0-1.51 1.21ZM395.07 137.18a27 27 0 0 1 2.84.36 28 28 0 0 1 2.65 1.24c.1 0 0-.33-.42-.73a5.27 5.27 0 0 0-2.08-1.15 4.85 4.85 0 0 0-2.31-.13c-.53.13-.75.32-.68.41ZM409.93 141.56a1.42 1.42 0 0 0 1.31 1.48 1.36 1.36 0 0 0 1.51-1.22 1.43 1.43 0 0 0-1.31-1.48 1.36 1.36 0 0 0-1.51 1.22ZM410.19 139.18c.22.1 1.31-.63 3-.87s3.05.13 3.21 0-.16-.29-.75-.46a6.32 6.32 0 0 0-2.6-.14 5.61 5.61 0 0 0-2.37.86c-.47.3-.6.56-.49.61ZM404.93 149.11a9.71 9.71 0 0 0-2.45-.55c-.39-.05-.75-.15-.8-.41A1.92 1.92 0 0 1 402 147c.4-.93.83-1.9 1.27-2.92 1.76-4.14 3.06-7.56 2.89-7.63s-1.73 3.24-3.5 7.38c-.42 1-.83 2-1.22 2.93a2.26 2.26 0 0 0-.26 1.52 1 1 0 0 0 .61.59 2.57 2.57 0 0 0 .66.12 10.16 10.16 0 0 0 2.48.12Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M403.51 166a26.64 26.64 0 0 0 14.77-3.19c-.13 0-4.52 7.67-15 6.11ZM405.43 152.8a2.75 2.75 0 0 1 2.5-.92 2.51 2.51 0 0 1 1.69 1 1.58 1.58 0 0 1 .05 1.81 1.86 1.86 0 0 1-2 .43 5.89 5.89 0 0 1-1.9-1.24 1.53 1.53 0 0 1-.41-.47.5.5 0 0 1 0-.56"
      style={{
        fill: '#f4af9b',
      }}
    />
    <path
      d="M409.37 150.34c-.25 0-.32 1.63-1.78 2.74s-3.21.86-3.23 1.09.38.34 1.12.39a4.13 4.13 0 0 0 2.69-.82 3.62 3.62 0 0 0 1.39-2.3c.09-.69-.08-1.11-.19-1.1ZM410.38 133.77c.14.41 1.66.28 3.44.57s3.2.83 3.45.47c.11-.18-.12-.57-.69-1a6.06 6.06 0 0 0-5.25-.79c-.66.23-1 .55-.95.75ZM396.71 132.78c.25.36 1.31 0 2.57.08s2.32.32 2.58 0c.12-.17 0-.52-.49-.87a3.75 3.75 0 0 0-4.16 0c-.45.27-.62.62-.5.79Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M404.11 116.37a104 104 0 0 1 23.89 6.76c1.78.75 3.64 1.62 4.64 3.26s.92 3.92.75 6L430.84 162l-1.06-.06c-3 .5-3.26-1.06-5.45-3.25a10 10 0 0 1-2.72-8.51c.44-2.69 2-5.39 1.16-8-1.11-3.44-5.82-4.66-6.95-8.09-.65-2 .09-4.26-.67-6.22s-2.57-3-4.4-3.7-3.8-1.28-5.31-2.55-2.44-3.62-1.33-5.25Z"
      style={{
        fill: '#263238',
      }}
    />
    <rect
      width={92.62}
      height={76.78}
      x={250.6}
      y={96.53}
      rx={5.95}
      style={{
        fill: '#263238',
      }}
    />
    <circle
      cx={296.91}
      cy={133.43}
      r={13.35}
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M293.7 137.34v-7.88a.5.5 0 0 1 .74-.44l7 4a.51.51 0 0 1 0 .88l-7 3.9a.49.49 0 0 1-.74-.46Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="m380.68 431.54 2.32 22.25s-21.84 9.82-21.82 14l43.3-2.92L402.3 430Z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="m380.68 431.54 2.32 22.25s-21.84 9.82-21.82 14l43.3-2.92L402.3 430Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <g
      style={{
        opacity: 0.6000000000000001,
      }}
    >
      <path
        d="M396.25 451a1.77 1.77 0 0 1 1.36 1.93 1.71 1.71 0 0 1-1.91 1.37 1.88 1.88 0 0 1-1.43-2.06 1.77 1.77 0 0 1 2.14-1.2"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.6000000000000001,
      }}
    >
      <path
        d="m404.47 464.84-.35-3.51-41.39 4.12s-1.87 1-1.56 2.31Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M382.27 453.62c0 .21 1.09.23 2.17.84s1.7 1.46 1.89 1.37-.21-1.3-1.54-2-2.58-.4-2.52-.21ZM377.86 455.8c0 .21.9.51 1.68 1.35s1 1.78 1.26 1.76.26-1.23-.73-2.26-2.22-1.05-2.21-.85ZM375.64 461.41c.2 0 .42-1-.2-2.16s-1.64-1.52-1.72-1.34.58.76 1.07 1.69.64 1.81.85 1.81ZM382.31 448.49c.1.18 1.05-.17 2.25-.17s2.16.31 2.25.12-.84-.88-2.26-.86-2.36.75-2.24.91ZM381.92 444a4.07 4.07 0 0 0 2.17-.16 9.59 9.59 0 0 0 2.22-.85 10.23 10.23 0 0 0 1.2-.74 2.59 2.59 0 0 0 .62-.56.91.91 0 0 0 0-1.13 1.15 1.15 0 0 0-.95-.42 2.63 2.63 0 0 0-.81.17 8.29 8.29 0 0 0-1.32.55 7.76 7.76 0 0 0-1.91 1.45c-.94 1-1.23 1.81-1.15 1.85s.54-.63 1.51-1.47a8.56 8.56 0 0 1 1.86-1.22 8.83 8.83 0 0 1 1.22-.47c.45-.15.84-.18 1 0s.06.11 0 .25a2.68 2.68 0 0 1-.45.41c-.37.27-.74.51-1.11.73a11.94 11.94 0 0 1-2 .94 15.49 15.49 0 0 0-2.1.67Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M382.22 444.28a3.52 3.52 0 0 0 .43-2.14 7.05 7.05 0 0 0-.46-2.35 6.65 6.65 0 0 0-.64-1.28 1.79 1.79 0 0 0-1.46-1 1 1 0 0 0-.86.66 3.46 3.46 0 0 0-.17.81 5 5 0 0 0 1.18 3.64c.92 1.07 1.82 1.26 1.83 1.19s-.7-.47-1.42-1.51a5 5 0 0 1-.83-2 4.72 4.72 0 0 1 0-1.25c0-.47.18-.85.36-.82s.6.31.81.66a7.91 7.91 0 0 1 .61 1.14 7.6 7.6 0 0 1 .57 2.14c.1 1.27-.07 2.08.05 2.11Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M376.92 268.7s-19.18 87.08-19.77 94.86 23.78 74.9 23.78 74.9l21-4s-1.33-99.13-2-101.07 5.35-50.67 5.35-50.67Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m390.32 432.58.23 22.36s-22.66 7.75-23 11.89l43.38.45 1.07-34.21Z"
      style={{
        fill: '#455a64',
      }}
    />
    <g
      style={{
        opacity: 0.6000000000000001,
      }}
    >
      <path
        d="M404 453.41a1.77 1.77 0 0 1 1.18 2 1.71 1.71 0 0 1-2 1.18 1.87 1.87 0 0 1-1.23-2.18 1.76 1.76 0 0 1 2.24-1"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.6000000000000001,
      }}
    >
      <path
        d="M410.91 467.28v-2.86l-41.59.25s-1.95.79-1.77 2.16Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M389.85 454.71c0 .21 1.06.33 2.09 1s1.55 1.61 1.75 1.53-.09-1.31-1.34-2.14-2.54-.59-2.5-.39ZM385.25 456.47c-.05.21.86.59 1.55 1.5s.89 1.87 1.1 1.87.37-1.2-.51-2.32-2.13-1.25-2.14-1.05ZM382.53 461.85c.19 0 .51-1 0-2.17s-1.49-1.66-1.59-1.49.5.81.91 1.78.47 1.86.68 1.88ZM390.37 449.6c.08.2 1.06-.07 2.25 0s2.12.52 2.24.34-.76-1-2.17-1.07-2.42.56-2.32.73Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m390.37 437.01.03 3.98 21.33.35.12-3.59-21.48-.74z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M390.4 445.09a4 4 0 0 0 2.17 0 9.71 9.71 0 0 0 2.3-.64 9.87 9.87 0 0 0 1.26-.63 2.63 2.63 0 0 0 .67-.49.94.94 0 0 0 .13-1.13 1.16 1.16 0 0 0-.92-.5 2.58 2.58 0 0 0-.82.09 7.2 7.2 0 0 0-1.36.43 7.58 7.58 0 0 0-2 1.26c-1 .91-1.39 1.69-1.32 1.73s.6-.58 1.64-1.32a8.61 8.61 0 0 1 2-1 7.73 7.73 0 0 1 1.26-.36c.46-.1.85-.1 1 .11s.05.11-.05.24a2.07 2.07 0 0 1-.49.36 11.15 11.15 0 0 1-1.17.63 11.59 11.59 0 0 1-2.12.75 14.74 14.74 0 0 0-2.18.47Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M390.67 445.4a3.54 3.54 0 0 0 .63-2.08 7.18 7.18 0 0 0-.24-2.39 6.65 6.65 0 0 0-.52-1.33 1.8 1.8 0 0 0-1.36-1.14 1 1 0 0 0-.92.59 2.71 2.71 0 0 0-.24.79 4.5 4.5 0 0 0 0 1.46 5 5 0 0 0 .87 2.27c.82 1.14 1.7 1.42 1.71 1.35s-.65-.53-1.27-1.63a5.05 5.05 0 0 1-.64-2.06 4.44 4.44 0 0 1 .08-1.25c.08-.46.26-.83.43-.78s.57.36.74.73a8 8 0 0 1 .51 1.19 8.15 8.15 0 0 1 .37 2.18c-.02 1.28-.26 2.06-.15 2.1Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m425.67 267.36-48.75 1.34 7 86.74 4.34 83.61 25.29.88c1.29-16.14 1.38-73.17 1.38-73.17l5.07-34.44c1.18-9.29 4.91-19.05 6.71-27.39 1.05-4.89 2.59-11.24 2.92-13.32 1.01-6.13-3.96-24.25-3.96-24.25Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M379.76 288.66a2.29 2.29 0 0 1 0 .39v1.14c0 1-.1 2.5-.17 4.38s-.18 4.23-.26 6.93-.22 5.78-.28 9.18c-.19 6.8-.29 14.9-.25 23.9s.28 18.88.79 29.25 1.31 20.24 2.21 29.19c.49 4.47.92 8.72 1.46 12.69.26 2 .48 3.9.74 5.74s.53 3.6.78 5.28c.46 3.37 1 6.4 1.43 9.07s.84 5 1.2 6.83l.77 4.31c.08.48.15.85.19 1.12a2.33 2.33 0 0 1 0 .39 2.58 2.58 0 0 1-.1-.38c-.05-.27-.13-.64-.23-1.11-.21-1-.5-2.45-.87-4.3s-.83-4.15-1.28-6.82-1-5.69-1.51-9.06q-.39-2.52-.81-5.28c-.27-1.84-.5-3.76-.77-5.74-.56-4-1-8.22-1.51-12.7-.92-8.95-1.72-18.82-2.25-29.2s-.71-20.28-.75-29.28.13-17.11.36-23.91c.08-3.4.24-6.48.35-9.18s.23-5 .34-6.93.21-3.35.27-4.38c0-.48.06-.85.08-1.13a2.33 2.33 0 0 1 .07-.39Z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="m352.01 172.66-12.48-13.33-9.84 6.89 9.84 14.56 12.48-8.12z"
      style={{
        fill: '#ffd3c1',
      }}
    />
    <path
      d="m430.94 177.58-.6-8.45-28.19-.4-.24 10.61s-13.84 15-18.87 31.75c-8.44 28.22-11.5 64.14-11.5 64.14h57.69c-.6-9.94-4.7-32.18-4.7-32.18l12.82-38.25c3.19-13.75-6.41-27.22-6.41-27.22Z"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M361.14 215.77s.16.16.48.43a12 12 0 0 0 1.47 1.14 15.11 15.11 0 0 0 6.32 2.43 15.49 15.49 0 0 0 4.7-.05c1.67-.19 3.46-.42 5.34-.67 3.77-.51 7.9-1.15 12.22-1.88l22.1-3.87 6.71-1.1 1.83-.29.63-.12-.64.08-1.83.25c-1.59.22-3.89.57-6.73 1-5.67.91-13.47 2.35-22.11 3.82-4.32.73-8.44 1.37-12.2 1.89-1.88.25-3.67.49-5.35.69a15.43 15.43 0 0 1-10.95-2.27 16.69 16.69 0 0 1-1.48-1.1c-.33-.25-.51-.38-.51-.38ZM432.72 202.14a.79.79 0 0 0 .06-.23c0-.2.09-.42.14-.65.11-.57.25-1.39.4-2.42a21.54 21.54 0 0 0-.21-8.05 11.25 11.25 0 0 0-4.49-6.58 7.47 7.47 0 0 0-2.23-1 5 5 0 0 0-.88-.17 9.32 9.32 0 0 1 3 1.31 11.32 11.32 0 0 1 4.36 6.5 22.19 22.19 0 0 1 .26 8c-.12 1-.24 1.85-.33 2.42q0 .36-.09.66a.86.86 0 0 0 .01.21ZM372.71 209a4.18 4.18 0 0 0 0-.63l-.16-1.72a54.94 54.94 0 0 1-.17-5.67 55.6 55.6 0 0 1 .45-5.66c.09-.61.17-1.17.25-1.7a4.07 4.07 0 0 0 .07-.63 4.78 4.78 0 0 0-.16.61c-.09.4-.2 1-.31 1.7a43.31 43.31 0 0 0-.29 11.37c.08.72.16 1.31.23 1.71a3.13 3.13 0 0 0 .09.62Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M402.24 179.18a18 18 0 0 1 3.08-5.39c2-2.73 4.2-5.59 7.35-6.88 2.14-.87 7.67-.39 9.15-2.17 4.6-5.58-2.14-9 .3-16.71 2.69-8.39 1.3-7.1 1.51-13.71l10.21-4.41c1.61 1.2 6.07 5.53 5.58 7.48a11.64 11.64 0 0 0 .39 6.42c1.57 4.93 7 7.83 9.56 12.32s-.34 10.69-.41 16 1.93 11.66 7.1 12.81a20 20 0 0 1-9.06 9l-3.24-4.41a8.64 8.64 0 0 1-6 9.35c-1.09-4.65-2.23-9.43-5.05-13.29s-7.71-6.59-12.32-5.35c-3.09.82-5.47 3.2-8.23 4.81s-6.66 2.3-8.86 0a6.06 6.06 0 0 1-1.06-5.87Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M383 218.38c13.3-2.42 31-5.32 44.3-7.75a2.73 2.73 0 0 1 1.5 0 1.82 1.82 0 0 1 .74 2.43 4.39 4.39 0 0 1-2.08 1.86c-4.37 2.28-9.48 2.47-14.4 2.6l-30.06.85Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M373.33 219.71c4.14.08 51.74-8.62 51.74-8.62"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M424.26 181.37c3.49.43 5.76 1 9.84 5.4a8.6 8.6 0 0 1 1.21 1.71c4.54 8 1.26 19-7.44 22.05l-8.42 1.73-46.12 7.45s-9.82 2.2-18.26-10.89c-3.09-4.81-24.4-36.59-24.4-36.59l14-9.37 28.22 30.19Z"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M361.14 215.77s.16.16.48.43a12 12 0 0 0 1.47 1.14 15.11 15.11 0 0 0 6.32 2.43 15.49 15.49 0 0 0 4.7-.05c1.67-.19 3.46-.42 5.34-.67 3.77-.51 7.9-1.15 12.22-1.88l22.1-3.87 6.71-1.1 1.83-.29.63-.12-.64.08-1.83.25c-1.59.22-3.89.57-6.73 1-5.67.91-13.47 2.35-22.11 3.82-4.32.73-8.44 1.37-12.2 1.89-1.88.25-3.67.49-5.35.69a15.43 15.43 0 0 1-10.95-2.27 16.69 16.69 0 0 1-1.48-1.1c-.33-.25-.51-.38-.51-.38ZM432.72 202.14a.79.79 0 0 0 .06-.23c0-.2.09-.42.14-.65.11-.57.25-1.39.4-2.42a21.54 21.54 0 0 0-.21-8.05 11.25 11.25 0 0 0-4.49-6.58 7.47 7.47 0 0 0-2.23-1 5 5 0 0 0-.88-.17 9.32 9.32 0 0 1 3 1.31 11.32 11.32 0 0 1 4.36 6.5 22.19 22.19 0 0 1 .26 8c-.12 1-.24 1.85-.33 2.42q0 .36-.09.66a.86.86 0 0 0 .01.21ZM372.71 209a4.18 4.18 0 0 0 0-.63l-.16-1.72a54.94 54.94 0 0 1-.17-5.67 55.6 55.6 0 0 1 .45-5.66c.09-.61.17-1.17.25-1.7a4.07 4.07 0 0 0 .07-.63 4.78 4.78 0 0 0-.16.61c-.09.4-.2 1-.31 1.7a43.31 43.31 0 0 0-.29 11.37c.08.72.16 1.31.23 1.71a3.13 3.13 0 0 0 .09.62Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M339.47 149a28.7 28.7 0 0 1-.26-5.74.65.65 0 0 0-.73-.71 3.08 3.08 0 0 0-2.57 2.24 8.28 8.28 0 0 0-.29 3.23v3.19s-8.88-10.63-10.68-11.11a1.25 1.25 0 0 0-1.68.6c-.33.53.33 1.77.33 1.77l5 7.16s-7.57-8.9-8.94-8.88-2 1-1.11 2.65c.65 1.21 7.26 9 7.26 9s-7.07-6.82-8.48-6.77-1.17 1.55-.43 2.17 6 6.71 6 6.71-5.11-4.32-6.14-4-.59 1.48-.05 2.41 13.36 13.74 13.36 13.74l11.12-5.58a10.81 10.81 0 0 0-.17-4.7 58.43 58.43 0 0 1-1.54-7.38Z"
      style={{
        fill: '#ffd3c1',
      }}
    />
    <path
      d="M176.19 275.31A10.23 10.23 0 0 1 166 265.08v-88.94a8.49 8.49 0 0 1 8.75-8.19h8.36a8.81 8.81 0 0 0 8-4.76l1.79-3.65a8.81 8.81 0 0 1 7.95-4.76h39.69a8.89 8.89 0 0 1 7.54 4l1.95 3.09a8.88 8.88 0 0 0 7.53 4h50.6a8.48 8.48 0 0 1 8.75 8.2v91a10.23 10.23 0 0 1-10.23 10.23Z"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M176.19 275.31A10.23 10.23 0 0 1 166 265.08v-88.94a8.49 8.49 0 0 1 8.75-8.19h8.36a8.81 8.81 0 0 0 8-4.76l1.79-3.65a8.81 8.81 0 0 1 7.95-4.76h39.69a8.89 8.89 0 0 1 7.54 4l1.95 3.09a8.88 8.88 0 0 0 7.53 4h50.6a8.48 8.48 0 0 1 8.75 8.2v91a10.23 10.23 0 0 1-10.23 10.23Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m252.48 165.92-32.2-.07-2.87-5.38a11.11 11.11 0 0 0-9.84-5.69H176.1c-5.6 0-10.14 4.25-10.14 9.5v100.24c0 6 5.16 10.79 11.52 10.79H305.3c6.37 0 11.52-4.83 11.52-10.79v-91.23a7.37 7.37 0 0 0-7.37-7.37Z"
      style={{
        fill: '#ef0202',
      }}
    />
    <path
      d="M268.71 241.88h-14.25a1 1 0 0 1 0-2h14.25a14.17 14.17 0 0 0 .78-28.32 1 1 0 0 1-.92-.75 22.64 22.64 0 0 0-39.57-8.5 1 1 0 0 1-.91.37 17 17 0 0 0-2.16-.14 18.67 18.67 0 0 0 0 37.33h11.55a1 1 0 1 1 0 2h-11.6a20.68 20.68 0 1 1 0-41.36 17.12 17.12 0 0 1 1.84.1 24.66 24.66 0 0 1 42.61 9 16.18 16.18 0 0 1-1.62 32.29Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M245.67 251a1 1 0 0 1-1-1v-26a1 1 0 1 1 2 0v26a1 1 0 0 1-1 1Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M253.27 234.35a1 1 0 0 1-.81-.4l-6.62-8.88a.25.25 0 0 0-.2-.09.26.26 0 0 0-.19.09l-7 8.63a1 1 0 1 1-1.57-1.27l7-8.63a2.34 2.34 0 0 1 1.8-.84 2.25 2.25 0 0 1 1.76.89l6.64 8.89a1 1 0 0 1-.21 1.41 1 1 0 0 1-.6.2Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M226.16 296.38v-4.48h.84v4.45c0 1.86.87 2.71 2.39 2.71s2.4-.85 2.4-2.71v-4.45h.81v4.48c0 2.25-1.21 3.41-3.21 3.41s-3.23-1.16-3.23-3.41ZM240.87 296.79a2.84 2.84 0 0 1-2.93 3 2.58 2.58 0 0 1-2.24-1.16v3.28h-.79v-8h.76V295a2.57 2.57 0 0 1 2.27-1.21 2.85 2.85 0 0 1 2.93 3Zm-.79 0a2.2 2.2 0 1 0-2.2 2.29 2.17 2.17 0 0 0 2.2-2.29ZM242.49 291.43h.79v8.3h-.79ZM244.9 296.79a3 3 0 1 1 3 3 2.9 2.9 0 0 1-3-3Zm5.18 0a2.19 2.19 0 1 0-2.18 2.29 2.15 2.15 0 0 0 2.18-2.29ZM256.91 296.08v3.65h-.76v-.92a2.22 2.22 0 0 1-2 1c-1.33 0-2.14-.69-2.14-1.71s.58-1.66 2.27-1.66h1.86v-.36c0-1-.57-1.56-1.66-1.56a3 3 0 0 0-2 .7l-.36-.6a3.77 3.77 0 0 1 2.39-.79 2.09 2.09 0 0 1 2.4 2.25Zm-.8 1.88v-1h-1.84c-1.14 0-1.51.45-1.51 1.05s.55 1.11 1.49 1.11a1.88 1.88 0 0 0 1.86-1.12ZM264.45 291.43v8.3h-.76v-1.17a2.54 2.54 0 0 1-2.27 1.22 3 3 0 0 1 0-6 2.57 2.57 0 0 1 2.24 1.16v-3.53Zm-.78 5.36a2.19 2.19 0 1 0-2.18 2.29 2.16 2.16 0 0 0 2.18-2.29ZM266.15 299.17a.59.59 0 0 1 .59-.61.6.6 0 0 1 .6.61.61.61 0 0 1-.6.61.6.6 0 0 1-.59-.61ZM268.52 299.17a.6.6 0 0 1 .59-.61.6.6 0 0 1 .6.61.61.61 0 0 1-.6.61.6.6 0 0 1-.59-.61ZM270.89 299.17a.59.59 0 0 1 .59-.61.6.6 0 0 1 .6.61.61.61 0 0 1-.6.61.6.6 0 0 1-.59-.61Z"
      style={{
        fill: '#263238',
      }}
    />
    <rect
      width={169.22}
      height={15.19}
      x={159.22}
      y={313.11}
      rx={6.63}
      style={{
        fill: '#e0e0e0',
      }}
    />
    <rect
      width={84.61}
      height={15.19}
      x={159.22}
      y={313.11}
      rx={6.63}
      style={{
        fill: '#ef0202',
      }}
    />
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="m172 313.11-9 14.29 1.41.63a3.08 3.08 0 0 0 1.27.27h2.86l9.78-15.19Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="m188.1 313.11-9.57 15.19h6.11l9.77-15.19h-6.31z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="m204.19 313.11-9.57 15.19h6.11l9.78-15.19h-6.32z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="m220.29 313.11-9.58 15.19h6.11l9.78-15.19h-6.31z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="m236.38 313.11-9.57 15.19h6.11l7.7-12 .92-1.3c-1.18-1.27-2.99-1.8-5.16-1.89Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M322 328.3s.31 0 .91-.1a6.37 6.37 0 0 0 2.51-.91 6.6 6.6 0 0 0 2.73-3.3 6.94 6.94 0 0 0 .44-2.72 11.77 11.77 0 0 0-.24-3.15 6.56 6.56 0 0 0-5.14-4.77 11.78 11.78 0 0 0-2.08-.11h-28.66l-126.32.07a6.44 6.44 0 0 0-6.31 5.18 11.54 11.54 0 0 0-.12 2.22 10 10 0 0 0 .15 2.21A6.41 6.41 0 0 0 166 328h46.84l56.79.06 38.29.08 10.41.05h3.63-14.04l-38.29.09-56.79.05H166a6.94 6.94 0 0 1-6.72-5.47 10.15 10.15 0 0 1-.16-2.32 11.41 11.41 0 0 1 .13-2.32 6.94 6.94 0 0 1 6.82-5.6l126.32.08h28.66a10.79 10.79 0 0 1 2.13.13 6.78 6.78 0 0 1 5.29 4.95 12.34 12.34 0 0 1 .22 3.21 7.32 7.32 0 0 1-.47 2.78 6.77 6.77 0 0 1-2.82 3.33 6.54 6.54 0 0 1-2.56.88 4.9 4.9 0 0 1-.84.32Z"
      style={{
        fill: '#263238',
      }}
    />
  </svg>
);
export default React.memo(FileUploadIllustration);
