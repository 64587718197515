import * as React from 'react';

const PresentationIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path
      d="M0 382.4h500v.25H0zM416.78 398.49h33.12v.25h-33.12zM322.53 401.21h8.69v.25h-8.69zM396.59 389.21h19.19v.25h-19.19zM52.46 390.89h43.19v.25H52.46zM104.56 390.89h6.33v.25h-6.33zM131.47 395.11h93.68v.25h-93.68zM237 337.8H43.91a5.71 5.71 0 0 1-5.7-5.71V60.66a5.71 5.71 0 0 1 5.7-5.66H237a5.71 5.71 0 0 1 5.71 5.71v271.38a5.71 5.71 0 0 1-5.71 5.71ZM43.91 55.2a5.46 5.46 0 0 0-5.45 5.46v271.43a5.46 5.46 0 0 0 5.45 5.46H237a5.47 5.47 0 0 0 5.46-5.46V60.66A5.47 5.47 0 0 0 237 55.2ZM453.31 337.8h-193.1a5.72 5.72 0 0 1-5.71-5.71V60.66a5.72 5.72 0 0 1 5.71-5.66h193.1a5.71 5.71 0 0 1 5.69 5.66v271.43a5.71 5.71 0 0 1-5.69 5.71ZM260.21 55.2a5.47 5.47 0 0 0-5.46 5.46v271.43a5.47 5.47 0 0 0 5.46 5.46h193.1a5.47 5.47 0 0 0 5.46-5.46V60.66a5.47 5.47 0 0 0-5.46-5.46Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M285.11 222.99h239.84v78.97H285.11z"
      style={{
        fill: '#f0f0f0',
      }}
      transform="rotate(90 405.03 262.48)"
    />
    <path
      d="M369.88 149.57h70.3v70.3h-70.3z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(90 405.03 184.72)"
    />
    <path
      d="M369.88 226.18h70.3v70.3h-70.3z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(90 405.03 261.33)"
    />
    <path
      d="M369.88 302.79h70.3v70.3h-70.3z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(90 405.03 337.94)"
    />
    <path
      d="M389.47 148.64h31.12a7.29 7.29 0 0 1-7.29 7.29h-16.54a7.29 7.29 0 0 1-7.29-7.29ZM389.47 225.25h31.12a7.29 7.29 0 0 1-7.29 7.29h-16.54a7.29 7.29 0 0 1-7.29-7.29ZM389.47 301.86h31.12a7.29 7.29 0 0 1-7.29 7.29h-16.54a7.29 7.29 0 0 1-7.29-7.29Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M225.9 242.76h239.84v39.44H225.9z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(90 345.825 262.475)"
    />
    <path
      d="M75.72 226.93v2.17h1.39l1.14 17.01h16.9l1.14-17.01h1.39v-2.17H75.72zM81.28 184.71c1.39-10.14 15.06-13 32.42-13.4 16.51-.36 21.8-6.2 21.8-6.2s-.05 23.66-23.85 32.23c-22.59 8.13-31.76-2.48-30.37-12.63Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M89.71 207.68c-.29-4.17-6.94-11.83-22.95-8.34-11.57 2.51-15.87-3.49-15.87-3.49s1.07 16.94 18.47 22c16.53 4.86 20.86-2.85 20.35-10.17Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M86.06 233.32a.63.63 0 0 1-.47-.22c-1.16-1.37-.21-4.66 1.1-9.2 1.69-5.84 3.79-13.1 2-18.37a73.23 73.23 0 0 0-3.44-8.14c-2.52-5.31-4.69-9.9-2.88-14.69a.62.62 0 0 1 .8-.36.61.61 0 0 1 .36.79c-1.63 4.31.34 8.47 2.83 13.73a76.16 76.16 0 0 1 3.5 8.28c1.9 5.63-.26 13.1-2 19.1-1 3.54-2.08 7.19-1.35 8.06a.62.62 0 0 1-.07.87.6.6 0 0 1-.38.15Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M278.41 246.11h70.07V382.4h-70.07z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M342.7 246.11h5.78V382.4h-5.78z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M122.48 250h161.33v57.67H122.48z"
      style={{
        fill: '#e6e6e6',
      }}
      transform="rotate(180 203.145 278.835)"
    />
    <path
      d="M189.76 382.37h-69.17a1 1 0 0 1 0-2h69.17c10 0 11.33-2.91 11.33-14.5v-146a1 1 0 0 1 2 0v146c0 12.38-1.93 16.5-13.33 16.5Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M130.26 246.11h218.22v10.22H130.26z"
      style={{
        fill: '#f0f0f0',
      }}
      transform="rotate(180 239.37 251.22)"
    />
    <path
      d="M60.19 246.11h70.07V382.4H60.19z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M124.48 246.11h5.78V382.4h-5.78zM209.12 246.11h-15.11l3.67-29.42h15.12l-3.68 29.42z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M212.47 246.11h-15.12l3.68-29.42h15.11l-3.67 29.42z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M214.31 231.4h-15.12l1.84-14.71h15.11l-1.83 14.71z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M158.76 227.88h106.93a1.48 1.48 0 0 0 1.47-1.67l-8.24-65.92a1.47 1.47 0 0 0-1.47-1.3H150.52a1.48 1.48 0 0 0-1.47 1.66l8.24 65.93a1.48 1.48 0 0 0 1.47 1.3Z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M160.35 227.88h106.93a1.48 1.48 0 0 0 1.47-1.67l-8.24-65.92A1.48 1.48 0 0 0 259 159H152.11a1.48 1.48 0 0 0-1.47 1.66l8.24 65.93a1.48 1.48 0 0 0 1.47 1.29Z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="m265.3 224.23-7.71-61.7a.42.42 0 0 0-.41-.36H154.5a.4.4 0 0 0-.41.46l7.71 61.71a.42.42 0 0 0 .41.36h102.68a.42.42 0 0 0 .41-.47Z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="m254.34 224.7-27.14-62.53h-23.8l27.15 62.53h23.79zM225.96 224.7l-27.14-62.53h-8.57l27.14 62.53h8.57z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M201.6 243.35h24.62v.31a2.45 2.45 0 0 1-2.45 2.45h-19.72a2.45 2.45 0 0 1-2.45-2.45v-.31Z"
      style={{
        fill: '#f5f5f5',
      }}
      transform="rotate(-180 213.91 244.73)"
    />
    <path
      d="M187.75 243.35h16.7v.31a2.45 2.45 0 0 1-2.45 2.45h-11.8a2.45 2.45 0 0 1-2.45-2.45v-.31Z"
      style={{
        fill: '#f0f0f0',
      }}
      transform="rotate(180 196.1 244.73)"
    />
    <path
      d="M253.43 246.11h-76.12l-2.06-6.11h68.28l9.9 6.11z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M185.69 246.11h-12.58l-1.7-6.11h12.57l1.71 6.11zM272.69 241h11.45a5.11 5.11 0 0 1 5.11 5.11h-21.67a5.11 5.11 0 0 1 5.11-5.11Z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M86.16 83.64h111.91v56.15H86.16z"
      style={{
        fill: '#e6e6e6',
      }}
    />
    <path
      d="M87.58 83.64h116.03v56.15H87.58z"
      style={{
        fill: '#f0f0f0',
      }}
    />
    <path
      d="M121.94 58.13h47.3v107.18h-47.3z"
      style={{
        fill: '#fff',
      }}
      transform="rotate(90 145.595 111.715)"
    />
    <path
      d="M96.25 111.71a1.81 1.81 0 0 1 1.81-1.81h95.07a1.81 1.81 0 0 1 1.81 1.81 1.81 1.81 0 0 1-1.81 1.81H98.06a1.81 1.81 0 0 1-1.81-1.81Z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <ellipse
      cx={250}
      cy={416.24}
      rx={193.89}
      ry={11.32}
      style={{
        fill: '#f5f5f5',
      }}
    />
    <rect
      width={259.37}
      height={171.44}
      x={81.72}
      y={78.56}
      rx={12}
      style={{
        fill: '#263238',
      }}
      transform="rotate(180 211.4 164.28)"
    />
    <path
      d="M149.77 406.84 206.64 350l1.4-1.39V180a2.26 2.26 0 0 1 2.26-2.26h2.2a2.26 2.26 0 0 1 2.26 2.26v168.58l1.39 1.39L273 406.84a1.93 1.93 0 0 1 0 2.74l-2 2a1.94 1.94 0 0 1-2.73 0l-53.51-53.51v51.83a2.26 2.26 0 0 1-2.26 2.26h-2.2a2.26 2.26 0 0 1-2.3-2.26v-51.81l-53.47 53.51a2 2 0 0 1-2.74 0l-2-2a2 2 0 0 1-.02-2.76Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M304 75.06h7.59v1H304zM281.17 75.06H297v1h-15.83z"
      style={{
        fill: '#407bff',
      }}
    />
    <rect
      width={259.37}
      height={171.44}
      x={84.16}
      y={78.56}
      rx={12}
      style={{
        fill: '#407bff',
      }}
      transform="rotate(180 213.845 164.28)"
    />
    <rect
      width={157.44}
      height={245.37}
      x={135.12}
      y={41.59}
      rx={5}
      style={{
        fill: '#fafafa',
      }}
      transform="rotate(90 213.845 164.275)"
    />
    <rect
      width={19.5}
      height={15.33}
      x={201.65}
      y={335.5}
      rx={3.17}
      style={{
        fill: '#407bff',
      }}
      transform="rotate(-180 211.4 343.165)"
    />
    <path
      d="M182.49 217.19a45 45 0 1 0-45-45 45 45 0 0 0 45 45Zm0-89.08a44.1 44.1 0 1 1-44.1 44.1 44.15 44.15 0 0 1 44.1-44.1Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M128.8 104.82h39.61a5.32 5.32 0 0 1 5.32 5.32v73.2a5.32 5.32 0 0 1-5.32 5.32h-50.69a5.32 5.32 0 0 1-5.32-5.32v-62.12Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M131.13 181.34h33a2.33 2.33 0 0 0 2.27-2.34 2.32 2.32 0 0 0-2.32-2.32h-33a2.33 2.33 0 0 0-2.28 2.32 2.34 2.34 0 0 0 2.33 2.34ZM146.65 116.79h17.43a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-17.43a2.32 2.32 0 0 0-2.32 2.32 2.32 2.32 0 0 0 2.32 2.32ZM135.28 126h28.8a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-28.8a2.32 2.32 0 0 0-2.32 2.32 2.32 2.32 0 0 0 2.32 2.32ZM122.05 135.23h42a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-42a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32ZM122.05 144.45h24.43a2.33 2.33 0 0 0 2.33-2.32 2.33 2.33 0 0 0-2.33-2.32h-24.43a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32ZM157.69 144.45h6.39a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-6.39a2.32 2.32 0 0 0-2.32 2.32 2.32 2.32 0 0 0 2.32 2.32ZM122.05 153.67h42a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-42a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32ZM138.27 162.89h25.81a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-25.81a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32ZM122.05 162.89h6.89a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-6.89a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32ZM122.05 172.11h42a2.32 2.32 0 0 0 2.32-2.32 2.32 2.32 0 0 0-2.32-2.32h-42a2.33 2.33 0 0 0-2.33 2.32 2.33 2.33 0 0 0 2.33 2.32Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M128.8 104.82v11.08a5.32 5.32 0 0 1-5.32 5.32H112.4Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M261.67 111.53V95.69A60.7 60.7 0 0 0 231.33 209l7.92-13.72a44.86 44.86 0 0 1 22.42-83.72Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M322.37 156.4a60.72 60.72 0 0 0-60.7-60.71v15.84a44.88 44.88 0 0 1 44.87 44.87Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M322.37 156.4h-15.83a44.86 44.86 0 0 1-67.29 38.85L231.33 209a60.7 60.7 0 0 0 91-52.57ZM176.26 199.97l19.19-24.79 19.19 24.79h-9.03v32.1h-20.32v-32.1h-9.03z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="m338 403.73-7.58-2.62 4.06-18.27 7.59 2.63-4.07 18.26z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="m334.48 382.85-2.09 9.41 7.58 2.63 2.1-9.41-7.59-2.63z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M410.93 408.04h-8.02l-6.26-18.59h8.03l6.25 18.59z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M404.68 389.46h-8.03l3.23 9.58h8.03l-3.23-9.58z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M401.84 407.11h9.78a.7.7 0 0 1 .68.55l1.59 7.14a1.17 1.17 0 0 1-1.17 1.42c-3.15-.05-5.45-.24-9.42-.24-2.44 0-9.79.26-13.16.26s-3.81-3.33-2.43-3.64c6.18-1.35 10.84-3.21 12.82-5a2 2 0 0 1 1.31-.49ZM330.78 400.25l8.28 2.87a.7.7 0 0 1 .47.74l-.84 7.26a1.17 1.17 0 0 1-1.56 1c-3-1.08-7.23-2.75-11-4-4.39-1.52-8.27-2.6-13.42-4.38-3.11-1.08-2.89-4.53-1.48-4.36 6.38.76 11.27 2.29 17.49.91a3.72 3.72 0 0 1 2.06-.04ZM237.23 138.85l81.92 72.92-1.4 1.57-81.19-73.75.67-.74z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M374.39 166.74c-.58 1.74-1.14 3.25-1.78 4.83s-1.28 3.11-2 4.65q-2.06 4.6-4.55 9.07a78.54 78.54 0 0 1-5.66 8.72 42.83 42.83 0 0 1-7.63 8l-1.21.9c-.41.29-.87.57-1.31.85a23.43 23.43 0 0 1-2.71 1.42 29.82 29.82 0 0 1-5.5 1.83 45.09 45.09 0 0 1-10.71 1.06 68.71 68.71 0 0 1-10.21-1c-1.66-.27-3.31-.64-4.94-1s-3.22-.81-4.92-1.37l1.34-5.87c1.45.12 3.06.24 4.59.32s3.1.09 4.64.11a75.52 75.52 0 0 0 9.09-.44 40.81 40.81 0 0 0 8.37-1.74 20 20 0 0 0 3.53-1.55 12.32 12.32 0 0 0 1.5-.94c.22-.18.44-.33.67-.52l.7-.6a36.37 36.37 0 0 0 5.31-6.27 81.12 81.12 0 0 0 4.52-7.68c1.4-2.69 2.71-5.46 3.95-8.27q.94-2.12 1.8-4.25c.56-1.41 1.16-2.91 1.64-4.2Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M377.65 162.8c1.72 7.47-12.1 28.49-12.1 28.49l-17.33-12.67a98.11 98.11 0 0 1 11.7-17.39c6.76-7.62 15.84-6.64 17.73 1.57Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m313.62 198.84-8.56-1.93 1.25 10.17a7.71 7.71 0 0 0 8.51-3.34Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="m299.47 196.66 2.78 7.69 4.06 2.73-1.25-10.17-5.59-.25z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M413.56 161.68c-10.45 29.92-10.64 62.32-11.81 68.82-6.24-.83-29.74-4-42.55-5.73-2.88-45.94 9.74-68.7 9.74-68.7s2.78-.37 6.54-.53c1.18 0 2.45-.08 3.77-.07.73 0 1.47 0 2.22.05a159.1 159.1 0 0 1 18.09 2.48c1.13.21 2.28.45 3.39.7s2 .49 3 .74c4.3 1.09 7.61 2.24 7.61 2.24Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m405.94 159.4-3.36 2.54c-4.27 3.23-9.18 6.22-16.23 6.22-.8 0-1.62 0-2.44-.12-5.84-.58-8.07-3.42-8.92-5.71a8.67 8.67 0 0 1 .49-6.79c1.18 0 2.45-.08 3.77-.07.73 0 1.47 0 2.22.05a159.1 159.1 0 0 1 18.09 2.48c1.13.21 2.28.45 3.39.7s2.05.45 2.99.7Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M400.51 137.35c-1.92 5.67-4.5 16.18-.95 20.61-3.59 2.72-8.21 5.79-15.16 5.1s-5.09-5.28-2.93-7.54c6.7-.67 7.18-5.51 6.66-10.19Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="m395.46 140.61-7.32 4.72a19.13 19.13 0 0 1 .12 3.28c2.53 0 6.3-2.28 6.91-4.84a8.7 8.7 0 0 0 .29-3.16Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M385.78 114.61c-6.22-.51-8.37 14.46-4.49 17.33s16.13-16.38 4.49-17.33Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M402.38 128.69c-1.54 7.81-2.05 12.46-6.58 16-6.8 5.28-16 .36-16.86-7.76-.74-7.31 1.91-18.86 10.05-21a10.87 10.87 0 0 1 13.39 12.76Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M400.79 136.33c-3.85 2.4-7.56-18.94 3.13-18.44 13.08.62 5.08 13.35-3.13 18.44Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M404.12 120.94c-5.71 1.86-29.9-.59-32.9-6.58a10.89 10.89 0 0 0 5.08-.64s-5.12-1.39-5.94-6.08c9.06 4.63 28.19-1.4 35 3.74 5.69 4.24 3.2 8.12-1.24 9.56Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M406.89 119s-.33-2.32 6.4.34c-5.56-1-6.22.67-6.22.67Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M404.77 137.85a7.54 7.54 0 0 1-5.12 2.68c-2.65.22-3.47-2.26-2.32-4.5 1-2 3.67-4.55 6.12-3.72s2.88 3.69 1.32 5.54Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M330.15 389.53 343 394s12.58-49.59 16.52-74.29c1.2-7.51 3.88-17.91 7.12-29 4.08-14 9.05-29.08 13.08-40.89 4.26-12.44 7.45-21.23 7.45-21.23l-28-3.77s-21.31 65.33-23.71 89.71c-2.46 25.3-5.31 75-5.31 75Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M366.64 290.66c4.08-14 9.05-29.08 13.08-40.89l-2.51-6.47c-7.5 7.84-11.02 33.35-10.57 47.36Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M374.29 226.81s-1.38 68.77.51 92.21c2.06 25.68 21.3 78.16 21.3 78.16h14.71s-9.81-48-12.82-73c-3.43-28.4 3.76-93.7 3.76-93.7Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M394.35 397.5h17.31v-5.17l-19.35-.34 2.04 5.51zM328.43 389.27l15.31 5.3 1.71-4.95-17.02-6.18v5.83zM390.13 129.28c-.08.64-.49 1.11-.91 1.06s-.69-.63-.6-1.27.49-1.11.91-1.06.69.63.6 1.27ZM382.88 128.3c-.08.64-.49 1.12-.91 1.06s-.69-.62-.61-1.26.5-1.12.92-1.06.72.62.6 1.26Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M385.14 128.93a23 23 0 0 1-3.8 5.07 3.71 3.71 0 0 0 3 1Z"
      style={{
        fill: '#ff5652',
      }}
    />
    <path
      d="M386.8 137.07a5.34 5.34 0 0 0 3.91-1.61.19.19 0 1 0-.29-.25 5.11 5.11 0 0 1-4.41 1.43.19.19 0 0 0-.05.38 6.1 6.1 0 0 0 .84.05ZM392.69 127.24a.41.41 0 0 0 .18 0 .38.38 0 0 0 .15-.52 3.82 3.82 0 0 0-2.86-2 .38.38 0 0 0-.4.36.37.37 0 0 0 .35.4 3 3 0 0 1 2.24 1.58.38.38 0 0 0 .34.18ZM380.09 125.38a.37.37 0 0 0 .32-.17 3 3 0 0 1 2.36-1.41.39.39 0 0 0 .38-.38.38.38 0 0 0-.38-.38 3.75 3.75 0 0 0-3 1.75.38.38 0 0 0 .1.53.42.42 0 0 0 .22.06Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M399.18 408.52a9.76 9.76 0 0 0 2.1-.29.2.2 0 0 0 .06-.35c-.3-.18-2.88-1.77-3.77-1.32a.62.62 0 0 0-.33.54 1.17 1.17 0 0 0 .4 1 2.44 2.44 0 0 0 1.54.42Zm1.55-.56c-1.36.27-2.41.22-2.85-.15a.76.76 0 0 1-.26-.68.25.25 0 0 1 .12-.23c.46-.24 1.94.45 2.99 1.1Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M401.24 408.23a.19.19 0 0 0 .1 0 .2.2 0 0 0 .09-.17c0-.1-.2-2.42-1.14-3.18a1 1 0 0 0-.81-.24.61.61 0 0 0-.6.49c-.17.9 1.44 2.66 2.27 3.11Zm-1.62-3.23a.62.62 0 0 1 .43.17 4.49 4.49 0 0 1 .95 2.51c-.82-.63-1.85-2-1.75-2.48 0-.05 0-.17.28-.2h.09ZM329.67 401a.21.21 0 0 0 .17-.1.2.2 0 0 0 0-.22c-.28-.3-2.79-2.9-3.89-2.71a.61.61 0 0 0-.46.38 1.08 1.08 0 0 0 0 1c.58 1 2.85 1.48 4.16 1.66Zm-3.62-2.66c.64 0 2.08 1.2 3.08 2.19-1.77-.3-3-.8-3.32-1.38a.68.68 0 0 1 0-.65.2.2 0 0 1 .17-.15Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M329.67 401h.05a.17.17 0 0 0 .13-.14c0-.09.53-2.2-.16-3.33a1.42 1.42 0 0 0-.89-.65c-.52-.12-.74.08-.85.27-.43.81.81 3.08 1.59 3.81a.19.19 0 0 0 .13.04Zm-1.16-3.79h.2a1 1 0 0 1 .65.48 4.42 4.42 0 0 1 .19 2.69c-.74-.89-1.52-2.6-1.26-3.09.01.04.06-.04.22-.04Zm.24-.15Z"
      style={{
        fill: '#407bff',
      }}
    />
    <path
      d="M415.07 166.27c.79 1.45 1.44 2.73 2.08 4.11s1.26 2.73 1.87 4.1c1.18 2.76 2.28 5.57 3.25 8.43s1.83 5.8 2.56 8.79l.51 2.27.42 2.31c.15.76.24 1.56.35 2.35l.15 1.18v.29l.05.49v1a16.53 16.53 0 0 1-1.43 6.09 35.23 35.23 0 0 1-2.29 4.47 69.94 69.94 0 0 1-5.32 7.55 114.56 114.56 0 0 1-12.23 13l-4.48-4c3.21-4.63 6.39-9.41 9.25-14.14a76.71 76.71 0 0 0 3.85-7.12 26.16 26.16 0 0 0 1.33-3.38 6.52 6.52 0 0 0 .39-2.32s0-.05 0 0v-.05l-.05-.23-.21-1c-.14-.63-.26-1.26-.44-1.9l-.5-1.92-.57-1.92c-.8-2.56-1.69-5.13-2.69-7.69s-2.06-5.1-3.18-7.63c-.56-1.27-1.12-2.53-1.71-3.78l-1.71-3.62Z"
      style={{
        fill: '#ff8b7b',
      }}
    />
    <path
      d="M413.56 161.68c7.17 2.73 12 20.06 12 20.06l-14.82 14.8s-6.4-12.83-9-22.4c-2.81-10.43 3.13-15.76 11.82-12.46Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m402.39 227.51-7.73 2.55 7 7.51s6.1-3.64 4.54-7.09ZM390.9 235.62l6.07 5.93 4.67-3.98-6.99-7.51-3.75 5.56z"
      style={{
        fill: '#ff8b7b',
      }}
    />
  </svg>
);
export default React.memo(PresentationIllustration);
