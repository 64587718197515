/* eslint-disable import/no-extraneous-dependencies */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import inventoryReducer from './slices/inventory';
import emailOrPhoneReducer from './slices/emailOrPhone';
import ticketsReducer from './slices/tickets';
import bookReducer from './slices/book';
import selectTablesReducer from './slices/selectTables';
import kanbanReducer from './slices/kanban';
import fileManagerReducer from './slices/fileManager';
import clientsReducer from './slices/clients';
import virtualPhoneReducer from './slices/virtualPhone';
import virtualPhoneCallTimerReducer from './slices/virtualPhoneCallTimer';
import callReportsReducer from './slices/callReports';
import notificationsReducer from './slices/notifications';
import personsReducer from './slices/persons';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  inventory: inventoryReducer,
  product: persistReducer(productPersistConfig, productReducer),
  emailOrPhone: emailOrPhoneReducer,
  tickets: ticketsReducer,
  book: bookReducer,
  selectTables: selectTablesReducer,
  kanban: kanbanReducer,
  fileManager: fileManagerReducer,
  clients: clientsReducer,
  virtualPhone: virtualPhoneReducer,
  virtualPhoneCallTimer: virtualPhoneCallTimerReducer,
  callReports: callReportsReducer,
  notifications: notificationsReducer,
  persons: personsReducer,
});

export default rootReducer;
