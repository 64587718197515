import * as React from "react"

const MoneyIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
    <ellipse
      cx={251}
      cy={381.96}
      rx={227.47}
      ry={109.28}
      style={{
        fill: "#f0f0f0",
      }}
    />
    <ellipse
      cx={201.25}
      cy={351.29}
      rx={98.62}
      ry={56.94}
      style={{
        fill: "#e0e0e0",
      }}
    />
    <ellipse
      cx={148.91}
      cy={409.99}
      rx={88.49}
      ry={51.09}
      style={{
        fill: "#e0e0e0",
      }}
    />
    <ellipse
      cx={359.9}
      cy={379.34}
      rx={98.62}
      ry={56.94}
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M422.31 118.76c.05 4.71-.12 9.25-6.92 10.31 7.19-.1 6.64 4.43 7 10.11.2-5.68.7-9.75 6.3-10-5.81-.98-5.69-3.67-6.38-10.42ZM46.13 113.83c0 3.57-.09 7-5.25 7.8 5.45-.07 5 3.36 5.3 7.66.15-4.3.52-7.38 4.77-7.58-4.39-.71-4.27-2.77-4.82-7.88ZM146.59 26.63c.06 5.54-.14 10.87-8.14 12.12 8.45-.11 7.81 5.22 8.23 11.89.22-6.67.81-11.46 7.4-11.78-6.82-1.13-6.64-4.29-7.49-12.23ZM317.1 229.63c0 2.77-.07 5.44-4.08 6.06 4.23-.06 3.91 2.61 4.12 5.95.11-3.34.41-5.73 3.7-5.89-3.41-.57-3.32-2.15-3.74-6.12Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M398.29 154.59c.08 7.19-.18 14.12-10.57 15.73 11-.14 10.15 6.78 10.68 15.45.3-8.67 1.06-14.89 9.62-15.3-8.86-1.47-8.62-5.57-9.73-15.88ZM299.09 104.53c.07 6-.15 11.85-8.88 13.21 9.22-.12 8.53 5.69 9 13 .24-7.28.88-12.5 8.07-12.85-7.45-1.26-7.28-4.7-8.19-13.36ZM377.34 31.25c0 2.67-.06 5.23-3.91 5.82 4.06 0 3.76 2.51 3.95 5.71.11-3.2.39-5.5 3.56-5.65-3.27-.54-3.19-2.06-3.6-5.88ZM75.48 249.31c0 2.73-.06 5.35-4 6 4.16-.05 3.84 2.57 4 5.86.11-3.29.4-5.65 3.64-5.8-3.3-.6-3.22-2.15-3.64-6.06ZM71.82 130.78c.07 7.26-.19 14.25-10.68 15.88 11.09-.14 10.25 6.84 10.79 15.6.3-8.76 2.25-15.33 10.89-15.74-8.94-1.52-9.88-5.33-11-15.74Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M439.55 148.46c0 3.68-.74 7.38-6.06 8.2 5.62-.07 5.84 3.32 6.11 7.76.15-4.44 1.37-7.86 5.75-8.07-4.53-.75-5.24-2.61-5.8-7.89ZM455.79 267.21c0 3.94-.79 7.89-6.47 8.77 6-.08 6.24 3.55 6.54 8.29.16-4.74 1.45-8.4 6.14-8.62-4.85-.8-5.6-2.8-6.21-8.44ZM30.78 283.84c0 3.94-.79 7.89-6.48 8.77 6-.08 6.24 3.55 6.54 8.29.16-4.74 1.46-8.4 6.14-8.62-4.84-.8-5.6-2.8-6.2-8.44Z"
      style={{
        fill: "#ebebeb",
      }}
    />
    <path
      d="M394.87 38.64c.05 5.12-.13 10-7.53 11.2 7.82-.11 7.23 4.82 7.61 11 .21-6.17.75-10.6 6.84-10.89-6.3-1.05-6.13-3.95-6.92-11.31ZM82.72 113.05c0-5.12-.13-9.69-7.53-10.84 7.82.11 7.22-5.18 7.61-11.35C83 97 84 102.32 90 102.61c-6.26 1.04-6.49 3.1-7.28 10.44Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M52.32 332.72a10 10 0 0 0-6.18-8.66c-4.73-2.13-10.29-2-10.71-1.52s1.59 5.61 6.78 8.76a11.69 11.69 0 0 0 10.11 1.42ZM58 344.28a10 10 0 0 0-9.45-4.89c-5.18.27-10.06 2.93-10.22 3.53s4 4.27 10 4.71a11.68 11.68 0 0 0 9.67-3.35ZM67.88 343.49a10 10 0 0 0 3.18-10.15c-1.16-5.06-4.09-9.22-4.71-9.27s-3.93 5.11-3.75 10.57c.19 5.95 4.14 7.87 5.28 8.85Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M85.13 384.12c-.13-4.67-1.76-9.25-3.47-13.55a79.14 79.14 0 0 0-5.8-11.66 102.05 102.05 0 0 0-16.94-21.11c-4.16-4-8.75-8-14-10.43-.11-.05-.3.19-.2.27a81.07 81.07 0 0 1 12 10.34 95.26 95.26 0 0 1 9.48 10.54 98.27 98.27 0 0 1 12.19 20.75c2.15 4.89 3.45 10 5 15.11a.87.87 0 0 0 1.74-.26Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M68 347.7a31.83 31.83 0 0 0 .61-7 17.73 17.73 0 0 0-1.16-6.62c-.12-.29-.25.17-.23.37a52.43 52.43 0 0 1 .24 6 22.92 22.92 0 0 1-1.07 6.3 1.43 1.43 0 0 0 .53 1.54.75.75 0 0 0 1.08-.59Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M67.31 347.15c-2.71-1.89-5.36-2.7-9.1-3.66a28.87 28.87 0 0 0-4.43-.75 17.78 17.78 0 0 0-5.32.24c-.06 0-.42.27-.29.28a45.61 45.61 0 0 1 5.2.44 27 27 0 0 1 4.63 1 42.28 42.28 0 0 1 8.75 3.62c.58.33 1.03-.84.56-1.17Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M69.73 359.52a10 10 0 0 0-8.23-6.75c-5.12-.82-10.45.77-10.73 1.32s3 5 8.83 6.7a11.65 11.65 0 0 0 10.13-1.27Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M78.22 364.27c-2.26-2.41-4.68-3.76-8.13-5.47a28.41 28.41 0 0 0-4.18-1.66 17.86 17.86 0 0 0-5.25-.88c-.07 0-.47.18-.34.21a47.59 47.59 0 0 1 5 1.52 27.94 27.94 0 0 1 4.33 2 42.44 42.44 0 0 1 7.79 5.37c.49.39 1.17-.67.78-1.09Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M86.93 304.65a11 11 0 0 1 1.85-11.53c3.62-4.39 9.16-7 9.79-6.71s1.17 6.29-2.39 11.93a12.8 12.8 0 0 1-9.25 6.31ZM87 318.79a10.92 10.92 0 0 1 6.91-9.41c5.22-2.26 11.32-2 11.77-1.51s-1.83 6.14-7.58 9.52a12.77 12.77 0 0 1-11.1 1.4ZM76.88 322.83a10.93 10.93 0 0 1-8.07-8.44c-1.33-5.54-.47-11.05.11-11.41s6.35 3.12 8.84 8.57c2.71 5.94-.24 9.76-.88 11.28Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M79.72 371.19c-2.15-4.66-2.78-10-3.2-15a86.31 86.31 0 0 1 0-14.29 111.93 111.93 0 0 1 6.36-29c2.17-5.91 4.71-12.13 8.69-17.07.08-.11.39 0 .32.16a89.06 89.06 0 0 0-6.74 16A104.58 104.58 0 0 0 81 327a107.6 107.6 0 0 0-1.87 26.34c.27 5.86 1.48 11.54 2.42 17.31a1 1 0 0 1-1.83.54Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M78.82 327a34.8 34.8 0 0 1-4-6.6 19.68 19.68 0 0 1-2.09-7.08c0-.34.33.05.4.26a57 57 0 0 0 2.71 6 25.1 25.1 0 0 0 4.11 5.67 1.55 1.55 0 0 1 .24 1.76.81.81 0 0 1-1.37-.01Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M79.22 326.15c1.75-3.18 4-5.27 7.17-8a31.75 31.75 0 0 1 4-2.9 19.22 19.22 0 0 1 5.34-2.36c.08 0 .55.06.42.13a51.52 51.52 0 0 0-4.89 3 29.75 29.75 0 0 0-4.06 3.26 47.39 47.39 0 0 0-6.85 7.72c-.41.64-1.43-.3-1.13-.85Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="M82.88 339.49a10.93 10.93 0 0 1 4.79-10.64c4.64-3.31 10.65-4.34 11.2-3.93s-.51 6.38-5.42 10.89a12.81 12.81 0 0 1-10.57 3.68Z"
      style={{
        fill: "#455a64",
      }}
    />
    <path
      d="M76.84 348.3c1-3.47 2.76-6 5.33-9.34a31 31 0 0 1 3.3-3.67 19 19 0 0 1 4.73-3.42c.08 0 .56-.06.44 0a51.93 51.93 0 0 0-4.16 3.92 29.66 29.66 0 0 0-3.3 4 46.86 46.86 0 0 0-5.05 9.07c-.28.75-1.47.04-1.29-.56Z"
      style={{
        fill: "#37474f",
      }}
    />
    <path
      d="m465.85 228.63 2.65 1.19-2.46 5.4a8.15 8.15 0 0 1-1.14 1.8c-.16.19-.33.37-.51.55a10.61 10.61 0 0 1-3.19 2.11 12.61 12.61 0 0 1-1.35.51 13.31 13.31 0 0 1-1.51.41 20.91 20.91 0 0 1-2.08.36c-.76.09-1.54.15-2.34.17h-1.83c-9.08-.33-19.78-4.75-27-11.14-.49-.43-1-.88-1.42-1.33-.66-.64-1.28-1.31-1.86-2s-.91-1.11-1.31-1.66-.66-1-1-1.43-.54-.93-.77-1.39a13.83 13.83 0 0 1-1.35-4c0-.28-.08-.55-.1-.81a8.5 8.5 0 0 1 .33-3.29 6.12 6.12 0 0 1 .35-.93l2.33-5.1 2.41 1.09c.29-.13.59-.24.9-.35a18.61 18.61 0 0 1 3.91-.88 24.14 24.14 0 0 1 2.59-.17 31.64 31.64 0 0 1 3.48.14 47.06 47.06 0 0 1 23.56 9.71 34.89 34.89 0 0 1 2.79 2.45c.73.7 1.41 1.43 2.05 2.17a24.26 24.26 0 0 1 2.45 3.41 16.5 16.5 0 0 1 1.42 3.01Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m429.71 233.51 5-11 3.82 2.25-5 11q-1.98-1.04-3.82-2.25Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M466 235.22a8.15 8.15 0 0 1-1.14 1.8c-.16.19-.33.37-.51.55a10.61 10.61 0 0 1-3.19 2.11l-.48.2 5.12-11.28 2.65 1.19Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m455 224.08 2.29.45-7.55 16.47c-.75-.08-1.52-.19-2.28-.32ZM441.65 239.2l6.48-14.2 3.88 2.12-6 13.26a43.22 43.22 0 0 1-4.36-1.18ZM420.32 208.08l2.41 1.09c.29-.13.59-.24.9-.35a18.61 18.61 0 0 1 3.91-.88 24.14 24.14 0 0 1 2.59-.17 31.64 31.64 0 0 1 3.48.14c1.12.11 2.26.28 3.4.49l-10.4 22.87c-.53-.42-1-.85-1.54-1.29s-1-.88-1.42-1.33c-.66-.64-1.28-1.31-1.86-2s-.91-1.11-1.31-1.66-.66-1-1-1.43-.54-.93-.77-1.39a13.83 13.83 0 0 1-1.35-4c0-.28-.08-.55-.1-.81a8.5 8.5 0 0 1 .33-3.29 6.12 6.12 0 0 1 .35-.93Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M417.41 218.21c0-.28-.08-.55-.1-.81a8.5 8.5 0 0 1 .33-3.29 6.12 6.12 0 0 1 .35-.93l2.33-5.1 2.41 1.09.68.67-5.17 11.35a13.34 13.34 0 0 1-.83-2.98Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M464.64 216.47c-.64-.75-1.32-1.47-2.05-2.18a36.9 36.9 0 0 0-2.79-2.44 47.07 47.07 0 0 0-23.56-9.72 31.64 31.64 0 0 0-3.48-.14c-.89 0-1.75.08-2.59.18a18.62 18.62 0 0 0-3.91.87 11.83 11.83 0 0 0-3.41 1.81 7.9 7.9 0 0 0-2.8 4 8.46 8.46 0 0 0-.33 3.28c0 .27.06.54.1.81a13.78 13.78 0 0 0 1.35 4c.23.46.49.92.78 1.39s.6.95.94 1.42.84 1.11 1.31 1.67 1.2 1.34 1.86 2c.46.45.93.9 1.43 1.33 7.23 6.39 17.93 10.81 27 11.14h1.83c.8 0 1.58-.08 2.34-.17a20.41 20.41 0 0 0 2.08-.36c.53-.12 1-.25 1.51-.41a12.62 12.62 0 0 0 1.35-.52 10.59 10.59 0 0 0 3.19-2.1c.18-.18.35-.37.51-.56a7.52 7.52 0 0 0 1.51-2.79 9.36 9.36 0 0 0-.05-5.14 16 16 0 0 0-1.68-3.91 25.67 25.67 0 0 0-2.44-3.46Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M429 226c-.53-.43-1-.86-1.54-1.3s-1-.88-1.43-1.33c-.66-.65-1.28-1.31-1.86-2s-.91-1.11-1.31-1.67-.66-.95-.94-1.42-.55-.93-.78-1.39a13.78 13.78 0 0 1-1.35-4c0-.27-.08-.54-.1-.81a8.46 8.46 0 0 1 .33-3.28 7.9 7.9 0 0 1 2.8-4 11.83 11.83 0 0 1 3.41-1.81 18.62 18.62 0 0 1 3.91-.87c.75-.09 1.51-.14 2.29-.16Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M420.68 215.83a13.39 13.39 0 0 1-.86-2.93c0-.27-.08-.54-.1-.81a8.46 8.46 0 0 1 .33-3.28 7.7 7.7 0 0 1 2.12-3.37ZM435.93 230.47c-1.31-.7-2.59-1.46-3.81-2.26l3.74-26.11h.38c1.2.12 2.43.31 3.67.55ZM448.38 235c-1.43-.3-2.88-.69-4.32-1.14l4.12-28.74c1.43.58 2.83 1.23 4.21 1.93ZM452.16 235.64c-.76-.08-1.53-.19-2.3-.32l3.93-27.47c.74.41 1.46.83 2.17 1.28Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M465.55 217.61c.24.32.46.63.68.94l.21.31.21.31.44.7a16 16 0 0 1 1.68 3.91 9.36 9.36 0 0 1 .05 5.14 7.52 7.52 0 0 1-1.51 2.79c-.16.19-.33.38-.51.56a10.59 10.59 0 0 1-3.19 2.1l-.49.21Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M459.44 217c-5.66-6.61-16.48-11.24-24.67-10.69-.35 0-.7.05-1 .09-8.29 1-10.23 7.46-4.33 14.35s17.41 11.63 25.7 10.59q.51-.06 1-.15c7.4-1.37 8.96-7.55 3.3-14.19Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M458.77 217.12q-.27-.31-.57-.63c-5.53-6-15.44-10.09-23-9.58h-.2l-.78.08a11.58 11.58 0 0 0-4 1.16 5.86 5.86 0 0 0-3.33 6.35 11.79 11.79 0 0 0 2.37 5.17c.25.34.52.68.82 1s.55.63.84.94a25.31 25.31 0 0 0 2 1.85 33.25 33.25 0 0 0 18 7.42 19.78 19.78 0 0 0 2.55 0c.39 0 .78-.05 1.16-.1s.63-.09.94-.15l.25-.05a8.73 8.73 0 0 0 4.61-2.29c1.78-1.82 2.11-4.45.83-7.38a15.59 15.59 0 0 0-2.49-3.79Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M458.77 217.12q-.27-.31-.57-.63c-5.53-6-15.44-10.09-23-9.58h-.2l-.78.08a11.58 11.58 0 0 0-4 1.16 5.86 5.86 0 0 0-3.33 6.35 11.79 11.79 0 0 0 2.37 5.17c.25.34.52.68.82 1s.55.63.84.94a25.31 25.31 0 0 0 2 1.85 33.25 33.25 0 0 0 18 7.42 19.78 19.78 0 0 0 2.55 0c.39 0 .78-.05 1.16-.1s.63-.09.94-.15l.25-.05a8.73 8.73 0 0 0 4.61-2.29c1.78-1.82 2.11-4.45.83-7.38a15.59 15.59 0 0 0-2.49-3.79Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M457.29 218.5c.2.21.39.42.57.64a15.55 15.55 0 0 1 2.49 3.83 7.65 7.65 0 0 1 .62 4.73 5.38 5.38 0 0 1-.54.64 8.73 8.73 0 0 1-4.61 2.29l-.25.05c-.31.06-.62.11-.94.15s-.77.08-1.16.1a19.78 19.78 0 0 1-2.55 0 33.25 33.25 0 0 1-18-7.42 25.31 25.31 0 0 1-2-1.85c-.29-.31-.57-.62-.84-.94s-.57-.68-.82-1a11.79 11.79 0 0 1-2.37-5.17 6.4 6.4 0 0 1 .07-2.36 6.54 6.54 0 0 1 2.36-2 11.73 11.73 0 0 1 4-1.17l.78-.07h.21c7.55-.53 17.45 3.58 22.98 9.55Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M466.8 232.27c-5.48 4.81-14.2 3.75-20.9 2-10-2.94-19.81-8.76-24.65-17.46a11 11 0 0 1-1.21-8 10.9 10.9 0 0 0 1 8.12c3.8 7.23 11.25 12.41 19.14 15.75 8.1 3.11 19.51 5.76 26.62-.41Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M432.49 218.86c2.16 2.34 5 3 7.82 3.29l-.94.37-1.26 2.77a14.53 14.53 0 0 0 1.53 1 13.25 13.25 0 0 0 2.91 1.2 16.91 16.91 0 0 0 5.14.56l1.9 2.06 4.06-.51 1.26-2.76-.48-.53-.1-.11a2.88 2.88 0 0 0 .36-.62 2.09 2.09 0 0 0 .08-.23c.11-.36.9-1.89 1.18-2.54a2.09 2.09 0 0 0 .08-.23c.26-.81-.07-1.8-1.13-2.94a9.25 9.25 0 0 0-3.94-2.47l-.38-.12-.4-.11a3.58 3.58 0 0 0-.35-.09h-.15l-.27-.06a20.71 20.71 0 0 0-2.29-.33l-1-.07 1.24-.49 1.26-2.77a14.76 14.76 0 0 0-9-2.53l-1.9-2-4 .58-1.26 2.77.59.64a1.84 1.84 0 0 0-.31.49c-.17.44-1 2.25-1.25 2.75-.49.79-.12 1.82 1 3.03Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M455.89 223c-.32.71-1 2.08-1.12 2.41l-.08.23a3.14 3.14 0 0 1-.36.62l-1.33-1.52a3.55 3.55 0 0 0 2.89-1.74Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M438.87 215.15c-.57-.62-.44-1.22 1.24-1.47a5.73 5.73 0 0 1 4.4 1l4.05-1.61-1.27 2.76-1.22.49H446l-1.55-.07h-.59a18.33 18.33 0 0 1-3.23-.25 3.49 3.49 0 0 1-1.24-.46 2.43 2.43 0 0 1-.52-.39Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m432.93 212.5-.59-.64 1.26-2.76 1.89 2.05a5 5 0 0 0-2.56 1.35Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m450.85 227.36 4.06-.51-1.25 2.77-4.07.51-1.89-2.06a16.66 16.66 0 0 1-5.15-.57 12.74 12.74 0 0 1-2.91-1.19 13.12 13.12 0 0 1-1.53-1l1.26-2.77a13.92 13.92 0 0 0 1.52 1 13 13 0 0 0 2.92 1.19 16.33 16.33 0 0 0 5.14.57Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M447.7 228.07a12.79 12.79 0 0 1-4.23-.16 10.43 10.43 0 0 1-3.83-1.6 19.91 19.91 0 0 0 3.92 1.26 19.7 19.7 0 0 0 2 .33c.73.1 1.44.1 2.14.17ZM431.36 215.79c.21-.5 1.08-2.31 1.25-2.75-.32.84 0 1.86 1.14 3.07 2.51 2.71 5.9 3.23 9.16 3.38 3 .13 5.39-.12 6.61 1.19.76.82.2 1.32-1.12 1.5a6.18 6.18 0 0 1-4.74-1.32l-3.35 1.31c-2.83-.25-5.66-1-7.81-3.29-1.14-1.23-1.5-2.25-1.14-3.09Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m443.25 217.49 1.26-2.77 4.05-1.62-1.26 2.77-4.05 1.62z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m443.25 217.49 1.26-2.77 4.05-1.62-1.26 2.77-4.05 1.62z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m433.6 209.1 1.9 2.05-2.57 1.36-.59-.64 1.26-2.77z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m433.6 209.1 1.9 2.05-2.57 1.36-.59-.64 1.26-2.77z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m454.43 226.33-1.43-1.59a3.49 3.49 0 0 0 3-1.91 2.09 2.09 0 0 0 .08-.23c.26-.81-.07-1.8-1.13-2.94a9.25 9.25 0 0 0-3.94-2.47l-.38-.12-.4-.11a3.58 3.58 0 0 0-.35-.09h-.15l-.27-.06a20.71 20.71 0 0 0-2.29-.33c-.37 0-.74-.06-1.11-.07l-1.55-.07h-.59a19.55 19.55 0 0 1-3.23-.24 3.69 3.69 0 0 1-1.24-.47 2.43 2.43 0 0 1-.49-.41c-.57-.62-.44-1.22 1.24-1.46a5.64 5.64 0 0 1 4.4 1l4.05-1.62a14.76 14.76 0 0 0-9-2.53l-1.9-2-4 .58 1.9 2.05c-1.54.41-2.54 1-2.88 1.85-.32.84 0 1.86 1.14 3.08 2.5 2.7 5.9 3.22 9.16 3.37 3 .13 5.39-.12 6.61 1.19.75.82.2 1.32-1.12 1.5a6.22 6.22 0 0 1-4.74-1.31l-4.29 1.67a12.33 12.33 0 0 0 1.53 1 13.25 13.25 0 0 0 2.91 1.2 16.61 16.61 0 0 0 5.14.56l1.9 2.06 4.06-.5Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m454.43 226.33-1.43-1.59a3.49 3.49 0 0 0 3-1.91 2.09 2.09 0 0 0 .08-.23c.26-.81-.07-1.8-1.13-2.94a9.25 9.25 0 0 0-3.94-2.47l-.38-.12-.4-.11a3.58 3.58 0 0 0-.35-.09h-.15l-.27-.06a20.71 20.71 0 0 0-2.29-.33c-.37 0-.74-.06-1.11-.07l-1.55-.07h-.59a19.55 19.55 0 0 1-3.23-.24 3.69 3.69 0 0 1-1.24-.47 2.43 2.43 0 0 1-.49-.41c-.57-.62-.44-1.22 1.24-1.46a5.64 5.64 0 0 1 4.4 1l4.05-1.62a14.76 14.76 0 0 0-9-2.53l-1.9-2-4 .58 1.9 2.05c-1.54.41-2.54 1-2.88 1.85-.32.84 0 1.86 1.14 3.08 2.5 2.7 5.9 3.22 9.16 3.37 3 .13 5.39-.12 6.61 1.19.75.82.2 1.32-1.12 1.5a6.22 6.22 0 0 1-4.74-1.31l-4.29 1.67a12.33 12.33 0 0 0 1.53 1 13.25 13.25 0 0 0 2.91 1.2 16.61 16.61 0 0 0 5.14.56l1.9 2.06 4.06-.5Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M433.75 216.1a9.77 9.77 0 0 0 3.42 2.24 14.69 14.69 0 0 0 4.11.92c1.42.15 2.84.15 4.27.21a13.86 13.86 0 0 1 2.14.22 3.43 3.43 0 0 1 1.83 1 3.45 3.45 0 0 0-1.85-.88 15 15 0 0 0-2.12-.17c-1.43 0-2.86 0-4.29-.16a14.75 14.75 0 0 1-4.16-1 9.83 9.83 0 0 1-1.83-1 11.07 11.07 0 0 1-1.52-1.38Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m450.85 227.36-1.26 2.77-1.9-2.06 1.26-2.77 1.9 2.06z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m450.85 227.36-1.26 2.77-1.9-2.06 1.26-2.77 1.9 2.06z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m454.91 226.85-3.91.21-2.05-1.76 1.9 2.06 4.06-.51zM439.54 210.57l-2.01-1.75-3.93.28 4.04-.57 1.9 2.04z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M448.56 213.1a13.3 13.3 0 0 0-1.4-.84l-.21 1.48ZM433.84 216.18a9.47 9.47 0 0 0 3.79 2.42l1.25-8.74-1.24-1.34-2.76.39-.19 1.36.81.88a7.51 7.51 0 0 0-1 .32ZM454.51 219.25a9.22 9.22 0 0 0-2.14-1.48l-1.37 9.57 2.39-.3.23-1.59-.65-.71a3.77 3.77 0 0 0 .77-.13ZM450.54 217.05l-.4-.11a3.58 3.58 0 0 0-.35-.09h-.15l-.27-.06a20.71 20.71 0 0 0-2.29-.33h-.51l-.45 3.16c1.48.08 2.66.3 3.4 1.1s.2 1.32-1.12 1.5a6.09 6.09 0 0 1-2.63-.21l-.44 3.12a17.4 17.4 0 0 0 3.62.23l.72.78 1.27-8.91-.36-.11Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m327.28 65.4 2.7 1.06-2.2 5.54a8 8 0 0 1-1 1.84 6.82 6.82 0 0 1-.49.58 10.27 10.27 0 0 1-3.08 2.25 12.4 12.4 0 0 1-1.33.58 15.1 15.1 0 0 1-1.48.48 20.13 20.13 0 0 1-2.06.46c-.76.12-1.54.22-2.33.28-.6 0-1.21.07-1.83.07-9.07.1-20-3.8-27.48-9.84-.52-.41-1-.83-1.49-1.27a27 27 0 0 1-2-1.9c-.49-.53-1-1.06-1.38-1.59s-.7-.92-1-1.38-.59-.91-.85-1.36a14.06 14.06 0 0 1-1.53-3.9c-.05-.27-.1-.54-.14-.8a8.56 8.56 0 0 1 .18-3.29 7.53 7.53 0 0 1 .31-1l2.08-5.2 2.46 1c.29-.14.58-.27.88-.39a19.14 19.14 0 0 1 3.86-1.06 23.94 23.94 0 0 1 2.58-.29 28.79 28.79 0 0 1 3.48 0 47 47 0 0 1 24 8.58c1 .74 2 1.51 2.9 2.31.76.67 1.48 1.37 2.15 2.08a24.51 24.51 0 0 1 2.6 3.28 17.05 17.05 0 0 1 1.49 2.88Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m291.44 72 4.46-11.2 3.92 2.06-4.46 11.19q-2.03-.96-3.92-2.05Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M327.78 72a8 8 0 0 1-1 1.84 6.82 6.82 0 0 1-.49.58 10.27 10.27 0 0 1-3.08 2.25l-.48.23 4.59-11.5 2.7 1.06Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m316.19 61.37 2.3.35-6.71 16.73c-.76 0-1.52-.12-2.3-.21ZM303.63 77.08l5.79-14.51 4 1.94L308 78a43.6 43.6 0 0 1-4.37-.92ZM280.87 47l2.46 1c.29-.14.58-.27.88-.39a19.14 19.14 0 0 1 3.86-1.06 23.94 23.94 0 0 1 2.58-.29 28.79 28.79 0 0 1 3.48 0 33.78 33.78 0 0 1 3.42.33l-9.31 23.31a35.69 35.69 0 0 1-3.08-2.48 27 27 0 0 1-2-1.9c-.49-.53-1-1.06-1.38-1.59s-.7-.92-1-1.38-.59-.91-.85-1.36a14.06 14.06 0 0 1-1.53-3.9c-.05-.27-.1-.54-.14-.8a8.56 8.56 0 0 1 .18-3.29 7.53 7.53 0 0 1 .31-1Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M278.44 57.27c-.05-.27-.1-.54-.14-.8a8.56 8.56 0 0 1 .18-3.29 7.53 7.53 0 0 1 .31-1l2.08-5.2 2.46 1h.05l.7.63-4.62 11.58a13.23 13.23 0 0 1-1.02-2.92Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M325.5 53.32a35.17 35.17 0 0 0-2.14-2.08c-.91-.8-1.88-1.57-2.9-2.3a47 47 0 0 0-24-8.59 31 31 0 0 0-3.48 0q-1.32.09-2.58.3a19.15 19.15 0 0 0-3.86 1 11.57 11.57 0 0 0-3.31 2 8 8 0 0 0-2.62 4.08 8.78 8.78 0 0 0-.17 3.29c0 .27.09.54.14.81a14.18 14.18 0 0 0 1.53 3.9c.26.45.54.9.84 1.36s.65.91 1 1.37.88 1.07 1.38 1.6 1.26 1.29 1.95 1.9c.48.43 1 .85 1.48 1.26 7.52 6 18.41 9.95 27.49 9.85.62 0 1.23 0 1.83-.08.79-.06 1.57-.15 2.33-.28a20.15 20.15 0 0 0 2.06-.45 15.1 15.1 0 0 0 1.48-.48c.47-.18.91-.37 1.33-.58a10.45 10.45 0 0 0 3.12-2.2c.17-.19.33-.38.49-.58a7.72 7.72 0 0 0 1.37-2.86 9.3 9.3 0 0 0-.29-5.13 16.37 16.37 0 0 0-1.87-3.82 23.73 23.73 0 0 0-2.6-3.29Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M290.41 64.48c-.55-.4-1.09-.8-1.61-1.22s-1-.83-1.48-1.26c-.69-.61-1.34-1.25-1.95-1.9s-1-1.07-1.38-1.6-.71-.92-1-1.37-.58-.91-.84-1.36a14.18 14.18 0 0 1-1.53-3.9c-.05-.27-.1-.54-.14-.81a8.78 8.78 0 0 1 .17-3.29 8 8 0 0 1 2.62-4.08 11.57 11.57 0 0 1 3.31-2 19.15 19.15 0 0 1 3.86-1 21.38 21.38 0 0 1 2.29-.27Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M281.6 54.75a13.19 13.19 0 0 1-1-2.88c-.05-.27-.1-.54-.14-.81a8.78 8.78 0 0 1 .17-3.29 7.88 7.88 0 0 1 2-3.46ZM297.5 68.64c-1.34-.64-2.65-1.33-3.9-2.08l2.5-26.23h.38c1.21.06 2.44.19 3.69.37ZM310.15 72.62a43.19 43.19 0 0 1-4.37-.94l2.76-28.87c1.45.51 2.89 1.09 4.29 1.73ZM314 73.05c-.77 0-1.54-.12-2.32-.22l2.64-27.6c.75.37 1.5.76 2.22 1.17Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M326.47 54.42q.38.45.72.9l.22.3.23.31c.16.22.32.45.46.67a16.37 16.37 0 0 1 1.9 3.82 9.3 9.3 0 0 1 .29 5.13 7.72 7.72 0 0 1-1.37 2.86c-.16.2-.32.39-.49.58a10.45 10.45 0 0 1-3.08 2.25l-.48.22Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M320.34 54.13c-6-6.33-17-10.44-25.13-9.51-.34 0-.69.09-1 .15-8.23 1.42-9.86 7.92-3.65 14.52s17.92 10.79 26.15 9.36a10 10 0 0 0 1-.2c7.29-1.75 8.59-7.99 2.63-14.32Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M319.67 54.25c-.19-.21-.39-.41-.59-.61-5.81-5.7-15.88-9.34-23.41-8.48h-.21c-.26 0-.52.07-.77.12a11.69 11.69 0 0 0-3.94 1.35 5.84 5.84 0 0 0-3 6.49 11.8 11.8 0 0 0 2.61 5c.26.33.55.66.86 1s.58.6.89.89a25.71 25.71 0 0 0 2.08 1.76 33.21 33.21 0 0 0 18.33 6.56 19.27 19.27 0 0 0 2.54-.1c.39 0 .78-.09 1.15-.16s.63-.12.93-.19l.25-.06a8.83 8.83 0 0 0 4.5-2.5c1.69-1.91 1.89-4.54.47-7.41a15.26 15.26 0 0 0-2.69-3.66Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M319.67 54.25c-.19-.21-.39-.41-.59-.61-5.81-5.7-15.88-9.34-23.41-8.48h-.21c-.26 0-.52.07-.77.12a11.69 11.69 0 0 0-3.94 1.35 5.84 5.84 0 0 0-3 6.49 11.8 11.8 0 0 0 2.61 5c.26.33.55.66.86 1s.58.6.89.89a25.71 25.71 0 0 0 2.08 1.76 33.21 33.21 0 0 0 18.33 6.56 19.27 19.27 0 0 0 2.54-.1c.39 0 .78-.09 1.15-.16s.63-.12.93-.19l.25-.06a8.83 8.83 0 0 0 4.5-2.5c1.69-1.91 1.89-4.54.47-7.41a15.26 15.26 0 0 0-2.69-3.66Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m318.27 55.7.59.6a15.53 15.53 0 0 1 2.66 3.7 7.61 7.61 0 0 1 .85 4.69 5.39 5.39 0 0 1-.51.67 8.83 8.83 0 0 1-4.5 2.5l-.25.06c-.3.07-.61.14-.93.19s-.76.12-1.15.16a19.27 19.27 0 0 1-2.54.1 33.21 33.21 0 0 1-18.33-6.56 25.71 25.71 0 0 1-2.08-1.76c-.31-.29-.6-.59-.89-.89s-.6-.66-.86-1a11.8 11.8 0 0 1-2.61-5 6.48 6.48 0 0 1 0-2.36 6.71 6.71 0 0 1 2.26-2.08 11.63 11.63 0 0 1 3.93-1.35l.78-.11h.2c7.49-.91 17.57 2.74 23.38 8.44Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M328.4 69c-5.25 5.06-14 4.41-20.77 3-10.17-2.47-20.18-7.82-25.42-16.27a11 11 0 0 1-1.59-8 10.94 10.94 0 0 0 1.43 8.06c4.13 7 11.81 11.87 19.84 14.82 8.19 2.76 19.7 4.87 26.51-1.61Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M293.53 57.22c2.26 2.23 5.12 2.8 8 2.91l-.93.41-1.12 2.83a12.77 12.77 0 0 0 1.57.94 13 13 0 0 0 3 1.06 16.9 16.9 0 0 0 5.15.33l2 2 4-.69 1.13-2.83-.5-.5-.11-.11a2.67 2.67 0 0 0 .33-.63 2 2 0 0 0 .07-.23c.1-.36.81-1.93 1.06-2.59 0-.08.05-.16.07-.23.22-.83-.16-1.8-1.27-2.89a9.35 9.35 0 0 0-4.05-2.28l-.38-.1-.41-.09-.35-.07H310.36a20.31 20.31 0 0 0-2.3-.22h-1l1.21-.55 1.13-2.82a14.6 14.6 0 0 0-9.11-2.1l-2-2-4 .76-1.29 2.71.63.62a2.1 2.1 0 0 0-.29.5c-.16.45-.94 2.3-1.12 2.8-.22.89.13 1.9 1.31 3.06Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M317.07 60.21c-.28.72-.91 2.12-1 2.46a2 2 0 0 1-.07.23 3 3 0 0 1-.33.64l-1.43-1.42a3.51 3.51 0 0 0 2.83-1.91Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M299.72 53.21c-.6-.59-.5-1.19 1.17-1.52a5.66 5.66 0 0 1 4.43.83l4-1.8-1.13 2.82-1.2.55h-2.23a19.4 19.4 0 0 1-3.24-.09 3.4 3.4 0 0 1-1.26-.41 2.16 2.16 0 0 1-.54-.38Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m293.66 50.85-.62-.61 1.14-2.82 2 2a5.08 5.08 0 0 0-2.52 1.43Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m312.25 64.84 4-.7-1.09 2.86-4 .7-2-2a16.63 16.63 0 0 1-5.16-.33 12.69 12.69 0 0 1-4.54-2l1.12-2.83a12.41 12.41 0 0 0 4.54 2 17 17 0 0 0 5.16.33Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M309.14 65.7a12.78 12.78 0 0 1-4.23 0 10.27 10.27 0 0 1-3.9-1.42 19.61 19.61 0 0 0 4 1.08c.68.11 1.37.18 2.06.23s1.36.1 2.07.11ZM292.25 54.2c.18-.51 1-2.36 1.12-2.81-.29.85.09 1.86 1.27 3 2.63 2.58 6.05 2.94 9.31 2.94 3 0 5.37-.38 6.65.87.79.78.26 1.31-1.05 1.56a6.23 6.23 0 0 1-4.79-1.1l-3.28 1.47c-2.84-.12-5.7-.7-8-2.92-1.13-1.15-1.48-2.15-1.23-3.01Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m304.2 55.34 1.12-2.82 3.97-1.8-1.13 2.82-3.96 1.8z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m304.2 55.34 1.12-2.82 3.97-1.8-1.13 2.82-3.96 1.8z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m294.17 47.42 2 1.96-2.5 1.48-.63-.62 1.13-2.82z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m294.17 47.42 2 1.96-2.5 1.48-.63-.62 1.13-2.82z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m315.78 63.64-1.54-1.52a3.5 3.5 0 0 0 2.89-2c0-.08.05-.16.07-.23.22-.83-.16-1.8-1.27-2.89a9.35 9.35 0 0 0-4.05-2.28l-.38-.1-.41-.09-.35-.07H310.31a20.31 20.31 0 0 0-2.3-.22h-3.25a19.49 19.49 0 0 1-3.24-.09 3.35 3.35 0 0 1-1.25-.41 2.46 2.46 0 0 1-.51-.39c-.6-.59-.5-1.2 1.17-1.51a5.62 5.62 0 0 1 4.43.82l4-1.8a14.6 14.6 0 0 0-9.11-2.1l-2-2-4 .76 2 2c-1.52.48-2.49 1.15-2.79 2-.29.85.1 1.86 1.28 3 2.62 2.58 6 2.94 9.3 2.94 3 0 5.37-.38 6.65.87.79.79.26 1.31-1.05 1.56a6.19 6.19 0 0 1-4.79-1.1l-4.21 1.87a12.48 12.48 0 0 0 1.58 1 12.81 12.81 0 0 0 3 1.06 17 17 0 0 0 5.16.33l2 2 4-.7Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m315.78 63.64-1.54-1.52a3.5 3.5 0 0 0 2.89-2c0-.08.05-.16.07-.23.22-.83-.16-1.8-1.27-2.89a9.35 9.35 0 0 0-4.05-2.28l-.38-.1-.41-.09-.35-.07H310.31a20.31 20.31 0 0 0-2.3-.22h-3.25a19.49 19.49 0 0 1-3.24-.09 3.35 3.35 0 0 1-1.25-.41 2.46 2.46 0 0 1-.51-.39c-.6-.59-.5-1.2 1.17-1.51a5.62 5.62 0 0 1 4.43.82l4-1.8a14.6 14.6 0 0 0-9.11-2.1l-2-2-4 .76 2 2c-1.52.48-2.49 1.15-2.79 2-.29.85.1 1.86 1.28 3 2.62 2.58 6 2.94 9.3 2.94 3 0 5.37-.38 6.65.87.79.79.26 1.31-1.05 1.56a6.19 6.19 0 0 1-4.79-1.1l-4.21 1.87a12.48 12.48 0 0 0 1.58 1 12.81 12.81 0 0 0 3 1.06 17 17 0 0 0 5.16.33l2 2 4-.7Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M294.66 54.4a9.8 9.8 0 0 0 3.51 2.08 14.46 14.46 0 0 0 4.15.72c1.42.09 2.84 0 4.27 0a14 14 0 0 1 2.15.12 3.55 3.55 0 0 1 1.87.88 3.44 3.44 0 0 0-1.89-.78 13.7 13.7 0 0 0-2.13-.08c-1.42 0-2.85.12-4.29 0a14.5 14.5 0 0 1-4.19-.77 9.68 9.68 0 0 1-1.88-.93 9.38 9.38 0 0 1-1.57-1.24Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m312.25 64.84-1.13 2.82-1.99-1.96 1.13-2.82 1.99 1.96z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m312.25 64.84-1.13 2.82-1.99-1.96 1.13-2.82 1.99 1.96z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m316.28 64.14-3.89.39-2.13-1.65 1.99 1.96 4.03-.7zM300.18 48.62l-2.1-1.66-3.91.46 4.01-.76 2 1.96z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M309.29 50.72a11.68 11.68 0 0 0-1.43-.78l-.14 1.49ZM294.75 54.48a9.48 9.48 0 0 0 3.89 2.23l.84-8.78-1.3-1.27-2.73.52-.13 1.37.85.83a8.27 8.27 0 0 0-1 .36ZM315.52 56.58a9 9 0 0 0-2.2-1.39l-.92 9.62 2.37-.41.16-1.6-.69-.68A3.52 3.52 0 0 0 315 62ZM311.46 54.56l-.41-.09-.35-.07H310.27a20.31 20.31 0 0 0-2.3-.22h-.51l-.3 3.18c1.49 0 2.67.17 3.45.93s.26 1.31-1.05 1.56a6.2 6.2 0 0 1-2.63-.09l-.3 3.13a17.78 17.78 0 0 0 3.63.07l.75.74.85-8.95-.36-.1Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m356.81 210.71 2.06 1.52-3.12 4.2a6.65 6.65 0 0 1-1.31 1.34 5.3 5.3 0 0 1-.54.39 9.33 9.33 0 0 1-3.14 1.23 11.89 11.89 0 0 1-1.26.19 12.2 12.2 0 0 1-1.38.08 17.15 17.15 0 0 1-1.86-.08c-.67-.06-1.35-.15-2.05-.28-.52-.1-1-.21-1.57-.35-7.77-1.95-16.19-7.73-21.25-14.57q-.52-.69-1-1.41c-.45-.68-.86-1.36-1.23-2.06s-.58-1.12-.83-1.67-.39-1-.55-1.4-.3-.91-.42-1.35a12.46 12.46 0 0 1-.43-3.67c0-.25 0-.49.06-.72a7.46 7.46 0 0 1 .89-2.77 6.26 6.26 0 0 1 .48-.74l2.94-4 1.9 1.41.83-.14a16.62 16.62 0 0 1 3.54 0 20.14 20.14 0 0 1 2.26.32 26.15 26.15 0 0 1 3 .77 41.52 41.52 0 0 1 18.54 12.71 31.73 31.73 0 0 1 1.95 2.62c.5.74.95 1.49 1.36 2.25a20.77 20.77 0 0 1 1.49 3.39 14.45 14.45 0 0 1 .64 2.79Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m324.74 208.28 6.31-8.54 2.88 2.64-6.32 8.55c-1-.85-1.96-1.73-2.87-2.65Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M355.75 216.43a6.65 6.65 0 0 1-1.31 1.34 5.3 5.3 0 0 1-.54.39 9.33 9.33 0 0 1-3.14 1.23l-.45.08 6.49-8.78 2.06 1.52Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m348.24 204.79 1.89.81-9.49 12.78c-.63-.21-1.27-.45-1.91-.7ZM334 215.38l8.2-11.09 3 2.55-7.64 10.32a38.74 38.74 0 0 1-3.56-1.78ZM321.31 184.61l1.89 1.39.83-.14a16.62 16.62 0 0 1 3.54 0 20.14 20.14 0 0 1 2.26.32 26.15 26.15 0 0 1 3 .77c.94.29 1.89.65 2.84 1.05l-13.17 17.8c-.38-.45-.75-.92-1.09-1.39s-.68-.93-1-1.41c-.45-.68-.86-1.36-1.23-2.06s-.58-1.12-.83-1.67-.39-1-.55-1.4-.3-.91-.42-1.35a12.46 12.46 0 0 1-.43-3.67c0-.25 0-.49.06-.72a7.46 7.46 0 0 1 .89-2.77 6.26 6.26 0 0 1 .48-.74Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M316.94 192.82c0-.25 0-.49.06-.72a7.46 7.46 0 0 1 .89-2.77 6.26 6.26 0 0 1 .48-.74l2.94-4 1.89 1.41.46.7-6.54 8.85a11.29 11.29 0 0 1-.18-2.73Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M358 200c-.41-.76-.86-1.51-1.36-2.25a31.73 31.73 0 0 0-1.95-2.62 41.6 41.6 0 0 0-18.54-12.71 26.07 26.07 0 0 0-3-.76 22.81 22.81 0 0 0-2.26-.33 16 16 0 0 0-3.54 0 10.18 10.18 0 0 0-3.27.93 7 7 0 0 0-3.14 2.9 7.58 7.58 0 0 0-.89 2.77q0 .34-.06.72a11.83 11.83 0 0 0 .43 3.67c.11.44.25.89.41 1.35s.35.92.56 1.4.52 1.11.82 1.67.79 1.38 1.24 2.06c.31.48.64.95 1 1.41 5.06 6.85 13.48 12.62 21.25 14.58.53.13 1.06.24 1.58.34.69.13 1.37.22 2.05.29s1.27.08 1.86.07a12 12 0 0 0 1.37-.08 12.5 12.5 0 0 0 1.27-.19A9.36 9.36 0 0 0 357 214c.19-.12.37-.25.55-.39a6.6 6.6 0 0 0 1.81-2.13 8.27 8.27 0 0 0 .9-4.44 14.09 14.09 0 0 0-.73-3.68A21.67 21.67 0 0 0 358 200Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M325.54 201.65c-.38-.46-.75-.92-1.1-1.4s-.67-.93-1-1.41c-.45-.68-.86-1.36-1.24-2.06s-.58-1.12-.82-1.67-.4-.94-.56-1.4-.3-.91-.41-1.35a11.83 11.83 0 0 1-.43-3.67q0-.37.06-.72a7.58 7.58 0 0 1 .89-2.77 7 7 0 0 1 3.14-2.9 10.26 10.26 0 0 1 3.27-.93 16 16 0 0 1 3.54 0c.66.06 1.33.16 2 .28Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M320.2 191.37a12 12 0 0 1-.2-2.68q0-.37.06-.72a7.58 7.58 0 0 1 .89-2.77 6.87 6.87 0 0 1 2.45-2.52ZM330.66 206.8c-1-.85-2-1.73-2.87-2.65l8-21.83.32.1c1 .32 2 .71 3.06 1.14ZM340.56 213a38.74 38.74 0 0 1-3.52-1.78l8.84-24c1.12.76 2.22 1.58 3.27 2.45ZM343.71 214.25c-.64-.21-1.29-.45-1.93-.71l8.44-23c.56.49 1.11 1 1.64 1.51Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M358.57 201.16c.15.31.29.62.41.93 0 .1.09.2.13.3l.12.32c.09.23.17.46.25.68a14.09 14.09 0 0 1 .73 3.68 8.27 8.27 0 0 1-.9 4.44 6.6 6.6 0 0 1-1.81 2.13c-.18.14-.36.27-.55.39a9.36 9.36 0 0 1-3.13 1.23l-.46.08Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M353.4 199.54c-3.66-6.74-12.14-12.72-19.31-13.76l-.9-.11c-7.35-.63-10.2 4.55-6.38 11.58s12.87 13.23 20.22 13.86h.87c6.69.21 9.17-4.83 5.5-11.57Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M352.81 199.49c-.11-.22-.24-.44-.37-.65-3.68-6.17-11.46-11.54-18.08-12.5h-.18c-.23 0-.46-.06-.69-.08a10.37 10.37 0 0 0-3.66.27 5.16 5.16 0 0 0-4 4.86 10.34 10.34 0 0 0 1.09 4.89c.16.34.33.69.52 1s.36.64.56 1a20.55 20.55 0 0 0 1.38 2 29.24 29.24 0 0 0 14.17 9.72 18 18 0 0 0 2.19.48c.34.06.68.1 1 .13s.57 0 .84 0h.22a7.79 7.79 0 0 0 4.4-1.12c1.87-1.25 2.64-3.45 2.07-6.22a13.64 13.64 0 0 0-1.46-3.78Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M352.81 199.49c-.11-.22-.24-.44-.37-.65-3.68-6.17-11.46-11.54-18.08-12.5h-.18c-.23 0-.46-.06-.69-.08a10.37 10.37 0 0 0-3.66.27 5.16 5.16 0 0 0-4 4.86 10.34 10.34 0 0 0 1.09 4.89c.16.34.33.69.52 1s.36.64.56 1a20.55 20.55 0 0 0 1.38 2 29.24 29.24 0 0 0 14.17 9.72 18 18 0 0 0 2.19.48c.34.06.68.1 1 .13s.57 0 .84 0h.22a7.79 7.79 0 0 0 4.4-1.12c1.87-1.25 2.64-3.45 2.07-6.22a13.64 13.64 0 0 0-1.46-3.78Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M351.29 200.41c.13.21.25.43.37.65a14 14 0 0 1 1.44 3.76 6.72 6.72 0 0 1-.33 4.19 4.44 4.44 0 0 1-.59.46 7.79 7.79 0 0 1-4.4 1.12h-1.06c-.28 0-.68-.07-1-.13a18 18 0 0 1-2.19-.48 29.24 29.24 0 0 1-14.17-9.72 20.55 20.55 0 0 1-1.38-2c-.2-.32-.38-.64-.56-1s-.36-.7-.52-1a10.34 10.34 0 0 1-1.09-4.89 5.88 5.88 0 0 1 .49-2 5.93 5.93 0 0 1 2.4-1.27 10.35 10.35 0 0 1 3.66-.27l.69.08h.18c6.6.96 14.38 6.33 18.06 12.5Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M357 214c-5.61 3.14-12.93.62-18.39-2.11-8.13-4.39-15.47-11.2-18.05-19.59a9.63 9.63 0 0 1 .44-7.14 9.57 9.57 0 0 0-.6 7.19c1.94 6.94 7.42 12.78 13.61 17.1 6.33 4.21 15.68 8.55 22.99 4.55Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M329.83 196.16a10 10 0 0 0 6.14 4.27l-.88.14-1.6 2.16a12.44 12.44 0 0 0 1.13 1.16 11.87 11.87 0 0 0 2.3 1.57 14.61 14.61 0 0 0 4.32 1.43l1.26 2.11 3.6.31 1.6-2.16-.32-.54-.07-.12a2.39 2.39 0 0 0 .43-.46l.11-.18c.16-.29 1.12-1.47 1.48-2a1.74 1.74 0 0 0 .11-.18c.38-.65.28-1.57-.43-2.75a8.2 8.2 0 0 0-2.94-2.86l-.31-.17-.32-.17-.29-.14h-.12a1.15 1.15 0 0 0-.23-.1 17.81 17.81 0 0 0-1.92-.71c-.28-.09-.57-.17-.86-.24l1.16-.2 1.59-2.16a12.92 12.92 0 0 0-7.3-3.83l-1.27-2.12-3.59-.25-1.6 2.15.4.67a1.75 1.75 0 0 0-.36.36c-.23.35-1.32 1.75-1.58 2.14-.47.71-.39 1.61.36 2.87Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M349.26 204c-.41.56-1.26 1.61-1.41 1.88l-.11.18a3.25 3.25 0 0 1-.43.47l-.9-1.53a3.11 3.11 0 0 0 2.85-1Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M336 194.12c-.38-.63-.15-1.13 1.34-1a5 5 0 0 1 3.6 1.7l3.79-.64-1.59 2.15-1.15.2h-.09l-1.32-.34-.5-.12a17.32 17.32 0 0 1-2.75-.81 3.13 3.13 0 0 1-1-.63 1.94 1.94 0 0 1-.33-.51Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m331.38 190.75-.4-.66 1.6-2.15 1.26 2.11a4.42 4.42 0 0 0-2.46.7Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m344.1 206.86 3.6.31-1.6 2.16-3.6-.31-1.25-2.12a14.9 14.9 0 0 1-4.33-1.44 11.57 11.57 0 0 1-2.3-1.57 11.32 11.32 0 0 1-1.13-1.16l1.6-2.16a9.57 9.57 0 0 0 1.13 1.16 11.38 11.38 0 0 0 2.29 1.57 14.8 14.8 0 0 0 4.33 1.44Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M341.25 206.9a11.42 11.42 0 0 1-3.62-.92 9.29 9.29 0 0 1-3-2.09 17.61 17.61 0 0 0 3.15 1.81c.56.25 1.13.46 1.71.66s1.16.37 1.76.54ZM329.42 193.29c.26-.39 1.35-1.79 1.58-2.14-.43.66-.33 1.6.41 2.86 1.67 2.79 4.5 3.87 7.29 4.6 2.59.67 4.67.88 5.47 2.23.51.85-.06 1.18-1.23 1.1a5.5 5.5 0 0 1-3.85-2l-3.13.51c-2.4-.74-4.7-1.87-6.13-4.27-.75-1.28-.83-2.18-.41-2.89Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m339.36 196.95 1.59-2.16 3.79-.65-1.59 2.16-3.79.65z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m339.36 196.95 1.59-2.16 3.79-.65-1.59 2.16-3.79.65z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m332.58 187.94 1.26 2.11-2.46.7-.4-.66 1.6-2.15z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m332.58 187.94 1.26 2.11-2.46.7-.4-.66 1.6-2.15z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m347.38 206.63-1-1.64a3.07 3.07 0 0 0 2.92-1.1 1.74 1.74 0 0 0 .11-.18c.38-.65.28-1.57-.43-2.75a8.2 8.2 0 0 0-2.94-2.86l-.31-.17-.32-.17-.29-.14H345a1.15 1.15 0 0 0-.23-.1 17.81 17.81 0 0 0-1.92-.71l-.94-.27-1.32-.34-.5-.12a17 17 0 0 1-2.75-.81 3.13 3.13 0 0 1-1-.63 2.51 2.51 0 0 1-.35-.44c-.38-.64-.15-1.14 1.34-1a5 5 0 0 1 3.6 1.7l3.79-.65a12.92 12.92 0 0 0-7.3-3.83l-1.27-2.12-3.59-.25 1.26 2.11c-1.4.08-2.38.43-2.82 1.07-.43.66-.33 1.61.42 2.86 1.65 2.79 4.49 3.87 7.28 4.6 2.59.67 4.67.88 5.47 2.24.5.84-.06 1.17-1.24 1.09a5.52 5.52 0 0 1-3.84-2l-4 .65a10.36 10.36 0 0 0 1.13 1.16 11.56 11.56 0 0 0 2.29 1.58 15.07 15.07 0 0 0 4.33 1.43l1.26 2.12 3.6.31Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m347.38 206.63-1-1.64a3.07 3.07 0 0 0 2.92-1.1 1.74 1.74 0 0 0 .11-.18c.38-.65.28-1.57-.43-2.75a8.2 8.2 0 0 0-2.94-2.86l-.31-.17-.32-.17-.29-.14H345a1.15 1.15 0 0 0-.23-.1 17.81 17.81 0 0 0-1.92-.71l-.94-.27-1.32-.34-.5-.12a17 17 0 0 1-2.75-.81 3.13 3.13 0 0 1-1-.63 2.51 2.51 0 0 1-.35-.44c-.38-.64-.15-1.14 1.34-1a5 5 0 0 1 3.6 1.7l3.79-.65a12.92 12.92 0 0 0-7.3-3.83l-1.27-2.12-3.59-.25 1.26 2.11c-1.4.08-2.38.43-2.82 1.07-.43.66-.33 1.61.42 2.86 1.65 2.79 4.49 3.87 7.28 4.6 2.59.67 4.67.88 5.47 2.24.5.84-.06 1.17-1.24 1.09a5.52 5.52 0 0 1-3.84-2l-4 .65a10.36 10.36 0 0 0 1.13 1.16 11.56 11.56 0 0 0 2.29 1.58 15.07 15.07 0 0 0 4.33 1.43l1.26 2.12 3.6.31Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M331.43 194a8.6 8.6 0 0 0 2.53 2.57 13 13 0 0 0 3.38 1.54c1.19.4 2.42.66 3.64 1a11.59 11.59 0 0 1 1.81.59 2.92 2.92 0 0 1 1.39 1.17 3 3 0 0 0-1.43-1.1 13.83 13.83 0 0 0-1.8-.54c-1.22-.28-2.46-.54-3.67-.93a12.61 12.61 0 0 1-3.4-1.6 8.58 8.58 0 0 1-1.4-1.21 8.16 8.16 0 0 1-1.05-1.49Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m344.1 206.86-1.6 2.16-1.25-2.12 1.59-2.16 1.26 2.12z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m344.1 206.86-1.6 2.16-1.25-2.12 1.59-2.16 1.26 2.12z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m347.7 207.17-3.41-.54-1.45-1.89 1.26 2.12 3.6.31zM337.44 190.31l-1.42-1.89-3.44-.48 3.59.25 1.27 2.12z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M344.74 194.14a12 12 0 0 0-1-1l-.46 1.24ZM331.49 194.09a8.23 8.23 0 0 0 2.82 2.78l2.69-7.3-.83-1.38-2.45-.17-.42 1.13.54.9a8 8 0 0 0-.9.1ZM348.75 200.54a8.35 8.35 0 0 0-1.57-1.67l-2.95 8 2.12.19.49-1.34-.43-.73a3.39 3.39 0 0 0 .69 0ZM345.73 197.91l-.32-.17-.29-.14H345a1.15 1.15 0 0 0-.23-.1 17.81 17.81 0 0 0-1.92-.71l-.43-.12-1 2.65a4.18 4.18 0 0 1 2.73 1.57c.5.84-.06 1.17-1.24 1.09a5.36 5.36 0 0 1-2.22-.67l-1 2.61a15.74 15.74 0 0 0 3.08.87l.48.8 2.73-7.45-.29-.16Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m462.56 93.9 2 1.46-3 4.06a7 7 0 0 1-1.26 1.3c-.17.13-.35.25-.53.37a9 9 0 0 1-3 1.18 9.31 9.31 0 0 1-1.23.19c-.42.05-.86.07-1.32.08s-1.17 0-1.8-.07-1.31-.16-2-.28c-.5-.09-1-.2-1.52-.33-7.49-1.89-15.62-7.46-20.5-14.07-.34-.45-.66-.9-.95-1.36-.44-.65-.84-1.32-1.2-2s-.56-1.09-.79-1.62-.38-.91-.54-1.35-.29-.88-.4-1.3a12 12 0 0 1-.41-3.55q0-.34.06-.69a7.16 7.16 0 0 1 .85-2.67c.14-.25.3-.49.46-.72l2.84-3.83 1.82 1.33.81-.13a16 16 0 0 1 3.41 0 21.35 21.35 0 0 1 2.19.31 27 27 0 0 1 2.87.74 40.1 40.1 0 0 1 17.87 12.25c.68.83 1.31 1.68 1.88 2.53s.92 1.45 1.32 2.18a19.73 19.73 0 0 1 1.43 3.27 14.18 14.18 0 0 1 .64 2.72Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m431.61 91.55 6.09-8.23 2.79 2.54-6.11 8.25c-.96-.81-1.89-1.67-2.77-2.56Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M461.54 99.42a7 7 0 0 1-1.26 1.3c-.17.13-.35.25-.53.37a9 9 0 0 1-3 1.18l-.45.08 6.28-8.47 2 1.46Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m454.29 88.19 1.82.78L447 101.3c-.61-.2-1.23-.43-1.84-.68ZM440.54 98.41l7.92-10.7 2.85 2.45-7.38 10c-1.14-.54-2.27-1.16-3.39-1.75ZM428.3 68.72l1.82 1.33.81-.13a16 16 0 0 1 3.41 0 21.35 21.35 0 0 1 2.19.31 27 27 0 0 1 2.87.74c.91.28 1.83.63 2.74 1l-12.71 17.17c-.37-.45-.72-.89-1.05-1.35s-.66-.9-.95-1.36c-.44-.65-.84-1.32-1.2-2s-.56-1.09-.79-1.62-.38-.91-.54-1.35-.29-.88-.4-1.3a12 12 0 0 1-.41-3.55q0-.34.06-.69a7.16 7.16 0 0 1 .85-2.67c.14-.25.3-.49.46-.72Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M424.09 76.63q0-.34.06-.69a7.16 7.16 0 0 1 .85-2.67c.14-.25.3-.49.46-.72l2.84-3.83 1.82 1.33.45.67-6.32 8.54a11.56 11.56 0 0 1-.16-2.63Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M463.7 83.57c-.39-.73-.83-1.46-1.31-2.18s-1.21-1.7-1.89-2.53a40.1 40.1 0 0 0-17.89-12.26 25.77 25.77 0 0 0-2.87-.74 21.35 21.35 0 0 0-2.19-.31 16.06 16.06 0 0 0-3.41 0 10 10 0 0 0-3.15.9 6.74 6.74 0 0 0-3 2.8 7.49 7.49 0 0 0-.86 2.67v.7a11.59 11.59 0 0 0 .41 3.54c.11.43.25.86.4 1.3s.34.89.54 1.35.5 1.07.79 1.62.76 1.33 1.19 2c.3.46.62.91.95 1.36 4.89 6.6 13 12.18 20.51 14.06.51.13 1 .24 1.52.34q1 .18 2 .27a15.91 15.91 0 0 0 1.8.07c.46 0 .9 0 1.32-.07s.83-.11 1.22-.19a8.77 8.77 0 0 0 3-1.19c.18-.12.36-.24.53-.37a6.52 6.52 0 0 0 1.69-2.04 8.07 8.07 0 0 0 .87-4.28 13.46 13.46 0 0 0-.71-3.55 20.7 20.7 0 0 0-1.46-3.27Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M432.38 85.16c-.36-.44-.72-.89-1.06-1.35s-.65-.9-.95-1.36c-.43-.66-.83-1.32-1.19-2s-.56-1.09-.79-1.62-.38-.91-.54-1.35-.29-.87-.4-1.3a11.59 11.59 0 0 1-.41-3.54v-.7a7.49 7.49 0 0 1 .86-2.67 6.74 6.74 0 0 1 3-2.8 10 10 0 0 1 3.15-.9 16.06 16.06 0 0 1 3.41 0 18.83 18.83 0 0 1 1.94.27Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M427.23 75.23a11.06 11.06 0 0 1-.19-2.58v-.7a7.49 7.49 0 0 1 .86-2.67 6.57 6.57 0 0 1 2.37-2.43ZM437.33 90.12c-1-.81-1.9-1.67-2.77-2.55l7.74-21.07.31.1c1 .31 2 .68 3 1.1ZM446.88 96.14a34.82 34.82 0 0 1-3.39-1.72L452 71.24c1.09.73 2.14 1.52 3.16 2.35ZM449.92 97.31c-.62-.2-1.24-.43-1.86-.67l8.15-22.17c.54.47 1.06 1 1.57 1.45Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M464.26 84.68c.15.31.28.6.4.9l.12.29.12.31.24.66a13.46 13.46 0 0 1 .71 3.55 8.07 8.07 0 0 1-.87 4.28 6.52 6.52 0 0 1-1.75 2.06c-.17.13-.35.25-.53.37a8.77 8.77 0 0 1-3 1.19l-.44.08Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M459.27 83.12c-3.53-6.5-11.71-12.28-18.63-13.28-.29 0-.59-.08-.88-.1-7.09-.61-9.84 4.39-6.15 11.17S446 93.68 453.12 94.29h.85c6.45.2 8.84-4.67 5.3-11.17Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M458.7 83.07q-.17-.31-.36-.63c-3.54-6-11-11.13-17.44-12.06h-.18l-.66-.08a10.1 10.1 0 0 0-3.53.26 5 5 0 0 0-3.9 4.7 10 10 0 0 0 1.05 4.74c.15.32.31.66.5 1s.35.62.54.92a19.73 19.73 0 0 0 1.33 1.9 28.29 28.29 0 0 0 13.67 9.38 16.79 16.79 0 0 0 2.12.46c.33.06.66.09 1 .12s.55 0 .81.05h.22a7.52 7.52 0 0 0 4.24-1.08c1.81-1.21 2.55-3.34 2-6a13.34 13.34 0 0 0-1.41-3.68Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M458.7 83.07q-.17-.31-.36-.63c-3.54-6-11-11.13-17.44-12.06h-.18l-.66-.08a10.1 10.1 0 0 0-3.53.26 5 5 0 0 0-3.9 4.7 10 10 0 0 0 1.05 4.74c.15.32.31.66.5 1s.35.62.54.92a19.73 19.73 0 0 0 1.33 1.9 28.29 28.29 0 0 0 13.67 9.38 16.79 16.79 0 0 0 2.12.46c.33.06.66.09 1 .12s.55 0 .81.05h.22a7.52 7.52 0 0 0 4.24-1.08c1.81-1.21 2.55-3.34 2-6a13.34 13.34 0 0 0-1.41-3.68Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M457.23 84q.19.32.36.63a13.1 13.1 0 0 1 1.41 3.59 6.48 6.48 0 0 1-.32 4 4.35 4.35 0 0 1-.57.45 7.52 7.52 0 0 1-4.24 1.08h-.22c-.26 0-.53 0-.81-.05s-.65-.06-1-.12a16.79 16.79 0 0 1-2.12-.46 28.29 28.29 0 0 1-13.67-9.38 19.73 19.73 0 0 1-1.33-1.9c-.19-.3-.37-.61-.54-.92s-.35-.68-.5-1a10 10 0 0 1-1.05-4.72 5.27 5.27 0 0 1 .47-1.95 5.68 5.68 0 0 1 2.31-1.23 10.14 10.14 0 0 1 3.53-.26l.67.08h.17c6.39.98 13.9 6.16 17.45 12.16Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M462.7 97.1c-5.42 3-12.48.61-17.75-2-7.85-4.27-14.95-10.84-17.42-18.94a9.34 9.34 0 0 1 .42-6.9 9.26 9.26 0 0 0-.57 6.94c1.88 6.7 7.16 12.33 13.14 16.51 6.14 4.03 15.17 8.29 22.18 4.39Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M436.53 79.86a9.7 9.7 0 0 0 5.92 4.14l-.85.14-1.54 2.08a10.52 10.52 0 0 0 1.09 1.12 11.45 11.45 0 0 0 2.21 1.52 14.64 14.64 0 0 0 4.18 1.38l1.22 2 3.47.3 1.54-2.08-.31-.53-.07-.11a2 2 0 0 0 .41-.45.82.82 0 0 0 .11-.17c.16-.28 1.08-1.42 1.43-1.91a.82.82 0 0 0 .11-.17c.36-.64.26-1.52-.42-2.66a8 8 0 0 0-2.84-2.76l-.3-.16-.31-.17-.28-.13h-.12l-.22-.1a16.5 16.5 0 0 0-1.85-.68l-.83-.24 1.12-.19 1.54-2.08a12.49 12.49 0 0 0-7.06-3.7l-1.21-2-3.47-.24-1.56 1.99.38.64a1.7 1.7 0 0 0-.35.36c-.22.34-1.27 1.69-1.53 2.07-.44.66-.34 1.58.39 2.79Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M455.27 87.42c-.39.53-1.21 1.55-1.36 1.81l-.1.17a2.28 2.28 0 0 1-.42.45l-.87-1.47a3 3 0 0 0 2.75-.96Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M442.49 77.89c-.37-.61-.15-1.09 1.29-1a4.81 4.81 0 0 1 3.48 1.64l3.66-.62-1.55 2.09-1.1.2h-.09l-1.27-.33-.49-.12a16.21 16.21 0 0 1-2.64-.78 2.78 2.78 0 0 1-.95-.61 1.79 1.79 0 0 1-.34-.47Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m438 74.64-.38-.64 1.54-2.08 1.21 2a4.15 4.15 0 0 0-2.37.72Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m450.3 90.18 3.47.31-1.54 2.08-3.48-.3-1.21-2a13.84 13.84 0 0 1-4.17-1.39 10.76 10.76 0 0 1-2.22-1.51 10.52 10.52 0 0 1-1.09-1.12l1.54-2.08a11.59 11.59 0 0 0 1.09 1.12 11 11 0 0 0 2.21 1.51 14.27 14.27 0 0 0 4.18 1.39Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M447.54 90.22a10.69 10.69 0 0 1-3.49-.89 8.87 8.87 0 0 1-2.9-2 17 17 0 0 0 3 1.75c.53.24 1.08.44 1.64.64s1.21.34 1.75.5ZM436.12 77.09c.26-.38 1.31-1.73 1.53-2.07-.42.64-.32 1.55.4 2.76 1.61 2.7 4.34 3.74 7 4.44 2.5.65 4.51.86 5.29 2.16.48.82-.07 1.14-1.2 1.06a5.33 5.33 0 0 1-3.71-1.94l-3 .49a9.72 9.72 0 0 1-5.92-4.13c-.71-1.22-.8-2.13-.39-2.77Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m445.72 80.62 1.54-2.08 3.66-.63-1.54 2.08-3.66.63z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m445.72 80.62 1.54-2.08 3.66-.63-1.54 2.08-3.66.63z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m439.18 71.92 1.21 2.05-2.37.67-.38-.64 1.54-2.08z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m439.18 71.92 1.21 2.05-2.37.67-.38-.64 1.54-2.08z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m453.46 90-.94-1.58a3 3 0 0 0 2.82-1.06.82.82 0 0 0 .11-.17c.36-.64.26-1.52-.42-2.66a8 8 0 0 0-2.84-2.76l-.3-.16-.31-.17-.28-.13h-.12l-.22-.1a16.5 16.5 0 0 0-1.85-.68l-.9-.26-1.28-.33-.49-.12a16.7 16.7 0 0 1-2.65-.78 3 3 0 0 1-.94-.6 2.3 2.3 0 0 1-.34-.43c-.37-.62-.15-1.1 1.29-1a4.81 4.81 0 0 1 3.48 1.64l3.66-.63a12.49 12.49 0 0 0-7.06-3.7l-1.21-2-3.47-.24 1.22 2a3.43 3.43 0 0 0-2.73 1c-.42.64-.32 1.55.41 2.77 1.59 2.69 4.33 3.73 7 4.43 2.5.65 4.51.86 5.29 2.16.48.82-.07 1.13-1.2 1.06a5.35 5.35 0 0 1-3.71-1.94l-3.87.63a10.52 10.52 0 0 0 1.09 1.12 11.45 11.45 0 0 0 2.21 1.52 14.64 14.64 0 0 0 4.18 1.38l1.22 2 3.47.3Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m453.46 90-.94-1.58a3 3 0 0 0 2.82-1.06.82.82 0 0 0 .11-.17c.36-.64.26-1.52-.42-2.66a8 8 0 0 0-2.84-2.76l-.3-.16-.31-.17-.28-.13h-.12l-.22-.1a16.5 16.5 0 0 0-1.85-.68l-.9-.26-1.28-.33-.49-.12a16.7 16.7 0 0 1-2.65-.78 3 3 0 0 1-.94-.6 2.3 2.3 0 0 1-.34-.43c-.37-.62-.15-1.1 1.29-1a4.81 4.81 0 0 1 3.48 1.64l3.66-.63a12.49 12.49 0 0 0-7.06-3.7l-1.21-2-3.47-.24 1.22 2a3.43 3.43 0 0 0-2.73 1c-.42.64-.32 1.55.41 2.77 1.59 2.69 4.33 3.73 7 4.43 2.5.65 4.51.86 5.29 2.16.48.82-.07 1.13-1.2 1.06a5.35 5.35 0 0 1-3.71-1.94l-3.87.63a10.52 10.52 0 0 0 1.09 1.12 11.45 11.45 0 0 0 2.21 1.52 14.64 14.64 0 0 0 4.18 1.38l1.22 2 3.47.3Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M438.07 77.78a8.09 8.09 0 0 0 2.44 2.47 13.06 13.06 0 0 0 3.26 1.5c1.15.38 2.34.63 3.52.93a11.76 11.76 0 0 1 1.74.56 3 3 0 0 1 1.35 1.13 2.83 2.83 0 0 0-1.38-1.05 11.15 11.15 0 0 0-1.73-.52c-1.18-.28-2.38-.53-3.54-.9a12.25 12.25 0 0 1-3.29-1.55 7.93 7.93 0 0 1-1.35-1.17 8 8 0 0 1-1.02-1.4Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m450.3 90.19-1.55 2.08-1.21-2.05 1.54-2.08 1.22 2.05z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m450.3 90.19-1.55 2.08-1.21-2.05 1.54-2.08 1.22 2.05z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m453.77 90.49-3.29-.53-1.4-1.82 1.22 2.05 3.47.3zM443.87 74.21l-1.37-1.82-3.32-.47 3.46.25 1.23 2.04z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M450.92 77.91a12.91 12.91 0 0 0-1-1l-.45 1.2ZM438.12 77.87a8.12 8.12 0 0 0 2.73 2.68l2.59-7.06-.79-1.33-2.37-.16-.4 1.1.52.87a7.46 7.46 0 0 0-.88.09ZM454.78 84.09a7.83 7.83 0 0 0-1.52-1.62l-2.84 7.73 2.05.17.47-1.28-.42-.71a3.26 3.26 0 0 0 .67 0ZM451.87 81.55l-.31-.17-.28-.13h-.12l-.22-.1a16.5 16.5 0 0 0-1.85-.68l-.41-.12-.94 2.56a4.08 4.08 0 0 1 2.64 1.51c.48.82-.07 1.13-1.2 1.06a5.22 5.22 0 0 1-2.15-.65l-.93 2.52a15.4 15.4 0 0 0 3 .84l.46.77 2.64-7.19-.28-.15Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m350.66 132.9-1.94 1.42 2.93 4a6.72 6.72 0 0 0 1.23 1.27l.51.36a8.66 8.66 0 0 0 2.95 1.15c.38.08.78.14 1.19.19s.84.07 1.29.07 1.14 0 1.75-.07 1.28-.15 1.93-.27c.49-.09 1-.2 1.48-.32 7.3-1.84 15.21-7.27 20-13.7.33-.44.64-.88.93-1.33.42-.63.81-1.28 1.16-1.93s.54-1.06.77-1.58.37-.88.53-1.31.28-.86.38-1.27a11.6 11.6 0 0 0 .4-3.45c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6c-.14-.24-.29-.47-.45-.7l-2.81-3.78-1.77 1.3-.78-.13a15.3 15.3 0 0 0-3.33 0 20.19 20.19 0 0 0-2.13.3 25.63 25.63 0 0 0-2.8.72 39.1 39.1 0 0 0-17.42 12c-.66.8-1.28 1.63-1.84 2.46s-.89 1.41-1.28 2.12a20.8 20.8 0 0 0-1.39 3.18 13.52 13.52 0 0 0-.6 2.58Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m380.8 130.61-5.93-8-2.71 2.48 5.94 8c.9-.78 1.9-1.61 2.7-2.48Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M351.65 138.27a6.72 6.72 0 0 0 1.23 1.27l.51.36a8.66 8.66 0 0 0 2.95 1.15l.43.08-6.11-8.25-1.94 1.42Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m358.71 127.33-1.77.76 8.91 12c.6-.19 1.2-.41 1.8-.65ZM372.1 137.28l-7.71-10.42-2.77 2.4 7.18 9.7a33.06 33.06 0 0 0 3.3-1.68ZM384 108.37l-1.77 1.3-.78-.13a15.3 15.3 0 0 0-3.33 0 20.19 20.19 0 0 0-2.13.3 25.63 25.63 0 0 0-2.8.72c-.88.28-1.78.61-2.67 1l12.39 16.74c.35-.43.7-.87 1-1.31s.64-.88.93-1.33c.42-.63.81-1.28 1.16-1.93s.54-1.06.77-1.58.37-.88.53-1.31.28-.86.38-1.27a11.6 11.6 0 0 0 .4-3.45c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6c-.14-.24-.29-.47-.45-.7Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M388.13 116.08c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6c-.14-.24-.29-.47-.45-.7l-2.79-3.73-1.77 1.3-.43.66 6.15 8.31a10.85 10.85 0 0 0 .18-2.56Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M348.15 126a12.78 12.78 0 0 0-.69 3.45 7.7 7.7 0 0 0 .84 4.18 6.18 6.18 0 0 0 1.71 2c.16.13.33.25.51.37a8.58 8.58 0 0 0 3 1.15 10.61 10.61 0 0 0 1.19.18c.41.05.84.07 1.29.08s1.14 0 1.75-.07 1.27-.15 1.93-.27c.48-.09 1-.2 1.48-.32 7.3-1.84 15.21-7.27 20-13.7.33-.44.63-.88.93-1.33.42-.64.81-1.28 1.16-1.93s.54-1.06.77-1.58.37-.88.52-1.32.28-.85.39-1.26a11.34 11.34 0 0 0 .41-3.45c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6 6.56 6.56 0 0 0-3-2.73 9.59 9.59 0 0 0-3.08-.87 15.16 15.16 0 0 0-3.32 0 20.26 20.26 0 0 0-2.13.31 28 28 0 0 0-2.8.71 39.1 39.1 0 0 0-17.42 12c-.66.81-1.28 1.63-1.84 2.46s-.89 1.41-1.28 2.12a20.47 20.47 0 0 0-1.43 3.1Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M380.05 124.38c.36-.43.7-.87 1-1.31s.63-.88.93-1.33c.42-.64.81-1.28 1.16-1.93s.54-1.06.77-1.58.37-.88.52-1.32.28-.85.39-1.26a11.34 11.34 0 0 0 .41-3.45c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6 6.56 6.56 0 0 0-3-2.73 9.59 9.59 0 0 0-3.08-.87 15.16 15.16 0 0 0-3.32 0q-.93.09-1.89.27Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M385.06 114.72a10.6 10.6 0 0 0 .2-2.52c0-.23 0-.46-.06-.68a7.09 7.09 0 0 0-.83-2.6 6.47 6.47 0 0 0-2.31-2.37ZM375.24 129.22c.94-.8 1.84-1.63 2.69-2.49l-7.54-20.52-.31.09c-.95.3-1.91.67-2.87 1.08ZM365.93 135.08a35.33 35.33 0 0 0 3.3-1.68l-8.3-22.58c-1.06.72-2.09 1.48-3.08 2.3ZM363 136.22c.6-.2 1.21-.42 1.81-.66L356.85 114c-.53.46-1 .93-1.54 1.41Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M349 123.92q-.21.43-.39.87l-.12.29c0 .1-.07.2-.11.29s-.16.44-.23.65a12.78 12.78 0 0 0-.69 3.45 7.7 7.7 0 0 0 .84 4.18 6.18 6.18 0 0 0 1.71 2c.16.13.33.25.51.37a8.58 8.58 0 0 0 3 1.15l.43.08Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M353.86 122.4c3.44-6.34 11.41-12 18.15-12.94l.85-.1c6.91-.59 9.59 4.28 6 10.88s-12.1 12.44-19 13h-.82c-6.3.23-8.63-4.51-5.18-10.84Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M354.41 122.35c.11-.21.23-.41.35-.61 3.46-5.8 10.77-10.85 17-11.75h.16l.65-.08a9.84 9.84 0 0 1 3.44.26 4.83 4.83 0 0 1 3.8 4.57 9.73 9.73 0 0 1-1 4.59c-.15.32-.31.65-.49 1s-.34.6-.53.9a18.65 18.65 0 0 1-1.29 1.85 27.62 27.62 0 0 1-13.32 9.13 17.35 17.35 0 0 1-2.06.46c-.32.05-.64.09-1 .11s-.53 0-.79 0h-.21a7.26 7.26 0 0 1-4.13-1.06c-1.76-1.17-2.48-3.24-1.95-5.84a12.74 12.74 0 0 1 1.37-3.53Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M354.41 122.35c.11-.21.23-.41.35-.61 3.46-5.8 10.77-10.85 17-11.75h.16l.65-.08a9.84 9.84 0 0 1 3.44.26 4.83 4.83 0 0 1 3.8 4.57 9.73 9.73 0 0 1-1 4.59c-.15.32-.31.65-.49 1s-.34.6-.53.9a18.65 18.65 0 0 1-1.29 1.85 27.62 27.62 0 0 1-13.32 9.13 17.35 17.35 0 0 1-2.06.46c-.32.05-.64.09-1 .11s-.53 0-.79 0h-.21a7.26 7.26 0 0 1-4.13-1.06c-1.76-1.17-2.48-3.24-1.95-5.84a12.74 12.74 0 0 1 1.37-3.53Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M355.85 123.21c-.13.21-.24.41-.35.61a13 13 0 0 0-1.35 3.54 6.32 6.32 0 0 0 .3 3.94 4 4 0 0 0 .56.43 7.26 7.26 0 0 0 4.13 1.06h1c.27 0 .64-.06 1-.11a17.35 17.35 0 0 0 2.06-.46 27.62 27.62 0 0 0 13.28-9.22 18.65 18.65 0 0 0 1.29-1.85c.19-.3.36-.6.53-.9s.34-.66.49-1a9.73 9.73 0 0 0 1-4.59 5.25 5.25 0 0 0-.46-1.9 5.58 5.58 0 0 0-2.25-1.2 9.88 9.88 0 0 0-3.44-.25l-.65.07h-.17c-6.2.99-13.52 6.03-16.97 11.83Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M350.52 136c5.28 2.95 12.16.58 17.29-2 7.64-4.12 14.54-10.53 17-18.41a9 9 0 0 0-.4-6.71 9.09 9.09 0 0 1 .56 6.76c-1.83 6.52-7 12-12.79 16.07-6.04 3.96-14.83 8.08-21.66 4.29Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="m361.88 123.87 1.23 1.83-5.11 1.27-1.24-1.83 5.12-1.27zM361.14 122.7c.57 1 2.44 1.31 4 1.52.39 0 .74.08 1.05.13a2 2 0 0 0 .47-.55c.57-1-.47-1.14-2.76-1.41a16.84 16.84 0 0 1-2.29-.41c.23-.54.12-1.17-1.74-1.1Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m373.06 111.59 1.24 1.83-1.16 2.05-1.24-1.83 1.16-2.05z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M365.6 118a3.35 3.35 0 0 1 2.75-1.35c2-.15 2.18.8 1.93 1.53l.22.32a3.8 3.8 0 0 0-.91 0 4.06 4.06 0 0 0-2.32.82c-1.27-.2-2.19-.43-1.67-1.32ZM375.15 116a1.27 1.27 0 0 0 .58.46c0 .12-.05.24-.09.37l1.24 1.82a2.2 2.2 0 0 0-.18-1.94l-1.24-1.82s-.67.52-.31 1.11Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M363.93 122.39c2.29.27 3.33.4 2.76 1.41a2 2 0 0 1-.47.55c1.54.21 2.19.44 1.71 1.28a3.31 3.31 0 0 1-2.79 1.3c-1.68.13-2.3-.36-2-1.23L358 127v.14a2.4 2.4 0 0 0-.75 0l1 1.48.1.14a2.61 2.61 0 0 0 1.25.84 7.24 7.24 0 0 0 2.08.35l-2.38.19 1.24 1.82 1.61-.12 2.18-.17 1.16-2c3.42-.59 6.21-2.13 7.58-4.55.62-1.1.67-1.86.35-2.41a.6.6 0 0 0-.09-.13L372.2 121a1.19 1.19 0 0 0-.28.69 9.73 9.73 0 0 0-1.58-.34c-2.14-.31-4.24-.21-3.5-1.52a2.18 2.18 0 0 1 .43-.53c-1.31-.15-2.19-.38-1.67-1.3a3.35 3.35 0 0 1 2.75-1.35c2-.15 2.18.8 1.93 1.53l.22.32 1 1.51 5.36-1.32-1.24-1.82a3.26 3.26 0 0 0 .14-.86 6.71 6.71 0 0 0-2.64-.51l.47-.83-3.07-.93-1.16 2.05c-3.46.62-6.51 2.2-7.9 4.67a3.78 3.78 0 0 0-.5 1.32 11.68 11.68 0 0 0 2.97.61Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M363.93 122.39c2.29.27 3.33.4 2.76 1.41a2 2 0 0 1-.47.55c1.54.21 2.19.44 1.71 1.28a3.31 3.31 0 0 1-2.79 1.3c-1.68.13-2.3-.36-2-1.23L358 127v.14a2.4 2.4 0 0 0-.75 0l1 1.48.1.14a2.61 2.61 0 0 0 1.25.84 7.24 7.24 0 0 0 2.08.35l-2.38.19 1.24 1.82 1.61-.12 2.18-.17 1.16-2c3.42-.59 6.21-2.13 7.58-4.55.62-1.1.67-1.86.35-2.41a.6.6 0 0 0-.09-.13L372.2 121a1.19 1.19 0 0 0-.28.69 9.73 9.73 0 0 0-1.58-.34c-2.14-.31-4.24-.21-3.5-1.52a2.18 2.18 0 0 1 .43-.53c-1.31-.15-2.19-.38-1.67-1.3a3.35 3.35 0 0 1 2.75-1.35c2-.15 2.18.8 1.93 1.53l.22.32 1 1.51 5.36-1.32-1.24-1.82a3.26 3.26 0 0 0 .14-.86 6.71 6.71 0 0 0-2.64-.51l.47-.83-3.07-.93-1.16 2.05c-3.46.62-6.51 2.2-7.9 4.67a3.78 3.78 0 0 0-.5 1.32 11.68 11.68 0 0 0 2.97.61Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M365.43 129.59a14 14 0 0 0 5.4-2.29 7.74 7.74 0 0 0 2-2.08 4.61 4.61 0 0 0 .62-1.26 1.75 1.75 0 0 0-.05-1.33 1.71 1.71 0 0 1 .17 1.35 4.8 4.8 0 0 1-.57 1.32 7.6 7.6 0 0 1-2 2.18 10.65 10.65 0 0 1-2.64 1.41 13.42 13.42 0 0 1-2.93.7Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M361 121.75a11.68 11.68 0 0 0 2.95.64c2.29.27 3.33.4 2.76 1.41a2 2 0 0 1-.47.55 4.27 4.27 0 0 1-2.32.75c-1.67.13-2.29-.36-2-1.23l-5.12 1.27a2 2 0 0 0 1.18 2.42 6.3 6.3 0 0 0 2.46.5l-1.16 2.05 2.38-.19 1.41-.11 1.16-2c3.42-.6 6.21-2.14 7.58-4.55a4 4 0 0 0 .53-1.42c.19-1.54-1.39-2-3.2-2.31-.63-.09-1.27-.15-1.83-.22-1.31-.15-2.19-.38-1.67-1.3a3.35 3.35 0 0 1 2.75-1.35c2-.15 2.18.8 1.93 1.53l5.36-1.31a3.26 3.26 0 0 0 .14-.86c0-1.08-.71-1.71-1.78-2a7.87 7.87 0 0 0-2.1-.3l1.16-2-3.75.28-1.17 2.06c-3.46.62-6.51 2.2-7.9 4.67-1.05 1.64-.45 2.53.72 3.02Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M361 121.75a11.68 11.68 0 0 0 2.95.64c2.29.27 3.33.4 2.76 1.41a2 2 0 0 1-.47.55 4.27 4.27 0 0 1-2.32.75c-1.67.13-2.29-.36-2-1.23l-5.12 1.27a2 2 0 0 0 1.18 2.42 6.3 6.3 0 0 0 2.46.5l-1.16 2.05 2.38-.19 1.41-.11 1.16-2c3.42-.6 6.21-2.14 7.58-4.55a4 4 0 0 0 .53-1.42c.19-1.54-1.39-2-3.2-2.31-.63-.09-1.27-.15-1.83-.22-1.31-.15-2.19-.38-1.67-1.3a3.35 3.35 0 0 1 2.75-1.35c2-.15 2.18.8 1.93 1.53l5.36-1.31a3.26 3.26 0 0 0 .14-.86c0-1.08-.71-1.71-1.78-2a7.87 7.87 0 0 0-2.1-.3l1.16-2-3.75.28-1.17 2.06c-3.46.62-6.51 2.2-7.9 4.67-1.05 1.64-.45 2.53.72 3.02Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="m370.65 118.06 3.29-.81-1.14-3.54a8.64 8.64 0 0 0-.9-.07l.56-1-.32-1-2.83.21-.42.73ZM361 121.75a11.68 11.68 0 0 0 2.95.64l.74.09-2.06-6.39a7.64 7.64 0 0 0-2.37 2.51 4.53 4.53 0 0 0-.39.87l.65 2a3.62 3.62 0 0 0 .48.28ZM365.41 124.79a5.27 5.27 0 0 1-1.51.31c-1.67.13-2.29-.36-2-1.23l-.57.14 1.82 5.64 1.06-1.88a18.23 18.23 0 0 0 2-.49ZM359.26 127.94a7.67 7.67 0 0 0 1.14.12l-.7 1.24.24.75 1.68-.13h.29l-1.8-5.59-1.87.46ZM371.31 120.09l.79 2.45a3.59 3.59 0 0 0 .2-.74 1.6 1.6 0 0 0-.99-1.71Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m364.19 127.77 1.24 1.82-1.16 2.05-1.24-1.83 1.16-2.04z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m364.19 127.77 1.24 1.82-1.16 2.05-1.24-1.83 1.16-2.04z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m368.14 113.93-.4.09-.46.11-.59.15-.69.21-.37.13-.38.14-.4.16-.4.17c-.26.13-.53.25-.79.4s-.51.29-.76.44-.48.33-.69.5-.42.35-.61.51l-.26.26-.13.12-.11.12c-.14.17-.28.32-.38.46s-.2.26-.27.37l-.21.33.2-.34a3.75 3.75 0 0 1 .26-.38 3.39 3.39 0 0 1 .37-.46l.1-.13.13-.13.25-.26a6.38 6.38 0 0 1 .61-.52c.21-.18.45-.34.69-.51s.5-.32.77-.45.53-.27.8-.4l.4-.17.4-.16.39-.14.38-.11c.24-.09.48-.14.69-.2s.42-.1.6-.14l.46-.09Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m363.03 129.81-3.79.3 1.16-2.05-.77 1.81 3.4-.06zM371.9 113.64l.85-1.84-3.44.07 3.75-.28-1.16 2.05z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="m43.05 68.58-2.73 2 4.13 5.57a9.4 9.4 0 0 0 1.73 1.78c.23.18.48.35.73.51a12.38 12.38 0 0 0 4.15 1.63 14.11 14.11 0 0 0 1.68.25 17.62 17.62 0 0 0 1.82.11 23.31 23.31 0 0 0 2.46-.1c.89-.08 1.8-.21 2.72-.38.69-.13 1.38-.28 2.09-.46C72.12 76.91 83.27 69.25 90 60.19c.46-.62.89-1.24 1.3-1.87.6-.9 1.14-1.81 1.64-2.73.4-.75.77-1.49 1.09-2.22s.52-1.25.74-1.85.39-1.2.54-1.79a16.08 16.08 0 0 0 .56-4.86c0-.33 0-.65-.08-1a10 10 0 0 0-1.17-3.67 7.74 7.74 0 0 0-.64-1l-3.9-5.2-2.5 1.83c-.36-.07-.73-.13-1.11-.17a21.31 21.31 0 0 0-4.68 0 28.89 28.89 0 0 0-3 .43 35.26 35.26 0 0 0-4 1 55.07 55.07 0 0 0-24.5 16.8 41.66 41.66 0 0 0-2.59 3.47c-.66 1-1.26 2-1.81 3a29.35 29.35 0 0 0-2 4.48 20.13 20.13 0 0 0-.84 3.74Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M85.54 65.35 77.17 54l-3.82 3.5 8.38 11.32q2-1.64 3.81-3.47Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M44.45 76.15a9.4 9.4 0 0 0 1.73 1.78c.23.18.48.35.73.51a12.38 12.38 0 0 0 4.15 1.63l.61.11-8.61-11.64-2.73 2Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m54.4 60.73-2.5 1.07 12.57 16.93c.84-.28 1.68-.59 2.53-.93ZM73.28 74.76 62.41 60.07l-3.91 3.37 10.12 13.68c1.57-.7 3.13-1.49 4.66-2.36ZM90.08 34l-2.5 1.83c-.36-.07-.73-.13-1.11-.17a21.31 21.31 0 0 0-4.68 0 28.89 28.89 0 0 0-3 .43 35.26 35.26 0 0 0-4 1c-1.25.39-2.51.86-3.76 1.39L88.54 62c.5-.61 1-1.22 1.44-1.84s.89-1.24 1.3-1.87c.6-.9 1.14-1.81 1.64-2.73.4-.75.77-1.49 1.09-2.22s.52-1.25.74-1.85.39-1.2.54-1.79a16.08 16.08 0 0 0 .56-4.86c0-.33 0-.65-.08-1a10 10 0 0 0-1.17-3.67 7.74 7.74 0 0 0-.64-1Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M95.86 44.87c0-.33 0-.65-.08-1a10 10 0 0 0-1.17-3.67 7.74 7.74 0 0 0-.64-1L90.08 34l-2.5 1.83h-.06l-.61.93 8.67 11.72a14.65 14.65 0 0 0 .28-3.61Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M39.51 58.88a18.66 18.66 0 0 0-1 4.87 10.93 10.93 0 0 0 1.19 5.88 8.94 8.94 0 0 0 2.41 2.83q.34.27.72.51A12.38 12.38 0 0 0 47 74.6a14.75 14.75 0 0 0 1.68.25 17.62 17.62 0 0 0 1.82.11 23.21 23.21 0 0 0 2.46-.1c.9-.08 1.8-.21 2.72-.38.69-.13 1.39-.28 2.09-.46 10.29-2.58 21.44-10.24 28.15-19.3.46-.62.89-1.24 1.31-1.87.59-.9 1.14-1.81 1.63-2.73a29.598 29.598 0 0 0 1.83-4.07c.22-.6.39-1.2.55-1.79a16.11 16.11 0 0 0 .57-4.86c0-.33 0-.65-.08-1a10 10 0 0 0-1.18-3.67 9.23 9.23 0 0 0-4.17-3.84 13.48 13.48 0 0 0-4.33-1.23 22 22 0 0 0-4.68-.05 28.89 28.89 0 0 0-3 .43 35.26 35.26 0 0 0-3.95 1 55 55 0 0 0-24.54 16.89 41.66 41.66 0 0 0-2.59 3.47c-.66 1-1.27 2-1.81 3a28.09 28.09 0 0 0-1.97 4.48Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M84.48 56.57c.5-.61 1-1.23 1.45-1.85s.89-1.24 1.31-1.87c.59-.9 1.14-1.81 1.63-2.73a29.598 29.598 0 0 0 1.83-4.07c.22-.6.39-1.2.55-1.79a16.11 16.11 0 0 0 .57-4.86c0-.33 0-.65-.08-1a10 10 0 0 0-1.18-3.67 9.23 9.23 0 0 0-4.17-3.84 13.48 13.48 0 0 0-4.33-1.23 22 22 0 0 0-4.68-.05c-.88.08-1.77.21-2.66.37Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M91.54 43a15.23 15.23 0 0 0 .28-3.55c0-.33 0-.65-.08-1a10 10 0 0 0-1.18-3.67 9 9 0 0 0-3.25-3.33ZM77.69 63.39q2-1.68 3.8-3.51L70.86 31l-.43.13a42.66 42.66 0 0 0-4.05 1.52ZM64.58 71.65c1.56-.7 3.12-1.49 4.65-2.36l-11.7-31.83c-1.49 1-2.94 2.09-4.34 3.23ZM60.41 73.26q1.28-.42 2.55-.93L51.77 41.9c-.74.64-1.46 1.31-2.16 2Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M40.71 55.92q-.28.61-.54 1.23c-.06.13-.12.27-.17.4s-.11.28-.16.42c-.12.3-.23.61-.33.91a18.66 18.66 0 0 0-1 4.87 10.93 10.93 0 0 0 1.19 5.88 8.94 8.94 0 0 0 2.41 2.83q.34.27.72.51A12.38 12.38 0 0 0 47 74.6l.61.11Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M47.56 53.77c4.86-8.93 16.09-16.85 25.58-18.23l1.21-.14c9.73-.83 13.51 6 8.44 15.34S65.74 68.27 56 69.11l-1.16.06c-8.84.21-12.14-6.47-7.28-15.4Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M48.34 53.7c.16-.28.32-.57.5-.86 4.87-8.17 15.18-15.28 24-16.55h.24l.91-.1a13.57 13.57 0 0 1 4.85.36 6.82 6.82 0 0 1 5.3 6.45 13.69 13.69 0 0 1-1.44 6.47 25.119 25.119 0 0 1-1.43 2.64 29.86 29.86 0 0 1-1.82 2.61 38.87 38.87 0 0 1-18.78 12.83 22.59 22.59 0 0 1-2.9.64c-.46.07-.91.13-1.35.16s-.75.06-1.11.07H55a10.34 10.34 0 0 1-5.83-1.49c-2.48-1.65-3.49-4.57-2.74-8.24a18.11 18.11 0 0 1 1.91-4.99Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M48.34 53.7c.16-.28.32-.57.5-.86 4.87-8.17 15.18-15.28 24-16.55h.24l.91-.1a13.57 13.57 0 0 1 4.85.36 6.82 6.82 0 0 1 5.3 6.45 13.69 13.69 0 0 1-1.44 6.47 25.119 25.119 0 0 1-1.43 2.64 29.86 29.86 0 0 1-1.82 2.61 38.87 38.87 0 0 1-18.78 12.83 22.59 22.59 0 0 1-2.9.64c-.46.07-.91.13-1.35.16s-.75.06-1.11.07H55a10.34 10.34 0 0 1-5.83-1.49c-2.48-1.65-3.49-4.57-2.74-8.24a18.11 18.11 0 0 1 1.91-4.99Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M50.37 54.92c-.18.29-.34.58-.5.86a18.11 18.11 0 0 0-1.9 5 8.91 8.91 0 0 0 .43 5.55 5.83 5.83 0 0 0 .78.61A10.34 10.34 0 0 0 55 68.42h.3c.36 0 .73 0 1.11-.07s.89-.09 1.35-.16a22.59 22.59 0 0 0 2.9-.64 38.87 38.87 0 0 0 18.79-12.87 29.86 29.86 0 0 0 1.82-2.61c.26-.42.51-.84.74-1.27s.48-.92.69-1.37A13.69 13.69 0 0 0 84.14 43a7.44 7.44 0 0 0-.65-2.69 7.83 7.83 0 0 0-3.17-1.68 13.8 13.8 0 0 0-4.85-.36c-.3 0-.61.06-.91.11h-.24c-8.77 1.26-19.08 8.37-23.95 16.54Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M42.86 73c7.43 4.16 17.13.83 24.37-2.79 10.77-5.82 20.5-14.85 23.92-26 .84-3.13 1-6.58-.58-9.47 1.65 2.88 1.61 6.37.79 9.54-2.58 9.19-9.84 16.93-18 22.66-8.49 5.53-20.87 11.34-30.5 6.06Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="m58.86 55.85 1.75 2.57-7.21 1.79-1.75-2.57 7.21-1.79zM57.82 54.21c.8 1.47 3.45 1.84 5.69 2.13l1.47.18a2.63 2.63 0 0 0 .67-.77c.81-1.42-.67-1.6-3.89-2a22.7 22.7 0 0 1-3.23-.57c.33-.76.18-1.66-2.45-1.56Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m74.62 38.55 1.75 2.57-1.64 2.89-1.74-2.58 1.63-2.88z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M64.11 47.53c.65-1.14 2.13-1.75 3.88-1.89 2.79-.22 3.07 1.12 2.71 2.15l.31.45a5.8 5.8 0 0 0-1.28 0 5.71 5.71 0 0 0-3.26 1.16c-1.85-.25-3.09-.57-2.36-1.87ZM77.58 44.78a1.71 1.71 0 0 0 .8.64c0 .17-.07.34-.12.52L80 48.51a3.11 3.11 0 0 0-.25-2.74L78 43.2s-.93.73-.42 1.58Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M61.76 53.76c3.22.39 4.7.57 3.89 2a2.63 2.63 0 0 1-.67.77c2.18.3 3.09.62 2.42 1.81-.49.87-1.71 1.66-3.93 1.83s-3.24-.51-2.86-1.74l-7.21 1.78v.21a3.64 3.64 0 0 0-1.06-.08l1.38 2.09.13.2a3.7 3.7 0 0 0 1.76 1.18 10 10 0 0 0 2.93.49l-3.35.26 1.75 2.58 2.23-.14 3.07-.24 1.63-2.88c4.82-.84 8.76-3 10.68-6.42.88-1.55.94-2.63.49-3.4l-.13-.19-1.48-2.12a1.68 1.68 0 0 0-.4 1 13.61 13.61 0 0 0-2.23-.49c-3-.43-6-.3-4.93-2.14a2.54 2.54 0 0 1 .61-.74c-1.85-.22-3.09-.54-2.36-1.84.65-1.14 2.13-1.75 3.88-1.89 2.79-.22 3.07 1.12 2.72 2.15l.3.45 1.44 2.12L80 48.51l-1.75-2.57a4.83 4.83 0 0 0 .2-1.22 9.61 9.61 0 0 0-3.72-.72l.67-1.17-4.33-1.31-1.63 2.88c-4.88.88-9.17 3.11-11.14 6.58a5.37 5.37 0 0 0-.7 1.88 16.67 16.67 0 0 0 4.16.9Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M61.76 53.76c3.22.39 4.7.57 3.89 2a2.63 2.63 0 0 1-.67.77c2.18.3 3.09.62 2.42 1.81-.49.87-1.71 1.66-3.93 1.83s-3.24-.51-2.86-1.74l-7.21 1.78v.21a3.64 3.64 0 0 0-1.06-.08l1.38 2.09.13.2a3.7 3.7 0 0 0 1.76 1.18 10 10 0 0 0 2.93.49l-3.35.26 1.75 2.58 2.23-.14 3.07-.24 1.63-2.88c4.82-.84 8.76-3 10.68-6.42.88-1.55.94-2.63.49-3.4l-.13-.19-1.48-2.12a1.68 1.68 0 0 0-.4 1 13.61 13.61 0 0 0-2.23-.49c-3-.43-6-.3-4.93-2.14a2.54 2.54 0 0 1 .61-.74c-1.85-.22-3.09-.54-2.36-1.84.65-1.14 2.13-1.75 3.88-1.89 2.79-.22 3.07 1.12 2.72 2.15l.3.45 1.44 2.12L80 48.51l-1.75-2.57a4.83 4.83 0 0 0 .2-1.22 9.61 9.61 0 0 0-3.72-.72l.67-1.17-4.33-1.31-1.63 2.88c-4.88.88-9.17 3.11-11.14 6.58a5.37 5.37 0 0 0-.7 1.88 16.67 16.67 0 0 0 4.16.9Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M63.87 63.92a19.84 19.84 0 0 0 7.62-3.24 11.13 11.13 0 0 0 2.76-2.93 7 7 0 0 0 .87-1.75 2.43 2.43 0 0 0-.12-1.9 2.42 2.42 0 0 1 .25 1.9 6.67 6.67 0 0 1-.8 1.86 11 11 0 0 1-2.79 3.08 15.37 15.37 0 0 1-3.73 2 19.46 19.46 0 0 1-4.06.98Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M57.6 52.87a16.67 16.67 0 0 0 4.16.89c3.22.39 4.7.57 3.89 2a2.63 2.63 0 0 1-.67.77 5.93 5.93 0 0 1-3.26 1.07c-2.36.18-3.24-.52-2.86-1.74l-7.21 1.79a2.85 2.85 0 0 0 1.67 3.41 8.87 8.87 0 0 0 3.46.71l-1.63 2.88 3.35-.26 2-.15 1.63-2.89c4.82-.84 8.76-3 10.69-6.41a5.51 5.51 0 0 0 .74-2c.27-2.17-2-2.87-4.5-3.25-.9-.13-1.8-.21-2.58-.31-1.85-.22-3.09-.54-2.36-1.84.65-1.14 2.13-1.75 3.88-1.89 2.79-.22 3.07 1.12 2.71 2.15l7.56-1.85a4.83 4.83 0 0 0 .2-1.22c0-1.52-1-2.4-2.51-2.86a11.11 11.11 0 0 0-3-.43l1.63-2.88-5.29.39-1.64 2.9c-4.88.88-9.17 3.11-11.13 6.58-1.39 2.49-.53 3.75 1.07 4.44Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M57.6 52.87a16.67 16.67 0 0 0 4.16.89c3.22.39 4.7.57 3.89 2a2.63 2.63 0 0 1-.67.77 5.93 5.93 0 0 1-3.26 1.07c-2.36.18-3.24-.52-2.86-1.74l-7.21 1.79a2.85 2.85 0 0 0 1.67 3.41 8.87 8.87 0 0 0 3.46.71l-1.63 2.88 3.35-.26 2-.15 1.63-2.89c4.82-.84 8.76-3 10.69-6.41a5.51 5.51 0 0 0 .74-2c.27-2.17-2-2.87-4.5-3.25-.9-.13-1.8-.21-2.58-.31-1.85-.22-3.09-.54-2.36-1.84.65-1.14 2.13-1.75 3.88-1.89 2.79-.22 3.07 1.12 2.71 2.15l7.56-1.85a4.83 4.83 0 0 0 .2-1.22c0-1.52-1-2.4-2.51-2.86a11.11 11.11 0 0 0-3-.43l1.63-2.88-5.29.39-1.64 2.9c-4.88.88-9.17 3.11-11.13 6.58-1.39 2.49-.53 3.75 1.07 4.44Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="m71.23 47.66 4.64-1.14-1.62-5c-.41 0-.83-.08-1.26-.09l.79-1.43-.46-1.4-4 .3-.58 1ZM57.6 52.87a16.67 16.67 0 0 0 4.16.89l1 .13-2.9-9a10.91 10.91 0 0 0-3.34 3.54 6.44 6.44 0 0 0-.52 1.22l.92 2.86a6.23 6.23 0 0 0 .68.36ZM63.85 57.14a7.7 7.7 0 0 1-2.13.45c-2.36.18-3.24-.52-2.86-1.74l-.79.2L60.63 64l1.49-2.65a21 21 0 0 0 2.88-.7ZM55.18 61.59a14.3 14.3 0 0 0 1.6.17l-1 1.74.35 1.06 2.36-.18h.41l-2.54-7.88-2.63.65ZM72.16 50.53 73.27 54a4.34 4.34 0 0 0 .28-1 2.22 2.22 0 0 0-1.39-2.47Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m62.13 61.34 1.74 2.58-1.63 2.88-1.75-2.57 1.64-2.89z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m62.13 61.34 1.74 2.58-1.63 2.88-1.75-2.57 1.64-2.89z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m67.69 41.84-.56.12-.65.16c-.24.06-.53.12-.83.22s-.63.17-1 .29l-.53.18-.54.2-.55.22-.57.25c-.37.17-.75.35-1.11.56s-.72.41-1.08.63-.67.45-1 .69-.59.49-.86.72l-.37.36-.17.18-.16.17c-.2.23-.39.44-.53.64s-.29.37-.38.52l-.3.47.28-.48c.09-.15.22-.33.36-.53a8.22 8.22 0 0 1 .52-.66l.15-.18.18-.18.36-.37c.27-.23.53-.5.85-.73s.64-.48 1-.71.71-.45 1.08-.64.75-.38 1.13-.56c.19-.09.38-.16.57-.24a5.42 5.42 0 0 1 .56-.22l.54-.2.54-.17c.34-.11.67-.19 1-.28s.59-.14.84-.19.46-.1.64-.13Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m60.49 64.23-5.34.41 1.63-2.88-1.09 2.54 4.8-.07zM72.99 41.43l1.2-2.6-4.86.11 5.29-.39-1.63 2.88z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="m39.08 214.22-2.26 1.67 3.42 4.61a7.64 7.64 0 0 0 1.43 1.5 6.42 6.42 0 0 0 .6.42 10.1 10.1 0 0 0 3.44 1.35 11.89 11.89 0 0 0 1.39.21 12.09 12.09 0 0 0 1.5.09c.66 0 1.34 0 2-.08s1.49-.17 2.25-.31c.57-.11 1.14-.24 1.73-.38 8.52-2.14 17.76-8.48 23.31-16 .38-.51.74-1 1.08-1.55.49-.74 1-1.5 1.35-2.26s.64-1.23.91-1.84.43-1 .61-1.53.33-1 .45-1.48a13.31 13.31 0 0 0 .47-4c0-.27 0-.53-.06-.79a8.35 8.35 0 0 0-1-3 8.67 8.67 0 0 0-.53-.81L78 185.59l-2 1.52c-.29-.06-.6-.11-.91-.15a17.8 17.8 0 0 0-3.88 0 22 22 0 0 0-2.49.36 31.18 31.18 0 0 0-3.26.83 45.64 45.64 0 0 0-20.34 13.95 35.73 35.73 0 0 0-2.14 2.88c-.55.81-1 1.64-1.5 2.47a24 24 0 0 0-1.63 3.72 15.92 15.92 0 0 0-.77 3.05Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m74.26 211.55-6.93-9.36-3.16 2.9 6.94 9.37c1.1-.93 2.16-1.9 3.15-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M40.24 220.5a7.64 7.64 0 0 0 1.43 1.5 6.42 6.42 0 0 0 .6.42 10.1 10.1 0 0 0 3.44 1.35c.16 0 .33.06.5.09l-7.13-9.63-2.26 1.67Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="m48.47 207.73-2.06.88 10.4 14c.7-.23 1.4-.49 2.1-.76ZM64.11 219.34l-9-12.16-3.24 2.82 8.38 11.33a42.43 42.43 0 0 0 3.86-1.99ZM78 185.59l-2 1.52c-.29-.06-.6-.11-.91-.15a17.8 17.8 0 0 0-3.88 0 22 22 0 0 0-2.49.36 31.18 31.18 0 0 0-3.26.83c-1 .33-2.08.72-3.12 1.16l14.45 19.54q.63-.76 1.2-1.53c.38-.51.74-1 1.08-1.55.49-.74 1-1.5 1.35-2.26s.64-1.23.91-1.84.43-1 .61-1.53.33-1 .45-1.48a13.31 13.31 0 0 0 .47-4c0-.27 0-.53-.06-.79a8.35 8.35 0 0 0-1-3 8.67 8.67 0 0 0-.53-.81Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M82.81 194.59c0-.27 0-.53-.06-.79a8.35 8.35 0 0 0-1-3 8.67 8.67 0 0 0-.53-.81l-3.22-4.4-2 1.52-.51.77 7.18 9.7a12.44 12.44 0 0 0 .14-2.99Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M36.15 206.19a15.39 15.39 0 0 0-.81 4 9 9 0 0 0 1 4.87 7.44 7.44 0 0 0 2 2.34 6.42 6.42 0 0 0 .6.42 10 10 0 0 0 3.44 1.35 11.58 11.58 0 0 0 1.39.21 12.09 12.09 0 0 0 1.5.09c.65 0 1.33 0 2-.08s1.49-.17 2.25-.31c.58-.11 1.15-.24 1.73-.38 8.53-2.14 17.76-8.48 23.32-16 .38-.51.74-1 1.08-1.55.49-.74.94-1.5 1.35-2.26s.64-1.23.91-1.84.43-1 .61-1.53.32-1 .45-1.48a13.31 13.31 0 0 0 .47-4c0-.27 0-.53-.06-.79a8.36 8.36 0 0 0-1-3 7.68 7.68 0 0 0-3.38-3.2 11.18 11.18 0 0 0-3.59-1 17.7 17.7 0 0 0-3.87 0 24.42 24.42 0 0 0-2.49.35 29.11 29.11 0 0 0-3.27.84 45.47 45.47 0 0 0-20.33 13.95 33.5 33.5 0 0 0-2.18 2.81c-.54.82-1 1.64-1.49 2.47a22.56 22.56 0 0 0-1.63 3.72Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M73.38 204.28c.42-.5.82-1 1.21-1.53s.74-1 1.08-1.55c.49-.74.94-1.5 1.35-2.26s.64-1.23.91-1.84.43-1 .61-1.53.32-1 .45-1.48a13.31 13.31 0 0 0 .47-4c0-.27 0-.53-.06-.79a8.36 8.36 0 0 0-1-3 7.68 7.68 0 0 0-3.4-3.25 11.18 11.18 0 0 0-3.59-1 17.7 17.7 0 0 0-3.87 0 21.34 21.34 0 0 0-2.21.31Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M79.24 193a12.6 12.6 0 0 0 .22-2.94c0-.27 0-.53-.06-.79a8.36 8.36 0 0 0-1-3 7.48 7.48 0 0 0-2.69-2.76ZM67.77 209.93q1.65-1.4 3.14-2.91l-8.8-24-.36.11c-1.11.35-2.24.78-3.36 1.26ZM56.9 216.77a42.43 42.43 0 0 0 3.86-2l-9.69-26.36q-1.86 1.26-3.59 2.68ZM53.45 218.1c.7-.23 1.41-.49 2.12-.77l-9.27-25.2c-.61.54-1.21 1.08-1.79 1.65Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M37.14 203.74c-.16.35-.31.68-.45 1 0 .11-.1.23-.14.34s-.09.23-.13.34-.19.5-.27.75a15.39 15.39 0 0 0-.81 4 9 9 0 0 0 1 4.87 7.44 7.44 0 0 0 2 2.34 6.42 6.42 0 0 0 .6.42 10 10 0 0 0 3.44 1.35c.16 0 .33.06.5.09Z"
      style={{
        fill: "#e0e0e0",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M42.81 202c4-7.39 13.32-14 21.19-15.09.33-.05.67-.09 1-.12 8.06-.69 11.19 5 7 12.7S57.87 214 49.81 214.66l-1 .06c-7.3.17-10.02-5.36-6-12.72Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M43.46 201.91c.13-.24.26-.48.41-.72 4-6.76 12.57-12.66 19.83-13.71h.2c.25 0 .51-.07.76-.09a11.32 11.32 0 0 1 4 .3 5.66 5.66 0 0 1 4.45 5.31 11.48 11.48 0 0 1-1.2 5.36q-.25.56-.57 1.14c-.19.35-.39.7-.61 1.05a25.33 25.33 0 0 1-1.51 2.15 32.17 32.17 0 0 1-15.55 10.67 19.7 19.7 0 0 1-2.41.53c-.37.06-.74.1-1.11.13s-.63 0-.92.05H49a8.42 8.42 0 0 1-4.83-1.23c-2-1.37-2.89-3.79-2.27-6.82a14.84 14.84 0 0 1 1.56-4.12Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M43.46 201.91c.13-.24.26-.48.41-.72 4-6.76 12.57-12.66 19.83-13.71h.2c.25 0 .51-.07.76-.09a11.32 11.32 0 0 1 4 .3 5.66 5.66 0 0 1 4.45 5.31 11.48 11.48 0 0 1-1.2 5.36q-.25.56-.57 1.14c-.19.35-.39.7-.61 1.05a25.33 25.33 0 0 1-1.51 2.15 32.17 32.17 0 0 1-15.55 10.67 19.7 19.7 0 0 1-2.41.53c-.37.06-.74.1-1.11.13s-.63 0-.92.05H49a8.42 8.42 0 0 1-4.83-1.23c-2-1.37-2.89-3.79-2.27-6.82a14.84 14.84 0 0 1 1.56-4.12Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M45.14 202.92c-.15.24-.28.47-.41.71a14.84 14.84 0 0 0-1.58 4.13 7.35 7.35 0 0 0 .36 4.6 5.23 5.23 0 0 0 .64.5 8.42 8.42 0 0 0 4.85 1.23h.25c.29 0 .6 0 .92-.05s.74-.07 1.11-.13a19.7 19.7 0 0 0 2.41-.53 32.17 32.17 0 0 0 15.55-10.67 25.33 25.33 0 0 0 1.51-2.15 20.185 20.185 0 0 0 1.18-2.19 11.48 11.48 0 0 0 1.2-5.36 6.35 6.35 0 0 0-.54-2.23 6.49 6.49 0 0 0-2.63-1.39 11.17 11.17 0 0 0-4-.29l-.75.08h-.2c-7.3 1.08-15.84 6.97-19.87 13.74Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M38.92 217.86c6.16 3.45 14.19.69 20.18-2.31 8.92-4.82 17-12.29 19.81-21.5a10.61 10.61 0 0 0-.48-7.84 10.54 10.54 0 0 1 .66 7.9c-2.13 7.61-8.14 14-14.93 18.76-7.01 4.58-17.27 9.39-25.24 4.99Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="m52.17 203.69 1.45 2.13-5.97 1.48-1.45-2.13 5.97-1.48zM51.31 202.32c.66 1.23 2.85 1.53 4.71 1.77l1.22.15a2.26 2.26 0 0 0 .55-.63c.67-1.18-.55-1.34-3.22-1.65a19.35 19.35 0 0 1-2.67-.48c.27-.63.14-1.37-2-1.29Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m65.22 189.36 1.45 2.13-1.36 2.39-1.44-2.14 1.35-2.38z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M56.52 196.8a3.86 3.86 0 0 1 3.21-1.57c2.31-.18 2.54.93 2.25 1.78l.25.37a5.14 5.14 0 0 0-1.06 0 4.67 4.67 0 0 0-2.7 1c-1.53-.24-2.56-.51-1.95-1.58ZM67.67 194.52a1.5 1.5 0 0 0 .67.53c0 .14-.06.28-.1.43l1.44 2.13a2.55 2.55 0 0 0-.2-2.27L68 193.21s-.75.6-.33 1.31Z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="M54.57 202c2.67.31 3.89.47 3.22 1.65a2.26 2.26 0 0 1-.55.63c1.8.25 2.55.52 2 1.5-.41.72-1.42 1.37-3.25 1.52s-2.69-.43-2.38-1.44l-6 1.48v.17a3.11 3.11 0 0 0-.87-.07l1.14 1.73a1.63 1.63 0 0 0 .11.17 3 3 0 0 0 1.46 1 8.27 8.27 0 0 0 2.43.41l-2.78.25 1.44 2.13 1.88-.15 2.55-.2 1.35-2.38c4-.7 7.25-2.49 8.85-5.32.72-1.28.77-2.17.4-2.81l-.11-.15-1.23-1.76a1.43 1.43 0 0 0-.32.81 11.75 11.75 0 0 0-1.85-.41c-2.49-.35-4.95-.24-4.09-1.77a2.27 2.27 0 0 1 .51-.61c-1.53-.18-2.56-.45-1.95-1.52a3.86 3.86 0 0 1 3.21-1.57c2.31-.18 2.54.93 2.25 1.78l.25.37 1.19 1.76 6.26-1.53-1.44-2.13a4 4 0 0 0 .16-1 7.86 7.86 0 0 0-3.08-.59l.54-1-3.58-1.08-1.35 2.39c-4 .72-7.6 2.57-9.22 5.44a4.26 4.26 0 0 0-.58 1.56 13.87 13.87 0 0 0 3.43.74Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M54.57 202c2.67.31 3.89.47 3.22 1.65a2.26 2.26 0 0 1-.55.63c1.8.25 2.55.52 2 1.5-.41.72-1.42 1.37-3.25 1.52s-2.69-.43-2.38-1.44l-6 1.48v.17a3.11 3.11 0 0 0-.87-.07l1.14 1.73a1.63 1.63 0 0 0 .11.17 3 3 0 0 0 1.46 1 8.27 8.27 0 0 0 2.43.41l-2.78.25 1.44 2.13 1.88-.15 2.55-.2 1.35-2.38c4-.7 7.25-2.49 8.85-5.32.72-1.28.77-2.17.4-2.81l-.11-.15-1.23-1.76a1.43 1.43 0 0 0-.32.81 11.75 11.75 0 0 0-1.85-.41c-2.49-.35-4.95-.24-4.09-1.77a2.27 2.27 0 0 1 .51-.61c-1.53-.18-2.56-.45-1.95-1.52a3.86 3.86 0 0 1 3.21-1.57c2.31-.18 2.54.93 2.25 1.78l.25.37 1.19 1.76 6.26-1.53-1.44-2.13a4 4 0 0 0 .16-1 7.86 7.86 0 0 0-3.08-.59l.54-1-3.58-1.08-1.35 2.39c-4 .72-7.6 2.57-9.22 5.44a4.26 4.26 0 0 0-.58 1.56 13.87 13.87 0 0 0 3.43.74Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="M56.32 210.37a16.66 16.66 0 0 0 6.31-2.68 9.43 9.43 0 0 0 2.29-2.43 5.85 5.85 0 0 0 .71-1.47 2 2 0 0 0-.06-1.55 2 2 0 0 1 .2 1.57 5.51 5.51 0 0 1-.66 1.54 9 9 0 0 1-2.31 2.55 12.56 12.56 0 0 1-3.09 1.64 15.4 15.4 0 0 1-3.39.83Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M51.13 201.22a14 14 0 0 0 3.44.74c2.67.31 3.89.47 3.22 1.65a2.26 2.26 0 0 1-.55.63 4.86 4.86 0 0 1-2.7.88c-2 .16-2.68-.42-2.37-1.43l-6 1.48a2.35 2.35 0 0 0 1.41 2.83 7.38 7.38 0 0 0 2.87.59L49.1 211l2.77-.22 1.65-.13 1.35-2.39c4-.69 7.25-2.49 8.85-5.3a4.85 4.85 0 0 0 .62-1.66c.22-1.8-1.62-2.38-3.73-2.7-.75-.1-1.49-.17-2.14-.25-1.53-.18-2.56-.45-1.95-1.52a3.86 3.86 0 0 1 3.21-1.57c2.31-.18 2.54.93 2.25 1.78l6.26-1.53a4 4 0 0 0 .16-1c0-1.26-.83-2-2.08-2.37a9.5 9.5 0 0 0-2.45-.36l1.35-2.38-4.38.32-1.36 2.41c-4 .72-7.59 2.57-9.22 5.44-1.17 2.04-.47 3.07.87 3.65Z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="M51.13 201.22a14 14 0 0 0 3.44.74c2.67.31 3.89.47 3.22 1.65a2.26 2.26 0 0 1-.55.63 4.86 4.86 0 0 1-2.7.88c-2 .16-2.68-.42-2.37-1.43l-6 1.48a2.35 2.35 0 0 0 1.41 2.83 7.38 7.38 0 0 0 2.87.59L49.1 211l2.77-.22 1.65-.13 1.35-2.39c4-.69 7.25-2.49 8.85-5.3a4.85 4.85 0 0 0 .62-1.66c.22-1.8-1.62-2.38-3.73-2.7-.75-.1-1.49-.17-2.14-.25-1.53-.18-2.56-.45-1.95-1.52a3.86 3.86 0 0 1 3.21-1.57c2.31-.18 2.54.93 2.25 1.78l6.26-1.53a4 4 0 0 0 .16-1c0-1.26-.83-2-2.08-2.37a9.5 9.5 0 0 0-2.45-.36l1.35-2.38-4.38.32-1.36 2.41c-4 .72-7.59 2.57-9.22 5.44-1.17 2.04-.47 3.07.87 3.65Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="m62.41 196.9 3.84-.94-1.33-4.14c-.34 0-.69-.06-1.05-.08l.65-1.15-.37-1.15-3.31.24-.48.86ZM51.13 201.22a13.87 13.87 0 0 0 3.44.74l.86.1L53 194.6a9.07 9.07 0 0 0-2.77 2.93 5.82 5.82 0 0 0-.46 1l.77 2.37a3.69 3.69 0 0 0 .59.32ZM56.3 204.76a6.19 6.19 0 0 1-1.76.36c-2 .16-2.68-.42-2.37-1.43l-.66.16 2.12 6.58 1.24-2.2a17.45 17.45 0 0 0 2.37-.57ZM49.12 208.44a10.07 10.07 0 0 0 1.33.14l-.82 1.42.28.88 2-.15h.34l-2.1-6.52-2.18.54ZM63.18 199.28l.92 2.85a4.17 4.17 0 0 0 .24-.86 1.84 1.84 0 0 0-1.16-1.99Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m54.87 208.23 1.45 2.14-1.35 2.38-1.45-2.13 1.35-2.39z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m54.87 208.23 1.45 2.14-1.35 2.38-1.45-2.13 1.35-2.39z"
      style={{
        opacity: 0.13,
      }}
    />
    <path
      d="m59.48 192.09-.46.1-.54.12-.69.18c-.25.08-.52.15-.8.25l-.44.14a4.7 4.7 0 0 1-.45.17l-.46.19-.46.2c-.31.14-.62.29-.92.46s-.6.35-.89.52-.56.38-.81.58-.49.41-.71.59l-.31.3-.14.15-.13.14c-.17.2-.33.37-.45.54s-.23.3-.31.43l-.25.38.24-.39c.07-.13.18-.27.29-.44a6.22 6.22 0 0 1 .44-.55l.12-.15.14-.15.3-.3c.23-.2.45-.42.71-.61a9.9 9.9 0 0 1 .81-.59c.29-.18.59-.37.9-.53s.62-.32.93-.46l.47-.2.46-.18.46-.17.44-.14c.28-.09.56-.16.81-.23s.49-.11.7-.16l.53-.11Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m53.52 210.62-4.42.35 1.35-2.39-.91 2.1 3.98-.06zM63.87 191.74l1-2.15-4.03.09 4.38-.32-1.35 2.38z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M271.81 339.2h8.47v17.3a22.78 22.78 0 0 1-.83 6.15c-.2.71-.44 1.41-.7 2.11a30.56 30.56 0 0 1-5.91 9.45 38.51 38.51 0 0 1-3 3 43.27 43.27 0 0 1-3.5 2.92 56.72 56.72 0 0 1-5.1 3.47 74.91 74.91 0 0 1-6 3.29c-1.58.78-3.2 1.51-4.88 2.19-24.53 10.15-58.36 11.38-85.36 3.15a89.94 89.94 0 0 1-5.4-1.81c-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.87-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75c.62-.67 1.27-1.34 2-2a54.76 54.76 0 0 1 9.33-7.08 74.22 74.22 0 0 1 6.67-3.6 88.62 88.62 0 0 1 9.44-3.84c22.17-7.67 50.41-8.7 74.41-2.74a106.84 106.84 0 0 1 10.37 3.11c2.79 1 5.49 2.1 8.08 3.32a71.33 71.33 0 0 1 10.61 5.99 48.27 48.27 0 0 1 7.32 6.2Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M181.65 396v-35.18l12.88 1.35v35.21c-4.34-.27-8.65-.73-12.88-1.38Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M280.28 356.5a22.78 22.78 0 0 1-.83 6.15c-.2.71-.44 1.41-.7 2.11a30.56 30.56 0 0 1-5.91 9.45c-.34.38-.7.75-1 1.12v-36.19l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m237.33 340.33 6.61-1.56v52.71q-3.16 1-6.47 1.92ZM220.29 396.63v-45.69l12.88.95v42.55a129.86 129.86 0 0 1-12.88 2.19ZM125.84 339.76h7.75c.62-.67 1.27-1.34 2-2a54.76 54.76 0 0 1 9.33-7.08 74.22 74.22 0 0 1 6.67-3.6 88.62 88.62 0 0 1 9.44-3.84c3.1-1.08 6.33-2 9.64-2.82l.06 73.4c-1.91-.48-3.8-1-5.65-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.87-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M130.4 370.22c-.45-.68-.87-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91V339.8h7.75l.1-.1 2.61.95v36.46a38.45 38.45 0 0 1-5.92-6.89Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M253.84 308.35a94.12 94.12 0 0 0-8.08-3.31 106.84 106.84 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a91.52 91.52 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93A23.2 23.2 0 0 0 126 342a24.9 24.9 0 0 0 3.11 9.13c.38.68.8 1.36 1.25 2a40.46 40.46 0 0 0 8.39 8.92q1.75 1.41 3.75 2.76c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.11-1 4.11-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a42.44 42.44 0 0 0 3-3 30.82 30.82 0 0 0 5.91-9.45c.26-.7.5-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.59 46.48 46.48 0 0 0-9.21-8.34 71.35 71.35 0 0 0-10.59-5.99Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M253.84 308.35a94.12 94.12 0 0 0-8.08-3.31 106.84 106.84 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a91.52 91.52 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93A23.2 23.2 0 0 0 126 342a24.9 24.9 0 0 0 3.11 9.13c.38.68.8 1.36 1.25 2a40.46 40.46 0 0 0 8.39 8.92q1.75 1.41 3.75 2.76c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.11-1 4.11-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a42.44 42.44 0 0 0 3-3 30.82 30.82 0 0 0 5.91-9.45c.26-.7.5-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.59 46.48 46.48 0 0 0-9.21-8.34 71.35 71.35 0 0 0-10.59-5.99Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M253.84 308.35a94.12 94.12 0 0 0-8.08-3.31 106.84 106.84 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a91.52 91.52 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93A23.2 23.2 0 0 0 126 342a24.9 24.9 0 0 0 3.11 9.13c.38.68.8 1.36 1.25 2a40.46 40.46 0 0 0 8.39 8.92q1.75 1.41 3.75 2.76c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.11-1 4.11-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a42.44 42.44 0 0 0 3-3 30.82 30.82 0 0 0 5.91-9.45c.26-.7.5-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.59 46.48 46.48 0 0 0-9.21-8.34 71.35 71.35 0 0 0-10.59-5.99Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M170.71 376.78c-1.92-.48-3.82-1-5.68-1.56s-3.64-1.16-5.41-1.82c-2.53-.92-5-1.93-7.35-3-1.93-.91-3.76-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.75-2.76a40.46 40.46 0 0 1-8.39-8.92c-.45-.67-.87-1.35-1.25-2A24.9 24.9 0 0 1 126 342a23.2 23.2 0 0 1 2.65-13.93 34.35 34.35 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.14 3.86-2.21 5.91-3.21Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M136.22 359.93a38.17 38.17 0 0 1-5.84-6.73c-.45-.67-.87-1.35-1.25-2A24.9 24.9 0 0 1 126 342a22.66 22.66 0 0 1 1.56-11.54ZM194.49 380.36c-4.34-.26-8.64-.73-12.86-1.39L159.92 305l1.05-.38c3.34-1.15 6.83-2.14 10.41-3ZM233.15 377.43c-4.18.93-8.5 1.66-12.88 2.19l-23.89-81.38c4.49-.2 9-.18 13.53 0ZM243.92 374.45c-2.13.71-4.3 1.35-6.52 1.94l-22.84-77.81c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M257.66 310.29c1 .54 2 1.09 2.93 1.65l.94.57.94.59c.69.44 1.36.89 2 1.34a46.48 46.48 0 0 1 9.21 8.34 27.44 27.44 0 0 1 6.37 13.59 22.36 22.36 0 0 1-.63 9.26c-.19.71-.43 1.41-.69 2.11a30.82 30.82 0 0 1-5.91 9.45c-.34.38-.7.76-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M240.71 316.17c-23.06-10.71-57.42-9.91-78.55 1.48-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.38s60.37 9.83 81.17-3c.84-.52 1.66-1.06 2.43-1.6 18.12-12.76 14.76-31.07-8.29-41.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M240.71 316.17c-23.06-10.71-57.42-9.91-78.55 1.48-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.38s60.37 9.83 81.17-3c.84-.52 1.66-1.06 2.43-1.6 18.12-12.76 14.76-31.07-8.29-41.77Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M239 317.2c-.74-.35-1.5-.68-2.27-1-22-9.16-53.27-8.1-72.78 2.42l-.52.28c-.68.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.61 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a74.24 74.24 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64A45.77 45.77 0 0 0 239 317.2Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M239 317.2c-.74-.35-1.5-.68-2.27-1-22-9.16-53.27-8.1-72.78 2.42l-.52.28c-.68.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.61 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a74.24 74.24 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64A45.77 45.77 0 0 0 239 317.2Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M236.79 322.66c.77.32 1.53.66 2.27 1a45.51 45.51 0 0 1 11.25 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.1-9.49 11.67l-.61.44c-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a74.24 74.24 0 0 1-7.55-2.52c-1.15-.45-2.28-.94-3.38-1.45s-2.34-1.13-3.43-1.74c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.31-.78 2-1.16l.53-.28c19.53-10.48 50.83-11.54 72.8-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M236.79 322.66c.77.32 1.53.66 2.27 1a45.51 45.51 0 0 1 11.25 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.1-9.49 11.67l-.61.44c-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a74.24 74.24 0 0 1-7.55-2.52c-1.15-.45-2.28-.94-3.38-1.45s-2.34-1.13-3.43-1.74c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.31-.78 2-1.16l.53-.28c19.53-10.48 50.83-11.54 72.8-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M278.74 347.74c-8.74 19.43-33.19 27.18-53.12 30.68-30.3 4.35-63.28.71-86.71-16.54h.05c-6.37-5-11.8-11.74-12.94-19.88 1 8.23 6.24 15.18 12.61 20.33 18.87 14.63 45 19.37 70 18.67 25.15-1.47 58.68-8.25 70.11-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M171.29 353.68c8.56 3.58 17 2 24.77-.74l-2.06 2.12V364a37.08 37.08 0 0 0 5.29.86 38.06 38.06 0 0 0 9.21-.35 49.21 49.21 0 0 0 14.35-4.72l7.54 3.17 10.18-6.27v-8.89l-1.91-.81-.42-.18a8.08 8.08 0 0 0 .21-2.07 5.22 5.22 0 0 0-.06-.71c-.13-1.09.1-6.11.06-8.18 0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.14.15h-.13l-1.2.19c-.35.06-.7.12-1.05.21-.14 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-.93.33-1.86.68-2.78 1l2.69-2.8v-8.9c-8.67-1.65-17.92-.25-27 4.2l-7.56-3.1-10.07 6.43v8.89l2.36 1a5.51 5.51 0 0 0-.22 1.68c.08 1.38-.06 7.26 0 8.82v.07c.16 2.61 2.31 4.92 6.77 6.79Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M238.46 336.21c0 2.28-.17 6.75-.05 7.77 0 .24 0 .46.06.7a8.33 8.33 0 0 1-.22 2.09l-5.42-2.28s5.47-2.64 5.63-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M183.73 336.07c-2.26-.94-2.64-2.71 1.52-5.39 4.46-2.82 9.05-3.56 12.95-2.58l8.82-9.19v8.87l-2.67 2.81-.27.1c-1.45.56-2.85 1.13-4.19 1.68-.54.24-1.07.47-1.59.67a55.34 55.34 0 0 1-8.89 3.27 10 10 0 0 1-3.86.26 7.16 7.16 0 0 1-1.82-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m164.73 336.24-2.34-1v-8.87l7.51 3.15c-2.79 2.33-4.58 4.59-5.17 6.72Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m230.39 354 10.19-6.26v8.88l-10.2 6.29-7.52-3.18a48.78 48.78 0 0 1-14.36 4.71A36.47 36.47 0 0 1 194 364v-8.89a36.15 36.15 0 0 0 14.5.51 48.79 48.79 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M222.87 359.74a37.16 37.16 0 0 1-11.44 4.68 30.51 30.51 0 0 1-12.13.39 57.88 57.88 0 0 0 12-1.39c2-.43 3.9-1 5.84-1.61s3.8-1.34 5.73-2.07Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M164.53 346.88v-.05c-.06-1.57.07-7.44 0-8.84.15 2.63 2.28 4.94 6.74 6.81 9.94 4.14 19.61 1.41 28.46-2.13 8.21-3.33 14.18-6.86 19-4.86 3 1.26 2.15 3.26-1.13 5.36a18.14 18.14 0 0 1-14.2 2.23l-7.33 7.6c-7.84 2.77-16.22 4.3-24.76.72-4.49-1.9-6.62-4.22-6.78-6.84Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m198.21 336.99-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m198.21 336.99-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m162.38 326.38 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m162.38 326.38 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m238.67 346.93-5.83-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.14.15h-.13l-1.2.19c-.35.06-.7.12-1.05.21-.14 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10 10 0 0 1-3.84.27 7.82 7.82 0 0 1-1.81-.5c-2.28-.95-2.66-2.72 1.52-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.59-3.11-10.07 6.43 7.54 3.14c-3.59 3-5.49 5.84-5.39 8.41v.02c.15 2.61 2.29 4.93 6.76 6.79 9.91 4.16 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.13 3.25-1.16 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.37 9.65a37.45 37.45 0 0 0 5.3.86 38.08 38.08 0 0 0 9.21-.34 49.26 49.26 0 0 0 14.34-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m238.67 346.93-5.83-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.14.15h-.13l-1.2.19c-.35.06-.7.12-1.05.21-.14 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10 10 0 0 1-3.84.27 7.82 7.82 0 0 1-1.81-.5c-2.28-.95-2.66-2.72 1.52-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.59-3.11-10.07 6.43 7.54 3.14c-3.59 3-5.49 5.84-5.39 8.41v.02c.15 2.61 2.29 4.93 6.76 6.79 9.91 4.16 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.13 3.25-1.16 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.37 9.65a37.45 37.45 0 0 0 5.3.86 38.08 38.08 0 0 0 9.21-.34 49.26 49.26 0 0 0 14.34-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M171.29 344.79a28.41 28.41 0 0 0 11.8 1.84 43.44 43.44 0 0 0 12-2.55c4-1.3 7.74-3 11.61-4.63a40 40 0 0 1 6-2 9.88 9.88 0 0 1 6 .35 9.91 9.91 0 0 0-6-.09 41.33 41.33 0 0 0-5.85 2.12c-3.82 1.66-7.63 3.4-11.6 4.76a42.34 42.34 0 0 1-12.23 2.45 28.27 28.27 0 0 1-6.11-.46 28.62 28.62 0 0 1-5.62-1.79Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m230.39 354.02.01 8.89-7.54-3.17-.01-8.89 7.54 3.17z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m230.39 354.02.01 8.89-7.54-3.17-.01-8.89 7.54 3.17z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m240.58 347.75-10.15 5.27-7.58-2.17 7.54 3.17 10.19-6.27zM179.99 323.1l-7.5-2.22-10.11 5.5 10.07-6.43 7.54 3.15z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M207 318.9a37.45 37.45 0 0 0-4.73-.56l1.23 4.21ZM171.63 344.92a27.57 27.57 0 0 0 13 1.83L177.35 322l-4.9-2.05-6.87 4.39 1.14 3.85 3.2 1.33a26.31 26.31 0 0 0-2.21 2.05ZM230.29 328a26 26 0 0 0-7.49-1.37l8 27.12 6-3.69-1.33-4.52-2.6-1.1a11 11 0 0 0 1.9-1.27ZM217.07 327l-1.2.19c-.35.06-.7.12-1.05.21-.14 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91l-1.39.53 2.63 9c4.05-1.6 7.43-2.44 10.38-1.21s2.13 3.25-1.15 5.35a17.71 17.71 0 0 1-7.25 2.62l2.6 8.84a52 52 0 0 0 9.92-3.78l2.84 1.2-7.4-25.23-1.09.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M261.69 318.15h8.47v17.33a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46 42.34 42.34 0 0 1-3 3 45.44 45.44 0 0 1-3.5 2.92 58.9 58.9 0 0 1-5.1 3.47 78.196 78.196 0 0 1-6 3.29c-1.58.77-3.2 1.5-4.88 2.19-24.52 10.13-58.33 11.36-85.31 3.14q-2.76-.84-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05q-2.91-1.36-5.5-2.83c-1.49-.86-2.91-1.73-4.24-2.64s-2.59-1.82-3.76-2.76a40.66 40.66 0 0 1-8.38-8.93c-.45-.67-.88-1.35-1.26-2a24.91 24.91 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 89.34 89.34 0 0 1 9.43-3.85c22.18-7.67 50.42-8.7 74.42-2.74a106.86 106.86 0 0 1 10.37 3.12c2.79 1 5.49 2.1 8.08 3.31a71.33 71.33 0 0 1 10.64 6.08 49.44 49.44 0 0 1 7.24 6.13Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M171.53 374.94v-35.18l12.88 1.35v35.22c-4.34-.27-8.65-.73-12.88-1.39Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M270.16 335.45a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46c-.34.37-.7.75-1.06 1.12v-36.2l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m227.21 319.28 6.61-1.56v52.72c-2.11.7-4.27 1.33-6.47 1.91ZM210.17 375.59v-45.7l12.88.95v42.55a129.86 129.86 0 0 1-12.88 2.2ZM115.72 318.72h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 89.34 89.34 0 0 1 9.43-3.85q4.66-1.61 9.65-2.81l.06 73.39c-1.91-.47-3.8-1-5.65-1.55s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05q-2.91-1.36-5.5-2.83c-1.49-.86-2.91-1.73-4.24-2.64s-2.59-1.82-3.76-2.76a40.66 40.66 0 0 1-8.38-8.93c-.45-.67-.88-1.35-1.26-2a24.91 24.91 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M120.28 349.17c-.45-.67-.88-1.35-1.26-2a24.91 24.91 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75l.1-.1 2.61 1v36.45a38.1 38.1 0 0 1-5.92-6.93Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M243.72 287.31a91.007 91.007 0 0 0-8.08-3.31 107.71 107.71 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.87 8.93A23.13 23.13 0 0 0 115.9 321a25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.28 40.28 0 0 0 8.39 8.93q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29t5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.62-6.02Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M243.72 287.31a91.007 91.007 0 0 0-8.08-3.31 107.71 107.71 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.87 8.93A23.13 23.13 0 0 0 115.9 321a25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.28 40.28 0 0 0 8.39 8.93q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29t5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.62-6.02Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M243.72 287.31a91.007 91.007 0 0 0-8.08-3.31 107.71 107.71 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.87 8.93A23.13 23.13 0 0 0 115.9 321a25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.28 40.28 0 0 0 8.39 8.93q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29t5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.62-6.02Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M160.59 355.74c-1.92-.48-3.82-1-5.68-1.57s-3.64-1.16-5.41-1.81c-2.53-.92-5-1.94-7.35-3-1.93-.91-3.77-1.86-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.77a40.28 40.28 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.19 23.13 23.13 0 0 1 2.65-13.93 34.19 34.19 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.15 3.86-2.21 5.91-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M126.1 338.88a37.82 37.82 0 0 1-5.84-6.73c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.15 22.66 22.66 0 0 1 1.56-11.54ZM184.37 359.32c-4.34-.27-8.64-.73-12.86-1.39L149.8 284l1-.38a107.42 107.42 0 0 1 10.41-3ZM223 356.38a127 127 0 0 1-12.87 2.19l-23.87-81.37c4.49-.2 9-.19 13.53 0ZM233.8 353.41c-2.13.7-4.3 1.35-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M247.54 289.24c1 .54 2 1.1 2.93 1.66l.94.56.94.6c.68.44 1.36.88 2 1.33a46.48 46.48 0 0 1 9.21 8.34 27.47 27.47 0 0 1 6.37 13.6 22.37 22.37 0 0 1-.63 9.26q-.3 1.05-.69 2.1a30.71 30.71 0 0 1-5.92 9.46c-.33.38-.69.75-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M230.58 295.12c-23.05-10.7-57.41-9.9-78.54 1.48-.89.49-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.84-.53 1.66-1.07 2.42-1.61 18.13-12.76 14.77-31.07-8.29-41.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M230.58 295.12c-23.05-10.7-57.41-9.9-78.54 1.48-.89.49-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.84-.53 1.66-1.07 2.42-1.61 18.13-12.76 14.77-31.07-8.29-41.77Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M228.91 296.15c-.74-.34-1.5-.67-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.4 34.4 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.94 2.57 4 6.76 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.05 71.05 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.52l.61-.45c4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.49 45.49 0 0 0-11.29-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M228.91 296.15c-.74-.34-1.5-.67-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.4 34.4 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.94 2.57 4 6.76 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.05 71.05 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.52l.61-.45c4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.49 45.49 0 0 0-11.29-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M226.66 301.62c.78.32 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.68 7.4 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.63 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.05 71.05 0 0 1-7.55-2.51c-1.15-.46-2.28-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a19 19 0 0 1-2.69-6.37 19.54 19.54 0 0 1 3.87-8.1 34.45 34.45 0 0 1 9.22-7.94c.64-.4 1.3-.78 2-1.15l.53-.29c19.53-10.48 50.83-11.55 72.79-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M226.66 301.62c.78.32 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.68 7.4 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.63 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.05 71.05 0 0 1-7.55-2.51c-1.15-.46-2.28-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a19 19 0 0 1-2.69-6.37 19.54 19.54 0 0 1 3.87-8.1 34.45 34.45 0 0 1 9.22-7.94c.64-.4 1.3-.78 2-1.15l.53-.29c19.53-10.48 50.83-11.55 72.79-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M268.62 326.69c-8.74 19.44-33.19 27.19-53.12 30.69-30.3 4.35-63.28.7-86.71-16.54h.05c-6.37-5-11.8-11.73-12.94-19.87 1 8.22 6.24 15.18 12.61 20.32 18.87 14.62 45 19.36 70 18.66 25.15-1.47 58.68-8.24 70.11-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M161.17 332.63c8.56 3.59 17 2 24.77-.73l-2.06 2.1v8.89a37.08 37.08 0 0 0 5.29.86 38.08 38.08 0 0 0 9.21-.34 49.22 49.22 0 0 0 14.35-4.73l7.54 3.17 10.18-6.27v-8.88l-1.91-.82-.42-.17a8.2 8.2 0 0 0 .21-2.08 5.07 5.07 0 0 0-.06-.7c-.13-1.1.1-6.11.06-8.19 0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.14 0-.29.05-.42.09a6.66 6.66 0 0 0-.8.18 60.25 60.25 0 0 0-6.47 1.92c-.94.33-1.87.68-2.78 1l2.69-2.8v-8.89c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43v8.88l2.36 1a5.18 5.18 0 0 0-.22 1.68v.14c.07 1.37-.06 7.25 0 8.81v.07c.12 2.58 2.27 4.89 6.73 6.75Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M228.33 315.17c0 2.27-.16 6.74-.05 7.76 0 .24.05.47.07.71a8.25 8.25 0 0 1-.22 2.08l-5.42-2.28s5.47-2.64 5.62-8.27Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M173.61 315c-2.26-.95-2.64-2.71 1.52-5.39 4.46-2.83 9.05-3.56 13-2.59l8.82-9.19v8.88l-2.66 2.8c-.1 0-.17.08-.27.11-1.45.55-2.85 1.12-4.19 1.68q-.81.36-1.59.66a55 55 0 0 1-8.9 3.27 10 10 0 0 1-3.85.27 7.16 7.16 0 0 1-1.88-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m154.61 315.19-2.34-1v-8.88l7.51 3.15c-2.78 2.34-4.58 4.61-5.17 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m220.27 333 10.19-6.26v8.89l-10.2 6.28-7.52-3.18a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51V334a36.47 36.47 0 0 0 14.5.5 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M212.75 338.69a37.16 37.16 0 0 1-11.44 4.68 30.33 30.33 0 0 1-12.13.4 58.41 58.41 0 0 0 11.95-1.4c2-.43 3.91-1 5.85-1.6s3.84-1.35 5.77-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M154.41 325.83v-.05c-.07-1.56.07-7.44 0-8.83.16 2.62 2.29 4.94 6.75 6.8 9.94 4.14 19.61 1.42 28.46-2.13 8.21-3.32 14.18-6.86 19-4.86 3 1.27 2.15 3.27-1.13 5.36a18.15 18.15 0 0 1-14.2 2.24L186 331.9c-7.84 2.77-16.22 4.3-24.76.73-4.55-1.86-6.67-4.17-6.83-6.8Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M188.08 315.94v-8.89l8.81-9.19.01 8.88-8.82 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M188.08 315.94v-8.89l8.81-9.19.01 8.88-8.82 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m152.26 305.34 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m152.26 305.34 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m228.55 325.89-5.83-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.29.05-.42.09a6.66 6.66 0 0 0-.8.18 60.25 60.25 0 0 0-6.48 1.91c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7l-1.59.67a57.86 57.86 0 0 1-8.9 3.26 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-1-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43 7.54 3.14c-3.6 3-5.49 5.83-5.39 8.41v.11c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.44 9.58a35.9 35.9 0 0 0 5.3.87 38.08 38.08 0 0 0 9.21-.35 49.25 49.25 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m228.55 325.89-5.83-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.29.05-.42.09a6.66 6.66 0 0 0-.8.18 60.25 60.25 0 0 0-6.48 1.91c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7l-1.59.67a57.86 57.86 0 0 1-8.9 3.26 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-1-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43 7.54 3.14c-3.6 3-5.49 5.83-5.39 8.41v.11c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.44 9.58a35.9 35.9 0 0 0 5.3.87 38.08 38.08 0 0 0 9.21-.35 49.25 49.25 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M161.17 323.75a28.41 28.41 0 0 0 11.8 1.83A43.09 43.09 0 0 0 185 323c4-1.3 7.74-3 11.61-4.62a39 39 0 0 1 6-2 9.84 9.84 0 0 1 6 .35 9.8 9.8 0 0 0-6-.08 40.08 40.08 0 0 0-5.85 2.11c-3.82 1.67-7.63 3.41-11.6 4.76A42.35 42.35 0 0 1 173 326a28.91 28.91 0 0 1-6.11-.46 29.29 29.29 0 0 1-5.72-1.79Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m220.27 332.98.01 8.88-7.54-3.17-.01-8.88 7.54 3.17z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m220.27 332.98.01 8.88-7.54-3.17-.01-8.88 7.54 3.17z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m230.46 326.71-10.15 5.27-7.58-2.17 7.54 3.17 10.19-6.27zM169.87 302.05l-7.5-2.22-10.11 5.51 10.06-6.43 7.55 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M196.89 297.86a37.46 37.46 0 0 0-4.73-.57l1.23 4.22ZM161.51 323.88a27.56 27.56 0 0 0 13 1.82l-7.28-24.7-4.9-2-6.87 4.38 1.14 3.85 3.2 1.34a28.06 28.06 0 0 0-2.21 2ZM220.17 307a26 26 0 0 0-7.49-1.36l8 27.11 6-3.69-1.33-4.52-2.6-1.09a10.71 10.71 0 0 0 1.9-1.28ZM207 305.93l-1.2.19c-.35.06-.7.12-1.05.2-.14 0-.29.05-.42.09a6.66 6.66 0 0 0-.8.18 60.25 60.25 0 0 0-6.53 1.91c-.47.16-.93.35-1.39.52l2.63 9c4.05-1.6 7.43-2.45 10.37-1.22s2.14 3.25-1.15 5.36a17.59 17.59 0 0 1-7.24 2.61l2.6 8.84a51.31 51.31 0 0 0 9.92-3.77l2.84 1.19-7.4-25.22c-.36 0-.74.1-1.1.13Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M265.13 297.11h8.47v17.34a23.18 23.18 0 0 1-.83 6.15c-.2.71-.44 1.41-.7 2.11a30.56 30.56 0 0 1-5.91 9.45 40.34 40.34 0 0 1-3 3 43.27 43.27 0 0 1-3.5 2.92 56.72 56.72 0 0 1-5.1 3.47 74.91 74.91 0 0 1-6 3.29c-1.58.78-3.2 1.51-4.88 2.19-24.52 10.14-58.33 11.37-85.31 3.14a89.94 89.94 0 0 1-5.4-1.81c-2.53-.92-5-1.93-7.35-3-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75c.62-.67 1.27-1.34 2-2a54.76 54.76 0 0 1 9.33-7.08 74.22 74.22 0 0 1 6.67-3.6 89.3 89.3 0 0 1 9.43-3.84c22.18-7.67 50.42-8.7 74.42-2.74a106.84 106.84 0 0 1 10.37 3.11c2.79 1 5.49 2.1 8.08 3.32a71.33 71.33 0 0 1 10.64 6.08 48.76 48.76 0 0 1 7.24 6.08Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M175 353.9v-35.18l12.88 1.35v35.21c-4.37-.28-8.68-.72-12.88-1.38Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M273.6 314.41a23.18 23.18 0 0 1-.83 6.15c-.2.71-.44 1.41-.7 2.11a30.56 30.56 0 0 1-5.91 9.45c-.34.38-.7.75-1.06 1.12v-36.19l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m230.65 298.24 6.61-1.56v52.71q-3.16 1-6.47 1.92ZM213.61 354.54v-45.69l12.88.95v42.55a129.86 129.86 0 0 1-12.88 2.19ZM119.16 297.67h7.75c.62-.67 1.27-1.34 2-2a54.76 54.76 0 0 1 9.33-7.08 74.22 74.22 0 0 1 6.67-3.6 89.3 89.3 0 0 1 9.43-3.84c3.11-1.08 6.33-2 9.64-2.82l.07 73.4c-1.91-.48-3.8-1-5.65-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.93-7.35-3-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M123.72 328.13c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75l.1-.1 2.61.95v36.46a38.45 38.45 0 0 1-5.92-6.89Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M247.16 266.26a94.12 94.12 0 0 0-8.08-3.31 107.69 107.69 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a90.62 90.62 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07A34.35 34.35 0 0 0 122 286a23.13 23.13 0 0 0-2.66 14 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.36 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29a60.27 60.27 0 0 0 5.09-3.47c1.26-1 2.43-1.93 3.51-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.92-9.45c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.58 46.28 46.28 0 0 0-9.22-8.34 70.72 70.72 0 0 0-10.62-6.09Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M247.16 266.26a94.12 94.12 0 0 0-8.08-3.31 107.69 107.69 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a90.62 90.62 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07A34.35 34.35 0 0 0 122 286a23.13 23.13 0 0 0-2.66 14 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.36 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29a60.27 60.27 0 0 0 5.09-3.47c1.26-1 2.43-1.93 3.51-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.92-9.45c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.58 46.28 46.28 0 0 0-9.22-8.34 70.72 70.72 0 0 0-10.62-6.09Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M247.16 266.26a94.12 94.12 0 0 0-8.08-3.31 107.69 107.69 0 0 0-10.37-3.11c-24-6-52.24-4.93-74.42 2.73a90.62 90.62 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07A34.35 34.35 0 0 0 122 286a23.13 23.13 0 0 0-2.66 14 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.36 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29a60.27 60.27 0 0 0 5.09-3.47c1.26-1 2.43-1.93 3.51-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.92-9.45c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.44 27.44 0 0 0-6.37-13.58 46.28 46.28 0 0 0-9.22-8.34 70.72 70.72 0 0 0-10.62-6.09Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M164 334.69c-1.92-.48-3.82-1-5.68-1.56s-3.64-1.16-5.41-1.81c-2.53-.93-5-1.94-7.35-3.05-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.23 40.23 0 0 1-8.39-8.92c-.45-.67-.87-1.35-1.26-2a25.06 25.06 0 0 1-3.1-9.13A23.13 23.13 0 0 1 122 286a34.35 34.35 0 0 1 6.87-8.93 54.34 54.34 0 0 1 9.33-7.07c1.88-1.14 3.86-2.21 5.91-3.21Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M129.53 317.84a38.51 38.51 0 0 1-5.83-6.73c-.45-.67-.87-1.35-1.26-2a25.06 25.06 0 0 1-3.1-9.13 22.66 22.66 0 0 1 1.56-11.54ZM187.81 338.27c-4.34-.26-8.64-.73-12.87-1.39L153.24 263l1-.38c3.34-1.15 6.83-2.14 10.41-3ZM226.46 335.34c-4.18.93-8.49 1.66-12.87 2.19l-23.89-81.38c4.49-.2 9-.18 13.53 0ZM237.24 332.36c-2.13.71-4.3 1.35-6.52 1.94l-22.84-77.81c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M251 268.2c1 .54 2 1.09 2.93 1.65l.94.57.94.59c.68.44 1.36.89 2 1.34a46.28 46.28 0 0 1 9.22 8.34 27.44 27.44 0 0 1 6.37 13.59 22.36 22.36 0 0 1-.63 9.26c-.2.71-.43 1.41-.69 2.11a30.67 30.67 0 0 1-5.92 9.45c-.33.38-.69.76-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M234 274.08c-23.05-10.71-57.41-9.91-78.54 1.48-.89.48-1.78 1-2.63 1.51-20.79 12.81-18.17 32.23 5.86 43.38s60.38 9.83 81.18-3c.84-.52 1.66-1.06 2.42-1.6 18.15-12.76 14.79-31.07-8.29-41.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M234 274.08c-23.05-10.71-57.41-9.91-78.54 1.48-.89.48-1.78 1-2.63 1.51-20.79 12.81-18.17 32.23 5.86 43.38s60.38 9.83 81.18-3c.84-.52 1.66-1.06 2.42-1.6 18.15-12.76 14.79-31.07-8.29-41.77Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M232.35 275.11c-.74-.35-1.5-.68-2.27-1-22-9.16-53.27-8.1-72.78 2.42l-.53.28c-.67.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a73.42 73.42 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.28-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M232.35 275.11c-.74-.35-1.5-.68-2.27-1-22-9.16-53.27-8.1-72.78 2.42l-.53.28c-.67.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a73.42 73.42 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.28-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M230.1 280.57c.78.32 1.53.66 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.1-9.49 11.67l-.61.44c-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a73.42 73.42 0 0 1-7.55-2.52 58.17 58.17 0 0 1-6.81-3.19c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.3-.78 2-1.16l.53-.28c19.53-10.48 50.83-11.54 72.79-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M230.1 280.57c.78.32 1.53.66 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.1-9.49 11.67l-.61.44c-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a73.42 73.42 0 0 1-7.55-2.52 58.17 58.17 0 0 1-6.81-3.19c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.3-.78 2-1.16l.53-.28c19.53-10.48 50.83-11.54 72.79-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M272.06 305.65c-8.74 19.43-33.19 27.18-53.12 30.68-30.3 4.35-63.28.71-86.71-16.54h.05c-6.37-5-11.8-11.74-12.94-19.88 1 8.23 6.24 15.18 12.61 20.33 18.87 14.63 45 19.37 70 18.66 25.15-1.46 58.68-8.24 70.11-33.25Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M164.61 311.59c8.56 3.58 16.95 2 24.77-.74l-2.06 2.15v8.89a37.08 37.08 0 0 0 5.29.86 38.08 38.08 0 0 0 9.21-.35 49 49 0 0 0 14.34-4.72l7.55 3.17 10.18-6.27v-8.89l-1.92-.81-.41-.18a8.08 8.08 0 0 0 .21-2.07 5.22 5.22 0 0 0-.06-.71c-.13-1.09.09-6.11.06-8.18 0-.25 0-.48-.07-.7-.3-2.49-2.36-4.72-6.58-6.47a27.18 27.18 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.21-.15 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-.94.33-1.87.68-2.78 1.05l2.69-2.8v-8.89c-8.67-1.65-17.92-.25-27 4.2l-7.55-3.15-10.06 6.43v8.89l2.36 1a5.22 5.22 0 0 0-.22 1.68v.07c.07 1.38-.06 7.26 0 8.82v.07c.14 2.55 2.29 4.86 6.75 6.73Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M231.77 294.12c0 2.28-.16 6.75-.05 7.77 0 .24.05.46.07.7a8.33 8.33 0 0 1-.22 2.09l-5.42-2.28s5.47-2.64 5.62-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M177.05 294c-2.26-.94-2.64-2.71 1.52-5.39 4.46-2.82 9.05-3.56 13-2.58l8.82-9.19v8.87l-2.66 2.81-.27.1c-1.45.56-2.85 1.13-4.19 1.68-.54.24-1.07.47-1.59.67a55 55 0 0 1-8.9 3.27 10 10 0 0 1-3.85.26 7.16 7.16 0 0 1-1.88-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m158.05 294.15-2.35-1v-8.87l7.51 3.15c-2.78 2.33-4.57 4.57-5.16 6.72Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m223.71 311.92 10.19-6.26v8.88l-10.2 6.29-7.52-3.18a48.78 48.78 0 0 1-14.36 4.71 36.47 36.47 0 0 1-14.5-.5V313a36.15 36.15 0 0 0 14.5.51 48.79 48.79 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M216.19 317.65a37.16 37.16 0 0 1-11.44 4.68 30.51 30.51 0 0 1-12.13.39 57.79 57.79 0 0 0 11.95-1.39c2-.43 3.91-1 5.85-1.61s3.84-1.34 5.77-2.07Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M157.85 304.79v-.05c-.07-1.57.07-7.44 0-8.84.16 2.63 2.29 4.94 6.75 6.81 9.94 4.14 19.61 1.41 28.46-2.13 8.21-3.33 14.18-6.86 19-4.86 3 1.26 2.15 3.26-1.13 5.36a18.14 18.14 0 0 1-14.2 2.23l-7.32 7.55c-7.83 2.77-16.21 4.3-24.75.72-4.53-1.85-6.66-4.17-6.81-6.79Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M191.52 294.89v-8.88l8.81-9.2.01 8.89-8.82 9.19z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M191.52 294.89v-8.88l8.81-9.2.01 8.89-8.82 9.19z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m155.7 284.29 7.54 3.15-5.17 6.72-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m155.7 284.29 7.54 3.15-5.17 6.72-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m232 304.84-5.82-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.3-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.21-.15 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-.95-2.65-2.72 1.53-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.15-10.07 6.43 7.54 3.14c-3.6 3-5.49 5.84-5.39 8.41v.07c.14 2.61 2.29 4.93 6.76 6.79 9.91 4.16 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.14 3.25-1.15 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.45 9.67a37.22 37.22 0 0 0 5.3.86 38 38 0 0 0 9.2-.34 49.22 49.22 0 0 0 14.35-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m232 304.84-5.82-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.3-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.21-.15 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-.95-2.65-2.72 1.53-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.15-10.07 6.43 7.54 3.14c-3.6 3-5.49 5.84-5.39 8.41v.07c.14 2.61 2.29 4.93 6.76 6.79 9.91 4.16 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.14 3.25-1.15 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.45 9.67a37.22 37.22 0 0 0 5.3.86 38 38 0 0 0 9.2-.34 49.22 49.22 0 0 0 14.35-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M164.61 302.7a28.41 28.41 0 0 0 11.8 1.84 43.44 43.44 0 0 0 12-2.55c4-1.3 7.74-3 11.61-4.63a40 40 0 0 1 6-2 9.84 9.84 0 0 1 6 .35 9.88 9.88 0 0 0-6-.09 41.33 41.33 0 0 0-5.85 2.12c-3.82 1.66-7.63 3.4-11.6 4.76a42.34 42.34 0 0 1-12.18 2.5 28.27 28.27 0 0 1-6.11-.46 28.62 28.62 0 0 1-5.67-1.84Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m223.71 311.93.01 8.89-7.54-3.17-.01-8.89 7.54 3.17z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m223.71 311.93.01 8.89-7.54-3.17-.01-8.89 7.54 3.17z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m233.9 305.66-10.15 5.27-7.58-2.17 7.54 3.17 10.19-6.27zM173.31 281.01l-7.5-2.22-10.11 5.5 10.06-6.42 7.55 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M200.33 276.81a37.39 37.39 0 0 0-4.74-.56l1.24 4.21ZM165 302.83a27.57 27.57 0 0 0 13 1.83l-7.27-24.75-4.9-2.05-6.87 4.39 1.04 3.85 3.21 1.33a24.84 24.84 0 0 0-2.21 2.05ZM223.61 286a26 26 0 0 0-7.49-1.37l8 27.12 6-3.69-1.33-4.52-2.6-1.1a11 11 0 0 0 1.9-1.27ZM210.39 284.88l-1.2.19c-.35.06-.7.12-1.05.21-.15 0-.29.05-.42.09s-.55.1-.8.17a62.61 62.61 0 0 0-6.49 1.91L199 288l2.63 9c4.05-1.6 7.43-2.44 10.37-1.21s2.14 3.25-1.15 5.35a17.62 17.62 0 0 1-7.24 2.62l2.6 8.84a52 52 0 0 0 9.92-3.78L219 310l-7.4-25.23-1.1.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M281.69 269h8.47v17.33a22.46 22.46 0 0 1-.83 6.16c-.2.7-.43 1.4-.7 2.1a30.45 30.45 0 0 1-5.91 9.45 38.43 38.43 0 0 1-3 3c-1.08 1-2.25 2-3.51 2.92a60.27 60.27 0 0 1-5.09 3.47 80.125 80.125 0 0 1-6 3.29c-1.57.77-3.2 1.5-4.87 2.19-24.53 10.13-58.34 11.36-85.31 3.14q-2.77-.84-5.41-1.81c-2.53-.93-5-1.94-7.35-3.05-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.23 40.23 0 0 1-8.39-8.92c-.45-.67-.87-1.35-1.26-2a25.06 25.06 0 0 1-3.1-9.13 21.66 21.66 0 0 1-.18-2.91v-16.39h7.76c.61-.67 1.26-1.34 2-2a54.44 54.44 0 0 1 9.34-7.07 71.59 71.59 0 0 1 6.67-3.6 87.63 87.63 0 0 1 9.43-3.85c22.17-7.67 50.42-8.7 74.41-2.74a105.14 105.14 0 0 1 10.38 3.12c2.78 1 5.48 2.1 8.07 3.31a71.33 71.33 0 0 1 10.64 6.08 48.35 48.35 0 0 1 7.24 6.14Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M191.53 325.8v-35.18l12.85 1.38v35.21q-6.48-.42-12.85-1.41Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M290.16 286.31a22.46 22.46 0 0 1-.83 6.16c-.2.7-.43 1.4-.7 2.1a30.45 30.45 0 0 1-5.91 9.45c-.33.38-.69.75-1.05 1.13v-36.2l.05.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m247.21 270.14 6.61-1.56v52.72c-2.1.69-4.26 1.33-6.46 1.91ZM230.17 326.45v-45.7l12.88.95v42.55a129.56 129.56 0 0 1-12.88 2.2ZM135.72 269.57h7.76c.61-.67 1.26-1.34 2-2a54.44 54.44 0 0 1 9.34-7.07 71.59 71.59 0 0 1 6.67-3.6 87.63 87.63 0 0 1 9.43-3.85c3.1-1.07 6.33-2 9.64-2.81l.06 73.39q-2.87-.71-5.64-1.55t-5.41-1.81c-2.53-.93-5-1.94-7.35-3.05-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.23 40.23 0 0 1-8.39-8.92c-.45-.67-.87-1.35-1.26-2a25.06 25.06 0 0 1-3.1-9.13 21.66 21.66 0 0 1-.18-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M140.28 300c-.45-.67-.87-1.35-1.26-2a25.06 25.06 0 0 1-3.1-9.13 21.66 21.66 0 0 1-.18-2.91v-16.39h7.76l.09-.1 2.61.95v36.46a38.1 38.1 0 0 1-5.92-6.88Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M263.72 238.17a92.05 92.05 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.76 90.76 0 0 0-9.44 3.84 75.26 75.26 0 0 0-6.66 3.61 54.44 54.44 0 0 0-9.34 7.07 34.46 34.46 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.65 13.93A24.91 24.91 0 0 0 139 281c.38.68.81 1.36 1.25 2a40.5 40.5 0 0 0 8.39 8.93c1.17.94 2.42 1.87 3.75 2.77s2.75 1.77 4.25 2.63c1.73 1 3.56 1.93 5.5 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.38 27.38 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.61-6.08Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M263.72 238.17a92.05 92.05 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.76 90.76 0 0 0-9.44 3.84 75.26 75.26 0 0 0-6.66 3.61 54.44 54.44 0 0 0-9.34 7.07 34.46 34.46 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.65 13.93A24.91 24.91 0 0 0 139 281c.38.68.81 1.36 1.25 2a40.5 40.5 0 0 0 8.39 8.93c1.17.94 2.42 1.87 3.75 2.77s2.75 1.77 4.25 2.63c1.73 1 3.56 1.93 5.5 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.38 27.38 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.61-6.08Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M263.72 238.17a92.05 92.05 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.76 90.76 0 0 0-9.44 3.84 75.26 75.26 0 0 0-6.66 3.61 54.44 54.44 0 0 0-9.34 7.07 34.46 34.46 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.65 13.93A24.91 24.91 0 0 0 139 281c.38.68.81 1.36 1.25 2a40.5 40.5 0 0 0 8.39 8.93c1.17.94 2.42 1.87 3.75 2.77s2.75 1.77 4.25 2.63c1.73 1 3.56 1.93 5.5 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.38 27.38 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.61-6.08Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M180.59 306.59c-1.92-.47-3.82-1-5.68-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05-1.94-.9-3.77-1.85-5.5-2.83-1.5-.86-2.91-1.73-4.25-2.63s-2.58-1.83-3.75-2.77a40.5 40.5 0 0 1-8.39-8.93c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.25 23.25 0 0 1 2.65-13.93 34.46 34.46 0 0 1 6.87-8.93 54.44 54.44 0 0 1 9.34-7.07c1.87-1.15 3.85-2.21 5.9-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M146.1 289.74a37.82 37.82 0 0 1-5.84-6.73c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 22.77 22.77 0 0 1 1.56-11.54ZM204.37 310.17c-4.33-.26-8.63-.73-12.86-1.38l-21.71-73.93 1.06-.38a107.42 107.42 0 0 1 10.41-3ZM243 307.24a129.56 129.56 0 0 1-12.88 2.19l-23.89-81.37c4.5-.2 9-.19 13.53 0ZM253.8 304.27c-2.12.7-4.3 1.34-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.77Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M267.55 240.1c1 .54 2 1.09 2.92 1.66.32.18.63.37.94.56l.94.6c.69.44 1.36.88 2 1.33a45.93 45.93 0 0 1 9.21 8.34 27.38 27.38 0 0 1 6.37 13.6 22.55 22.55 0 0 1-.62 9.26c-.2.7-.44 1.4-.7 2.1a30.49 30.49 0 0 1-5.91 9.46c-.34.38-.7.75-1.06 1.12Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M250.59 246c-23.06-10.7-57.42-9.91-78.54 1.48-.9.48-1.78 1-2.63 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6C277 275 273.64 256.68 250.59 246Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M250.59 246c-23.06-10.7-57.42-9.91-78.54 1.48-.9.48-1.78 1-2.63 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6C277 275 273.64 256.68 250.59 246Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M248.91 247c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.11-72.78 2.41l-.52.28c-.68.38-1.34.76-2 1.16a34.38 34.38 0 0 0-9.21 7.93c-5.21 6.7-5.53 14.17-1.16 20.93 2.58 4 6.77 7.69 12.55 10.86 1.08.6 2.22 1.18 3.42 1.73s2.23 1 3.39 1.46a73.21 73.21 0 0 0 7.54 2.51c18.19 5 40.23 4.2 56.88-2.09a59.36 59.36 0 0 0 6.8-3.05c1-.53 2-1.09 2.95-1.68s1.59-1 2.32-1.53c.21-.14.42-.29.61-.44 4.87-3.56 8-7.55 9.49-11.67 2.52-7 .21-14.39-6.76-20.64a45.39 45.39 0 0 0-11.25-7.17Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M248.91 247c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.11-72.78 2.41l-.52.28c-.68.38-1.34.76-2 1.16a34.38 34.38 0 0 0-9.21 7.93c-5.21 6.7-5.53 14.17-1.16 20.93 2.58 4 6.77 7.69 12.55 10.86 1.08.6 2.22 1.18 3.42 1.73s2.23 1 3.39 1.46a73.21 73.21 0 0 0 7.54 2.51c18.19 5 40.23 4.2 56.88-2.09a59.36 59.36 0 0 0 6.8-3.05c1-.53 2-1.09 2.95-1.68s1.59-1 2.32-1.53c.21-.14.42-.29.61-.44 4.87-3.56 8-7.55 9.49-11.67 2.52-7 .21-14.39-6.76-20.64a45.39 45.39 0 0 0-11.25-7.17Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M246.67 252.48c.77.31 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.67 7.39 11.79a15.92 15.92 0 0 1-.66 2.38c-1.47 4.12-4.62 8.11-9.49 11.67-.19.15-.4.3-.61.44-.73.52-1.51 1-2.32 1.53s-1.93 1.15-2.95 1.68a59.36 59.36 0 0 1-6.8 3.05c-16.65 6.29-38.69 7.1-56.88 2.09a73.21 73.21 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.39-1.46s-2.34-1.13-3.42-1.73c-5.78-3.17-10-6.89-12.55-10.86a18.83 18.83 0 0 1-2.68-6.36 19.39 19.39 0 0 1 3.87-8.11 34.22 34.22 0 0 1 9.21-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.49-10.53 50.8-11.59 72.76-2.42Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M246.67 252.48c.77.31 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.67 7.39 11.79a15.92 15.92 0 0 1-.66 2.38c-1.47 4.12-4.62 8.11-9.49 11.67-.19.15-.4.3-.61.44-.73.52-1.51 1-2.32 1.53s-1.93 1.15-2.95 1.68a59.36 59.36 0 0 1-6.8 3.05c-16.65 6.29-38.69 7.1-56.88 2.09a73.21 73.21 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.39-1.46s-2.34-1.13-3.42-1.73c-5.78-3.17-10-6.89-12.55-10.86a18.83 18.83 0 0 1-2.68-6.36 19.39 19.39 0 0 1 3.87-8.11 34.22 34.22 0 0 1 9.21-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.49-10.53 50.8-11.59 72.76-2.42Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M288.62 277.55c-8.74 19.43-33.18 27.18-53.12 30.68-30.3 4.36-63.28.71-86.71-16.53h.06c-6.38-5-11.8-11.73-12.95-19.87 1 8.22 6.24 15.17 12.62 20.32h-.05c18.88 14.63 45 19.37 70 18.67 25.19-1.48 58.73-8.26 70.15-33.27Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M181.18 283.49c8.55 3.59 16.94 2 24.76-.74l-2.06 2.12v8.89a37.45 37.45 0 0 0 5.3.86 38.59 38.59 0 0 0 9.21-.34 49.26 49.26 0 0 0 14.34-4.73l7.54 3.17 10.19-6.27v-8.88l-1.91-.82-.41-.18a9.08 9.08 0 0 0 .21-2.07c0-.25 0-.48-.07-.7-.13-1.1.1-6.12.06-8.19 0-.25 0-.47-.06-.7-.3-2.48-2.37-4.71-6.58-6.47a27.17 27.17 0 0 0-13.47-1.79l-1.15.14H227l-1.19.2c-.35.06-.7.12-1 .2-.15 0-.3.05-.43.09s-.54.1-.79.17a62.82 62.82 0 0 0-6.5 1.92c-.93.33-1.86.68-2.78 1l2.69-2.8v-8.89c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42v8.89l2.36 1a5.88 5.88 0 0 0-.22 1.68v.07c.08 1.38 0 7.26 0 8.82v.07c.04 2.62 2.18 4.94 6.65 6.8Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M248.34 266c0 2.28-.17 6.75-.05 7.77 0 .24.05.46.06.71a8.3 8.3 0 0 1-.22 2.08l-5.41-2.28s5.47-2.62 5.62-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M193.61 265.88c-2.26-.94-2.63-2.7 1.53-5.38 4.46-2.83 9-3.57 12.94-2.59l8.82-9.19v8.88l-2.66 2.8-.28.11c-1.44.55-2.85 1.12-4.18 1.67-.54.25-1.07.47-1.59.67a55.38 55.38 0 0 1-8.9 3.27 10.05 10.05 0 0 1-3.86.27 6.79 6.79 0 0 1-1.82-.51Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m174.62 266.05-2.35-1v-8.88l7.51 3.15c-2.78 2.34-4.58 4.61-5.16 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m240.28 283.82 10.18-6.26v8.88l-10.2 6.29-7.52-3.18a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.89a36.15 36.15 0 0 0 14.5.51 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M232.75 289.55a37.16 37.16 0 0 1-11.44 4.68 30.51 30.51 0 0 1-12.13.4 58.58 58.58 0 0 0 12-1.4c2-.43 3.91-1 5.84-1.6s3.8-1.35 5.73-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M174.42 276.69v-.05c-.06-1.57.07-7.44 0-8.84.16 2.63 2.28 4.94 6.74 6.81 9.94 4.14 19.61 1.42 28.46-2.13 8.22-3.33 14.18-6.86 19-4.86 3 1.27 2.15 3.26-1.14 5.36a18.15 18.15 0 0 1-14.2 2.24l-7.28 7.54c-7.84 2.77-16.21 4.3-24.76.72-4.54-1.85-6.66-4.16-6.82-6.79Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m208.09 266.8-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m208.09 266.8-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m172.26 256.19 7.55 3.15-5.18 6.72-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m172.26 256.19 7.55 3.15-5.18 6.72-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m248.55 276.75-5.83-2.46s5.73-2.77 5.62-8.68c0-.25 0-.47-.06-.7-.3-2.48-2.37-4.71-6.58-6.47a27.17 27.17 0 0 0-13.47-1.79l-1.15.14H227l-1.19.2c-.35.06-.7.12-1 .2-.15 0-.3.05-.43.09s-.54.1-.79.17a62.82 62.82 0 0 0-6.5 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.69-.54.24-1.06.46-1.59.67a57.37 57.37 0 0 1-8.89 3.27 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-1-2.66-2.72 1.52-5.4 4.45-2.84 9-3.58 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42 7.55 3.15c-3.6 3-5.5 5.83-5.4 8.4v.07c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.44-2.13 8.22-3.33 14.18-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.64a39 39 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49 49 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m248.55 276.75-5.83-2.46s5.73-2.77 5.62-8.68c0-.25 0-.47-.06-.7-.3-2.48-2.37-4.71-6.58-6.47a27.17 27.17 0 0 0-13.47-1.79l-1.15.14H227l-1.19.2c-.35.06-.7.12-1 .2-.15 0-.3.05-.43.09s-.54.1-.79.17a62.82 62.82 0 0 0-6.5 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.69-.54.24-1.06.46-1.59.67a57.37 57.37 0 0 1-8.89 3.27 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-1-2.66-2.72 1.52-5.4 4.45-2.84 9-3.58 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42 7.55 3.15c-3.6 3-5.5 5.83-5.4 8.4v.07c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.44-2.13 8.22-3.33 14.18-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.64a39 39 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49 49 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M181.17 274.61a28.46 28.46 0 0 0 11.8 1.83 43.16 43.16 0 0 0 12.03-2.55c3.94-1.3 7.74-3 11.61-4.62a39.21 39.21 0 0 1 6-2 9.88 9.88 0 0 1 6 .35 9.83 9.83 0 0 0-6-.08 40 40 0 0 0-5.84 2.11c-3.82 1.66-7.64 3.41-11.61 4.76a42.62 42.62 0 0 1-12.16 2.47 28.77 28.77 0 0 1-6.1-.46 29.17 29.17 0 0 1-5.73-1.81Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m240.27 283.83.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m240.27 283.83.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m250.46 277.56-10.15 5.28-7.58-2.17 7.54 3.16 10.19-6.27zM189.87 252.91l-7.49-2.22-10.12 5.5 10.07-6.42 7.54 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M216.89 248.71a39.38 39.38 0 0 0-4.73-.56l1.24 4.21ZM181.51 274.73a27.46 27.46 0 0 0 13 1.83l-7.26-24.75-4.91-2-6.86 4.38 1.12 3.81 3.21 1.34a25 25 0 0 0-2.22 2ZM240.17 257.85a26.33 26.33 0 0 0-7.48-1.37l8 27.12 6-3.69-1.33-4.52-2.6-1.1a11.22 11.22 0 0 0 1.91-1.27ZM227 256.78l-1.19.2c-.35.06-.7.12-1 .2-.15 0-.3.05-.43.09s-.54.1-.79.17a62.82 62.82 0 0 0-6.5 1.92c-.47.16-.92.35-1.39.52l2.63 9c4.06-1.59 7.43-2.44 10.38-1.21s2.14 3.25-1.15 5.36a17.64 17.64 0 0 1-7.24 2.61l2.59 8.84a51.72 51.72 0 0 0 9.92-3.77l2.85 1.19-7.41-25.23-1.09.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M250 247.86h8.47v17.34a23.18 23.18 0 0 1-.83 6.15c-.2.7-.44 1.41-.7 2.1a30.49 30.49 0 0 1-5.91 9.46 40.34 40.34 0 0 1-3 3 43.27 43.27 0 0 1-3.5 2.92 56.72 56.72 0 0 1-5.1 3.47 74.91 74.91 0 0 1-6 3.29c-1.58.77-3.2 1.51-4.88 2.19-24.52 10.14-58.33 11.37-85.31 3.14a89.94 89.94 0 0 1-5.4-1.81c-2.53-.92-5-1.94-7.35-3-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75c.62-.67 1.27-1.34 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 91.52 91.52 0 0 1 9.43-3.85c22.18-7.66 50.42-8.7 74.42-2.73a106.84 106.84 0 0 1 10.33 2.96c2.79 1 5.49 2.1 8.08 3.31a71.35 71.35 0 0 1 10.64 6.09 48.27 48.27 0 0 1 7.28 6.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M159.87 304.65v-35.18l12.88 1.35v35.21c-4.34-.26-8.65-.72-12.88-1.38Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M258.5 265.16a23.18 23.18 0 0 1-.83 6.15c-.2.7-.44 1.41-.7 2.1a30.49 30.49 0 0 1-5.91 9.46c-.34.38-.7.75-1.06 1.12V247.8l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m215.55 249 6.61-1.57v52.72q-3.16 1-6.47 1.92ZM198.51 305.29V259.6l12.88.95v42.55a129.86 129.86 0 0 1-12.88 2.19ZM104.06 248.42h7.75c.62-.67 1.27-1.34 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 91.52 91.52 0 0 1 9.43-3.85q4.67-1.61 9.65-2.81l.06 73.4c-1.91-.48-3.8-1-5.65-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.77a40.4 40.4 0 0 1-8.38-8.92c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M108.62 278.88c-.45-.68-.88-1.35-1.26-2a24.85 24.85 0 0 1-3.11-9.12 21.85 21.85 0 0 1-.17-2.91v-16.39h7.75l.1-.1 2.61 1v36.45a38.06 38.06 0 0 1-5.92-6.93Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M232.06 217a94.12 94.12 0 0 0-8.08-3.31q-5-1.78-10.37-3.12c-24-6-52.24-4.92-74.42 2.74a90.62 90.62 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.46 40.46 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.55 6-3.29t5.1-3.47q1.88-1.44 3.5-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.97-9.38c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.55 46.55 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.67-6.1Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M232.06 217a94.12 94.12 0 0 0-8.08-3.31q-5-1.78-10.37-3.12c-24-6-52.24-4.92-74.42 2.74a90.62 90.62 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.46 40.46 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.55 6-3.29t5.1-3.47q1.88-1.44 3.5-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.97-9.38c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.55 46.55 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.67-6.1Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M232.06 217a94.12 94.12 0 0 0-8.08-3.31q-5-1.78-10.37-3.12c-24-6-52.24-4.92-74.42 2.74a90.62 90.62 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.46 40.46 0 0 0 8.39 8.92q1.77 1.41 3.76 2.76c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3 1.77.66 3.57 1.26 5.41 1.82 27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.55 6-3.29t5.1-3.47q1.88-1.44 3.5-2.92a40.23 40.23 0 0 0 3-3 30.67 30.67 0 0 0 5.97-9.38c.26-.7.49-1.4.69-2.11a22.36 22.36 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.55 46.55 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.67-6.1Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M148.93 285.44c-1.92-.48-3.82-1-5.68-1.56s-3.64-1.16-5.41-1.82c-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.46 40.46 0 0 1-8.39-8.92c-.45-.68-.87-1.35-1.25-2a24.9 24.9 0 0 1-3.11-9.13 23.13 23.13 0 0 1 2.65-13.93 34.35 34.35 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.14 3.86-2.21 5.91-3.21Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M114.44 268.59a38.17 38.17 0 0 1-5.84-6.73c-.45-.68-.87-1.35-1.25-2a24.9 24.9 0 0 1-3.11-9.13 22.66 22.66 0 0 1 1.56-11.54ZM172.71 289c-4.34-.26-8.64-.73-12.86-1.39l-21.71-73.91 1.05-.38c3.34-1.15 6.83-2.14 10.41-3ZM211.36 286.09c-4.18.93-8.49 1.66-12.87 2.19L174.6 206.9c4.49-.2 9-.18 13.53 0ZM222.14 283.11c-2.13.71-4.3 1.35-6.52 1.94l-22.84-77.81c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M235.88 219c1 .54 2 1.09 2.93 1.65l.94.57.94.59c.68.44 1.36.89 2 1.34a46.76 46.76 0 0 1 9.21 8.33 27.51 27.51 0 0 1 6.37 13.6 22.36 22.36 0 0 1-.63 9.26c-.2.71-.43 1.41-.69 2.11a30.67 30.67 0 0 1-5.92 9.45c-.33.38-.69.76-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M218.92 224.83c-23-10.71-57.41-9.91-78.54 1.48-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.38s60.37 9.83 81.17-3c.84-.52 1.66-1.06 2.42-1.6 18.13-12.76 14.79-31.07-8.29-41.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M218.92 224.83c-23-10.71-57.41-9.91-78.54 1.48-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.38s60.37 9.83 81.17-3c.84-.52 1.66-1.06 2.42-1.6 18.13-12.76 14.79-31.07-8.29-41.77Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M217.25 225.86c-.74-.35-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.42l-.52.28c-.68.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a73.42 73.42 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.1 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.56 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.29-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M217.25 225.86c-.74-.35-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.42l-.52.28c-.68.37-1.34.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a73.42 73.42 0 0 0 7.55 2.52c18.18 5 40.22 4.2 56.88-2.1a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.1 3-1.68s1.59-1 2.32-1.53l.61-.44c4.86-3.57 8-7.56 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.29-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M215 231.32c.78.32 1.53.65 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.11-4.63 8.1-9.49 11.67l-.61.44c-.73.51-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a73.42 73.42 0 0 1-7.55-2.52 58.17 58.17 0 0 1-6.81-3.19c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.3-.79 2-1.16l.53-.28c19.53-10.48 50.79-11.54 72.79-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M215 231.32c.78.32 1.53.65 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.21 17.21 0 0 1-.66 2.38c-1.48 4.11-4.63 8.1-9.49 11.67l-.61.44c-.73.51-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.1a73.42 73.42 0 0 1-7.55-2.52 58.17 58.17 0 0 1-6.81-3.19c-5.78-3.16-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.3-.79 2-1.16l.53-.28c19.53-10.48 50.79-11.54 72.79-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M257 256.4c-8.74 19.43-33.19 27.18-53.12 30.68-30.3 4.35-63.28.7-86.71-16.54h.05c-6.37-5-11.8-11.74-12.94-19.88 1 8.23 6.24 15.18 12.61 20.33 18.87 14.63 45 19.36 70 18.66 25.11-1.46 58.64-8.24 70.11-33.25Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M149.51 262.34c8.56 3.58 17 2 24.77-.74l-2.06 2.12v8.89a37.08 37.08 0 0 0 5.29.86 38.08 38.08 0 0 0 9.21-.35 49.21 49.21 0 0 0 14.35-4.72l7.54 3.17 10.18-6.27v-8.89l-1.91-.82-.42-.17a8.08 8.08 0 0 0 .21-2.07 5.22 5.22 0 0 0-.06-.71c-.13-1.09.1-6.11.06-8.18 0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.06-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-.94.33-1.87.68-2.78 1.05l2.69-2.8v-8.89c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.15L140.6 235v8.89l2.36 1a5.18 5.18 0 0 0-.22 1.68v.07c.07 1.38-.06 7.26 0 8.82v.07c.16 2.63 2.31 4.94 6.77 6.81Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M216.67 244.87c0 2.28-.16 6.75 0 7.77 0 .24 0 .46.07.7a8.33 8.33 0 0 1-.22 2.09l-5.42-2.28s5.42-2.65 5.57-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M162 244.73c-2.26-.95-2.64-2.71 1.52-5.39 4.46-2.82 9.05-3.56 12.95-2.58l8.82-9.19v8.87l-2.66 2.81-.27.1c-1.45.56-2.85 1.13-4.19 1.68-.54.24-1.07.47-1.59.67a53.88 53.88 0 0 1-8.9 3.26 9.81 9.81 0 0 1-3.85.27 7.16 7.16 0 0 1-1.83-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m143 244.9-2.35-1v-8.87l7.51 3.15c-2.83 2.32-4.62 4.59-5.16 6.72Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m208.61 262.67 10.19-6.27v8.89l-10.2 6.28-7.52-3.18a48.5 48.5 0 0 1-14.36 4.72 38.07 38.07 0 0 1-9.21.36 38.8 38.8 0 0 1-5.29-.86v-8.89a36.15 36.15 0 0 0 14.5.51 48.79 48.79 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M201.09 268.4a37.16 37.16 0 0 1-11.44 4.68 30.51 30.51 0 0 1-12.13.39 57 57 0 0 0 11.95-1.4c2-.42 3.91-1 5.85-1.6s3.84-1.34 5.77-2.07Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M142.75 255.54c-.07-1.57.07-7.45 0-8.84.16 2.63 2.29 4.94 6.75 6.81 9.94 4.14 19.61 1.41 28.46-2.14 8.21-3.32 14.18-6.85 19-4.85 3 1.26 2.15 3.26-1.13 5.36a18.18 18.18 0 0 1-14.2 2.23l-7.32 7.55c-7.84 2.77-16.22 4.3-24.76.72-4.55-1.9-6.64-4.22-6.8-6.84Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M176.42 245.64v-8.88l8.81-9.2.01 8.88-8.82 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M176.42 245.64v-8.88l8.81-9.2.01 8.88-8.82 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m140.6 235.04 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m140.6 235.04 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m216.89 255.59-5.83-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.06-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-1-2.65-2.72 1.53-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.66-17.92-.25-27 4.2l-7.54-3.15L140.6 235l7.54 3.14c-3.6 3-5.49 5.84-5.39 8.41v.07c.15 2.61 2.29 4.93 6.76 6.79 9.91 4.15 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.14 3.25-1.15 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.65a37.22 37.22 0 0 0 5.3.86 38.59 38.59 0 0 0 9.21-.34 49.26 49.26 0 0 0 14.34-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m216.89 255.59-5.83-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.7-.29-2.49-2.36-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.8l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.06-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.44.57-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-1-2.65-2.72 1.53-5.39 4.45-2.85 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.66-17.92-.25-27 4.2l-7.54-3.15L140.6 235l7.54 3.14c-3.6 3-5.49 5.84-5.39 8.41v.07c.15 2.61 2.29 4.93 6.76 6.79 9.91 4.15 19.59 1.43 28.43-2.13 8.22-3.33 14.19-6.85 19-4.84 3 1.26 2.14 3.25-1.15 5.35a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.65a37.22 37.22 0 0 0 5.3.86 38.59 38.59 0 0 0 9.21-.34 49.26 49.26 0 0 0 14.34-4.73l7.54 3.17 10.19-6.27Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M149.51 253.45a28.41 28.41 0 0 0 11.8 1.84 43.44 43.44 0 0 0 12-2.55c4-1.3 7.74-3 11.61-4.63a41.26 41.26 0 0 1 6-2 9.92 9.92 0 0 1 6 .36 9.88 9.88 0 0 0-6-.09 40.13 40.13 0 0 0-5.85 2.12c-3.82 1.66-7.63 3.4-11.6 4.76a42.34 42.34 0 0 1-12.23 2.45 28.27 28.27 0 0 1-6.11-.46 29.29 29.29 0 0 1-5.62-1.8Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m208.61 262.68.01 8.88-7.55-3.16v-8.89l7.54 3.17z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m208.61 262.68.01 8.88-7.55-3.16v-8.89l7.54 3.17z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m218.79 256.41-10.14 5.27-7.58-2.17 7.54 3.17 10.18-6.27zM158.21 231.76l-7.5-2.23-10.11 5.51 10.06-6.43 7.55 3.15z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M185.23 227.56a37.45 37.45 0 0 0-4.73-.56l1.23 4.21ZM149.85 253.58a27.57 27.57 0 0 0 13 1.83l-7.27-24.75-4.9-2-6.88 4.34 1.14 3.85 3.2 1.33a26.31 26.31 0 0 0-2.21 2ZM208.51 236.69a26.31 26.31 0 0 0-7.49-1.36l8 27.12 6-3.69-1.33-4.52-2.6-1.1a11 11 0 0 0 1.9-1.27ZM195.29 235.63l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.06-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91l-1.39.53 2.63 9c4.05-1.6 7.43-2.44 10.37-1.21s2.14 3.25-1.15 5.35a17.76 17.76 0 0 1-7.24 2.62l2.6 8.83a51.31 51.31 0 0 0 9.92-3.77l2.84 1.2-7.4-25.23-1.1.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M279.37 218.58h8.46v17.33A22.74 22.74 0 0 1 287 242c-.19.71-.43 1.41-.69 2.11a30.67 30.67 0 0 1-5.92 9.45 40.23 40.23 0 0 1-3 3q-1.62 1.49-3.5 2.92a60.48 60.48 0 0 1-5.1 3.47q-2.88 1.74-6 3.29c-1.57.77-3.2 1.5-4.87 2.19-24.53 10.13-58.34 11.36-85.31 3.14-1.84-.56-3.64-1.16-5.41-1.82-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.46 40.46 0 0 1-8.35-8.92c-.45-.68-.87-1.35-1.25-2a24.9 24.9 0 0 1-3.11-9.13 21.76 21.76 0 0 1-.18-2.91v-16.42h7.75c.61-.67 1.26-1.34 1.95-2a54.44 54.44 0 0 1 9.34-7.07 74.07 74.07 0 0 1 6.67-3.61 90.59 90.59 0 0 1 9.43-3.84c22.18-7.67 50.42-8.7 74.41-2.74a105.14 105.14 0 0 1 10.32 3.12c2.78 1 5.49 2.1 8.07 3.31a70.34 70.34 0 0 1 10.6 6.05 49 49 0 0 1 7.37 6.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M189.2 275.37v-35.18l12.88 1.35v35.21c-4.33-.26-8.64-.75-12.88-1.38Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M287.84 235.88A22.74 22.74 0 0 1 287 242c-.19.71-.43 1.41-.69 2.11a30.67 30.67 0 0 1-5.92 9.45c-.33.38-.69.75-1.05 1.13v-36.2l.06.06h8.46Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m244.88 219.71 6.62-1.56v52.71c-2.1.7-4.26 1.34-6.46 1.92ZM227.84 276v-45.69l12.88 1v42.55a126.72 126.72 0 0 1-12.88 2.14ZM133.4 219.14h7.75c.61-.67 1.26-1.34 1.95-2a54.44 54.44 0 0 1 9.34-7.07 74.07 74.07 0 0 1 6.67-3.61 90.59 90.59 0 0 1 9.43-3.84c3.1-1.07 6.33-2 9.64-2.81l.06 73.39q-2.87-.71-5.64-1.55c-1.84-.56-3.64-1.16-5.41-1.82-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.76a40.46 40.46 0 0 1-8.34-9c-.45-.68-.87-1.35-1.25-2a24.9 24.9 0 0 1-3.11-9.13 21.76 21.76 0 0 1-.18-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M138 249.6c-.45-.68-.87-1.35-1.25-2a24.9 24.9 0 0 1-3.11-9.13 21.76 21.76 0 0 1-.18-2.91v-16.42h7.75l.09-.1 2.61 1v36.46a38.1 38.1 0 0 1-5.91-6.9Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M261.39 187.74a89.064 89.064 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.59 90.59 0 0 0-9.43 3.84q-3.51 1.68-6.67 3.6a54.47 54.47 0 0 0-9.34 7.08 34.58 34.58 0 0 0-6.87 8.92 23.29 23.29 0 0 0-2.65 13.94 24.91 24.91 0 0 0 3.11 9.12c.39.68.81 1.36 1.26 2a40.66 40.66 0 0 0 8.38 8.93c1.17.94 2.43 1.87 3.76 2.76s2.75 1.78 4.24 2.64c1.73 1 3.57 1.92 5.5 2.83a73.39 73.39 0 0 0 7.35 3q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 42.34 42.34 0 0 0 3-3 30.6 30.6 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.26 27.26 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.62-6.01Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M261.39 187.74a89.064 89.064 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.59 90.59 0 0 0-9.43 3.84q-3.51 1.68-6.67 3.6a54.47 54.47 0 0 0-9.34 7.08 34.58 34.58 0 0 0-6.87 8.92 23.29 23.29 0 0 0-2.65 13.94 24.91 24.91 0 0 0 3.11 9.12c.39.68.81 1.36 1.26 2a40.66 40.66 0 0 0 8.38 8.93c1.17.94 2.43 1.87 3.76 2.76s2.75 1.78 4.24 2.64c1.73 1 3.57 1.92 5.5 2.83a73.39 73.39 0 0 0 7.35 3q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 42.34 42.34 0 0 0 3-3 30.6 30.6 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.26 27.26 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.62-6.01Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M261.39 187.74a89.064 89.064 0 0 0-8.07-3.31 105.14 105.14 0 0 0-10.38-3.12c-24-6-52.24-4.93-74.41 2.74a90.59 90.59 0 0 0-9.43 3.84q-3.51 1.68-6.67 3.6a54.47 54.47 0 0 0-9.34 7.08 34.58 34.58 0 0 0-6.87 8.92 23.29 23.29 0 0 0-2.65 13.94 24.91 24.91 0 0 0 3.11 9.12c.39.68.81 1.36 1.26 2a40.66 40.66 0 0 0 8.38 8.93c1.17.94 2.43 1.87 3.76 2.76s2.75 1.78 4.24 2.64c1.73 1 3.57 1.92 5.5 2.83a73.39 73.39 0 0 0 7.35 3q2.64 1 5.4 1.81c27 8.22 60.79 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a58.9 58.9 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 42.34 42.34 0 0 0 3-3 30.6 30.6 0 0 0 5.91-9.46c.26-.7.5-1.4.7-2.1a22.55 22.55 0 0 0 .62-9.26 27.26 27.26 0 0 0-6.37-13.6 45.93 45.93 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.62-6.01Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M178.26 256.16c-1.92-.48-3.81-1-5.68-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3a82.95 82.95 0 0 1-5.5-2.83c-1.49-.86-2.91-1.73-4.24-2.64s-2.59-1.82-3.76-2.76a40.66 40.66 0 0 1-8.38-8.93c-.45-.67-.87-1.35-1.26-2a24.91 24.91 0 0 1-3.11-9.12 23.29 23.29 0 0 1 2.65-13.94 34.58 34.58 0 0 1 6.87-8.92 54.47 54.47 0 0 1 9.34-7.08c1.88-1.14 3.85-2.2 5.9-3.21Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M143.77 239.31a37.74 37.74 0 0 1-5.83-6.73c-.45-.67-.87-1.35-1.26-2a24.91 24.91 0 0 1-3.11-9.12 22.75 22.75 0 0 1 1.56-11.54ZM202 259.74c-4.33-.26-8.63-.73-12.86-1.39l-21.7-73.92c.35-.13.69-.26 1.05-.38 3.34-1.16 6.83-2.14 10.41-3ZM240.7 256.81a129.56 129.56 0 0 1-12.88 2.19l-23.89-81.37c4.5-.2 9-.19 13.53 0ZM251.47 253.84c-2.12.7-4.3 1.34-6.52 1.93L222.11 178c2.45.2 4.9.45 7.32.77Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M265.22 189.67c1 .54 2 1.09 2.92 1.66.32.18.64.37.94.56l.95.6c.68.44 1.36.88 2 1.33a45.93 45.93 0 0 1 9.21 8.34 27.26 27.26 0 0 1 6.37 13.6A22.55 22.55 0 0 1 287 225c-.2.7-.44 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46c-.34.37-.7.75-1.06 1.12Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M248.26 195.55c-23-10.7-57.42-9.91-78.54 1.48-.9.48-1.78 1-2.63 1.51-20.8 12.81-18.17 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.12-12.77 14.76-31.08-8.29-41.78Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M248.26 195.55c-23-10.7-57.42-9.91-78.54 1.48-.9.48-1.78 1-2.63 1.51-20.8 12.81-18.17 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.12-12.77 14.76-31.08-8.29-41.78Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M246.58 196.58c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.11-72.77 2.41l-.53.28c-.68.38-1.34.76-2 1.16a34.53 34.53 0 0 0-9.21 7.93c-5.21 6.7-5.53 14.17-1.15 20.93 2.57 4 6.76 7.69 12.54 10.86 1.08.6 2.22 1.18 3.42 1.73s2.23 1 3.39 1.46a74 74 0 0 0 7.54 2.51c18.19 5 40.23 4.2 56.89-2.1a58.23 58.23 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.62-.44c4.86-3.57 8-7.55 9.48-11.67 2.53-7 .21-14.39-6.76-20.64a45.39 45.39 0 0 0-11.3-7.21Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M246.58 196.58c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.11-72.77 2.41l-.53.28c-.68.38-1.34.76-2 1.16a34.53 34.53 0 0 0-9.21 7.93c-5.21 6.7-5.53 14.17-1.15 20.93 2.57 4 6.76 7.69 12.54 10.86 1.08.6 2.22 1.18 3.42 1.73s2.23 1 3.39 1.46a74 74 0 0 0 7.54 2.51c18.19 5 40.23 4.2 56.89-2.1a58.23 58.23 0 0 0 6.79-3c1-.53 2-1.09 3-1.68s1.59-1 2.32-1.53l.62-.44c4.86-3.57 8-7.55 9.48-11.67 2.53-7 .21-14.39-6.76-20.64a45.39 45.39 0 0 0-11.3-7.21Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M244.34 202.05c.77.31 1.53.65 2.27 1a45.77 45.77 0 0 1 11.26 7.18c4.06 3.65 6.53 7.68 7.39 11.8a15.92 15.92 0 0 1-.66 2.38c-1.47 4.12-4.62 8.1-9.48 11.67l-.62.44c-.73.52-1.51 1-2.32 1.53s-1.93 1.15-3 1.68a58.23 58.23 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.89 2.1a74 74 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.39-1.46s-2.34-1.13-3.42-1.73c-5.78-3.17-10-6.9-12.54-10.86a18.74 18.74 0 0 1-2.69-6.36 19.39 19.39 0 0 1 3.87-8.11 34.22 34.22 0 0 1 9.21-7.93c.65-.4 1.31-.78 2-1.16l.52-.28c19.55-10.49 50.86-11.55 72.82-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M244.34 202.05c.77.31 1.53.65 2.27 1a45.77 45.77 0 0 1 11.26 7.18c4.06 3.65 6.53 7.68 7.39 11.8a15.92 15.92 0 0 1-.66 2.38c-1.47 4.12-4.62 8.1-9.48 11.67l-.62.44c-.73.52-1.51 1-2.32 1.53s-1.93 1.15-3 1.68a58.23 58.23 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.89 2.1a74 74 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.39-1.46s-2.34-1.13-3.42-1.73c-5.78-3.17-10-6.9-12.54-10.86a18.74 18.74 0 0 1-2.69-6.36 19.39 19.39 0 0 1 3.87-8.11 34.22 34.22 0 0 1 9.21-7.93c.65-.4 1.31-.78 2-1.16l.52-.28c19.55-10.49 50.86-11.55 72.82-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M286.29 227.12c-8.74 19.43-33.18 27.18-53.12 30.68-30.3 4.35-63.28.71-86.7-16.53h.05c-6.37-5-11.8-11.73-13-19.87 1 8.22 6.25 15.17 12.62 20.32h-.05c18.88 14.63 45 19.37 70 18.67 25.24-1.48 58.78-8.26 70.2-33.27Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M178.85 233.06c8.56 3.59 16.94 2 24.76-.74l-2.06 2.12v8.89a37.45 37.45 0 0 0 5.3.86 38.08 38.08 0 0 0 9.21-.35 48.85 48.85 0 0 0 14.34-4.72l7.54 3.17 10.2-6.29v-8.89l-1.91-.81-.41-.18a9.08 9.08 0 0 0 .21-2.07c0-.25 0-.48-.07-.71-.13-1.09.1-6.11.06-8.18a5.07 5.07 0 0 0-.06-.7c-.3-2.48-2.37-4.72-6.58-6.47a27.17 27.17 0 0 0-13.47-1.79l-1.15.14h-.13l-1.19.2c-.35.06-.7.11-1.05.2-.15 0-.3.05-.42.09-.28 0-.55.1-.8.17a60.45 60.45 0 0 0-6.5 1.92c-.93.32-1.86.68-2.77 1l2.68-2.8v-8.89c-8.66-1.65-17.92-.25-27 4.2l-7.58-3.09-10.06 6.42v8.89l2.36 1a5.84 5.84 0 0 0-.22 1.68v.07c.08 1.38-.05 7.26 0 8.82v.07c.16 2.59 2.3 4.91 6.77 6.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M246 215.59c0 2.28-.17 6.75 0 7.77 0 .24 0 .46.06.7a8.82 8.82 0 0 1-.21 2.09l-5.42-2.28s5.43-2.64 5.57-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M191.28 215.45c-2.26-.94-2.63-2.7 1.53-5.38 4.46-2.83 9-3.57 12.94-2.59l8.82-9.19v8.88L211.9 210l-.28.1c-1.44.56-2.84 1.13-4.18 1.68-.54.25-1.07.47-1.59.67a55.38 55.38 0 0 1-8.9 3.27 10 10 0 0 1-3.86.26 6.42 6.42 0 0 1-1.81-.53Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m172.29 215.62-2.35-1v-8.88l7.52 3.15c-2.8 2.34-4.59 4.61-5.17 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m238 233.39 10.18-6.26V236l-10.2 6.29-7.52-3.18a48.78 48.78 0 0 1-14.36 4.71 38 38 0 0 1-9.21.37 38.85 38.85 0 0 1-5.29-.87v-8.89a36.19 36.19 0 0 0 14.5.51 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M230.42 239.12A37.16 37.16 0 0 1 219 243.8a30.69 30.69 0 0 1-12.13.4 58.5 58.5 0 0 0 12-1.4c2-.43 3.91-1 5.84-1.6s3.78-1.35 5.71-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M172.09 226.26c-.06-1.57.07-7.44 0-8.84.16 2.63 2.28 4.94 6.75 6.81 9.93 4.14 19.6 1.42 28.45-2.13 8.22-3.33 14.19-6.86 19-4.86 3 1.27 2.15 3.26-1.14 5.36a18.15 18.15 0 0 1-14.2 2.24l-7.32 7.54c-7.83 2.77-16.21 4.3-24.75.72-4.51-1.9-6.63-4.21-6.79-6.84Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m205.76 216.37-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m205.76 216.37-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m169.94 205.76 7.54 3.15-5.18 6.72-2.36-.98v-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m169.94 205.76 7.54 3.15-5.18 6.72-2.36-.98v-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m246.22 226.32-5.83-2.46s5.73-2.77 5.62-8.68a5.07 5.07 0 0 0-.06-.7c-.3-2.49-2.37-4.72-6.58-6.47a27.06 27.06 0 0 0-13.47-1.79l-1.15.14h-.13l-1.19.2c-.35.06-.7.11-1.05.2-.15 0-.3.05-.42.09-.28 0-.55.1-.8.17a60.45 60.45 0 0 0-6.5 1.92c-1 .35-2 .74-3 1.14-1.44.56-2.85 1.14-4.2 1.69-.54.24-1.06.46-1.58.67a57.78 57.78 0 0 1-8.9 3.27 10.21 10.21 0 0 1-3.85.26 7.47 7.47 0 0 1-1.81-.5c-2.27-1-2.65-2.71 1.52-5.39 4.46-2.84 9.05-3.58 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.59-3.15-10.06 6.42 7.54 3.15c-3.6 3-5.5 5.83-5.4 8.4v.07c.15 2.62 2.3 4.93 6.76 6.79 9.91 4.16 19.6 1.43 28.44-2.12 8.22-3.33 14.18-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.64a39 39 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49 49 0 0 0 14.34-4.72l7.54 3.16 10.19-6.27Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m246.22 226.32-5.83-2.46s5.73-2.77 5.62-8.68a5.07 5.07 0 0 0-.06-.7c-.3-2.49-2.37-4.72-6.58-6.47a27.06 27.06 0 0 0-13.47-1.79l-1.15.14h-.13l-1.19.2c-.35.06-.7.11-1.05.2-.15 0-.3.05-.42.09-.28 0-.55.1-.8.17a60.45 60.45 0 0 0-6.5 1.92c-1 .35-2 .74-3 1.14-1.44.56-2.85 1.14-4.2 1.69-.54.24-1.06.46-1.58.67a57.78 57.78 0 0 1-8.9 3.27 10.21 10.21 0 0 1-3.85.26 7.47 7.47 0 0 1-1.81-.5c-2.27-1-2.65-2.71 1.52-5.39 4.46-2.84 9.05-3.58 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.59-3.15-10.06 6.42 7.54 3.15c-3.6 3-5.5 5.83-5.4 8.4v.07c.15 2.62 2.3 4.93 6.76 6.79 9.91 4.16 19.6 1.43 28.44-2.12 8.22-3.33 14.18-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.64a39 39 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49 49 0 0 0 14.34-4.72l7.54 3.16 10.19-6.27Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M178.84 224.17a28.47 28.47 0 0 0 11.8 1.84 43.16 43.16 0 0 0 12.05-2.55c3.94-1.3 7.74-3 11.61-4.62a39.21 39.21 0 0 1 5.95-2 9.88 9.88 0 0 1 6 .35 9.83 9.83 0 0 0-6-.08 40 40 0 0 0-5.84 2.11c-3.82 1.66-7.64 3.41-11.61 4.76a42.27 42.27 0 0 1-12.23 2.45 28.13 28.13 0 0 1-6.1-.45 29.19 29.19 0 0 1-5.63-1.81Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m237.94 233.4.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m237.94 233.4.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m248.13 227.13-10.15 5.28-7.58-2.17 7.54 3.16 10.19-6.27zM187.54 202.48l-7.49-2.22-10.11 5.5 10.06-6.42 7.54 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M214.56 198.28a37.23 37.23 0 0 0-4.73-.56l1.24 4.21ZM179.18 224.3a27.48 27.48 0 0 0 13 1.83l-7.26-24.75-4.91-2-6.86 4.38 1.13 3.85 3.21 1.34a23.56 23.56 0 0 0-2.22 2ZM237.84 207.42a26.33 26.33 0 0 0-7.48-1.37l8 27.12 6-3.69L243 225l-2.6-1.1a11.22 11.22 0 0 0 1.91-1.27ZM224.62 206.35l-1.19.2c-.35.06-.7.11-1.05.2-.15 0-.3.05-.42.09-.28 0-.55.1-.8.17a60.45 60.45 0 0 0-6.5 1.92c-.46.16-.92.35-1.39.52l2.64 9c4.05-1.6 7.43-2.44 10.37-1.21s2.14 3.25-1.15 5.36a17.59 17.59 0 0 1-7.24 2.61l2.59 8.84a51.47 51.47 0 0 0 9.92-3.77l2.85 1.19-7.41-25.23-1.09.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m139.37 221.26 1.23-9.32 1.23 9.32 16.14.71-16.14.71-1.23 9.32-1.23-9.32-16.14-.71 16.14-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m152.73 228.27.59-4.52.6 4.52 7.82.34-7.82.35-.6 4.52-.59-4.52-7.83-.35 7.83-.34zM117.92 274.35l1.23-9.32 1.23 9.32 16.15.71-16.15.71-1.23 9.32-1.23-9.32-16.14-.71 16.14-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m126.65 281.58.59-4.52.6 4.52 7.82.34-7.82.34-.6 4.52-.59-4.52-7.83-.34 7.83-.34z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M115.46 137a78.38 78.38 0 0 0 .61 10.1 76.92 76.92 0 0 0 55 64.16 77.47 77.47 0 0 0 21 3 77.49 77.49 0 0 0 16.08-1.61 77.17 77.17 0 0 0 49.28-34.2c.71-1.12 1.39-2.27 2.05-3.42a76.55 76.55 0 0 0 7.09-17.19c.56-2 1-4.1 1.44-6.2s.71-4.23.93-6.4q.39-3.75.4-7.59a76 76 0 0 0-.51-9.23q-.36-3.18-1-6.27a77 77 0 0 0-60.26-60.26 76.05 76.05 0 0 0-6.28-1 75.74 75.74 0 0 0-8.58-.51 74.73 74.73 0 0 0-8.23.4c-2.16.21-4.3.53-6.4.93s-4.17.87-6.21 1.43a76.12 76.12 0 0 0-17.19 7.1c-1.16.65-2.31 1.33-3.43 2a77.42 77.42 0 0 0-28.46 32.2 76 76 0 0 0-5.73 17.09 77.55 77.55 0 0 0-1.6 15.47Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M115.46 137a78.38 78.38 0 0 0 .61 10.1 76.92 76.92 0 0 0 55 64.16 77.47 77.47 0 0 0 21 3 77.49 77.49 0 0 0 16.08-1.61 77.17 77.17 0 0 0 49.28-34.2c.71-1.12 1.39-2.27 2.05-3.42a76.55 76.55 0 0 0 7.09-17.19c.56-2 1-4.1 1.44-6.2s.71-4.23.93-6.4q.39-3.75.4-7.59a76 76 0 0 0-.51-9.23q-.36-3.18-1-6.27a77 77 0 0 0-60.26-60.26 76.05 76.05 0 0 0-6.28-1 75.74 75.74 0 0 0-8.58-.51 74.73 74.73 0 0 0-8.23.4c-2.16.21-4.3.53-6.4.93s-4.17.87-6.21 1.43a76.12 76.12 0 0 0-17.19 7.1c-1.16.65-2.31 1.33-3.43 2a77.42 77.42 0 0 0-28.46 32.2 76 76 0 0 0-5.73 17.09 77.55 77.55 0 0 0-1.6 15.47Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M266.33 116c.58 2 1.09 4.08 1.51 6.18s.75 4.15 1 6.27a76 76 0 0 1 .51 9.23q0 3.84-.4 7.59-.33 3.26-.93 6.4c-.4 2.1-.88 4.16-1.44 6.2a76.55 76.55 0 0 1-7.1 17.13c-.66 1.15-1.34 2.3-2.05 3.42a76.76 76.76 0 0 1-65.36 35.81 77.63 77.63 0 0 1-8.4-.51Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="M265.14 162.44a75.86 75.86 0 0 1-5.66 12.56c-.66 1.15-1.34 2.3-2.05 3.42a77.19 77.19 0 0 1-28.11 26.37Z"
      style={{
        opacity: 0.05,
      }}
    />
    <path
      d="M255.81 93.73a75.63 75.63 0 0 1 6.46 11.36l-90 106.49-1.22-.33a76.1 76.1 0 0 1-11-4.17ZM226.38 68.27a77.92 77.92 0 0 1 11.52 7l-99.09 117.22a77.23 77.23 0 0 1-8.81-10.21ZM215.14 63.8q3.57 1.11 7 2.54l-94.75 112.08c-1.32-2.09-2.57-4.23-3.69-6.45ZM115.68 131.76c.1-1.42.25-2.83.42-4.22.06-.47.11-.94.18-1.4s.14-1 .22-1.43c.18-1 .36-2.1.57-3.14a76.78 76.78 0 0 1 20.73-38.46 77.27 77.27 0 0 1 13.46-10.83c1.12-.71 2.27-1.39 3.43-2a76.12 76.12 0 0 1 17.19-7.1c.76-.21 1.52-.39 2.29-.58Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M135.07 137.32a57.32 57.32 0 0 0 53.49 57.2c1.26.08 2.56.13 3.85.13a57.34 57.34 0 0 0 0-114.67c-1.29 0-2.59 0-3.85.13a57.34 57.34 0 0 0-53.49 57.21Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M135.07 137.32a57.32 57.32 0 0 0 53.49 57.2c1.26.08 2.56.13 3.85.13a57.34 57.34 0 0 0 0-114.67c-1.29 0-2.59 0-3.85.13a57.34 57.34 0 0 0-53.49 57.21Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M137.62 137.32c0 1 0 2 .08 2.92a54.77 54.77 0 0 0 51 51.73l.75.05c1 0 1.94.08 2.92.08A54.72 54.72 0 0 0 247 142c.14-1.55.2-3.11.2-4.7s-.05-2.89-.16-4.31a53.16 53.16 0 0 0-1.4-8.73 54.87 54.87 0 0 0-40.2-40.16 53.16 53.16 0 0 0-8.73-1.4c-1.42-.11-2.86-.16-4.31-.16-1.23 0-2.46 0-3.67.12-.34 0-.68 0-1 .08a54.3 54.3 0 0 0-22.65 7.1 54.7 54.7 0 0 0-27.43 47.48Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M137.62 137.32c0 1 0 2 .08 2.92a54.77 54.77 0 0 0 51 51.73l.75.05c1 0 1.94.08 2.92.08A54.72 54.72 0 0 0 247 142c.14-1.55.2-3.11.2-4.7s-.05-2.89-.16-4.31a53.16 53.16 0 0 0-1.4-8.73 54.87 54.87 0 0 0-40.2-40.16 53.16 53.16 0 0 0-8.73-1.4c-1.42-.11-2.86-.16-4.31-.16-1.23 0-2.46 0-3.67.12-.34 0-.68 0-1 .08a54.3 54.3 0 0 0-22.65 7.1 54.7 54.7 0 0 0-27.43 47.48Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M231 176.19A54.41 54.41 0 0 0 247 142c.14-1.55.21-3.11.21-4.7s-.06-2.89-.17-4.31a53.16 53.16 0 0 0-1.4-8.73 54.87 54.87 0 0 0-40.2-40.16 53.16 53.16 0 0 0-8.73-1.4c-1.42-.11-2.86-.16-4.31-.16-1.23 0-2.46 0-3.67.12-.34 0-.68 0-1 .08a54.3 54.3 0 0 0-22.65 7.1 55.09 55.09 0 0 0-6.86 4.75 54.46 54.46 0 0 0-16.12 38.78c0 1 0 1.95.09 2.92a54.75 54.75 0 0 0 51 51.73h.74c1 .05 1.95.08 2.93.08a54.38 54.38 0 0 0 34.13-12Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M231 176.19A54.41 54.41 0 0 0 247 142c.14-1.55.21-3.11.21-4.7s-.06-2.89-.17-4.31a53.16 53.16 0 0 0-1.4-8.73 54.87 54.87 0 0 0-40.2-40.16 53.16 53.16 0 0 0-8.73-1.4c-1.42-.11-2.86-.16-4.31-.16-1.23 0-2.46 0-3.67.12-.34 0-.68 0-1 .08a54.3 54.3 0 0 0-22.65 7.1 55.09 55.09 0 0 0-6.86 4.75 54.46 54.46 0 0 0-16.12 38.78c0 1 0 1.95.09 2.92a54.75 54.75 0 0 0 51 51.73h.74c1 .05 1.95.08 2.93.08a54.38 54.38 0 0 0 34.13-12Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M154.69 70.24c33.11-17.15 60-8.23 78 2.47 26 17.45 42.4 46.68 33.43 85.31v-.08a75.94 75.94 0 0 1-19.54 34 77.56 77.56 0 0 0 20.42-34.3v.07c8.27-32-2.13-57.41-19.48-75.34-18.59-17.14-50.09-34.07-92.83-12.13Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="m184.16 96.83-.06 7.38s-7.59-1.34-16.24 5.49c-.36.29-.68.57-1 .85a16 16 0 0 0-5.52 12.55 16 16 0 0 0 5.6 12.64c.29.25.63.48.92.73l.09.09 1 .72a9.93 9.93 0 0 0 .94.59 4.35 4.35 0 0 0 .39.23 5.16 5.16 0 0 0 .75.43 43.79 43.79 0 0 0 6.8 2.92c1.15.4 2.34.76 3.53 1.09 1.72.49 3.44.94 5.08 1.36l2 .49c4.68 1.13 8.09 2.05 10.25 3.27a5.58 5.58 0 0 1 2.75 2.72 5.34 5.34 0 0 1 .38 2c0 2.87-2.39 5.19-8.89 5-6.92-.26-10.81-3-11.74-7.22l-19 3.56c2.87 8.7 10.6 14.38 22.73 16.46v9.51l15.64.58v-9.51c6.57-.55 12-2.34 15.7-5.35l.1-.08a16.11 16.11 0 0 0 5.88-13.06c.06-12.53-9.89-17-20.54-19.92-9.95-2.67-18.8-3.33-18.78-9.42 0-3.8 3.48-5.38 8.6-5.2 8.17.29 11.35 5.11 12 8.61l20-3.63a21.87 21.87 0 0 0-2-5.15 20.78 20.78 0 0 0-6.15-6.85c-4.44-3.2-10.08-5-15.74-5.88v-9.54l-15.48-.86Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m184.16 96.83-.06 7.38s-7.59-1.34-16.24 5.49c-.36.29-.68.57-1 .85a16 16 0 0 0-5.52 12.55 16 16 0 0 0 5.6 12.64c.29.25.63.48.92.73l.09.09 1 .72a9.93 9.93 0 0 0 .94.59 4.35 4.35 0 0 0 .39.23 5.16 5.16 0 0 0 .75.43 43.79 43.79 0 0 0 6.8 2.92c1.15.4 2.34.76 3.53 1.09 1.72.49 3.44.94 5.08 1.36l2 .49c4.68 1.13 8.09 2.05 10.25 3.27a5.58 5.58 0 0 1 2.75 2.72 5.34 5.34 0 0 1 .38 2c0 2.87-2.39 5.19-8.89 5-6.92-.26-10.81-3-11.74-7.22l-19 3.56c2.87 8.7 10.6 14.38 22.73 16.46v9.51l15.64.58v-9.51c6.57-.55 12-2.34 15.7-5.35l.1-.08a16.11 16.11 0 0 0 5.88-13.06c.06-12.53-9.89-17-20.54-19.92-9.95-2.67-18.8-3.33-18.78-9.42 0-3.8 3.48-5.38 8.6-5.2 8.17.29 11.35 5.11 12 8.61l20-3.63a21.87 21.87 0 0 0-2-5.15 20.78 20.78 0 0 0-6.15-6.85c-4.44-3.2-10.08-5-15.74-5.88v-9.54l-15.48-.86Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M222.24 152.24a16.79 16.79 0 0 0-4.51-11.37c-2.78-3-6.74-5-11.14-6.56s-9.21-2.62-13.93-3.85a29.74 29.74 0 0 1-6.57-2.42 5.61 5.61 0 0 1-3.17-5.14c.07 2.29 1.56 3.83 3.53 4.8a31.14 31.14 0 0 0 6.7 2.18 131.9 131.9 0 0 1 14.12 3.69c4.38 1.61 8.42 3.68 11.11 6.82a15.6 15.6 0 0 1 3.06 5.32 21 21 0 0 1 .8 6.53Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m184.19 94.4 13.99 1.96 1.44 8.44.05-9.54-15.48-.86zM184.92 170.15l1.31 8.42 14.31 1.67-15.63-.58.01-9.51z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M162.19 153.69a21 21 0 0 0 2.07 4.36l5.46-5.77ZM222.22 151.83a16.15 16.15 0 0 0-5.25-12.3l-32.05 33.94v6.19l10.67.4 5-5.29v-4a43.93 43.93 0 0 0 4.37-.59ZM161.41 125a15.71 15.71 0 0 0 2.57 7.44l35.12-37.22-9.12-.5-5.85 6.2v3.29a13.45 13.45 0 0 0-3.05 0ZM167.93 136.58l1 .72a9.93 9.93 0 0 0 .94.59 4.35 4.35 0 0 0 .39.23 5.16 5.16 0 0 0 .75.43 43.79 43.79 0 0 0 6.8 2.92c.53.18 1.08.33 1.62.5l11.61-12.29c-4.84-1.37-8.15-3.06-8.14-6.78s3.48-5.38 8.6-5.2a13.87 13.87 0 0 1 8.29 2.71l11.44-12.12a41.68 41.68 0 0 0-11.63-3.5v-3.59L167 135.79c.28.24.6.47.87.7Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m143.19 91.23 1.27-16.72 1.27 16.72 16.72 1.27-16.72 1.28-1.27 16.71-1.27-16.71-16.72-1.28 16.72-1.27z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m156.3 104.95.62-8.11.61 8.11 8.11.61-8.11.62-.61 8.1-.62-8.1-8.1-.62 8.1-.61z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M431.55 365.53h8.47v17.33a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.45 30.45 0 0 1-5.91 9.45 40.43 40.43 0 0 1-3 3 45.44 45.44 0 0 1-3.5 2.92A58.9 58.9 0 0 1 421 410a78.196 78.196 0 0 1-6 3.29c-1.58.77-3.2 1.5-4.88 2.19-24.52 10.13-58.33 11.36-85.31 3.14q-2.76-.84-5.4-1.81c-2.53-.93-5-1.94-7.35-3.05-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.76a40.4 40.4 0 0 1-8.38-8.92c-.45-.67-.88-1.35-1.26-2a24.9 24.9 0 0 1-3.11-9.13 21.65 21.65 0 0 1-.17-2.91v-16.39h7.75c.62-.67 1.27-1.34 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 89.34 89.34 0 0 1 9.43-3.85c22.18-7.67 50.42-8.7 74.42-2.74a106.86 106.86 0 0 1 10.37 3.12c2.79 1 5.49 2.1 8.08 3.31a71.33 71.33 0 0 1 10.64 6.08 48.85 48.85 0 0 1 7.22 6.1Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M341.39 422.32v-35.18l12.88 1.35v35.21c-4.34-.26-8.65-.7-12.88-1.38Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M440 382.83a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.45 30.45 0 0 1-5.91 9.45c-.34.38-.7.75-1.06 1.13v-36.2l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m397.07 366.66 6.61-1.56v52.72q-3.16 1-6.47 1.91ZM380 423v-45.7l12.88 1v42.55A129.86 129.86 0 0 1 380 423ZM285.58 366.09h7.75c.62-.67 1.27-1.34 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 89.34 89.34 0 0 1 9.43-3.85q4.66-1.6 9.65-2.81l.06 73.39c-1.91-.47-3.8-1-5.65-1.55s-3.64-1.16-5.4-1.81c-2.53-.93-5-1.94-7.35-3.05-1.94-.91-3.77-1.85-5.5-2.84-1.49-.85-2.91-1.73-4.24-2.63s-2.59-1.82-3.76-2.76a40.4 40.4 0 0 1-8.38-8.92c-.45-.67-.88-1.35-1.26-2a24.9 24.9 0 0 1-3.11-9.13 21.65 21.65 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M290.14 396.55c-.45-.67-.88-1.35-1.26-2a24.9 24.9 0 0 1-3.11-9.13 21.65 21.65 0 0 1-.17-2.91v-16.39h7.75l.1-.1 2.61 1v36.46a38.1 38.1 0 0 1-5.92-6.93Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M413.58 334.69a91.007 91.007 0 0 0-8.08-3.31 105 105 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34 34 0 0 0-6.87 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.5 40.5 0 0 0 8.39 8.93c1.18.94 2.43 1.87 3.76 2.77s2.75 1.77 4.25 2.63c1.72 1 3.56 1.93 5.49 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.65 1 5.41 1.81c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29s3.53-2.28 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.38 22.38 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.48 46.48 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.06Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M413.58 334.69a91.007 91.007 0 0 0-8.08-3.31 105 105 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34 34 0 0 0-6.87 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.5 40.5 0 0 0 8.39 8.93c1.18.94 2.43 1.87 3.76 2.77s2.75 1.77 4.25 2.63c1.72 1 3.56 1.93 5.49 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.65 1 5.41 1.81c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29s3.53-2.28 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.38 22.38 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.48 46.48 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.06Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M413.58 334.69a91.007 91.007 0 0 0-8.08-3.31 105 105 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34 34 0 0 0-6.87 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40.5 40.5 0 0 0 8.39 8.93c1.18.94 2.43 1.87 3.76 2.77s2.75 1.77 4.25 2.63c1.72 1 3.56 1.93 5.49 2.83 2.36 1.11 4.82 2.13 7.35 3.05q2.65 1 5.41 1.81c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19 2.1-1 4.11-2.12 6-3.29s3.53-2.28 5.1-3.47c1.25-.95 2.42-1.93 3.5-2.92a38.34 38.34 0 0 0 3-3 30.71 30.71 0 0 0 5.92-9.46q.39-1.05.69-2.1a22.38 22.38 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.48 46.48 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.06Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M330.45 403.11c-1.92-.47-3.82-1-5.68-1.56s-3.64-1.16-5.41-1.81c-2.53-.92-5-1.94-7.35-3.05-1.93-.9-3.77-1.85-5.49-2.83-1.5-.86-2.91-1.73-4.25-2.63s-2.58-1.83-3.76-2.77a40.5 40.5 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.26-2a25.08 25.08 0 0 1-3.1-9.12 23.1 23.1 0 0 1 2.65-13.93 34 34 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.15 3.86-2.21 5.91-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M296 386.26a37.82 37.82 0 0 1-5.84-6.73c-.45-.67-.87-1.35-1.26-2a25.08 25.08 0 0 1-3.1-9.12 22.66 22.66 0 0 1 1.56-11.54ZM354.23 406.69c-4.34-.26-8.64-.73-12.86-1.38l-21.71-73.93 1-.38a107.42 107.42 0 0 1 10.41-3ZM392.88 403.76A129.86 129.86 0 0 1 380 406l-23.89-81.37c4.49-.2 9-.19 13.53 0ZM403.66 400.79q-3.2 1.05-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M417.4 336.62c1 .54 2 1.09 2.93 1.66l.94.56.94.6c.68.44 1.36.88 2 1.33a46.48 46.48 0 0 1 9.21 8.34 27.47 27.47 0 0 1 6.37 13.6 22.38 22.38 0 0 1-.63 9.26q-.3 1-.69 2.1a30.71 30.71 0 0 1-5.92 9.46c-.33.38-.69.75-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M400.44 342.5c-23.05-10.7-57.44-9.91-78.54 1.5-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.84-.52 1.66-1.06 2.42-1.6 18.13-12.79 14.77-31.1-8.29-41.8Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M400.44 342.5c-23.05-10.7-57.44-9.91-78.54 1.5-.89.48-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.84-.52 1.66-1.06 2.42-1.6 18.13-12.79 14.77-31.1-8.29-41.8Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M398.77 343.53c-.74-.34-1.5-.68-2.27-1-22-9.17-53.27-8.11-72.78 2.41l-.53.28c-.67.38-1.33.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.86 1.09.6 2.23 1.18 3.43 1.73s2.23 1 3.38 1.46a73.39 73.39 0 0 0 7.55 2.51c18.18 5 40.22 4.2 56.88-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.53c.21-.14.41-.29.61-.44 4.86-3.56 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.28-7.21Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M398.77 343.53c-.74-.34-1.5-.68-2.27-1-22-9.17-53.27-8.11-72.78 2.41l-.53.28c-.67.38-1.33.76-2 1.16a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.93 2.57 4 6.76 7.69 12.54 10.86 1.09.6 2.23 1.18 3.43 1.73s2.23 1 3.38 1.46a73.39 73.39 0 0 0 7.55 2.51c18.18 5 40.22 4.2 56.88-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.53c.21-.14.41-.29.61-.44 4.86-3.56 8-7.55 9.49-11.67 2.52-7 .2-14.39-6.76-20.64a45.77 45.77 0 0 0-11.28-7.21Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M396.52 349c.78.31 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.67 7.4 11.79a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.11-9.49 11.67-.2.15-.4.3-.61.44-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.29-38.7 7.1-56.88 2.09a73.39 73.39 0 0 1-7.55-2.51c-1.15-.46-2.28-.94-3.38-1.46s-2.34-1.13-3.43-1.73c-5.78-3.17-10-6.89-12.54-10.86a18.86 18.86 0 0 1-2.69-6.36 19.63 19.63 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.53-10.49 50.83-11.55 72.79-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M396.52 349c.78.31 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.67 7.4 11.79a17.21 17.21 0 0 1-.66 2.38c-1.48 4.12-4.63 8.11-9.49 11.67-.2.15-.4.3-.61.44-.73.52-1.51 1-2.32 1.53s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.29-38.7 7.1-56.88 2.09a73.39 73.39 0 0 1-7.55-2.51c-1.15-.46-2.28-.94-3.38-1.46s-2.34-1.13-3.43-1.73c-5.78-3.17-10-6.89-12.54-10.86a18.86 18.86 0 0 1-2.69-6.36 19.63 19.63 0 0 1 3.87-8.11 34.43 34.43 0 0 1 9.22-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.53-10.49 50.83-11.55 72.79-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M438.48 374.07c-8.74 19.43-33.19 27.18-53.12 30.68-30.3 4.36-63.28.71-86.71-16.53h.05c-6.37-5-11.8-11.73-12.94-19.87 1 8.22 6.24 15.17 12.61 20.32 18.87 14.63 45 19.37 70 18.67 25.15-1.48 58.68-8.25 70.11-33.27Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M331 380c8.56 3.59 17 2 24.77-.74l-2.06 2.12v8.89a37.08 37.08 0 0 0 5.29.86 38.59 38.59 0 0 0 9.21-.34 49.22 49.22 0 0 0 14.35-4.73l7.54 3.17 10.22-6.23v-8.88l-1.91-.82-.42-.18a8.08 8.08 0 0 0 .21-2.07 5.07 5.07 0 0 0-.06-.7c-.13-1.1.1-6.12.06-8.19 0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.2c-.35.06-.7.12-1.05.2-.14 0-.29.05-.42.09s-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-.94.33-1.87.68-2.78 1l2.69-2.8v-8.89c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42v8.89l2.36 1a5.25 5.25 0 0 0-.22 1.68v.07c.07 1.38-.06 7.26 0 8.82v.07c.13 2.59 2.28 4.91 6.71 6.76Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M398.19 362.54c0 2.28-.16 6.75 0 7.77 0 .24 0 .46.06.71a8.3 8.3 0 0 1-.22 2.08l-5.42-2.28s5.39-2.64 5.58-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M343.47 362.41c-2.26-1-2.64-2.71 1.52-5.39 4.46-2.83 9.05-3.57 12.95-2.59l8.82-9.19v8.88l-2.66 2.8c-.1 0-.17.07-.27.11-1.45.55-2.85 1.12-4.19 1.67-.54.25-1.07.47-1.59.67a55.34 55.34 0 0 1-8.89 3.27 10.07 10.07 0 0 1-3.86.27 7.16 7.16 0 0 1-1.83-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m324.47 362.57-2.34-1v-8.88l7.51 3.15c-2.79 2.34-4.58 4.61-5.17 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m390.13 380.34 10.19-6.26V383l-10.2 6.29-7.52-3.18a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.89a36.15 36.15 0 0 0 14.5.51 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M382.61 386.07a37.16 37.16 0 0 1-11.44 4.68 30.51 30.51 0 0 1-12.13.4 58.49 58.49 0 0 0 11.95-1.4c2-.43 3.91-1 5.85-1.6s3.84-1.35 5.77-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M324.27 373.21c-.07-1.57.07-7.44 0-8.84.16 2.63 2.29 4.94 6.75 6.81 9.94 4.14 19.61 1.42 28.46-2.13 8.21-3.33 14.18-6.86 19-4.86 3 1.27 2.15 3.26-1.13 5.36a18.15 18.15 0 0 1-14.2 2.24l-7.32 7.54c-7.84 2.77-16.22 4.3-24.76.73-4.51-1.91-6.64-4.22-6.8-6.85Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M357.94 363.32v-8.89l8.81-9.2v8.89l-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M357.94 363.32v-8.89l8.81-9.2v8.89l-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m322.12 352.71 7.54 3.15-5.17 6.72-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m322.12 352.71 7.54 3.15-5.17 6.72-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m398.41 373.27-5.83-2.46s5.73-2.77 5.62-8.68c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.2c-.35.06-.7.12-1.05.2-.15 0-.29.05-.42.09s-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.69l-1.59.67a57 57 0 0 1-8.9 3.27 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-1-2.65-2.72 1.53-5.4 4.45-2.84 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42 7.54 3.15c-3.6 3-5.49 5.83-5.39 8.4v.07c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.64a36.42 36.42 0 0 0 14.51.52 49.25 49.25 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m398.41 373.27-5.83-2.46s5.73-2.77 5.62-8.68c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.58-6.47a27.27 27.27 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.2c-.35.06-.7.12-1.05.2-.15 0-.29.05-.42.09s-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.69l-1.59.67a57 57 0 0 1-8.9 3.27 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-1-2.65-2.72 1.53-5.4 4.45-2.84 9-3.58 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.42 7.54 3.15c-3.6 3-5.49 5.83-5.39 8.4v.07c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.26 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.64a36.42 36.42 0 0 0 14.51.52 49.25 49.25 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M331 371.13a28.41 28.41 0 0 0 11.8 1.83 43.09 43.09 0 0 0 12-2.55c3.95-1.3 7.74-3 11.61-4.62a39 39 0 0 1 6-2 9.84 9.84 0 0 1 6 .35 9.8 9.8 0 0 0-6-.08 40.08 40.08 0 0 0-5.85 2.11c-3.82 1.66-7.63 3.41-11.6 4.76a42.35 42.35 0 0 1-12.23 2.46 28.91 28.91 0 0 1-6.11-.46 29.29 29.29 0 0 1-5.62-1.8Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m390.13 380.35.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m390.13 380.35.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m400.32 374.08-10.15 5.28-7.58-2.17 7.54 3.16 10.19-6.27zM339.73 349.43l-7.5-2.22-10.11 5.5 10.07-6.42 7.54 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M366.75 345.23a39.38 39.38 0 0 0-4.73-.56l1.23 4.21ZM331.37 371.25a27.45 27.45 0 0 0 13 1.83l-7.27-24.75-4.9-2-6.87 4.38 1.14 3.85 3.2 1.34a26.27 26.27 0 0 0-2.21 2ZM390 354.37a26.33 26.33 0 0 0-7.49-1.37l8 27.12 6-3.69-1.33-4.52-2.6-1.1a11 11 0 0 0 1.9-1.27ZM376.81 353.3l-1.2.2c-.35.06-.7.12-1.05.2-.14 0-.29.05-.42.09s-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-.47.16-.93.35-1.39.52l2.63 9c4.05-1.59 7.43-2.44 10.37-1.21s2.14 3.25-1.15 5.36a17.59 17.59 0 0 1-7.24 2.61l2.6 8.84a52 52 0 0 0 9.92-3.77l2.84 1.19-7.43-25.27-1.1.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M421.43 344.48h8.47v17.34a23.18 23.18 0 0 1-.83 6.15c-.2.7-.44 1.4-.7 2.1a30.49 30.49 0 0 1-5.91 9.46 40.34 40.34 0 0 1-3 3 43.27 43.27 0 0 1-3.5 2.92 58.9 58.9 0 0 1-5.1 3.47 74.91 74.91 0 0 1-6 3.29c-1.58.77-3.2 1.51-4.88 2.19-24.52 10.13-58.33 11.37-85.31 3.14q-2.76-.84-5.4-1.81c-2.53-.92-5-1.94-7.35-3q-2.91-1.37-5.5-2.84c-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.75-2.77a40.51 40.51 0 0 1-8.42-8.99c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91v-16.42h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 90.62 90.62 0 0 1 9.43-3.85c22.18-7.66 50.42-8.7 74.42-2.74q5.36 1.33 10.37 3.12c2.79 1 5.49 2.1 8.08 3.31a71.35 71.35 0 0 1 10.64 6.09 49.31 49.31 0 0 1 7.28 6.17Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M331.27 401.27V366.1l12.88 1.34v35.22c-4.34-.26-8.65-.73-12.88-1.39Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M429.9 361.79a23.18 23.18 0 0 1-.83 6.15c-.2.7-.44 1.4-.7 2.1a30.49 30.49 0 0 1-5.91 9.46c-.34.38-.7.75-1.06 1.12v-36.19l.06.05h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m387 345.62 6.61-1.57v52.72q-3.16 1.05-6.47 1.92ZM369.91 401.92v-45.69l12.88.94v42.56c-4.19.93-8.5 1.66-12.88 2.19ZM275.46 345.05h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 90.62 90.62 0 0 1 9.43-3.85c3.1-1.07 6.33-2 9.64-2.81l.06 73.4c-1.9-.48-3.79-1-5.64-1.56s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3q-2.91-1.37-5.5-2.84c-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.75-2.77a40.51 40.51 0 0 1-8.45-9.01c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M280 375.5c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91v-16.42h7.75a.94.94 0 0 1 .1-.1l2.61.95v36.45a38.23 38.23 0 0 1-5.92-6.85Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M403.46 313.64a94.12 94.12 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.23-10.37-3.12-24-6-52.24-4.92-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3q2.64 1 5.41 1.82c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.2q3.15-1.53 6-3.29a55.88 55.88 0 0 0 5.09-3.47 45.27 45.27 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.56 30.56 0 0 0 5.91-9.45c.27-.7.5-1.4.7-2.11a22.53 22.53 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.24 46.24 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.62-6.03Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M403.46 313.64a94.12 94.12 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.23-10.37-3.12-24-6-52.24-4.92-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3q2.64 1 5.41 1.82c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.2q3.15-1.53 6-3.29a55.88 55.88 0 0 0 5.09-3.47 45.27 45.27 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.56 30.56 0 0 0 5.91-9.45c.27-.7.5-1.4.7-2.11a22.53 22.53 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.24 46.24 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.62-6.03Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M403.46 313.64a94.12 94.12 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.23-10.37-3.12-24-6-52.24-4.92-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.35 34.35 0 0 0-6.87 8.93 23.13 23.13 0 0 0-2.65 13.93 25.06 25.06 0 0 0 3.1 9.13c.39.68.81 1.35 1.26 2a40.23 40.23 0 0 0 8.39 8.92q1.77 1.43 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.11 4.82 2.12 7.35 3q2.64 1 5.41 1.82c27 8.22 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.2q3.15-1.53 6-3.29a55.88 55.88 0 0 0 5.09-3.47 45.27 45.27 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.56 30.56 0 0 0 5.91-9.45c.27-.7.5-1.4.7-2.11a22.53 22.53 0 0 0 .63-9.26 27.51 27.51 0 0 0-6.37-13.6 46.24 46.24 0 0 0-9.22-8.33 70.72 70.72 0 0 0-10.62-6.03Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M320.33 382.07c-1.92-.48-3.82-1-5.68-1.56s-3.65-1.17-5.41-1.82c-2.53-.92-5-1.93-7.35-3-1.93-.91-3.77-1.85-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.77a40.23 40.23 0 0 1-8.39-8.92c-.45-.68-.87-1.35-1.26-2a25 25 0 0 1-3.1-9.12 23.13 23.13 0 0 1 2.65-13.93 34.35 34.35 0 0 1 6.87-8.93 54.34 54.34 0 0 1 9.33-7.07c1.88-1.14 3.86-2.21 5.91-3.21Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M285.83 365.21a38.46 38.46 0 0 1-5.83-6.72c-.45-.68-.87-1.35-1.26-2a25 25 0 0 1-3.1-9.12 22.66 22.66 0 0 1 1.56-11.54ZM344.11 385.65c-4.34-.27-8.64-.73-12.87-1.39l-21.7-73.93 1-.38c3.34-1.15 6.83-2.14 10.41-3ZM382.76 382.72c-4.18.93-8.49 1.66-12.87 2.19L346 303.53c4.49-.2 9-.18 13.53 0ZM393.54 379.74q-3.19 1.06-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M407.28 315.57c1 .55 2 1.1 2.93 1.66l.94.57a63.191 63.191 0 0 1 2.94 1.93 46.24 46.24 0 0 1 9.22 8.33 27.51 27.51 0 0 1 6.37 13.6 22.53 22.53 0 0 1-.63 9.26c-.2.71-.43 1.41-.7 2.11a30.56 30.56 0 0 1-5.91 9.45c-.33.38-.69.76-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M390.32 321.45c-23.05-10.7-57.42-9.9-78.54 1.49-.89.48-1.78 1-2.63 1.51-20.79 12.8-18.17 32.22 5.86 43.38s60.38 9.83 81.18-3c.84-.52 1.65-1.06 2.42-1.6 18.13-12.76 14.77-31.07-8.29-41.78Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M390.32 321.45c-23.05-10.7-57.42-9.9-78.54 1.49-.89.48-1.78 1-2.63 1.51-20.79 12.8-18.17 32.22 5.86 43.38s60.38 9.83 81.18-3c.84-.52 1.65-1.06 2.42-1.6 18.13-12.76 14.77-31.07-8.29-41.78Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M388.65 322.49c-.74-.35-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.53.29c-.67.37-1.34.76-2 1.15a34.42 34.42 0 0 0-9.21 7.94c-5.22 6.69-5.54 14.17-1.16 20.93 2.57 4 6.76 7.68 12.54 10.85 1.08.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.05 71.05 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.1 3-1.68s1.58-1 2.32-1.53c.21-.15.41-.29.61-.45 4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.48 45.48 0 0 0-11.28-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M388.65 322.49c-.74-.35-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.53.29c-.67.37-1.34.76-2 1.15a34.42 34.42 0 0 0-9.21 7.94c-5.22 6.69-5.54 14.17-1.16 20.93 2.57 4 6.76 7.68 12.54 10.85 1.08.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.05 71.05 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a59.16 59.16 0 0 0 6.79-3c1-.53 2-1.1 3-1.68s1.58-1 2.32-1.53c.21-.15.41-.29.61-.45 4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.48 45.48 0 0 0-11.28-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M386.4 328c.78.32 1.53.65 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.6 17.6 0 0 1-.66 2.38c-1.48 4.11-4.63 8.1-9.49 11.66-.2.16-.4.3-.61.45-.74.51-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.05 71.05 0 0 1-7.55-2.51c-1.15-.45-2.28-.94-3.38-1.45s-2.35-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.73 34.73 0 0 1 9.21-7.94c.65-.39 1.31-.78 2-1.15l.53-.29c19.53-10.52 50.84-11.59 72.8-2.37Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M386.4 328c.78.32 1.53.65 2.27 1a45.39 45.39 0 0 1 11.26 7.18c4.07 3.65 6.54 7.68 7.4 11.8a17.6 17.6 0 0 1-.66 2.38c-1.48 4.11-4.63 8.1-9.49 11.66-.2.16-.4.3-.61.45-.74.51-1.51 1-2.32 1.53s-1.94 1.15-3 1.68a59.16 59.16 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.05 71.05 0 0 1-7.55-2.51c-1.15-.45-2.28-.94-3.38-1.45s-2.35-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a18.94 18.94 0 0 1-2.69-6.36 19.57 19.57 0 0 1 3.87-8.11 34.73 34.73 0 0 1 9.21-7.94c.65-.39 1.31-.78 2-1.15l.53-.29c19.53-10.52 50.84-11.59 72.8-2.37Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M428.35 353c-8.73 19.43-33.18 27.18-53.11 30.68-30.3 4.35-63.28.7-86.71-16.54h.05c-6.37-5-11.8-11.73-12.94-19.87 1 8.23 6.24 15.18 12.61 20.33 18.87 14.63 45 19.36 70 18.66 25.14-1.44 58.68-8.26 70.1-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M320.91 359c8.56 3.58 16.95 2 24.77-.74l-2.06 2.12v8.88a35.77 35.77 0 0 0 5.29.87 38.59 38.59 0 0 0 9.21-.35 49 49 0 0 0 14.35-4.78l7.55 3.16 10.18-6.26V353l-1.92-.82-.41-.17a8.16 8.16 0 0 0 .21-2.08 5.21 5.21 0 0 0-.06-.7c-.13-1.1.09-6.11.06-8.18 0-.25 0-.48-.07-.71-.3-2.48-2.36-4.71-6.58-6.46A27.29 27.29 0 0 0 368 332.1l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.05-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-.94.33-1.87.68-2.78 1.05l2.68-2.81v-8.88c-8.67-1.66-17.92-.25-27 4.2l-7.55-3.15-10.12 6.44v8.89l2.36 1a5.49 5.49 0 0 0-.23 1.68v.07c.07 1.38-.06 7.26 0 8.82v.06c.17 2.6 2.32 4.91 6.78 6.81Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M388.07 341.5c0 2.27-.16 6.75-.05 7.76 0 .25.05.47.07.71a8.33 8.33 0 0 1-.22 2.09l-5.42-2.28s5.47-2.65 5.62-8.28Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M333.35 341.36c-2.26-.95-2.64-2.71 1.52-5.39 4.46-2.82 9.05-3.56 12.95-2.58l8.82-9.19v8.87l-2.66 2.8c-.1 0-.17.08-.27.11-1.45.55-2.85 1.13-4.19 1.68-.54.24-1.07.46-1.59.67a55.16 55.16 0 0 1-8.9 3.26 9.81 9.81 0 0 1-3.85.27 7.16 7.16 0 0 1-1.83-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m314.35 341.53-2.35-1v-8.88l7.51 3.15c-2.78 2.33-4.57 4.6-5.16 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m380 359.3 10.2-6.3v8.89L380 368.2l-7.51-3.2a48.5 48.5 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.88a36 36 0 0 0 14.5.5 48.78 48.78 0 0 0 14.36-4.71Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M372.49 365a36.94 36.94 0 0 1-11.44 4.69 30.51 30.51 0 0 1-12.13.39 57.12 57.12 0 0 0 11.95-1.4c2-.43 3.91-1 5.85-1.6s3.84-1.32 5.77-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M314.15 352.17v-.05c-.07-1.57.07-7.45 0-8.84.16 2.63 2.28 4.94 6.75 6.8 9.94 4.15 19.61 1.42 28.46-2.13 8.21-3.32 14.18-6.85 19-4.85 3 1.26 2.15 3.26-1.13 5.36a18.19 18.19 0 0 1-14.2 2.23l-7.32 7.55c-7.83 2.77-16.21 4.29-24.75.72-4.53-1.85-6.65-4.17-6.81-6.79Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M347.82 342.27v-8.88l8.81-9.2v8.88l-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M347.82 342.27v-8.88l8.81-9.2v8.88l-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m312 331.67 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m312 331.67 7.54 3.14-5.17 6.73-2.36-.98-.01-8.89z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m388.28 352.22-5.82-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.71-.3-2.48-2.36-4.71-6.58-6.46A27.29 27.29 0 0 0 368 332.1l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.05-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.45.56-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-.95-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.58l8.81-9.2c-8.67-1.66-17.92-.25-27 4.2l-7.55-3.15-10.15 6.44 7.54 3.14c-3.6 3-5.49 5.84-5.4 8.41v.07c.14 2.61 2.29 4.93 6.75 6.79 9.92 4.15 19.6 1.43 28.44-2.13 8.22-3.33 14.19-6.86 19-4.84 3 1.26 2.14 3.25-1.15 5.35A18.19 18.19 0 0 1 353 350.7l-9.38 9.65a37.22 37.22 0 0 0 5.3.86 38 38 0 0 0 9.2-.35 48.92 48.92 0 0 0 14.35-4.72l7.54 3.17 10.18-6.31Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m388.28 352.22-5.82-2.45s5.73-2.77 5.62-8.68c0-.25 0-.48-.07-.71-.3-2.48-2.36-4.71-6.58-6.46A27.29 27.29 0 0 0 368 332.1l-1.15.15h-.12l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.05-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91c-1 .36-2 .74-3 1.14-1.45.56-2.86 1.15-4.2 1.7l-1.59.67a55.79 55.79 0 0 1-8.9 3.26 10.2 10.2 0 0 1-3.85.27 8.1 8.1 0 0 1-1.81-.5c-2.27-.95-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.58l8.81-9.2c-8.67-1.66-17.92-.25-27 4.2l-7.55-3.15-10.15 6.44 7.54 3.14c-3.6 3-5.49 5.84-5.4 8.41v.07c.14 2.61 2.29 4.93 6.75 6.79 9.92 4.15 19.6 1.43 28.44-2.13 8.22-3.33 14.19-6.86 19-4.84 3 1.26 2.14 3.25-1.15 5.35A18.19 18.19 0 0 1 353 350.7l-9.38 9.65a37.22 37.22 0 0 0 5.3.86 38 38 0 0 0 9.2-.35 48.92 48.92 0 0 0 14.35-4.72l7.54 3.17 10.18-6.31Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M320.9 350.08a28.49 28.49 0 0 0 11.81 1.84 43.81 43.81 0 0 0 12-2.55c3.95-1.3 7.74-3 11.61-4.63a40.08 40.08 0 0 1 6-2 9.92 9.92 0 0 1 6 .36 9.8 9.8 0 0 0-6-.09 39 39 0 0 0-5.85 2.12c-3.82 1.66-7.63 3.4-11.6 4.76a42.4 42.4 0 0 1-12.23 2.45 28.91 28.91 0 0 1-6.11-.46 29.61 29.61 0 0 1-5.63-1.8Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m380.01 359.31.01 8.88-7.55-3.16v-8.89l7.54 3.17z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m380.01 359.31.01 8.88-7.55-3.16v-8.89l7.54 3.17z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m390.19 353.04-10.14 5.27-7.58-2.17 7.54 3.17 10.18-6.27zM329.61 328.39l-7.5-2.23-10.11 5.51 10.06-6.43 7.55 3.15z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M356.63 324.19a37.39 37.39 0 0 0-4.74-.56l1.24 4.21ZM321.25 350.21a27.57 27.57 0 0 0 13 1.83L327 327.29l-4.91-2.05-6.86 4.38 1.13 3.86 3.21 1.33a24.84 24.84 0 0 0-2.21 2.05ZM379.91 333.32a26.37 26.37 0 0 0-7.49-1.36l8 27.12 6-3.7-1.32-4.52-2.6-1.09a11 11 0 0 0 1.9-1.27ZM366.69 332.26l-1.2.19c-.35.06-.7.12-1.05.2l-.42.09c-.27.05-.55.11-.8.18a62.61 62.61 0 0 0-6.49 1.91l-1.39.52 2.63 9c4-1.6 7.43-2.44 10.37-1.21s2.14 3.25-1.15 5.35a17.76 17.76 0 0 1-7.24 2.62l2.6 8.83a51.31 51.31 0 0 0 9.92-3.77l2.84 1.2-7.4-25.23-1.1.14Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M413.78 322.44h8.47v17.33a22.46 22.46 0 0 1-.83 6.16c-.2.7-.43 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46 42.15 42.15 0 0 1-3 3c-1.08 1-2.25 2-3.51 2.92a58.9 58.9 0 0 1-5.1 3.47 76.358 76.358 0 0 1-6 3.29q-2.37 1.16-4.87 2.19C367.8 382.47 334 383.7 307 375.48q-2.76-.84-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05a82.95 82.95 0 0 1-5.5-2.83c-1.49-.86-2.91-1.73-4.24-2.64s-2.58-1.82-3.76-2.76a40.48 40.48 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91v-16.39h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 88.65 88.65 0 0 1 9.44-3.85c22.17-7.67 50.42-8.7 74.41-2.74a107.91 107.91 0 0 1 10.38 3.12c2.78 1 5.48 2.1 8.07 3.31a71.33 71.33 0 0 1 10.64 6.08 48.35 48.35 0 0 1 7.27 6.15Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M323.62 379.23v-35.18l12.88 1.35v35.22c-4.34-.27-8.65-.73-12.88-1.39Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M422.25 339.74a22.46 22.46 0 0 1-.83 6.16c-.2.7-.43 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46c-.33.37-.69.75-1.05 1.12v-36.2l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m379.3 323.57 6.61-1.56v52.72c-2.11.7-4.27 1.33-6.47 1.91ZM362.26 379.88v-45.7l12.88.95v42.55a129.86 129.86 0 0 1-12.88 2.2ZM267.81 323h7.75c.62-.68 1.27-1.35 2-2a54.74 54.74 0 0 1 9.33-7.07 74.22 74.22 0 0 1 6.67-3.6 88.65 88.65 0 0 1 9.44-3.85c3.1-1.07 6.33-2 9.64-2.81l.06 73.39c-1.91-.47-3.8-1-5.65-1.55s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05a82.95 82.95 0 0 1-5.5-2.83c-1.49-.86-2.91-1.73-4.24-2.64s-2.58-1.82-3.76-2.76a40.48 40.48 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M272.37 353.46c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91v-16.39h7.75a.94.94 0 0 1 .1-.1l2.61.94v36.46a38.1 38.1 0 0 1-5.92-6.88Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M395.81 291.6a91.007 91.007 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.64 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.39 8.93q1.75 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.83q3.56 1.66 7.35 3 2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14q2.51-1 4.88-2.19c2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.01Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M395.81 291.6a91.007 91.007 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.64 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.39 8.93q1.75 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.83q3.56 1.66 7.35 3 2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14q2.51-1 4.88-2.19c2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.01Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M395.81 291.6a91.007 91.007 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.25 23.25 0 0 0-2.64 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.39 8.93q1.75 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63 1.73 1 3.56 1.93 5.49 2.83q3.56 1.66 7.35 3 2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14q2.51-1 4.88-2.19c2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47 45.44 45.44 0 0 0 3.5-2.92 40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.63-6.01Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M312.68 360c-1.92-.48-3.82-1-5.68-1.57s-3.64-1.16-5.41-1.81q-3.79-1.38-7.35-3c-1.93-.9-3.76-1.85-5.49-2.83-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.75-2.77a40.28 40.28 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.25 23.25 0 0 1 2.65-13.93 34.4 34.4 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.15 3.86-2.21 5.91-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M278.19 343.17a37.82 37.82 0 0 1-5.84-6.73c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 22.66 22.66 0 0 1 1.56-11.54ZM336.46 363.6c-4.33-.26-8.64-.72-12.86-1.38l-21.71-73.93 1.06-.38a107.42 107.42 0 0 1 10.41-3ZM375.12 360.67a127.2 127.2 0 0 1-12.88 2.19l-23.89-81.37c4.5-.2 9-.19 13.53 0ZM385.89 357.7c-2.12.7-4.3 1.35-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M399.63 293.53c1 .54 2 1.1 2.93 1.66l.94.56.94.6c.69.44 1.36.88 2 1.33a46.2 46.2 0 0 1 9.21 8.34 27.47 27.47 0 0 1 6.37 13.6 22.37 22.37 0 0 1-.63 9.26c-.19.7-.43 1.4-.69 2.1a30.67 30.67 0 0 1-5.91 9.46c-.34.38-.7.75-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M382.68 299.41c-23.06-10.7-57.42-9.9-78.55 1.48-.89.49-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.12-12.77 14.76-31.08-8.29-41.78Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M382.68 299.41c-23.06-10.7-57.42-9.9-78.55 1.48-.89.49-1.78 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.12-12.77 14.76-31.08-8.29-41.78Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M381 300.44c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.53 14.17-1.16 20.94 2.58 4 6.76 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a74.21 74.21 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a61.11 61.11 0 0 0 6.8-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.52l.61-.45c4.87-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.4-6.76-20.64a45.49 45.49 0 0 0-11.3-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M381 300.44c-.74-.34-1.49-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.53 14.17-1.16 20.94 2.58 4 6.76 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a74.21 74.21 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a61.11 61.11 0 0 0 6.8-3c1-.54 2-1.1 3-1.69s1.59-1 2.32-1.52l.61-.45c4.87-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.4-6.76-20.64a45.49 45.49 0 0 0-11.3-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M378.76 305.91c.77.32 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.67 7.39 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.62 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a61.11 61.11 0 0 1-6.8 3c-16.66 6.3-38.7 7.11-56.88 2.09a74.21 74.21 0 0 1-7.55-2.51c-1.15-.46-2.27-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a18.76 18.76 0 0 1-2.68-6.37 19.39 19.39 0 0 1 3.87-8.11 34.4 34.4 0 0 1 9.21-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.54-10.48 50.84-11.55 72.81-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M378.76 305.91c.77.32 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.67 7.39 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.62 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a61.11 61.11 0 0 1-6.8 3c-16.66 6.3-38.7 7.11-56.88 2.09a74.21 74.21 0 0 1-7.55-2.51c-1.15-.46-2.27-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a18.76 18.76 0 0 1-2.68-6.37 19.39 19.39 0 0 1 3.87-8.11 34.4 34.4 0 0 1 9.21-7.93c.64-.4 1.31-.78 2-1.15l.53-.29c19.54-10.48 50.84-11.55 72.81-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M420.71 331c-8.74 19.43-33.19 27.18-53.12 30.69-30.3 4.35-63.28.7-86.71-16.54h.05c-6.37-5-11.79-11.73-12.94-19.87 1 8.22 6.24 15.18 12.61 20.32 18.87 14.62 45 19.36 70 18.66 25.15-1.49 58.69-8.26 70.11-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M313.26 336.92c8.56 3.59 16.95 2 24.77-.73L336 338.3v8.89a37.08 37.08 0 0 0 5.29.86 38 38 0 0 0 9.21-.34 49.22 49.22 0 0 0 14.33-4.71l7.54 3.17 10.18-6.27V331l-1.91-.82-.42-.17a8.2 8.2 0 0 0 .21-2.08 5.07 5.07 0 0 0-.06-.7c-.13-1.1.1-6.12.06-8.19 0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.33 27.33 0 0 0-13.48-1.79l-1.14.14H359l-1.2.2c-.35.06-.69.12-1 .2-.15 0-.3.05-.43.09s-.55.1-.79.17a62.1 62.1 0 0 0-6.5 1.92c-.93.33-1.86.68-2.78 1l2.7-2.8v-8.89c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43v8.88l2.36 1a5.47 5.47 0 0 0-.22 1.68v.06c.08 1.38-.06 7.26 0 8.82v.07c.12 2.63 2.27 4.95 6.73 6.81Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M380.43 319.46c0 2.27-.17 6.74-.05 7.76 0 .24 0 .46.06.71a8.3 8.3 0 0 1-.22 2.08l-5.42-2.28s5.47-2.64 5.63-8.27Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M325.7 319.32c-2.26-.95-2.63-2.71 1.53-5.39 4.45-2.83 9-3.56 12.94-2.59l8.82-9.19V311l-2.67 2.8-.27.11c-1.45.55-2.85 1.12-4.18 1.68-.55.24-1.08.46-1.6.66a55 55 0 0 1-8.89 3.27 10.07 10.07 0 0 1-3.86.27 7.16 7.16 0 0 1-1.82-.47Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m306.7 319.48-2.34-1v-8.88l7.51 3.15c-2.79 2.34-4.58 4.61-5.17 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m372.36 337.25 10.19-6.25v8.89l-10.2 6.28-7.51-3.17a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.91a36.15 36.15 0 0 0 14.5.51 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M364.84 343a37.16 37.16 0 0 1-11.44 4.68 30.33 30.33 0 0 1-12.13.4 58.58 58.58 0 0 0 12-1.4c2-.43 3.9-1 5.84-1.6s3.8-1.37 5.73-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M306.51 330.12v-.05c-.06-1.56.07-7.44 0-8.83.15 2.62 2.28 4.94 6.74 6.8 9.94 4.14 19.61 1.42 28.46-2.13 8.21-3.32 14.18-6.86 19-4.86 3 1.27 2.15 3.27-1.13 5.36a18.15 18.15 0 0 1-14.2 2.24l-7.38 7.54c-7.84 2.77-16.22 4.3-24.76.73-4.45-1.86-6.58-4.17-6.73-6.8Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m340.18 320.23-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m340.18 320.23-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m304.35 309.63 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m304.35 309.63 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m380.64 330.18-5.83-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.33 27.33 0 0 0-13.48-1.79l-1.14.14H359l-1.2.2c-.35.06-.69.12-1 .2-.15 0-.3.05-.43.09s-.55.1-.79.17a62.1 62.1 0 0 0-6.5 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7-.54.24-1.07.45-1.59.66a57.37 57.37 0 0 1-8.89 3.27 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-.95-2.66-2.72 1.52-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43 7.54 3.14c-3.59 3-5.49 5.83-5.39 8.41v.06c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.23-3.33 14.19-6.86 19-4.85 3 1.27 2.13 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24L336 338.3a36.11 36.11 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49.15 49.15 0 0 0 14.34-4.72l7.54 3.16 10.16-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m380.64 330.18-5.83-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.33 27.33 0 0 0-13.48-1.79l-1.14.14H359l-1.2.2c-.35.06-.69.12-1 .2-.15 0-.3.05-.43.09s-.55.1-.79.17a62.1 62.1 0 0 0-6.5 1.92c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7-.54.24-1.07.45-1.59.66a57.37 57.37 0 0 1-8.89 3.27 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-.95-2.66-2.72 1.52-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43 7.54 3.14c-3.59 3-5.49 5.83-5.39 8.41v.06c.15 2.62 2.29 4.93 6.76 6.8 9.91 4.15 19.59 1.42 28.43-2.13 8.23-3.33 14.19-6.86 19-4.85 3 1.27 2.13 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24L336 338.3a36.11 36.11 0 0 0 5.3.87 38.59 38.59 0 0 0 9.21-.35 49.15 49.15 0 0 0 14.34-4.72l7.54 3.16 10.16-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M313.26 328a28.43 28.43 0 0 0 11.8 1.83 43.23 43.23 0 0 0 12.05-2.55c3.94-1.3 7.73-3 11.6-4.62a39.32 39.32 0 0 1 6-2 9.88 9.88 0 0 1 6 .35 9.84 9.84 0 0 0-6-.08 40.5 40.5 0 0 0-5.85 2.11c-3.81 1.67-7.63 3.41-11.6 4.76a42.35 42.35 0 0 1-12.26 2.51 28.77 28.77 0 0 1-6.1-.46 29.17 29.17 0 0 1-5.64-1.85Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m372.36 337.26.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m372.36 337.26.01 8.89-7.54-3.17-.01-8.88 7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m382.55 331-10.15 5.27-7.58-2.17 7.54 3.16 10.19-6.26zM321.96 306.34l-7.5-2.22-10.11 5.51 10.07-6.43 7.54 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M349 302.14a39.38 39.38 0 0 0-4.73-.56l1.23 4.22ZM313.6 328.17a27.56 27.56 0 0 0 13 1.82l-7.27-24.75-4.9-2-6.86 4.38 1.13 3.85 3.2 1.34a28.06 28.06 0 0 0-2.21 2ZM372.26 311.28a26.33 26.33 0 0 0-7.49-1.37l8 27.12 6-3.69-1.33-4.52-2.6-1.09a11.74 11.74 0 0 0 1.91-1.28ZM359 310.21l-1.2.2c-.35.06-.7.12-1 .2-.15 0-.3.05-.43.09s-.55.1-.79.17a62.1 62.1 0 0 0-6.5 1.92c-.47.16-.93.35-1.39.52l2.63 9c4.06-1.6 7.43-2.45 10.38-1.22s2.13 3.25-1.15 5.36a17.7 17.7 0 0 1-7.24 2.61l2.59 8.84a51.72 51.72 0 0 0 9.92-3.77l2.85 1.19-7.41-25.22-1.09.13Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M433.13 302.68h8.47V320a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46 42.34 42.34 0 0 1-3 3 45.44 45.44 0 0 1-3.5 2.92 58.9 58.9 0 0 1-5.1 3.47 78.196 78.196 0 0 1-6 3.29c-1.58.77-3.2 1.5-4.88 2.19-24.52 10.13-58.33 11.36-85.31 3.14q-2.76-.84-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05q-2.91-1.37-5.5-2.83c-1.5-.86-2.91-1.73-4.25-2.64s-2.58-1.82-3.75-2.76a40.72 40.72 0 0 1-8.39-8.93c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91v-16.43h7.75c.62-.68 1.27-1.35 2-2a54.34 54.34 0 0 1 9.33-7.07 72.88 72.88 0 0 1 6.67-3.6 88.48 88.48 0 0 1 9.43-3.85c22.18-7.67 50.42-8.7 74.42-2.74a107.71 107.71 0 0 1 10.37 3.12c2.79 1 5.49 2.1 8.08 3.31a70.71 70.71 0 0 1 10.63 6.08 49 49 0 0 1 7.26 6.18Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M343 359.47v-35.18l12.88 1.35v35.22c-4.37-.27-8.68-.73-12.88-1.39Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M441.6 320a23.25 23.25 0 0 1-.83 6.16c-.2.7-.44 1.4-.7 2.1a30.6 30.6 0 0 1-5.91 9.46c-.34.37-.7.75-1.06 1.12v-36.2l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m398.65 303.81 6.61-1.56v52.72c-2.11.7-4.27 1.33-6.47 1.91ZM381.6 360.12v-45.7l12.87.95v42.55a129.56 129.56 0 0 1-12.87 2.2ZM287.16 303.25h7.75c.62-.68 1.27-1.35 2-2a54.34 54.34 0 0 1 9.33-7.07 72.88 72.88 0 0 1 6.67-3.6 88.48 88.48 0 0 1 9.43-3.85c3.1-1.07 6.33-2 9.64-2.81l.06 73.39c-1.9-.47-3.79-1-5.64-1.55s-3.64-1.16-5.4-1.81c-2.53-.92-5-1.94-7.35-3.05q-2.91-1.37-5.5-2.83c-1.5-.86-2.91-1.73-4.25-2.64s-2.58-1.82-3.75-2.76a40.72 40.72 0 0 1-8.39-8.93c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M291.71 333.7c-.44-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.68 23.68 0 0 1-.18-2.91v-16.42h7.75l.09-.1 2.62.94v36.46a38.58 38.58 0 0 1-5.92-6.85Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M415.16 271.84a91.007 91.007 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.24-10.37-3.12-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.86 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40 40 0 0 0 8.39 8.93q1.77 1.42 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.64 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29a60.27 60.27 0 0 0 5.09-3.47 47.65 47.65 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.27-.7.5-1.4.7-2.1a22.71 22.71 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.63-6.02Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M415.16 271.84a91.007 91.007 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.24-10.37-3.12-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.86 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40 40 0 0 0 8.39 8.93q1.77 1.42 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.64 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29a60.27 60.27 0 0 0 5.09-3.47 47.65 47.65 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.27-.7.5-1.4.7-2.1a22.71 22.71 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.63-6.02Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M415.16 271.84a91.007 91.007 0 0 0-8.08-3.31c-3.34-1.19-6.81-2.24-10.37-3.12-24-6-52.24-4.93-74.42 2.74a88.48 88.48 0 0 0-9.43 3.85 72.88 72.88 0 0 0-6.67 3.6 54.34 54.34 0 0 0-9.33 7.07 34.19 34.19 0 0 0-6.86 8.93 23.1 23.1 0 0 0-2.65 13.93 25.08 25.08 0 0 0 3.1 9.12c.39.68.81 1.36 1.26 2a40 40 0 0 0 8.39 8.93q1.77 1.42 3.76 2.77c1.34.9 2.75 1.78 4.25 2.63 1.72 1 3.56 1.93 5.49 2.84 2.36 1.1 4.82 2.12 7.35 3q2.64 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.67-.69 3.3-1.42 4.87-2.19q3.15-1.53 6-3.29a60.27 60.27 0 0 0 5.09-3.47 47.65 47.65 0 0 0 3.51-2.92 38.34 38.34 0 0 0 3-3 30.49 30.49 0 0 0 5.91-9.46c.27-.7.5-1.4.7-2.1a22.71 22.71 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.28 46.28 0 0 0-9.22-8.34 70.71 70.71 0 0 0-10.63-6.02Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M332 340.27c-1.92-.48-3.82-1-5.68-1.57s-3.65-1.16-5.41-1.81c-2.53-.92-5-1.94-7.35-3-1.93-.91-3.77-1.86-5.49-2.84-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.76-2.77a40 40 0 0 1-8.39-8.93c-.45-.67-.87-1.35-1.26-2a25.08 25.08 0 0 1-3.1-9.12 23.1 23.1 0 0 1 2.69-14 34.19 34.19 0 0 1 6.87-8.93 54.34 54.34 0 0 1 9.33-7.07c1.88-1.15 3.86-2.21 5.91-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M297.53 323.41a38.15 38.15 0 0 1-5.83-6.73c-.45-.67-.87-1.35-1.26-2a25.08 25.08 0 0 1-3.1-9.12A22.66 22.66 0 0 1 288.9 294ZM355.8 343.84c-4.33-.26-8.63-.72-12.86-1.38l-21.7-73.93 1.05-.38a107.42 107.42 0 0 1 10.41-3ZM394.46 340.91a127 127 0 0 1-12.87 2.19l-23.9-81.37c4.5-.2 9-.19 13.54 0ZM405.23 337.94c-2.12.7-4.29 1.35-6.51 1.93l-22.85-77.8c2.46.2 4.9.45 7.32.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M419 273.77c1 .54 2 1.1 2.93 1.66l.94.56.94.6c.68.44 1.36.88 2 1.33a46.28 46.28 0 0 1 9.22 8.34 27.47 27.47 0 0 1 6.37 13.6 22.71 22.71 0 0 1-.63 9.26c-.2.7-.43 1.4-.7 2.1a30.49 30.49 0 0 1-5.91 9.46c-.33.38-.7.75-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M402 279.65c-23-10.7-57.42-9.9-78.54 1.48-.89.49-1.78 1-2.63 1.51-20.79 12.81-18.17 32.23 5.86 43.39s60.38 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.15-12.77 14.79-31.08-8.29-41.78Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M402 279.65c-23-10.7-57.42-9.9-78.54 1.48-.89.49-1.78 1-2.63 1.51-20.79 12.81-18.17 32.23 5.86 43.39s60.38 9.82 81.17-3c.85-.52 1.66-1.06 2.43-1.6 18.15-12.77 14.79-31.08-8.29-41.78Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M400.35 280.68c-.74-.34-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.53.29c-.67.37-1.34.75-2 1.15a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.94 2.57 4 6.76 7.68 12.54 10.85 1.08.6 2.22 1.18 3.43 1.74s2.22 1 3.38 1.45a74 74 0 0 0 7.54 2.51c18.19 5 40.23 4.21 56.89-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.58-1 2.32-1.52l.61-.45c4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.4-6.76-20.64a45.49 45.49 0 0 0-11.28-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M400.35 280.68c-.74-.34-1.5-.68-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.53.29c-.67.37-1.34.75-2 1.15a34.22 34.22 0 0 0-9.21 7.93c-5.22 6.7-5.54 14.17-1.16 20.94 2.57 4 6.76 7.68 12.54 10.85 1.08.6 2.22 1.18 3.43 1.74s2.22 1 3.38 1.45a74 74 0 0 0 7.54 2.51c18.19 5 40.23 4.21 56.89-2.09a60.94 60.94 0 0 0 6.79-3c1-.54 2-1.1 3-1.69s1.58-1 2.32-1.52l.61-.45c4.86-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.4-6.76-20.64a45.49 45.49 0 0 0-11.28-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M398.1 286.15c.78.32 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.67 7.4 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.63 8.1-9.49 11.66l-.61.45c-.74.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.89 2.09a74 74 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.38-1.45s-2.35-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a19 19 0 0 1-2.69-6.37 19.63 19.63 0 0 1 3.87-8.11 34.71 34.71 0 0 1 9.21-7.93c.65-.4 1.31-.78 2-1.15l.53-.29c19.53-10.48 50.84-11.53 72.8-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M398.1 286.15c.78.32 1.53.65 2.27 1a45.12 45.12 0 0 1 11.26 7.19c4.07 3.64 6.54 7.67 7.4 11.79a17.34 17.34 0 0 1-.66 2.39c-1.48 4.11-4.63 8.1-9.49 11.66l-.61.45c-.74.51-1.51 1-2.32 1.52s-1.94 1.15-3 1.69a60.94 60.94 0 0 1-6.79 3c-16.66 6.3-38.7 7.11-56.89 2.09a74 74 0 0 1-7.54-2.51c-1.16-.46-2.28-.94-3.38-1.45s-2.35-1.14-3.43-1.74c-5.78-3.17-10-6.89-12.54-10.85a19 19 0 0 1-2.69-6.37 19.63 19.63 0 0 1 3.87-8.11 34.71 34.71 0 0 1 9.21-7.93c.65-.4 1.31-.78 2-1.15l.53-.29c19.53-10.48 50.84-11.53 72.8-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M440.05 311.22c-8.73 19.44-33.18 27.18-53.11 30.69-30.3 4.35-63.28.7-86.71-16.54-6.37-5-11.8-11.73-12.94-19.87 1 8.22 6.24 15.18 12.61 20.32 18.87 14.62 45 19.36 70 18.66 25.19-1.48 58.73-8.24 70.15-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M332.61 317.16c8.56 3.59 16.95 2 24.77-.73l-2.06 2.11v8.89a37.08 37.08 0 0 0 5.29.86 38.08 38.08 0 0 0 9.21-.34 49.06 49.06 0 0 0 14.34-4.73l7.55 3.17 10.18-6.27v-8.88l-1.91-.82-.41-.17a8.2 8.2 0 0 0 .21-2.08 5.07 5.07 0 0 0-.06-.7c-.13-1.1.09-6.12 0-8.19a5.21 5.21 0 0 0-.06-.7c-.3-2.48-2.36-4.71-6.58-6.47a27.29 27.29 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.3 0-.42.09-.27.05-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-.94.33-1.87.68-2.78 1l2.68-2.8v-8.89c-8.67-1.65-17.92-.25-27 4.2l-7.55-3.14-10.06 6.43v8.88l2.36 1a5.49 5.49 0 0 0-.23 1.68v.06c.08 1.38 0 7.26 0 8.82v.07c.2 2.66 2.35 4.96 6.81 6.82Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M399.77 299.7c0 2.27-.16 6.74 0 7.76 0 .24 0 .47.07.71a8.3 8.3 0 0 1-.22 2.08l-5.47-2.25s5.47-2.67 5.62-8.3Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M345.05 299.56c-2.27-.95-2.64-2.71 1.52-5.39 4.46-2.83 9.05-3.56 12.95-2.59l8.82-9.19v8.88l-2.66 2.8c-.1 0-.17.07-.27.11-1.45.55-2.85 1.12-4.19 1.68q-.81.36-1.59.66a55.38 55.38 0 0 1-8.9 3.27 10 10 0 0 1-3.85.27 7 7 0 0 1-1.83-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m326.05 299.72-2.35-1v-8.88l7.51 3.15c-2.78 2.34-4.57 4.61-5.16 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m391.71 317.49 10.19-6.26v8.89l-10.2 6.28-7.52-3.18a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.89a36.15 36.15 0 0 0 14.5.51 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M384.19 323.22a37.16 37.16 0 0 1-11.44 4.68 30.33 30.33 0 0 1-12.13.4 58.49 58.49 0 0 0 11.95-1.4c2-.43 3.91-1 5.85-1.6s3.84-1.3 5.77-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M325.85 310.36v-.05c-.07-1.56.07-7.44 0-8.83.16 2.62 2.28 4.94 6.75 6.8 9.94 4.14 19.61 1.42 28.46-2.13 8.21-3.32 14.18-6.86 19-4.86 3 1.27 2.15 3.27-1.13 5.36a18.17 18.17 0 0 1-14.21 2.24l-7.31 7.54c-7.83 2.77-16.21 4.3-24.75.73-4.53-1.86-6.66-4.16-6.81-6.8Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="M359.52 300.47v-8.89l8.81-9.2v8.89l-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M359.52 300.47v-8.89l8.81-9.2v8.89l-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m323.7 289.87 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m323.7 289.87 7.54 3.14-5.17 6.73-2.36-.99-.01-8.88z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m400 310.42-5.84-2.42s5.73-2.78 5.61-8.69a5.21 5.21 0 0 0-.06-.7c-.3-2.48-2.36-4.71-6.58-6.47a27.29 27.29 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.3 0-.42.09-.27.05-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-1 .36-2 .74-3 1.14-1.45.56-2.86 1.14-4.2 1.7-.55.24-1.07.45-1.59.66a57.41 57.41 0 0 1-8.9 3.27 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-.95-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.55-3.14-10.06 6.43 7.54 3.14c-3.6 3-5.49 5.83-5.4 8.41v.06c.15 2.62 2.3 4.93 6.76 6.8 9.92 4.15 19.6 1.42 28.44-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.64a36 36 0 0 0 5.29.87 38.55 38.55 0 0 0 9.21-.35 49.21 49.21 0 0 0 14.35-4.72l7.54 3.16 10.18-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m400 310.42-5.84-2.42s5.73-2.78 5.61-8.69a5.21 5.21 0 0 0-.06-.7c-.3-2.48-2.36-4.71-6.58-6.47a27.29 27.29 0 0 0-13.47-1.79l-1.15.14h-.12l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.3 0-.42.09-.27.05-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-1 .36-2 .74-3 1.14-1.45.56-2.86 1.14-4.2 1.7-.55.24-1.07.45-1.59.66a57.41 57.41 0 0 1-8.9 3.27 10.18 10.18 0 0 1-3.85.26 8.08 8.08 0 0 1-1.81-.49c-2.27-.95-2.65-2.72 1.53-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.2c-8.67-1.65-17.92-.25-27 4.2l-7.55-3.14-10.06 6.43 7.54 3.14c-3.6 3-5.49 5.83-5.4 8.41v.06c.15 2.62 2.3 4.93 6.76 6.8 9.92 4.15 19.6 1.42 28.44-2.13 8.22-3.33 14.19-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.19 18.19 0 0 1-14.19 2.24l-9.38 9.64a36 36 0 0 0 5.29.87 38.55 38.55 0 0 0 9.21-.35 49.21 49.21 0 0 0 14.35-4.72l7.54 3.16 10.18-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M332.6 308.28a28.48 28.48 0 0 0 11.81 1.83 43.09 43.09 0 0 0 12-2.55c3.95-1.3 7.74-3 11.61-4.62a39 39 0 0 1 6-2 9.84 9.84 0 0 1 6 .35 9.8 9.8 0 0 0-6-.08 40.08 40.08 0 0 0-5.85 2.11c-3.82 1.67-7.64 3.41-11.6 4.76a42.42 42.42 0 0 1-12.23 2.46 28.91 28.91 0 0 1-6.11-.46 29.39 29.39 0 0 1-5.63-1.8Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M391.71 317.5v8.89l-7.54-3.17v-8.88l7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M391.71 317.5v8.89l-7.54-3.17v-8.88l7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m401.89 311.24-10.14 5.27-7.58-2.17 7.54 3.16 10.18-6.26zM341.31 286.58l-7.5-2.22-10.11 5.51 10.06-6.43 7.55 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M368.33 282.38a39.3 39.3 0 0 0-4.74-.56l1.24 4.22ZM333 308.41a27.56 27.56 0 0 0 13 1.82l-7.27-24.75-4.91-2-6.86 4.38 1.13 3.85 3.21 1.34a26.39 26.39 0 0 0-2.21 2ZM391.61 291.52a26.39 26.39 0 0 0-7.49-1.37l8 27.12 6-3.69-1.32-4.52-2.6-1.09a10.71 10.71 0 0 0 1.9-1.28ZM378.39 290.46l-1.2.19c-.35.06-.7.12-1.05.2-.15 0-.3 0-.42.09-.27.05-.55.1-.8.17a62.64 62.64 0 0 0-6.49 1.92c-.47.16-.93.35-1.39.52l2.63 9c4.05-1.6 7.43-2.45 10.37-1.22s2.14 3.25-1.15 5.36a17.59 17.59 0 0 1-7.24 2.61l2.6 8.84a52 52 0 0 0 9.92-3.77l2.84 1.19-7.4-25.22-1.1.13Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m296.81 380.6 1.23-9.32 1.23 9.32 16.14.71-16.14.71-1.23 9.32-1.23-9.32-16.14-.71 16.14-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m308.85 388.59.6-4.51.59 4.51 7.83.35-7.83.34-.59 4.52-.6-4.52-7.82-.34 7.82-.35zM281.49 291.58l1.23-9.32 1.22 9.32 16.15.71-16.15.71-1.22 9.32-1.23-9.32-16.15-.71 16.15-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m290.21 298.81.6-4.52.59 4.52 7.83.34-7.83.35-.59 4.51-.6-4.51-7.82-.35 7.82-.34zM413.29 331.78l1.23-9.32 1.23 9.32 16.14.71-16.14.71-1.23 9.32-1.23-9.32-16.14-.71 16.14-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m422.02 339.01.59-4.52.6 4.52 7.82.34-7.82.34-.6 4.52-.59-4.52-7.83-.34 7.83-.34z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M215 392h8.47v17.34a22.44 22.44 0 0 1-.83 6.15c-.2.7-.43 1.4-.7 2.1A30.6 30.6 0 0 1 216 427a42.15 42.15 0 0 1-3 3c-1.08 1-2.25 2-3.51 2.92a58.9 58.9 0 0 1-5.1 3.47 76.358 76.358 0 0 1-6 3.29c-1.57.77-3.2 1.5-4.87 2.19C169 452 135.22 453.21 108.25 445q-2.77-.84-5.41-1.81c-2.53-.92-5-1.94-7.35-3.05-1.93-.9-3.77-1.85-5.49-2.83-1.5-.86-2.92-1.73-4.25-2.64s-2.58-1.82-3.76-2.76A40.26 40.26 0 0 1 73.6 423c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91v-16.39h7.75c.62-.68 1.27-1.35 2-2a54.44 54.44 0 0 1 9.34-7.07 72.7 72.7 0 0 1 6.66-3.6 88.65 88.65 0 0 1 9.44-3.85c22.17-7.67 50.42-8.7 74.41-2.74a107.91 107.91 0 0 1 10.34 3.02c2.78 1 5.48 2.1 8.07 3.31a70.48 70.48 0 0 1 10.64 6.09A48.31 48.31 0 0 1 215 392Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M124.85 448.74v-35.17l12.88 1.34v35.22c-4.34-.27-8.65-.73-12.88-1.39Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M223.48 409.26a22.44 22.44 0 0 1-.83 6.15c-.2.7-.43 1.4-.7 2.1A30.6 30.6 0 0 1 216 427c-.33.37-.69.75-1 1.12v-36.2l.06.06h8.47Z"
      style={{
        opacity: 0.1,
      }}
    />
    <path
      d="m180.53 393.08 6.61-1.56v52.72c-2.1.7-4.26 1.33-6.47 1.91ZM163.49 449.39v-45.7l12.88.95v42.55a130.06 130.06 0 0 1-12.88 2.2ZM69 392.52h7.75c.62-.68 1.27-1.35 2-2a54.44 54.44 0 0 1 9.34-7.07 72.7 72.7 0 0 1 6.66-3.6 88.65 88.65 0 0 1 9.44-3.85c3.1-1.07 6.33-2 9.64-2.81l.06 73.39c-1.91-.47-3.8-1-5.64-1.55s-3.65-1.16-5.41-1.81c-2.53-.92-5-1.94-7.35-3.05-1.93-.9-3.77-1.85-5.49-2.83-1.5-.86-2.92-1.73-4.25-2.64s-2.58-1.82-3.76-2.76A40.26 40.26 0 0 1 73.6 423c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M73.6 423c-.45-.67-.87-1.35-1.26-2a25.1 25.1 0 0 1-3.11-9.12 23.67 23.67 0 0 1-.17-2.91v-16.39h7.75a.94.94 0 0 1 .1-.1l2.61.95v36.45A38.1 38.1 0 0 1 73.6 423Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M197 361.11a94.12 94.12 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.28 23.28 0 0 0-2.65 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.46 8.96q1.76 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63q2.59 1.47 5.5 2.84a78.26 78.26 0 0 0 7.34 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47A45.44 45.44 0 0 0 213 413a40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.65-6.13Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M197 361.11a94.12 94.12 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.28 23.28 0 0 0-2.65 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.46 8.96q1.76 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63q2.59 1.47 5.5 2.84a78.26 78.26 0 0 0 7.34 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47A45.44 45.44 0 0 0 213 413a40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.65-6.13Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M197 361.11a94.12 94.12 0 0 0-8.08-3.31 106.86 106.86 0 0 0-10.37-3.12c-24-6-52.24-4.93-74.41 2.74a88.65 88.65 0 0 0-9.44 3.85 74.22 74.22 0 0 0-6.67 3.6 54.74 54.74 0 0 0-9.33 7.07 34.4 34.4 0 0 0-6.87 8.93 23.28 23.28 0 0 0-2.65 13.93 24.91 24.91 0 0 0 3.11 9.12c.38.68.8 1.36 1.25 2a40.28 40.28 0 0 0 8.46 8.96q1.76 1.42 3.75 2.77c1.34.9 2.75 1.78 4.25 2.63q2.59 1.47 5.5 2.84a78.26 78.26 0 0 0 7.34 3q2.66 1 5.41 1.81c27 8.23 60.78 7 85.31-3.14 1.68-.69 3.3-1.42 4.88-2.19 2.1-1 4.1-2.12 6-3.29a60.48 60.48 0 0 0 5.1-3.47A45.44 45.44 0 0 0 213 413a40.34 40.34 0 0 0 3-3 30.67 30.67 0 0 0 5.91-9.46c.26-.7.5-1.4.69-2.1a22.37 22.37 0 0 0 .63-9.26 27.47 27.47 0 0 0-6.37-13.6 46.2 46.2 0 0 0-9.21-8.34 71.33 71.33 0 0 0-10.65-6.13Z"
      style={{
        fill: "#fff",
        opacity: 0.2,
      }}
    />
    <path
      d="M113.91 429.54c-1.92-.48-3.82-1-5.68-1.57s-3.64-1.16-5.4-1.81c-2.54-.92-5-1.94-7.35-3q-2.91-1.37-5.5-2.84c-1.5-.85-2.91-1.73-4.25-2.63s-2.58-1.82-3.75-2.77a40.28 40.28 0 0 1-8.4-8.92c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 23.28 23.28 0 0 1 2.65-13.93 34.4 34.4 0 0 1 6.87-8.93 54.74 54.74 0 0 1 9.33-7.07c1.88-1.15 3.86-2.21 5.91-3.22Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M79.42 412.68a37.82 37.82 0 0 1-5.84-6.68c-.45-.67-.87-1.35-1.25-2a24.91 24.91 0 0 1-3.11-9.12 22.77 22.77 0 0 1 1.56-11.54ZM137.69 433.12c-4.33-.27-8.64-.73-12.86-1.39l-21.71-73.93 1.06-.38c3.34-1.15 6.83-2.14 10.41-3ZM176.35 430.18a126.72 126.72 0 0 1-12.88 2.19L139.58 351c4.5-.2 9-.19 13.53 0ZM187.12 427.21c-2.12.7-4.3 1.35-6.52 1.93l-22.84-77.8c2.45.2 4.89.45 7.31.78Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="M200.86 363c1 .54 2 1.1 2.93 1.66l.94.56.94.6c.69.44 1.36.88 2 1.33a46.2 46.2 0 0 1 9.21 8.34 27.47 27.47 0 0 1 6.37 13.6 22.37 22.37 0 0 1-.63 9.26c-.19.7-.43 1.4-.69 2.1A30.67 30.67 0 0 1 216 410c-.34.38-.7.75-1.06 1.13Z"
      style={{
        fill: "#ba68c8",
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M183.91 368.92c-23.06-10.7-57.42-9.9-78.55 1.48-.89.49-1.77 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.53 1.66-1.07 2.43-1.61 18.12-12.76 14.8-31.07-8.29-41.77Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M183.91 368.92c-23.06-10.7-57.42-9.9-78.55 1.48-.89.49-1.77 1-2.62 1.51-20.8 12.81-18.18 32.23 5.86 43.39s60.37 9.82 81.17-3c.85-.53 1.66-1.07 2.43-1.61 18.12-12.76 14.8-31.07-8.29-41.77Z"
      style={{
        opacity: 0.4,
      }}
    />
    <path
      d="M182.23 370c-.74-.34-1.49-.67-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.4 34.4 0 0 0-9.21 7.93c-5.22 6.7-5.53 14.17-1.16 20.94 2.58 4 6.77 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.82 71.82 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a61.11 61.11 0 0 0 6.8-3c1-.54 2-1.1 3-1.68s1.59-1 2.32-1.53l.61-.45c4.87-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.49 45.49 0 0 0-11.3-7.22Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M182.23 370c-.74-.34-1.49-.67-2.27-1-22-9.17-53.27-8.1-72.78 2.41l-.52.29c-.68.37-1.34.75-2 1.15a34.4 34.4 0 0 0-9.21 7.93c-5.22 6.7-5.53 14.17-1.16 20.94 2.58 4 6.77 7.68 12.54 10.85 1.09.6 2.23 1.18 3.43 1.74s2.23 1 3.38 1.45a71.82 71.82 0 0 0 7.55 2.51c18.18 5 40.22 4.21 56.88-2.09a61.11 61.11 0 0 0 6.8-3c1-.54 2-1.1 3-1.68s1.59-1 2.32-1.53l.61-.45c4.87-3.56 8-7.55 9.49-11.66 2.52-7 .2-14.39-6.76-20.64a45.49 45.49 0 0 0-11.3-7.22Z"
      style={{
        opacity: 0.5,
      }}
    />
    <path
      d="M180 375.42c.77.32 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.68 7.39 11.79a16 16 0 0 1-.66 2.39c-1.48 4.11-4.62 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.53s-1.94 1.14-3 1.68a61.11 61.11 0 0 1-6.8 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.82 71.82 0 0 1-7.55-2.51c-1.15-.46-2.27-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.77-3.17-10-6.89-12.54-10.85a18.76 18.76 0 0 1-2.68-6.37 19.29 19.29 0 0 1 3.87-8.1 34.24 34.24 0 0 1 9.21-7.94c.64-.4 1.31-.78 2-1.15l.53-.29c19.53-10.48 50.81-11.55 72.81-2.38Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M180 375.42c.77.32 1.53.65 2.27 1a45.49 45.49 0 0 1 11.26 7.19c4.06 3.64 6.53 7.68 7.39 11.79a16 16 0 0 1-.66 2.39c-1.48 4.11-4.62 8.1-9.49 11.66l-.61.45c-.73.51-1.51 1-2.32 1.53s-1.94 1.14-3 1.68a61.11 61.11 0 0 1-6.8 3c-16.66 6.3-38.7 7.11-56.88 2.09a71.82 71.82 0 0 1-7.55-2.51c-1.15-.46-2.27-.94-3.38-1.45s-2.34-1.14-3.43-1.74c-5.77-3.17-10-6.89-12.54-10.85a18.76 18.76 0 0 1-2.68-6.37 19.29 19.29 0 0 1 3.87-8.1 34.24 34.24 0 0 1 9.21-7.94c.64-.4 1.31-.78 2-1.15l.53-.29c19.53-10.48 50.81-11.55 72.81-2.38Z"
      style={{
        opacity: 0.25,
      }}
    />
    <path
      d="M221.94 400.49c-8.74 19.44-33.19 27.19-53.12 30.69-30.3 4.35-63.28.7-86.71-16.54-6.37-5-11.79-11.73-12.94-19.87 1 8.22 6.24 15.18 12.62 20.32 18.87 14.62 45 19.36 70 18.66 25.21-1.46 58.73-8.24 70.15-33.26Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M114.49 406.43c8.56 3.59 17 2 24.77-.73l-2.06 2.12v8.88a37.22 37.22 0 0 0 5.3.86 38 38 0 0 0 9.2-.34 49.22 49.22 0 0 0 14.35-4.73l7.54 3.17 10.18-6.27v-8.88l-1.91-.82-.42-.17a8.2 8.2 0 0 0 .21-2.08 5.07 5.07 0 0 0-.06-.7c-.13-1.1.1-6.11.06-8.19 0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.31 27.31 0 0 0-13.48-1.79l-1.14.14h-.13l-1.2.19c-.35.06-.69.12-1 .2-.15 0-.3.05-.43.09a6.1 6.1 0 0 0-.79.18 59.76 59.76 0 0 0-6.5 1.91c-.93.33-1.86.68-2.78 1l2.69-2.8v-8.89c-8.66-1.65-17.92-.25-27 4.2l-7.54-3.14-10.07 6.43v8.9l2.36 1a5.8 5.8 0 0 0-.22 1.68v.07c.08 1.37-.06 7.26 0 8.81v.07c.1 2.63 2.22 4.94 6.71 6.8Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="M181.66 389c0 2.27-.17 6.74 0 7.76 0 .24 0 .47.06.71a8.25 8.25 0 0 1-.22 2.08l-5.5-2.31s5.51-2.64 5.66-8.24Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M126.93 388.83c-2.26-.95-2.63-2.71 1.53-5.39 4.46-2.83 9-3.56 12.94-2.59l8.82-9.19v8.88l-2.66 2.8-.28.11c-1.44.55-2.85 1.12-4.18 1.68q-.83.36-1.59.66a55.38 55.38 0 0 1-8.9 3.27 10.07 10.07 0 0 1-3.86.27 7.16 7.16 0 0 1-1.82-.5Z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m107.93 389-2.34-1v-8.88l7.51 3.15c-2.79 2.33-4.58 4.6-5.17 6.73Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="m173.59 406.76 10.19-6.26v8.89l-10.2 6.28-7.52-3.18a48.79 48.79 0 0 1-14.36 4.72 36.15 36.15 0 0 1-14.5-.51v-8.88a36.47 36.47 0 0 0 14.5.5 48.5 48.5 0 0 0 14.36-4.72Z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="M166.07 412.49a37.16 37.16 0 0 1-11.44 4.68 30.33 30.33 0 0 1-12.13.4 58.58 58.58 0 0 0 12-1.4c2-.43 3.9-1 5.84-1.6s3.8-1.35 5.73-2.08Z"
      style={{
        opacity: 0.2,
      }}
    />
    <path
      d="M107.74 399.63v-.05c-.06-1.56.07-7.44 0-8.83.15 2.62 2.28 4.94 6.74 6.8 9.94 4.14 19.61 1.42 28.46-2.13 8.21-3.32 14.18-6.86 19-4.86 3 1.27 2.15 3.27-1.13 5.36a18.15 18.15 0 0 1-14.2 2.24l-7.32 7.54c-7.84 2.77-16.22 4.3-24.76.73-4.53-1.86-6.64-4.17-6.79-6.8Z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m141.41 389.74-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m141.41 389.74-.01-8.89 8.81-9.2.01 8.89-8.81 9.2z"
      style={{
        opacity: 0.15,
      }}
    />
    <path
      d="m105.58 379.14 7.55 3.14-5.18 6.73-2.36-.99-.01-8.88z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m105.58 379.14 7.55 3.14-5.18 6.73-2.36-.99-.01-8.88z"
      style={{
        opacity: 0.45,
      }}
    />
    <path
      d="m181.87 399.69-5.87-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.31 27.31 0 0 0-13.48-1.79l-1.14.14h-.13l-1.2.19c-.34.06-.69.12-1 .2-.15 0-.3.05-.43.09a6.1 6.1 0 0 0-.79.18 59.76 59.76 0 0 0-6.5 1.91c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7-.54.24-1.07.45-1.59.67a58.19 58.19 0 0 1-8.89 3.26 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-.95-2.66-2.72 1.52-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.19c-8.66-1.66-17.92-.26-27 4.19l-7.54-3.14-10.07 6.43 7.55 3.14c-3.6 3-5.5 5.83-5.4 8.41v.07c.15 2.61 2.29 4.92 6.76 6.79 9.91 4.15 19.59 1.42 28.44-2.13 8.22-3.33 14.18-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.65a37.45 37.45 0 0 0 5.3.86 38.08 38.08 0 0 0 9.21-.35 49.15 49.15 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m181.87 399.69-5.87-2.45s5.73-2.78 5.62-8.69c0-.25 0-.47-.07-.7-.29-2.48-2.36-4.71-6.57-6.47a27.31 27.31 0 0 0-13.48-1.79l-1.14.14h-.13l-1.2.19c-.34.06-.69.12-1 .2-.15 0-.3.05-.43.09a6.1 6.1 0 0 0-.79.18 59.76 59.76 0 0 0-6.5 1.91c-1 .36-2 .74-3 1.14-1.44.56-2.86 1.14-4.2 1.7-.54.24-1.07.45-1.59.67a58.19 58.19 0 0 1-8.89 3.26 10.21 10.21 0 0 1-3.85.26 7.94 7.94 0 0 1-1.81-.49c-2.27-.95-2.66-2.72 1.52-5.4 4.45-2.84 9-3.57 12.94-2.59l8.81-9.19c-8.66-1.66-17.92-.26-27 4.19l-7.54-3.14-10.07 6.43 7.55 3.14c-3.6 3-5.5 5.83-5.4 8.41v.07c.15 2.61 2.29 4.92 6.76 6.79 9.91 4.15 19.59 1.42 28.44-2.13 8.22-3.33 14.18-6.86 19-4.85 3 1.27 2.14 3.25-1.15 5.36a18.22 18.22 0 0 1-14.2 2.24l-9.38 9.65a37.45 37.45 0 0 0 5.3.86 38.08 38.08 0 0 0 9.21-.35 49.15 49.15 0 0 0 14.34-4.72l7.54 3.16 10.19-6.26Z"
      style={{
        fill: "#fff",
        opacity: 0.1,
      }}
    />
    <path
      d="M114.49 397.55a28.6 28.6 0 0 0 11.8 1.84 43.61 43.61 0 0 0 12-2.56c3.94-1.3 7.73-3 11.6-4.62a39.32 39.32 0 0 1 6-2 9.88 9.88 0 0 1 6 .35 9.83 9.83 0 0 0-6-.08 40.5 40.5 0 0 0-5.85 2.11c-3.81 1.67-7.63 3.41-11.6 4.76a42.35 42.35 0 0 1-12.23 2.46 28.77 28.77 0 0 1-6.1-.46 29.17 29.17 0 0 1-5.62-1.8Z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m173.59 406.77.01 8.89-7.54-3.16-.01-8.89 7.54 3.16z"
      style={{
        fill: "#ba68c8",
      }}
    />
    <path
      d="m173.59 406.77.01 8.89-7.54-3.16-.01-8.89 7.54 3.16z"
      style={{
        opacity: 0.35000000000000003,
      }}
    />
    <path
      d="m183.78 400.51-10.15 5.27-7.58-2.17 7.54 3.16 10.19-6.26zM123.19 375.85l-7.5-2.22-10.11 5.51 10.07-6.43 7.54 3.14z"
      style={{
        fill: "#fafafa",
      }}
    />
    <path
      d="M150.21 371.66a37.46 37.46 0 0 0-4.73-.57l1.23 4.22ZM114.83 397.68a27.58 27.58 0 0 0 13 1.82l-7.27-24.74-4.9-2.05-6.86 4.38 1.13 3.85 3.21 1.34a26.6 26.6 0 0 0-2.22 2ZM173.49 380.79a26 26 0 0 0-7.49-1.36l8 27.11 6-3.69-1.33-4.52-2.6-1.09A11 11 0 0 0 178 396ZM160.27 379.73l-1.2.19c-.34.06-.69.12-1 .2-.15 0-.3.05-.43.09a6.1 6.1 0 0 0-.79.18 59.76 59.76 0 0 0-6.5 1.91c-.47.16-.92.35-1.39.52l2.63 9c4.06-1.6 7.43-2.45 10.38-1.22s2.14 3.25-1.15 5.36a17.7 17.7 0 0 1-7.24 2.61l2.59 8.84a51.07 51.07 0 0 0 9.92-3.77l2.85 1.19-7.41-25.22c-.36 0-.74.1-1.09.13Z"
      style={{
        fill: "#fff",
        opacity: 0.15,
      }}
    />
    <path
      d="m73.06 390.64 1.23-9.32 1.23 9.32 16.14.71-16.14.71-1.23 9.32-1.23-9.32-16.15-.71 16.15-.71z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="m81.78 397.87.6-4.52.59 4.52 7.83.34-7.83.34-.59 4.52-.6-4.52-7.82-.34 7.82-.34z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
)
export default React.memo(MoneyIllustration);
