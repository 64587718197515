import { differenceInHours, format, getTime, formatDistanceToNow } from 'date-fns';
import ro from 'date-fns/locale/ro';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd-MM-yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMMM yyyy HH:mm';

  return date ? format(new Date(date), fm, { locale: ro }) : '';
}

export function fTime(date, newFormat) {
  const fm = newFormat || 'HH:mm';
  return date ? format(new Date(date), fm, { locale: ro }) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDifference(dateStr) {
  if (!dateStr) {
    return '';
  }

  const date1 = new Date(dateStr);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
  // const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

  const diffFormatted = `${diffDays.toString().padStart(2, '0')}z ${diffHours
    .toString()
    .padStart(2, '0')}h ${diffMinutes.toString().padStart(2, '0')}m`;

  return diffFormatted;
}

export function addDaysToDate(date, daysToAdd) {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return fDate(result);
}

export function calculateDiffInHours(date, addHours) {
  const parsedDate = new Date(date);
  const now = new Date();

  const hoursDiff = differenceInHours(parsedDate, now);

  return addHours ? hoursDiff + addHours : hoursDiff;
}

//!!! parsing multiple dates from object

const dateKeys = [
  'dataExpirareAutorizatieSudorPe',
  'dataExpirareAutorizatieSudorOl',
  'dataExpirareAutorizatieEgiu',
  'dataExpirareAutorizatieEgiuRte',
  'dataFacturaFitinguri',
  'dataFacturaKitDetectie',
  'dataFacturaRobinet',
  'dataFacturaTeavaOl',
];

export const processObjectDates = (obj) => {
  const processedObj = { ...obj };

  dateKeys.forEach((key) => {
    if (key in processedObj && processedObj[key]) {
      processedObj[key] = fDate(processedObj[key]);
    }
  });

  return processedObj;
};
