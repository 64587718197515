import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'axios';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  list: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // set the list
    setListNotifications(state, action) {
      state.list = action.payload;
    },

    // change status of a notification from list by id
    readStatus(state, action) {
      console.log('redux', action.payload);

      // Use spread operator to create a shallow copy of state
      const newState = {
        ...state,
        list: state.list.map((notification) =>
          // Use spread operator to create a shallow copy of notification object
          notification.id === action.payload ? { ...notification, isRead: true } : notification
        ),
      };

      return newState; // Return the new state object
    },

    // add notification
    addNotification(state, action) {
      state.list.push(action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReduxNotifications(obj) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const response = axios
      .get(`${BASE_URL}:9000/user/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.setListNotifications(res.data));
        return res;
      })
      .catch((err) => err);

    return response;
  };
}

// ----------------------------------------------------------------------

export function changeReadStatus(id) {
  return (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;
    const response = axios
      .get(`${BASE_URL}:9000/notifications/read?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.readStatus(id));
        return res;
      })
      .catch((err) => err);

    return response;
  };
}

export function addNotification(notification) {
  return (dispatch) => {
    dispatch(slice.actions.addNotification(notification));
  };
}
