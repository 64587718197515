import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// @mui
import { Box, Button, Chip, DialogActions, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { RHFTextField } from '../../../components/hook-form';
import FormProvider from '../../../components/hook-form/FormProvider';
import { getAuthenticatedUserFromCookies } from '../../../utils/cookies';
import { BASE_URL } from '../../../config-global';
import { setSearchedClientRedux } from '../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

LegalQualityForm.propTypes = {
  title: PropTypes.string,
};

export default function LegalQualityForm({ title }) {
  const { checkCUI } = useAuthContext();
  const { setValue, watch } = useFormContext();
  const values = watch();

  //* ------------------------------ ********************* ------------------------------ //

  const checkForCUI = async () => {
    try {
      console.log(values.cui);
      const response = await axios
        .get(
          `https://infocui.ro/system/api/data?key=7000bb2d5d40e02d82dd83cfb9a644154be9bd78&cui=${values.cui}`
        )
        .then((res) => {
          const { data } = res.data;
          console.log(data);
          setValue('companyName', data.nume);
          setValue('tradeRegisterNumber', data.cod_inmatriculare);
          setValue('companyAddress', data.adresa);

          setValue('companyStreet', data.adresa_strada);
          setValue('companyStreetNumber', data.adresa_nr_strada);
          setValue('companyCity', data.adresa_localitate);
          setValue('companyCounty', data.adresa_judet);
          setValue('companyBuilding', data.adresa_bloc);
          setValue('companyFloor', data.adresa_etaj);
          setValue('companyApartment', data.adresa_apartament);
          setValue('companyStair', data.adresa_scara);
          setValue('companyCorp', data.adresa_altele);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Stack spacing={2} direction="column">
        {title ? (
          <Chip color="primary" label="DATE COMPANIE" sx={{ mb: 4 }} />
        ) : (
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Detaliile companiei
          </Typography>
        )}

        <Stack spacing={3}>
          <Stack spacing={2}>
            <Stack direction="row" sx={{ display: 'flex' }} spacing={2}>
              <RHFTextField name="cui" label="CUI" sx={{ width: '75%' }} />
              <LoadingButton
                onClick={checkForCUI}
                name="check"
                size="medium"
                variant="contained"
                sx={{ width: '25%', fontSize: '1.2rem' }}
              >
                Cauta
              </LoadingButton>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField
                inputlabelprops={{ shrink: true }}
                name="companyName"
                label="Nume companie"
              />
              <RHFTextField
                inputlabelprops={{ shrink: true }}
                name="tradeRegisterNumber"
                label="Nr. inregistrate ONRC"
              />
            </Stack>

            <RHFTextField inputlabelprops={{ shrink: true }} name="companyAddress" label="Adresa" />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
