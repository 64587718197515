import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, forwardRef } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemButton,
  AlertTitle,
  Alert,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  ListItemIcon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItem,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { CloseIcon } from '../../../theme/overrides/CustomIcons';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import WebSocketService from '../../../api/WebSocketService';
import { useDispatch, useSelector } from '../../../redux/store';
import {
  addNotification,
  changeReadStatus,
  getReduxNotifications,
} from '../../../redux/slices/notifications';

// ----------------------------------------------------------------------
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [permissionNotification, setPermission] = useState(Notification.permission);
  const [open, setOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.notifications);

  const totalUnread = list?.filter((item) => !item.isRead);
  const totalRead = list?.filter((item) => item.isRead);

  const authenticatedUser = Cookies.get('authenticatedUser');
  const cookies = authenticatedUser ? JSON.parse(authenticatedUser) : null;
  const username = cookies?.email;
  const [openTickets, setOpenTickets] = useState([]);

  const handleTicketClick = (ticketNumber) => {
    setOpenTickets((prevOpenTickets) =>
      prevOpenTickets.includes(ticketNumber)
        ? prevOpenTickets.filter((ticket) => ticket !== ticketNumber)
        : [...prevOpenTickets, ticketNumber]
    );
  };

  // Group notifications by department
  const groupedNotifications = list.reduce((acc, notification) => {
    const department = notification.department || notification.departmentName;
    const { ticketNumber } = notification;

    if (!acc[department]) {
      acc[department] = {};
    }

    if (!acc[department][ticketNumber]) {
      acc[department][ticketNumber] = [];
    }

    acc[department][ticketNumber].push(notification);
    return acc;
  }, {});

  const departments = Object.keys(groupedNotifications);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const filteredNotifications = selectedDepartment
    ? groupedNotifications[selectedDepartment]
    : Object.assign(
        {},
        ...Object.values(groupedNotifications).map(
          (departmentNotifications) => departmentNotifications
        )
      );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNotifications = useCallback(async () => {
    try {
      await dispatch(getReduxNotifications());
    } catch (e) {
      console.log(e);
    }
  }, [dispatch]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handlePermission = () => {
    Notification.requestPermission().then((permission) => {
      setPermission(permission);
    });
  };

  useEffect(() => {
    const handleNotification = (notification) => {
      dispatch(addNotification(notification));
      if (permissionNotification === 'granted') {
        // eslint-disable-next-line no-new
        new Notification(notification.title, {
          body: notification.description,
        });
      } else {
        console.log('No permission for desktop notifications.');
      }
      if (!openPopover) {
        enqueueSnackbar(notification.title, {
          content: (key, message) => (
            <Alert
              severity="info"
              onClose={() => {
                closeSnackbar(key);
              }}
            >
              {notification.category === 'ticket' && (
                <AlertTitle>
                  Aveti o notificare noua - ticket nr. {notification.ticketNumber}
                </AlertTitle>
              )}
              {notification.category === 'ticket' ? (
                ''
              ) : (
                <>
                  <strong>{message}</strong>: {notification.description}
                </>
              )}
            </Alert>
          ),
          anchorOrigin: { vertical: 'right', horizontal: 'top' },
          autoHideDuration: 10000,
        });
      }
    };

    handlePermission();

    const notificationWebSocket = new WebSocketService('/notifications', handleNotification);
    notificationWebSocket.connect();
    return () => {
      notificationWebSocket.disconnect();
    };
  }, [username, enqueueSnackbar, closeSnackbar, openPopover, permissionNotification, dispatch]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handleChangeRead = async (ticketNumber, departmentName, id, status) => {
    try {
      if (status === true) {
        getTicketLink(departmentName, ticketNumber);
      } else {
        const res = await dispatch(changeReadStatus(id));
        if (res.status === 200) {
          navigate(`/dashboard/tickets/${departmentName}/${ticketNumber}`);
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const getTicketLink = (departmentName, ticketNumber) =>
    navigate(`/dashboard/tickets/${departmentName}/${ticketNumber}`);

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnread.length} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificari</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Aveti {totalUnread.length} notificari noi
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {totalUnread?.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Noi
                </ListSubheader>
              }
            >
              {totalUnread.slice(0, 5).map((notification) => (
                <NotificationItem
                  onClick={() => {
                    handleChangeRead(
                      notification.ticketNumber,
                      notification.department ?? notification.departmentName,
                      notification.id,
                      notification.isRead
                    );
                    handleClosePopover();
                  }}
                  key={Math.random()}
                  notification={notification}
                />
              ))}
            </List>
          )}
        </Scrollbar>

        {list.length > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={handleClickOpen}>
                Vezi tot
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar sx={{ position: 'relative', backgroundColor: 'white' }}>
            <Toolbar>
              <IconButton edge="start" color="success" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: 'black' }} variant="h6" component="div">
                Istoric Notificari
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="department-select-label">Departament</InputLabel>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  label="Departament"
                >
                  <MenuItem value="">Toate</MenuItem>
                  {departments.map((department) => (
                    <MenuItem key={department} value={department}>
                      {department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Toolbar>
          </AppBar>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, my: 2, typography: 'overline' }}>
                Notificari impartite per Solicitare
              </ListSubheader>
            }
          >
            {Object.entries(filteredNotifications).map(([ticketNumber, notifications]) => (
              <React.Fragment key={ticketNumber}>
                <ListItem button onClick={() => handleTicketClick(ticketNumber)}>
                  <ListItemText primary={`Solicitare ${ticketNumber}`} />
                  {openTickets.includes(ticketNumber) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse in={openTickets.includes(ticketNumber)} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {notifications.map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onClick={() => {
                          handleChangeRead(
                            notification.ticketNumber,
                            notification.department || notification.departmentName,
                            notification.id,
                            notification.isRead
                          );
                          handleClose();
                        }}
                      />
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Dialog>
      </div>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isRead: PropTypes.bool,
    description: PropTypes.string,
    scheduledTime: PropTypes.array,
  }),
  onClick: PropTypes.func,
};

function NotificationItem({ notification, onClick }) {
  const { title } = renderContent(notification);

  const [year, month, day, hours, minutes] = notification.scheduledTime;

  // Construct a Date object from the provided components
  const date = new Date(year, month - 1, day, hours, minutes);

  // Format the date using date-fns
  const formattedDate = format(date, 'dd-MM-yyyy HH:mm');

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification?.isRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemIcon color="success">
        <Iconify icon="mingcute:notification-fill" sx={{ color: 'success.main' }} />
      </ListItemIcon>

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon={!notification.isRead && 'eva:clock-fill'} width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{formattedDate}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Stack>
      <Typography variant="subtitle2">{notification.title}</Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        {notification.description}
      </Typography>
    </Stack>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
