import keyBy from 'lodash/keyBy';
import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../config-global';
import { getAuthenticatedUserFromCookies } from '../../utils/cookies';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  inventoryList: [],
  tehnic: [],
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET INVENTORY listStyle:
    getInventoryListSuccess(state, action) {
      state.inventoryList = action.payload;
    },

    // CREATE PRODUCT
    createProductSuccess(state, action) {
      state.inventoryList.push(action.payload);
    },

    // UPDATE PRODUCT
    updateProductSuccess(state, action) {
      const { id } = action.payload;
      const index = state.inventoryList.findIndex((item) => item.id === id);
      state.inventoryList[index] = { ...state.inventoryList[index], ...action.payload };
    },

    // DELETE PRODUCT
    deleteProductSuccess(state, action) {
      state.inventoryList = state.inventoryList.filter((item) => item.id !== action.payload);
    },

    updateStock(state, action) {
      state.inventoryList = state.inventoryList.map((item) => {
        if (item.CodObiect === action.payload.CodObiect) {
          console.log('l-am gasit');
          return {
            ...item,
            Stoc: action.payload.newStock,
          };
        }
        return item;
      });
    },

    // add all tehnic with team orders
    addTehnic(state, action) {
      state.tehnic = action.payload;
    },

    // add depozit status
    addDepozitStatus(state, action) {
      const { ticketNumber, orderNumber, status } = action.payload;
      const tehnic = state.tehnic.find((item) => item.ticketNumber === ticketNumber);

      const order = tehnic.teamOrders.find((item) => item.number === orderNumber);
      order.depozitStatuses.push(status);
    },

    // change team order
    changeTeamOrder(state, action) {
      const { ticketNumber, data } = action.payload;
      const tehnicIndex = state.tehnic.findIndex((item) => item.ticketNumber === ticketNumber);
      console.log(tehnicIndex);
      if (tehnicIndex !== -1) {
        // Directly update the state
        state.tehnic[tehnicIndex] = data;
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getInventoryList() {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .get(`${BASE_URL}:9000/inventory/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.getInventoryListSuccess(res.data));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function createProduct(data) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    console.log(data);

    const response = await axios
      .post(`${BASE_URL}:9000/inventory/product`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.createProductSuccess(res.data));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function updateProduct(data) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .put(`${BASE_URL}:9000/inventory/product`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.updateProductSuccess(data));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function deleteProduct(id) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .delete(`${BASE_URL}:9000/inventory/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.deleteProductSuccess(id));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function getStock(codObiect) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .get(`${BASE_URL}:9000/mentor/sync/articol/${codObiect}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.updateStock({ CodObiect: codObiect, newStock: res.data }));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function addDepozitStatus(data) {
  return async (dispatch) => {
    dispatch(slice.actions.addDepozitStatus(data));
  };
}

// ----------------------------------------------------------------------

export function addTehnic(page, rowsPerPage) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const response = await axios
      .get(`${BASE_URL}:9000/inventory/team-orders?page=${page}&rowsPerPage=${rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(slice.actions.addTehnic(res.data));
        return res;
      })
      .catch((err) => err.response.data);

    return response;
  };
}

// ----------------------------------------------------------------------

export function changeDepozitStatus(obj) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/inventory/change-depozit-status`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.changeTeamOrder({ data: response.data, ticketNumber: obj.ticketNumber })
        );
        return response;
      })
      .catch((error) => error);

    return res;
  };
}

// ----------------------------------------------------------------------

export function orderMaterialsStock(obj) {
  return async (dispatch) => {
    const cookies = getAuthenticatedUserFromCookies();
    const token = cookies.accessToken;

    const res = axios
      .post(`${BASE_URL}:9000/inventory/order-materials-stock`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.changeTeamOrder({ data: response.data, ticketNumber: obj.ticketNumber })
        );
        return response;
      })
      .catch((error) => error);

    return res;
  };
}
