import React, { useState, useEffect, useRef, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Box, ButtonBase, IconButton, Input, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SensorsIcon from '@mui/icons-material/Sensors';
import FormatNumberView from './Views/FormatNumberView/FormatNumberView';
import VirtualPhoneMenu from './VirtualPhoneMenu';
import RecentCallsView from './Views/RecentCallsView/RecentCallsView';
import InCallHeader from './Views/InCallView/InCallHeader';
import HomeView from './Views/HomeView/HomeView';
import SettingsView from './Views/SettingsView/SettingsView';
import { changeConnected, changePhoneState } from '../../../redux/slices/virtualPhone';

const VirtualPhone = ({ makeCall, sendMessage }) => {
  const [open, setOpen] = useState(false);
  const { phoneState, callState, unusable, phoneSettings, isConnected } = useSelector(
    (state) => state.virtualPhone
  );
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    dispatch(changePhoneState('home'));
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(changePhoneState('closed'));
    setOpen(false);
  };

  useEffect(() => {
    if (callState === 'inbound' && !open) setOpen(true);
  }, [callState, open]);

  const Notch = styled('div')`
    width: 170px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 1001;
  `;

  const Speaker = styled('div')`
    width: 60px;
    height: 5px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: grey;
    border-radius: 3px;
  `;

 
  const GradientDialogContent = styled(DialogContent)`
    background-color: white;
    width: 430px;
    height: 700px;
    border-radius: 0;
    padding: 0;
    padding-top: ${phoneSettings.iphoneLook ? '40px' : '15px'};
    position: relative;
    border-radius: 0;
    border: ${phoneSettings.iphoneLook ? '7px solid black' : 'none'};
    border-radius: 20px;
  `;

  const ContentWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    height: 100%;
  `;

  return (
    <div>
      <IconButton
        aria-label="headsetmic"
        size="large"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        <HeadsetMicIcon fontSize="large" />
      </IconButton>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={() => {
          if (callState !== 'inbound') {
            handleClose();
          }
        }}
        aria-labelledby="phone-dialog"
      >
        {phoneSettings.iphoneLook && (
          <Notch>
            <Speaker />
          </Notch>
        )}
        <GradientDialogContent sx={{ borderRadius: '0 !important' }}>
          <ContentWrapper>
            {!isConnected ? (
              <Button
                color="info"
                sx={{
                  width: '40%',
                  height: '65px',
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                variant="contained"
                onClick={() => dispatch(changeConnected(true))}
              >
                <Typography variant="h5">Conectare</Typography>
                <SensorsIcon fontSize="large" />
              </Button>
            ) : unusable ? (
              <Box sx={{ p: '20px', flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderBottom: '1px solid black',
                  }}
                >
                  <WarningAmberIcon fontSize="large" sx={{ color: 'red', mb: 2 }} />
                  Informatii incomplete sau gresite
                </Typography>
                <SettingsView />
              </Box>
            ) : (
              <>
                <Box sx={{ flexGrow: 1 }}>
                  {phoneState === 'formatNumber' ? (
                    <FormatNumberView makeCall={makeCall} />
                  ) : phoneState === 'home' ? (
                    <HomeView makeCall={makeCall} sendMessage={sendMessage} />
                  ) : phoneState === 'history' ? (
                    <RecentCallsView makeCall={makeCall} />
                  ) : phoneState === 'settings' ? (
                    <SettingsView />
                  ) : phoneState === 'messages' ? (
                    <p>Mesage</p>
                  ) : (
                    ''
                  )}
                </Box>
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                  }}
                >
                  {phoneState !== 'formatNumber' && callState === 'incall' && <InCallHeader />}
                  <VirtualPhoneMenu />
                </Box>
              </>
            )}
          </ContentWrapper>
        </GradientDialogContent>
      </Dialog>
    </div>
  );
};
VirtualPhone.propTypes = {
  makeCall: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
};
export default VirtualPhone;
