import { Box } from '@mui/material';
import * as React from 'react';

const PoliceIllustration = () => (
  <Box>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <path
        d="M416.78 398.49h33.12v.25h-33.12zM322.53 401.21h8.69v.25h-8.69zM396.59 389.21h19.19v.25h-19.19zM52.46 390.89h43.19v.25H52.46zM104.56 390.89h6.33v.25h-6.33zM131.47 395.11h93.68v.25h-93.68zM238.4 337.8H45.3a5.71 5.71 0 0 1-5.71-5.71V60.66A5.71 5.71 0 0 1 45.3 55h193.1a5.71 5.71 0 0 1 5.71 5.71v271.38a5.71 5.71 0 0 1-5.71 5.71ZM45.3 55.2a5.47 5.47 0 0 0-5.46 5.46v271.43a5.47 5.47 0 0 0 5.46 5.46h193.1a5.47 5.47 0 0 0 5.46-5.46V60.66a5.47 5.47 0 0 0-5.46-5.46ZM454.7 337.8H261.6a5.71 5.71 0 0 1-5.71-5.71V60.66A5.71 5.71 0 0 1 261.6 55h193.1a5.71 5.71 0 0 1 5.71 5.71v271.38a5.71 5.71 0 0 1-5.71 5.71ZM261.6 55.2a5.47 5.47 0 0 0-5.46 5.46v271.43a5.47 5.47 0 0 0 5.46 5.46h193.1a5.47 5.47 0 0 0 5.46-5.46V60.66a5.47 5.47 0 0 0-5.46-5.46ZM0 382.4h500v.25H0z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M194.25 327.34s9.74-15.24 10.2-9.86c0 0 7.65-6.67 8.27-.68 0 0 6.7-5.42 6.3.28 0 0 5.41-2.52 3.84 3.48 0 0 9.23 2.09 3.53 4.39 0 0 8.65 3 2.8 6.52 0 0 3.9 6.14-.28 7 0 0 .12 7.6-4.59 7.93 0 0 2.3 8.42-1.43 7.28 0 0 1.8 7.87-5.74 5.49 0 0-2.93 4-8.19-.41 0 0 .46 7.8-5.56.78 0 0-7.39 2.91-6.63-4.19 0 0-4.07 5.13-5.68-.81 0 0-5.54 10.38-8.71-2.79 0 0-6.53 2.39-3-5.65 0 0-7.73-.49-2.17-4.09 0 0-7.09-3.57 3-5 0 0-5.11-4.13 3.49-5.59 0-.06-1.13-2.53 10.55-4.08Z"
        style={{
          fill: '#ebebeb',
        }}
      />
      <path
        d="M195.7 382.62h7.27l-1.4-12.12-.11-11.2 6.87-9.19 9.68-7.8 6.96-.4.12-.78-6.37-.14 2.41-3.83-.72-.52-3.36 4.24-8.47 6.68 2.11-11.97 5.24-9.67-.8-.8-5.37 9.03-8.3-7.27-.6.68 8.39 8.41-2.84 12.84-6.13 7.46-1.94-7.75-.84-7.76 2.82-2.69-.3-.8-3.36 2.48-4.08-4.87-.36.8 3.31 4.84.45 7.25-9.16-5.14-.48.69 9.91 6.79 1.73 7.72.72 6.36-.36 2.35-.72 4.8-1.92 11.28z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M142.56 263.66s-44.87 15.53-36.42 3c0 0-38.66 9.44-29.46-4.7 0 0-20-2.47-9.39-14.13 0 0-12.47-9.66.89-19.86 0 0-5.46-22.48 9.34-17.18 0 0-5.1-15.87 12.15-13 0 0 4.39-16.28 13.76-10.56 0 0 5.66-27 15.16-19.22 0 0 10.23-22.45 15.32-13.23 0 0 10.22-20.36 20.39-1.56 0 0 12.46-3.4 14.81 15.68 0 0 14.58-10.95 11.87 8.36 0 0 19.64 12.39 13.74 18.82 0 0 20.29-1.13 13.05 14.68 0 0 19.38 2.75 6 26.57 0 0 15.3 12 3.67 18.38 0 0 6.48 12.42-10.31 10.21 0 0 2.66 12.9-19-2.15 0 0 6 19.94-12.76 7.56-.01-.05-7.99 10.09-32.81-7.67Z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M139.48 304.21s34.11 16.45 28.57 5.74c0 0 29.76 11 23.77-1 0 0 16.09-.1 8.75-10.32 0 0 10.77-6.5 1.12-15.81 0 0 6.41-17.31-5.8-14.48 0 0 5.5-12.1-8.42-11.45 0 0-2-13.3-9.93-9.64 0 0-2-21.92-10.23-16.62 0 0-6-18.74-10.92-11.9 0 0-6.21-17.07-16-3.12 0 0-9.56-3.84-13.18 11.06 0 0-10.53-10-10.17 5.52 0 0-16.7 8-12.63 13.64 0 0-16-2.77-11.69 10.42 0 0-15.61.39-7.19 20.5 0 0-13.23 8.11-4.61 14.22 0 0-6.27 9.24 7.22 9 0 0-3.29 10 15.25.05 0 0-6.64 15.24 9.41 7.17-.01.03 5.37 8.81 26.68-2.98Z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M120.75 382.88h21.24l-8.28-39.36 3.85-36.39 20.07-29.85 28.25-14.41 20.32-1.3.35-2.56-18.57-.44 7.01-12.44-2.11-1.71-9.79 13.81-24.74 10.78-5.03-38.88 21.58-31.43-2.33-2.6-21.96 29.35-24.26-23.63-1.75 2.2 24.52 27.32 2.89 41.71-17.89 24.24-11.97-21.26-.35-29.12 8.25-8.72-.89-2.6-9.81 8.05-9.11-8.05-1.05 2.6 6.88 7.94-.8 27.46-26.74-10.46-1.4 2.24 28.94 15.8 11.33 21.2-2.1 20.67-16.11-12.22-1.4 3.29 16.46 16.55-2.1 15.59-1.4 36.63z"
        style={{
          fill: '#e0e0e0',
        }}
      />
      <path
        d="M332.54 172.58h55.61v209.31h-55.61z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="M286.47 156.49h50.08v225.4h-50.08z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M245.19 265.48h50.08v116.41h-50.08z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <path
        d="M379.77 236.32h55.61v145.57h-55.61z"
        style={{
          fill: '#f0f0f0',
        }}
      />
      <path
        d="M295.27 178.62h5.12v5.17h-5.12zM322.62 178.62h5.12v5.17h-5.12zM295.27 195.37h5.12v5.17h-5.12zM308.95 195.37h5.12v5.17h-5.12zM322.62 212.11h5.12v5.17h-5.12zM295.27 228.86h5.12v5.17h-5.12zM308.95 228.86h5.12v5.17h-5.12zM308.95 245.6h5.12v5.17h-5.12zM322.62 245.6h5.12v5.17h-5.12zM308.95 262.35h5.12v5.17h-5.12zM322.62 262.35h5.12v5.17h-5.12zM322.62 279.09h5.12v5.17h-5.12zM308.95 295.84h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M371.45 306.79h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 374.015 309.375)"
      />
      <path
        d="M344.1 306.79h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 346.665 309.375)"
      />
      <path
        d="M371.45 290.05h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 374.015 292.63)"
      />
      <path
        d="M357.78 290.05h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 360.34 292.63)"
      />
      <path
        d="M344.1 273.3h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 346.665 275.885)"
      />
      <path
        d="M371.45 256.56h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 374.015 259.14)"
      />
      <path
        d="M357.78 256.56h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 360.34 259.14)"
      />
      <path
        d="M357.78 239.81h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 360.34 242.395)"
      />
      <path
        d="M344.1 239.81h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 346.665 242.395)"
      />
      <path
        d="M357.78 223.07h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 360.34 225.65)"
      />
      <path
        d="M344.1 223.07h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 346.665 225.65)"
      />
      <path
        d="M344.1 206.32h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 346.665 208.905)"
      />
      <path
        d="M357.78 189.58h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 360.34 192.16)"
      />
      <path
        d="M418.69 245.6h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 421.255 248.185)"
      />
      <path
        d="M391.34 245.6h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 393.9 248.185)"
      />
      <path
        d="M418.69 262.35h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 421.255 264.93)"
      />
      <path
        d="M405.01 262.35h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 407.575 264.93)"
      />
      <path
        d="M391.34 279.09h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 393.9 281.675)"
      />
      <path
        d="M418.69 295.84h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 421.255 298.42)"
      />
      <path
        d="M405.01 295.84h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 407.575 298.42)"
      />
      <path
        d="M405.01 312.58h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 407.575 315.165)"
      />
      <path
        d="M391.34 312.58h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 393.9 315.165)"
      />
      <path
        d="M405.01 329.32h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 407.575 331.91)"
      />
      <path
        d="M391.34 329.32h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 393.9 331.91)"
      />
      <path
        d="M391.34 346.07h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 393.9 348.655)"
      />
      <path
        d="M405.01 362.81h5.12v5.17h-5.12z"
        style={{
          fill: '#fff',
        }}
        transform="rotate(180 407.575 365.4)"
      />
      <path
        d="M397.73 151.93c-10.33 5.16-38.28 8.18-45.28-7.41-8.28-18.45 11.46-3 11.46-3s0-12.17 6.58-12.17c9 0 8.23 11.13 8.23 11.13a8.62 8.62 0 0 1 8.74-4.35c6.31.68 2.63 8.89 2.63 8.89s15.91 2.8 7.64 6.91ZM352.24 116.84c-20 6.89-74.9 8.44-79.21-20.33-5.12-34.14 24.62-12.27 24.62-12.27s.26-16 14-17.56 13.55 16.56 13.55 16.56 24.67-15.1 22.32 13.94c.01 0 38.48 7.99 4.72 19.66ZM182.28 126.13c-17.87 6.17-67 7.56-70.9-18.19-4.58-30.56 22-11 22-11s.23-14.3 12.54-15.71 12.13 14.82 12.13 14.82 22.08-13.51 20 12.48c.01.01 34.46 7.16 4.23 17.6ZM113.3 143c-8.57 4.27-31.75 6.78-37.56-6.15-6.87-15.31 9.5-2.46 9.5-2.46s0-10.1 5.47-10.1c7.44 0 6.82 9.24 6.82 9.24a7.15 7.15 0 0 1 7.25-3.61c5.23.56 2.18 7.37 2.18 7.37s13.17 2.28 6.34 5.71ZM438.72 124.66c-6.43 3.21-23.84 5.09-28.2-4.62-5.16-11.49 7.14-1.84 7.14-1.84s0-7.58 4.1-7.58c5.59 0 5.12 6.93 5.12 6.93a5.38 5.38 0 0 1 5.45-2.71c3.92.42 1.63 5.54 1.63 5.54s9.89 1.72 4.76 4.28Z"
        style={{
          fill: '#f5f5f5',
        }}
      />
      <ellipse
        cx={250}
        cy={415.69}
        rx={193.89}
        ry={11.32}
        style={{
          fill: '#f5f5f5',
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={107.76}
        y={372.73}
        rx={9.63}
        style={{
          fill: '#263238',
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={107.76}
        y={372.73}
        rx={9.63}
        style={{
          opacity: 0.8,
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={271.11}
        y={372.73}
        rx={9.63}
        style={{
          fill: '#263238',
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={271.11}
        y={372.73}
        rx={9.63}
        style={{
          opacity: 0.8,
        }}
      />
      <path
        d="M149.99 228.07h-3.72l3.62-7.86h6.79l-6.69 7.86zM255.66 228.07h3.72l-3.62-7.86h-6.78l6.68 7.86z"
        style={{
          fill: '#263238',
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={278.88}
        y={373.73}
        rx={9.63}
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M292.64 408.75a1.53 1.53 0 0 1-1.53-1.53v-6.65a1.53 1.53 0 0 1 1.53-1.54 1.53 1.53 0 0 1 1.53 1.54v6.65a1.53 1.53 0 0 1-1.53 1.53ZM292.64 394.43a1.52 1.52 0 0 1-1.53-1.53v-3.22a1.52 1.52 0 0 1 1.53-1.53 1.52 1.52 0 0 1 1.53 1.53v3.22a1.52 1.52 0 0 1-1.53 1.53ZM285.76 408.75a1.54 1.54 0 0 1-1.54-1.53v-.52a1.53 1.53 0 0 1 1.54-1.53 1.53 1.53 0 0 1 1.53 1.53v.52a1.54 1.54 0 0 1-1.53 1.53ZM285.76 401.52a1.53 1.53 0 0 1-1.54-1.53v-10.31a1.53 1.53 0 0 1 1.54-1.53 1.53 1.53 0 0 1 1.53 1.53V400a1.53 1.53 0 0 1-1.53 1.52ZM299.52 408.75a1.53 1.53 0 0 1-1.53-1.53v-.52a1.52 1.52 0 0 1 1.53-1.53 1.52 1.52 0 0 1 1.53 1.53v.52a1.53 1.53 0 0 1-1.53 1.53ZM299.52 401.52A1.52 1.52 0 0 1 298 400v-10.32a1.52 1.52 0 0 1 1.53-1.53 1.52 1.52 0 0 1 1.53 1.53V400a1.52 1.52 0 0 1-1.54 1.52Z"
        style={{
          opacity: 0.5,
        }}
      />
      <rect
        width={27.53}
        height={38.33}
        x={99}
        y={373.73}
        rx={9.63}
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M112.76 408.75a1.54 1.54 0 0 1-1.53-1.53v-6.65a1.54 1.54 0 0 1 1.53-1.54 1.54 1.54 0 0 1 1.54 1.54v6.65a1.54 1.54 0 0 1-1.54 1.53ZM112.76 394.43a1.53 1.53 0 0 1-1.53-1.53v-3.22a1.53 1.53 0 0 1 1.53-1.53 1.53 1.53 0 0 1 1.54 1.53v3.22a1.53 1.53 0 0 1-1.54 1.53ZM105.88 408.75a1.54 1.54 0 0 1-1.53-1.53v-.52a1.53 1.53 0 0 1 1.53-1.53 1.53 1.53 0 0 1 1.54 1.53v.52a1.54 1.54 0 0 1-1.54 1.53ZM105.88 401.52a1.53 1.53 0 0 1-1.53-1.53v-10.31a1.53 1.53 0 0 1 1.53-1.53 1.53 1.53 0 0 1 1.54 1.53V400a1.53 1.53 0 0 1-1.54 1.52ZM119.65 408.75a1.54 1.54 0 0 1-1.54-1.53v-.52a1.53 1.53 0 0 1 1.54-1.53 1.53 1.53 0 0 1 1.53 1.53v.52a1.54 1.54 0 0 1-1.53 1.53ZM119.65 401.52a1.53 1.53 0 0 1-1.54-1.53v-10.31a1.53 1.53 0 0 1 1.54-1.53 1.53 1.53 0 0 1 1.53 1.53V400a1.53 1.53 0 0 1-1.53 1.52Z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="M288.19 282.89H117.35L99 318.19a19.21 19.21 0 0 0-2.17 8.89v58.35a4.4 4.4 0 0 0 4.4 4.4h203.18a4.4 4.4 0 0 0 4.4-4.4V327.1a19.29 19.29 0 0 0-2.19-8.93Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M308.81 385.43v-41.84a13.49 13.49 0 0 1-13.5 13.5h-185a13.49 13.49 0 0 1-13.5-13.5v41.84a4.4 4.4 0 0 0 4.4 4.4h203.2a4.4 4.4 0 0 0 4.4-4.4Z"
        style={{
          opacity: 0.30000000000000004,
        }}
      />
      <path
        d="M286.55 281.74h8.23v-11.61a22.75 22.75 0 0 1 7-1c7.64 0 13.83 3.43 13.83 7.66s-6.19 7.67-13.83 7.67a24.29 24.29 0 0 1-4.27-.39l-7.71 3.07Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M294.78 277.92v-7.79a22.75 22.75 0 0 1 7-1c7.64 0 13.83 3.43 13.83 7.66a4.23 4.23 0 0 1-.05.5 128.68 128.68 0 0 1-14.81.78c-2.09-.07-4.07-.07-5.97-.15Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <path
        d="M119.46 281.74h-8.23v-11.61a22.75 22.75 0 0 0-7-1c-7.63 0-13.82 3.43-13.82 7.66s6.19 7.67 13.82 7.67a24.2 24.2 0 0 0 4.27-.39l7.72 3.07Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M111.23 277.92v-7.79a22.75 22.75 0 0 0-7-1c-7.63 0-13.82 3.43-13.82 7.66a4.21 4.21 0 0 0 0 .5 128.81 128.81 0 0 0 14.81.78c2.13-.07 4.12-.07 6.01-.15Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <path
        d="M260.16 228.07H145.6a9.23 9.23 0 0 0-8.58 5.84l-19.37 49H288l-19.26-49a9.23 9.23 0 0 0-8.58-5.84Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M260.16 228.07H145.6a9.23 9.23 0 0 0-8.58 5.84l-19.37 49H288l-19.26-49a9.23 9.23 0 0 0-8.58-5.84Z"
        style={{
          fill: '#fff',
          opacity: 0.8,
        }}
      />
      <path
        d="m284.88 281.74-17-43.21a5 5 0 0 0-4.66-3.18H142.53a5 5 0 0 0-4.66 3.17l-17.1 43.22Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m284.88 281.74-17-43.21a5 5 0 0 0-4.66-3.18H142.53a5 5 0 0 0-4.66 3.17l-17.1 43.22Z"
        style={{
          fill: '#fff',
          opacity: 0.2,
        }}
      />
      <rect
        width={130.71}
        height={14.57}
        x={137.47}
        y={207.76}
        rx={5.31}
        style={{
          fill: '#263238',
        }}
      />
      <rect
        width={130.71}
        height={14.57}
        x={137.47}
        y={207.76}
        rx={5.31}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={140.54}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={156.27}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={172.01}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={187.74}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={203.88}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={187.74}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={203.88}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={219.61}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={235.35}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <rect
        width={14.03}
        height={8.43}
        x={251.08}
        y={210.82}
        rx={2.93}
        style={{
          fill: '#407bff',
        }}
      />
      <g
        style={{
          opacity: 0.4,
        }}
      >
        <rect width={14.03} height={8.43} x={219.61} y={210.82} rx={2.93} />
        <rect width={14.03} height={8.43} x={235.35} y={210.82} rx={2.93} />
        <rect width={14.03} height={8.43} x={251.08} y={210.82} rx={2.93} />
      </g>
      <path
        d="m143.41 331.43-30-7.09a6.65 6.65 0 0 1-5-7.72l1.79-9.62a6.65 6.65 0 0 1 9.34-4.8l19.93 9.25a12.25 12.25 0 0 1 6.76 8.23l1.74 7.15a3.8 3.8 0 0 1-4.56 4.6Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <circle
        cx={135.52}
        cy={320.07}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M137.22 316.77a4.33 4.33 0 0 0-.85-.31 1 1 0 0 1-.85-1v-.25a1 1 0 0 1 1.25-1 5.58 5.58 0 0 1 1.61.59 1 1 0 0 1 .3 1.57l-.17.2a1 1 0 0 1-1.29.2Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M135.52 326.07a6 6 0 0 1-5.87-4.75 1 1 0 0 1 1-1.25h.25a1 1 0 0 1 1 .85 3.71 3.71 0 0 0 7.22 0 1 1 0 0 1 1-.85h.25a1 1 0 0 1 1 1.25 6 6 0 0 1-5.85 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <circle
        cx={118.91}
        cy={313.41}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
        transform="rotate(-76.72 118.901 313.4)"
      />
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M120.61 310.11a3.68 3.68 0 0 0-.86-.31 1.05 1.05 0 0 1-.84-1v-.25a1 1 0 0 1 1.25-1 6.23 6.23 0 0 1 1.61.59 1 1 0 0 1 .3 1.58l-.17.19a1 1 0 0 1-1.29.2Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M118.91 319.41a6 6 0 0 1-5.87-4.75 1 1 0 0 1 1-1.25h.25a1 1 0 0 1 1 .84 3.71 3.71 0 0 0 7.22 0 1 1 0 0 1 1-.84h.25a1 1 0 0 1 1 1.25 6 6 0 0 1-5.85 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M162 323.07a4 4 0 0 0-3.6 5.84c1.66 3.3 3.91 7 6.09 8.41 16.23 10.18 60.41 10.18 76.63 0 2.19-1.38 4.43-5.11 6.1-8.41a4 4 0 0 0-3.61-5.84Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M162 323.07a4 4 0 0 0-3.6 5.84c1.66 3.3 3.91 7 6.09 8.41 16.23 10.18 60.41 10.18 76.63 0 2.19-1.38 4.43-5.11 6.1-8.41a4 4 0 0 0-3.61-5.84Z"
        style={{
          fill: '#fff',
          opacity: 0.6000000000000001,
        }}
      />
      <path
        d="M267.85 369.52a51.23 51.23 0 0 1 3.57 6.1 4.77 4.77 0 0 1-4.26 6.9H138.49a4.76 4.76 0 0 1-4.25-6.9 49.85 49.85 0 0 1 3.56-6.1 9.12 9.12 0 0 1 7.46-3.83h115.13a9.12 9.12 0 0 1 7.46 3.83Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <path
        d="M171.15 347.28h63.34v15.33h-63.34z"
        style={{
          fill: '#fff',
          opacity: 0.8,
        }}
      />
      <path
        d="M143.47 282.89c-2.23 2.64-3.26 8.05-1.91 12.84 2.29 8.17 5.39 17.42 8.41 20.82h105.71c3-3.4 6.12-12.65 8.41-20.82 1.35-4.79.32-10.2-1.91-12.84Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M143.47 282.89c-2.23 2.64-3.26 8.05-1.91 12.84 2.29 8.17 5.39 17.42 8.41 20.82h105.71c3-3.4 6.12-12.65 8.41-20.82 1.35-4.79.32-10.2-1.91-12.84Z"
        style={{
          fill: '#fff',
          opacity: 0.8,
        }}
      />
      <path
        d="M175.91 293.73a2.38 2.38 0 0 1 1 1.92 5.93 5.93 0 0 1-.84 3 9.57 9.57 0 0 1-2.38 2.93 10.68 10.68 0 0 1-3.47 1.92 12.8 12.8 0 0 1-4.15.67h-3.28l-3 4.21h-4l12-15.27h5.65a4.24 4.24 0 0 1 2.47.62Zm-5.32 6.82a5.34 5.34 0 0 0 2-1.95c.51-.86.64-1.52.42-2s-.82-.68-1.78-.68h-2.62l-3.8 5.3h2.91a5.89 5.89 0 0 0 2.87-.67ZM177.85 307.57a4 4 0 0 1-2-2.83 6.76 6.76 0 0 1 .85-4.05 14.93 14.93 0 0 1 3-4 15.51 15.51 0 0 1 3.95-2.83 9.2 9.2 0 0 1 4.08-1 5.51 5.51 0 0 1 3.37 1 5.81 5.81 0 0 1 2 2.83 7.72 7.72 0 0 1 .28 4 8.35 8.35 0 0 1-1.91 4.05 10.59 10.59 0 0 1-3.95 2.83 13.26 13.26 0 0 1-5.18 1 8.82 8.82 0 0 1-4.49-1Zm8.37-2.61a6.53 6.53 0 0 0 2.31-1.74 6.13 6.13 0 0 0 1.28-2.53 5 5 0 0 0 0-2.53 3.13 3.13 0 0 0-1.12-1.73 3.24 3.24 0 0 0-2-.63 5.32 5.32 0 0 0-2.45.63 8.32 8.32 0 0 0-2.31 1.73 8.79 8.79 0 0 0-1.68 2.53 4.67 4.67 0 0 0-.44 2.53 2.51 2.51 0 0 0 1.13 1.74 4.38 4.38 0 0 0 2.43.62 6.87 6.87 0 0 0 2.85-.58ZM197.05 293.06h3c-.31 4.95-.47 7.43-.79 12.39h8.35c.1 1.15.14 1.73.24 2.88h-12.8ZM208.32 293.06h3l2.85 15.27h-4.05c-.71-6.11-1.07-9.16-1.8-15.27ZM221.63 307.58a11.29 11.29 0 0 1-4.08-2.82 9.09 9.09 0 0 1-2.13-4.07 7.86 7.86 0 0 1 0-4.07 5.31 5.31 0 0 1 1.85-2.81 5.17 5.17 0 0 1 3.29-1 8.93 8.93 0 0 1 3.37.7 14.1 14.1 0 0 1 3.36 2c-.39.84-.6 1.26-1.06 2.09a7.13 7.13 0 0 0-4.23-1.8 3.24 3.24 0 0 0-2.08.63 2.84 2.84 0 0 0-1.05 1.73 5.11 5.11 0 0 0 .18 2.53 6.57 6.57 0 0 0 1.43 2.53 7 7 0 0 0 2.42 1.74 7.48 7.48 0 0 0 3 .62c1.67 0 2.82-.6 3.34-1.81 1.36.84 2 1.26 3.48 2.09a3.7 3.7 0 0 1-2 2 9.12 9.12 0 0 1-3.82.7 13.66 13.66 0 0 1-5.27-.98ZM248 305.49l2.22 2.84h-13.57l-8.54-15.27H238l2.21 2.83h-7.3l2.07 3.32h6.89L244 302h-7.27l2.22 3.53ZM106.85 373.12V359a1.91 1.91 0 0 1 1.91-1.91h14.12a1.91 1.91 0 0 1 1.36 3.26l-14.12 14.12a1.91 1.91 0 0 1-3.27-1.35Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M106.85 373.12V359a1.91 1.91 0 0 1 1.91-1.91h14.12a1.91 1.91 0 0 1 1.36 3.26l-14.12 14.12a1.91 1.91 0 0 1-3.27-1.35Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <circle
        cx={115.83}
        cy={366.06}
        r={7.41}
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-76.72 115.82 366.053)"
      />
      <circle
        cx={115.83}
        cy={366.06}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
        transform="rotate(-76.72 115.82 366.053)"
      />
      <g
        style={{
          opacity: 0.8,
        }}
      >
        <path
          d="M117.52 362.77a3.63 3.63 0 0 0-.85-.32 1 1 0 0 1-.84-1v-.26a1 1 0 0 1 1.25-1 6 6 0 0 1 1.6.59 1 1 0 0 1 .3 1.58l-.16.19a1 1 0 0 1-1.3.22Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.8,
        }}
      >
        <path
          d="M115.83 372.07a6 6 0 0 1-5.88-4.75 1 1 0 0 1 1-1.26h.25a1.06 1.06 0 0 1 1 .85 3.7 3.7 0 0 0 7.21 0 1.07 1.07 0 0 1 1-.85h.25a1 1 0 0 1 1 1.26 6 6 0 0 1-5.83 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M299.8 373.12V359a1.91 1.91 0 0 0-1.91-1.91h-14.12a1.91 1.91 0 0 0-1.35 3.26l14.12 14.12a1.91 1.91 0 0 0 3.26-1.35Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M299.8 373.12V359a1.91 1.91 0 0 0-1.91-1.91h-14.12a1.91 1.91 0 0 0-1.35 3.26l14.12 14.12a1.91 1.91 0 0 0 3.26-1.35Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <circle
        cx={290.83}
        cy={366.06}
        r={7.41}
        style={{
          fill: '#407bff',
        }}
        transform="rotate(-67.5 290.82 366.063)"
      />
      <circle
        cx={290.83}
        cy={366.06}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
        transform="rotate(-67.5 290.82 366.063)"
      />
      <g
        style={{
          opacity: 0.8,
        }}
      >
        <path
          d="M289.13 362.77a3.63 3.63 0 0 1 .85-.32 1.05 1.05 0 0 0 .85-1v-.26a1 1 0 0 0-1.26-1 6 6 0 0 0-1.6.59 1 1 0 0 0-.3 1.58l.16.19a1 1 0 0 0 1.3.22Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.8,
        }}
      >
        <path
          d="M290.83 372.07a6 6 0 0 0 5.87-4.75 1 1 0 0 0-1-1.26h-.25a1.07 1.07 0 0 0-1 .85 3.7 3.7 0 0 1-7.21 0 1.06 1.06 0 0 0-1-.85H286a1 1 0 0 0-1 1.26 6 6 0 0 0 5.83 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="m262.24 331.43 30-7.09a6.66 6.66 0 0 0 5-7.72l-1.79-9.62a6.65 6.65 0 0 0-9.34-4.8l-19.93 9.25a12.25 12.25 0 0 0-6.76 8.23l-1.74 7.15a3.8 3.8 0 0 0 4.56 4.6Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <circle
        cx={270.13}
        cy={320.07}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M268.43 316.77a4.19 4.19 0 0 1 .86-.31 1 1 0 0 0 .84-1v-.25a1 1 0 0 0-1.25-1 5.58 5.58 0 0 0-1.61.59 1 1 0 0 0-.3 1.57l.17.2a1 1 0 0 0 1.29.2Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M270.13 326.07a6 6 0 0 0 5.88-4.75 1 1 0 0 0-1-1.25h-.25a1 1 0 0 0-1 .85 3.71 3.71 0 0 1-7.22 0 1 1 0 0 0-1-.85h-.25a1 1 0 0 0-1 1.25 6 6 0 0 0 5.84 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <circle
        cx={286.74}
        cy={313.41}
        r={7.41}
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
        transform="rotate(-67.5 286.745 313.409)"
      />
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M285 310.11a3.68 3.68 0 0 1 .86-.31 1.05 1.05 0 0 0 .84-1v-.25a1 1 0 0 0-1.25-1 6.23 6.23 0 0 0-1.61.59 1 1 0 0 0-.3 1.58l.17.19a1 1 0 0 0 1.29.2Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.9,
        }}
      >
        <path
          d="M286.74 319.41a6 6 0 0 0 5.88-4.75 1 1 0 0 0-1-1.25h-.25a1 1 0 0 0-1 .84 3.71 3.71 0 0 1-7.22 0 1 1 0 0 0-1-.84h-.25a1 1 0 0 0-1 1.25 6 6 0 0 0 5.84 4.75Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M284.51 280.8c-7.53-2.51-17.09-5.52-21.65-6.22-13.64-2.07-51.91-.42-59.6-.06-7.68-.36-46-2-59.59.06-4.76.73-15 4-22.66 6.55l-.24.61h164.11Z"
        style={{
          fill: '#263238',
        }}
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="M188.83 235.35h-22.27l-19.22 46.39h22.28l19.21-46.39zM276.59 260.67l-8.71-22.14a5 5 0 0 0-4.66-3.18h-59.83l-19.21 46.39h83.68Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="M245.72 336.36V340h-85.79v-3.68h-6.51v56a5.4 5.4 0 0 0 5.4 5.4h4.34a4.21 4.21 0 0 0 4.21-4.21v-3.72h-7.44v-20.6h85.79v20.6h-7.44v3.72a4.21 4.21 0 0 0 4.21 4.21h4.34a5.4 5.4 0 0 0 5.4-5.4v-56Zm-85.79 25.78v-15h85.79v15Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M245.72 336.36V340h-85.79v-3.68h-6.51v56a5.4 5.4 0 0 0 5.4 5.4h4.34a4.21 4.21 0 0 0 4.21-4.21v-3.72h-7.44v-20.6h85.79v20.6h-7.44v3.72a4.21 4.21 0 0 0 4.21 4.21h4.34a5.4 5.4 0 0 0 5.4-5.4v-56Zm-85.79 25.78v-15h85.79v15Z"
        style={{
          opacity: 0.6000000000000001,
        }}
      />
      <path
        d="M320.42 225.91a7.18 7.18 0 0 0 .73 2.42 6 6 0 0 0 2.66 2.63 6.61 6.61 0 0 0 3.22.65 9.84 9.84 0 0 0 2.15-.35l-.53.13a40.76 40.76 0 0 0 8.36-2.56c2.54-1 5-2.15 7.34-3.34s4.7-2.42 7-3.71 4.53-2.61 6.8-4.07a5 5 0 0 0-5.22-8.53h-.06c-2.12 1.24-4.32 2.56-6.51 3.8s-4.38 2.48-6.57 3.68-4.4 2.34-6.59 3.38c-1.57.73-3.2 1.45-4.66 2v-.39c.1-1.27.32-2.63.55-4s.53-2.8.78-4.15v-.12a2.51 2.51 0 0 0-4.57-1.83 39.25 39.25 0 0 0-2.31 4.07 32.34 32.34 0 0 0-1.82 4.45 19.51 19.51 0 0 0-.65 2.56 11 11 0 0 0-.1 3.28Zm8.26-1.38a1.44 1.44 0 0 1-.13-.48l.08.13c.13.3.08.39.05.35Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m325.08 215.13-4.44-5.32 7.91-1.81s3.7 4.52.64 6.79ZM321.58 205.23l3.14-1.18a2.12 2.12 0 0 1 2.75 1.09l1.08 2.86-7.91 1.81-.41-2.47a2 2 0 0 1 1.35-2.11Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M358.5 205.47c-7.69 0-18.84 9.52-18.84 9.52l7 11.07a26.36 26.36 0 0 0 10.61-5.6c1.61-1.38 9.04-14.97 1.23-14.99Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M354.07 217.58c-4.89 4.13-7.6 5.49-9.09 5.87l1.65 2.61a26.36 26.36 0 0 0 10.61-5.6 6.06 6.06 0 0 0 .86-1.06c.29-3.14-.56-4.76-4.03-1.82Z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="m355.33 407.3-6.34-.1.67-14.73 6.34.1-.67 14.73z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m348.74 405.28 6.93.11a.51.51 0 0 1 .51.44l.89 5.66a1 1 0 0 1-1 1.11c-2.49-.08-6.09-.28-9.23-.33-3.67-.06-3.31.09-7.61 0-2.61 0-3.16-2.68-2.06-2.9 5-1 5.55-1.06 10-3.64a3.08 3.08 0 0 1 1.57-.45Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M347.62 405.84a.14.14 0 0 0 .1-.12.16.16 0 0 0-.09-.15c-.29-.16-2.89-1.49-3.64-1.07a.45.45 0 0 0-.24.39.89.89 0 0 0 .31.74c.71.63 2.53.41 3.54.22Zm-3.43-1.1c.48-.16 1.88.39 2.9.89-1.39.21-2.44.13-2.84-.22a.6.6 0 0 1-.2-.49.17.17 0 0 1 .09-.16Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M347.62 405.84a.16.16 0 0 0 .06-.13 4.51 4.51 0 0 0-1.07-2.46 1.11 1.11 0 0 0-.84-.27c-.42 0-.53.24-.55.41-.09.71 1.47 2.11 2.25 2.47a.2.2 0 0 0 .15-.02Zm-1.93-2.56h.16a.82.82 0 0 1 .62.2 3.75 3.75 0 0 1 .9 2c-.8-.49-1.87-1.58-1.81-2 0-.09.01-.15.13-.2Zm.14-.17ZM365 330c.36-11.22 3.05-20.78 4.73-28.31 3.31-14.82 6.33-24.59 4.4-35.24l-23.14.27s-3.13 48.31-3.4 64.09c-.28 16.44.24 69.83.24 69.83l10.22.15s4.8-27.93 5.9-49.39c.4-7.94.86-15.4 1.05-21.4Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m347.29 399.47.2 4.14 10.88.44.45-4.14-11.53-.44z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M369.73 301.64c.93-4.13 1.82-7.87 2.6-11.34l-2.73-9.71-4 2.44a138.73 138.73 0 0 0 .29 37.21c1.02-7.09 2.67-13.35 3.84-18.6Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        d="m399.89 407.3-6.34-.1-1.36-14.73 6.34.1 1.36 14.73z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="m393.31 405.28 6.93.11a.51.51 0 0 1 .51.44l.89 5.66a1 1 0 0 1-1 1.11c-2.49-.08-6.1-.28-9.24-.33-3.67-.06-3.3.09-7.61 0-2.6 0-3.15-2.68-2.05-2.9 5-1 5.54-1.06 10-3.64a3.05 3.05 0 0 1 1.57-.45Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M392.19 405.84a.12.12 0 0 0 .09-.12.14.14 0 0 0-.08-.15c-.29-.16-2.89-1.49-3.65-1.07a.44.44 0 0 0-.23.39.88.88 0 0 0 .3.74c.72.63 2.53.41 3.55.22Zm-3.43-1.1c.47-.16 1.88.39 2.9.89-1.39.21-2.44.13-2.84-.22a.57.57 0 0 1-.2-.49.17.17 0 0 1 .09-.16Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M392.19 405.84a.13.13 0 0 0 0-.13 4.47 4.47 0 0 0-1.06-2.46 1.13 1.13 0 0 0-.85-.27c-.41 0-.52.24-.54.41-.1.71 1.47 2.11 2.25 2.47a.2.2 0 0 0 .2-.02Zm-1.93-2.56h.15a.8.8 0 0 1 .62.2 3.69 3.69 0 0 1 .91 2c-.8-.49-1.87-1.58-1.82-2 .01-.09.02-.15.14-.2Zm.13-.17Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m390.07 399.47.87 4.14 11.22.44.45-4.14-12.54-.44z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M391.56 330c-1.28-7.22-2.63-21.48-2.08-29.26 1-14.45 1.32-24.2-2.5-34.65l-23.3.63s4.74 45.09 10.11 64.09c5.59 19.76 17.62 69.83 17.62 69.83l10.24.15s-1.42-31.39-6.25-53.79c-1.34-6.33-3.08-12.76-3.84-17Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M360.23 205.67a75.76 75.76 0 0 1 21.41-1.11 7.94 7.94 0 0 1 7.44 7c1.16 9 2.9 26.66-1.31 54.87-3.38 1.36-13.29 2.15-21.16 2.27-.86 0-2.92-1.8-2.92-1.8s-1.91 1.79-3 1.76c-5.21-.17-9.35-.7-10.29-1.64-2-4.88-3.16-17.61 2.71-29.6.18-6.89-1.05-14.52-1-21.9a10.32 10.32 0 0 1 8.12-9.85Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M388.41 264.16c-21.1 4.88-35.24 2.17-38.4 1.42l-.35 4.11a69.9 69.9 0 0 0 14.91 1.42 109.76 109.76 0 0 0 24.18-2.85Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M388.41 264.16c-21.1 4.88-35.24 2.17-38.4 1.42l-.35 4.11a69.9 69.9 0 0 0 14.91 1.42 109.76 109.76 0 0 0 24.18-2.85Z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="M360.04 266.4h8.38v5.15h-8.38z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M360.04 266.4h8.38v5.15h-8.38z"
        style={{
          fill: '#fff',
          opacity: 0.6000000000000001,
        }}
      />
      <path
        d="M380.17 265.26h2.5v4.86h-2.5z"
        style={{
          fill: '#263238',
        }}
        transform="rotate(-9.05 381.493 267.705)"
      />
      <path
        d="M352.82 266.02h2.5v4.86h-2.5z"
        style={{
          fill: '#263238',
        }}
        transform="rotate(6.14 353.963 268.324)"
      />
      <path
        d="M384.32 270.46h-.77a2 2 0 1 0-4 0h-.77a2.78 2.78 0 1 1 5.55 0Z"
        style={{
          fill: '#263238',
        }}
      />
      <g
        style={{
          opacity: 0.7000000000000001,
        }}
      >
        <path
          d="M384.32 270.46h-.77a2 2 0 1 0-4 0h-.77a2.78 2.78 0 1 1 5.55 0Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
      <path
        d="m386 271.35-.29-.83a1.06 1.06 0 0 0-1-.7h-1.81a1 1 0 0 0-1 .7l-.29.83a4.63 4.63 0 1 0 4.37 0Zm-2.19 7.16a3.1 3.1 0 1 1 3.1-3.1 3.1 3.1 0 0 1-3.13 3.1Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m386 271.35-.29-.83a1.06 1.06 0 0 0-1-.7h-1.81a1 1 0 0 0-1 .7l-.29.83a4.63 4.63 0 1 0 4.37 0Zm-2.19 7.16a3.1 3.1 0 1 1 3.1-3.1 3.1 3.1 0 0 1-3.13 3.1Z"
        style={{
          fill: '#fff',
          opacity: 0.5,
        }}
      />
      <path
        d="m382.21 271.35-.29-.83a1.06 1.06 0 0 0-1-.7h-1.81a1.06 1.06 0 0 0-1 .7l-.28.83a4.63 4.63 0 1 0 4.37 0Zm-2.21 7.16a3.1 3.1 0 1 1 3.1-3.1 3.1 3.1 0 0 1-3.1 3.1Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="m382.21 271.35-.29-.83a1.06 1.06 0 0 0-1-.7h-1.81a1.06 1.06 0 0 0-1 .7l-.28.83a4.63 4.63 0 1 0 4.37 0Zm-2.21 7.16a3.1 3.1 0 1 1 3.1-3.1 3.1 3.1 0 0 1-3.1 3.1Z"
        style={{
          fill: '#fff',
          opacity: 0.7000000000000001,
        }}
      />
      <rect
        width={33.31}
        height={3.02}
        x={333.29}
        y={276.96}
        rx={1.21}
        style={{
          fill: '#fff',
        }}
        transform="rotate(-67.5 349.935 278.467)"
      />
      <path
        d="m349.4 273.47-6.13-2.55a1.21 1.21 0 0 1-.66-1.58 1.22 1.22 0 0 1 1.58-.65l6.14 2.54a1.21 1.21 0 0 1 .65 1.58 1.21 1.21 0 0 1-1.58.66Z"
        style={{
          fill: '#fff',
        }}
      />
      <g
        style={{
          opacity: 0.5,
        }}
      >
        <rect
          width={33.31}
          height={3.02}
          x={333.29}
          y={276.96}
          rx={1.21}
          transform="rotate(-67.5 349.935 278.467)"
        />
        <path d="m349.4 273.47-6.13-2.55a1.21 1.21 0 0 1-.66-1.58 1.22 1.22 0 0 1 1.58-.65l6.14 2.54a1.21 1.21 0 0 1 .65 1.58 1.21 1.21 0 0 1-1.58.66Z" />
      </g>
      <path
        d="M368.07 206.35a23.86 23.86 0 0 0 8.31-1.62l2.47-.93a32.17 32.17 0 0 1-2.52-13.35l-9.19 8.38c1 1.66.84 4.41-2.62 6.46-.07 0-.13.1-.2.15.52.56 1.59.97 3.75.91Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M365.25 204.28s-.66 1.73 3.34 1.54 10.46-2.93 10.46-2.93l1.5 1.59s-4.1 5.24-10.05 5.33l-2.82-3-4.42 3s-2.86-.58.35-5.08Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M365.25 204.28s-.66 1.73 3.34 1.54 10.46-2.93 10.46-2.93l1.5 1.59s-4.1 5.24-10.05 5.33l-2.82-3-4.42 3s-2.86-.58.35-5.08ZM367.13 210.91a161.75 161.75 0 0 0-.91 26.25s-4.45 12-3.91 23.18c0 0-4.68-6.12 2.51-23.31 0-.03-1.55-22.42 2.31-26.12ZM390.38 228.06c-.11-3.92-.33-7.33-.6-10.24a68.093 68.093 0 0 0-3.77-2.69l-4-2.61-1.48 3.79s4.9 8.35 9.85 11.75Z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M382.43 209.35a5 5 0 0 1 6.78-2c1.17.64 2.15 1.21 3.18 1.86s2 1.29 3 2c2 1.34 3.9 2.78 5.78 4.32a61.62 61.62 0 0 1 5.4 5 38.81 38.81 0 0 1 4.79 6c.37.56.71 1.14 1 1.74.06.1.21.4.34.65a8.09 8.09 0 0 1 .33.81 8.45 8.45 0 0 1 .42 1.7 10 10 0 0 1-1 5.73 21.59 21.59 0 0 1-5.2 6.47 52.87 52.87 0 0 1-12.15 7.83 2.49 2.49 0 0 1-2.69-4.14l.09-.08a81 81 0 0 0 9.29-9 15.58 15.58 0 0 0 2.83-4.47c.21-.62.11-1 .07-.91s0 0-.07-.09c-.26-.38-.52-.76-.81-1.14a47.8 47.8 0 0 0-8.84-8.42c-1.68-1.3-3.44-2.54-5.22-3.75-.89-.61-1.8-1.2-2.71-1.77s-1.86-1.16-2.67-1.64l-.1-.06a5 5 0 0 1-1.84-6.64Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M381.86 205.08c6.84-2.15 20 9.38 20 9.38l-5.28 12.11a24.94 24.94 0 0 1-11.47-5.16c-6.34-5.41-10.19-14.14-3.25-16.33Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m395.11 245.5-5.76 2.73 4.44 6.14s5-3.15 4-6.21ZM386.67 254.72l1.9 1.85a2 2 0 0 0 2.76 0l2.46-2.19-4.44-6.14-2.73 3.18a2.45 2.45 0 0 0 .05 3.3Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M354.07 224.03h8.24l-.22 1.53-4.96 1.62-3.06-1.62v-1.53zM369.23 224.03H380l-.29 1.53-5.14 1.62-5.34-1.62v-1.53zM376.33 190.45l-9.2 8.38a3.9 3.9 0 0 1 .2 3.52 7.72 7.72 0 0 0 5.29-1.9c2.37-2 3.17-4.39 3.76-7.75-.04-.7-.05-1.46-.05-2.25Z"
        style={{
          opacity: 0.2,
        }}
      />
      <path
        d="M379.07 186.12c-.41 6.18.47 7.17-2.67 10.3-4.72 4.7-12.4 5.6-13.49-.66-1-5.63-1.52-15.84 4.55-18.22a8.53 8.53 0 0 1 11.61 8.58Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M378.68 190.3c-2.2 2.09-3.66-1.6-3.54-1.93.53-1.44.31-2.54-1-4.86s1.71-6 5.18-5.54c3.19.44 1.59 3.6 2.34 5.7 1.34 3.74-1.21 4.94-2.98 6.63Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M364.77 193.65a38.64 38.64 0 0 1 6.61-1.14c-.46 1.42-1.43 2.65-3.94 2.92s-2.67-1.78-2.67-1.78Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M381.87 190.26a5.6 5.6 0 0 1-2.85 3c-1.65.71-2.56-.91-2.17-2.76.35-1.66 1.63-4 3.33-4s2.39 2.07 1.69 3.76Z"
        style={{
          fill: '#ff8b7b',
        }}
      />
      <path
        d="M370.58 186.72c0 .53-.27.57-.61.56s-.66-.1-.64-.62.32-.94.66-.92.61.46.59.98ZM365.15 187.1c0 .53-.26.57-.6.56s-.67-.1-.64-.62.32-.94.66-.92.61.45.58.98Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M366.15 186.87a14.5 14.5 0 0 1-1.87 3.48 2.34 2.34 0 0 0 1.94.34Z"
        style={{
          fill: '#ff5652',
        }}
      />
      <path
        d="M371.56 184.36a.27.27 0 0 0 .14-.07.3.3 0 0 0 0-.44 3.13 3.13 0 0 0-2.63-1.07.31.31 0 0 0 .1.61 2.48 2.48 0 0 1 2.07.88.34.34 0 0 0 .32.09ZM362 186.08a.32.32 0 0 0 .27-.1 2.44 2.44 0 0 1 2.08-.81.31.31 0 0 0 .35-.26.3.3 0 0 0-.25-.35 3 3 0 0 0-2.63 1 .31.31 0 0 0 0 .44.38.38 0 0 0 .18.08ZM366.22 192s-1.37-1.13-3 .51-2.25.92-2.25.92.39 1.43 2.62.93a3.32 3.32 0 0 0 2.63-2.36ZM366.52 191.89s1-1.69 3.26-.61 2.67.09 2.67.09.15 1.61-2.34 1.94a3.65 3.65 0 0 1-3.59-1.42ZM366.22 196.22a1.4 1.4 0 0 1 1.85.18 2.11 2.11 0 0 1 2.2-1c1.41.35.3 4.81-.32 3.58 0 0-1.76 2.74-2.05.8 0 0-1.65 1.28-1.38-.59a5.51 5.51 0 0 0-.3-2.97ZM380 178.75a6.12 6.12 0 0 0-6.51-5.16c-4.83.22-8.89 2.75-11.57 2.65a.92.92 0 0 0-.85 1.29c.48 1.11 1.36 2.27 2.86 1.73a2.59 2.59 0 0 1-2.64 1s2.82 2.14 8.06 0c2.18-.89 3.57-.64 4.46-.05a3.86 3.86 0 0 0 4.57-.15Z"
        style={{
          fill: '#263238',
        }}
      />
      <path
        d="M374.18 216.77a2.43 2.43 0 0 1-3.57 0 6 6 0 0 0 3.57 5.48 6 6 0 0 0 3.57-5.48 2.43 2.43 0 0 1-3.57 0Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M374.18 216.77a2.43 2.43 0 0 1-3.57 0 6 6 0 0 0 3.57 5.48 6 6 0 0 0 3.57-5.48 2.43 2.43 0 0 1-3.57 0Z"
        style={{
          fill: '#fff',
          opacity: 0.4,
        }}
      />
      <path
        d="M370.61 216.77a2.43 2.43 0 0 0 3.57 0v5.48a6 6 0 0 1-3.57-5.48Z"
        style={{
          fill: '#fff',
          opacity: 0.2,
        }}
      />
      <path
        d="m381.64 204.56-2.05 1.79s4.74-1.25 7.63 1.13l2.12-2s-2.44-1.08-7.7-.92Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m381.64 204.56-2.05 1.79s4.74-1.25 7.63 1.13l2.12-2s-2.44-1.08-7.7-.92Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        d="M362.84 204.81 362 206a10.33 10.33 0 0 0-7.57.84l2-2s4.94-.65 6.41-.03Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M362.84 204.81 362 206a10.33 10.33 0 0 0-7.57.84l2-2s4.94-.65 6.41-.03Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        d="m313.69 205.34-4.25-3.1 4.25-5.08 9.2-4.76 7.49 3.57 6.13 7.15-4.42 2.22h-18.4z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="m313.69 205.34-4.25-3.1 4.25-5.08 9.2-4.76 7.49 3.57 6.13 7.15-4.42 2.22h-18.4z"
        style={{
          opacity: 0.5,
        }}
      />
      <path
        d="M313.69 205.34s8.86-7 18.4 0c0 0-7.84 5.11-18.4 0Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M313.69 205.34s8.86-7 18.4 0c0 0-7.84 5.11-18.4 0Z"
        style={{
          opacity: 0.30000000000000004,
        }}
      />
      <path
        d="M323 196.42a2.18 2.18 0 0 1-3.19 0 5.33 5.33 0 0 0 3.19 4.89 5.33 5.33 0 0 0 3.18-4.89 2.17 2.17 0 0 1-3.18 0Z"
        style={{
          fill: '#407bff',
        }}
      />
      <path
        d="M323 196.42a2.18 2.18 0 0 1-3.19 0 5.33 5.33 0 0 0 3.19 4.89 5.33 5.33 0 0 0 3.18-4.89 2.17 2.17 0 0 1-3.18 0Z"
        style={{
          fill: '#fff',
          opacity: 0.4,
        }}
      />
      <path
        d="M319.79 196.42a2.18 2.18 0 0 0 3.19 0v4.89a5.33 5.33 0 0 1-3.19-4.89Z"
        style={{
          fill: '#fff',
          opacity: 0.2,
        }}
      />
    </svg>
  </Box>
);
export default React.memo(PoliceIllustration);
