import * as React from 'react';

const ConstructionIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path
      d="M237.3 59.28c.75-5.44-6.93-9.92-12.7-10.38-2.75-.23-5.61.19-8.22-.71-5.64-2-7.9-9.27-13.41-11.6-4.12-1.73-8.87-.19-12.86 1.82s-7.94 4.59-12.41 4.83c-3.88.25-8.09-1.19-11.49.71-2.53 1.41-3.86 4.33-6.19 6s-5.26 2-8.09 2.19-5.76.18-8.34 1.36-4.71 3.91-4.18 6.69ZM104.86 163.08c.59-4.28-5.44-7.8-10-8.17-2.16-.17-4.41.16-6.46-.55-4.44-1.55-6.21-7.29-10.55-9.12-3.23-1.36-7-.14-10.1 1.44s-6.25 3.56-9.75 3.79c-3.06.19-6.37-.94-9 .55-2 1.11-3 3.41-4.87 4.76s-4.13 1.6-6.35 1.72-4.54.15-6.56 1.08-3.71 3.07-3.28 5.26ZM292.07 271.88c.59-4.27-5.44-7.79-10-8.16-2.16-.17-4.41.16-6.46-.56-4.44-1.54-6.21-7.28-10.55-9.11-3.24-1.36-7-.15-10.1 1.43s-6.25 3.57-9.76 3.8c-3.05.19-6.36-.94-9 .55-2 1.11-3 3.4-4.87 4.76s-4.13 1.6-6.36 1.72-4.53.14-6.55 1.07-3.71 3.07-3.29 5.26Z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M295.03 326.43h.63v33.58h-.63zM284.36 326.95h.63v33.58h-.63zM273.68 326.34h.63v33.58h-.63zM263 326.95h.63v33.58H263z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M262.61 353.03h76.58v127.26h-76.58z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M303.28 353.03h35.91v127.26h-35.91z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M278.67 366.71h6.89v16.73h-6.89zM278.67 432.18h6.89v16.73h-6.89zM303.28 390c0 .19-9.1.34-20.33.34s-20.34-.15-20.34-.34 9.11-.34 20.34-.34 20.33.15 20.33.34ZM278.67 399.69h6.89v16.73h-6.89zM303.28 423c0 .19-9.1.34-20.33.34s-20.34-.15-20.34-.34 9.11-.34 20.34-.34 20.33.14 20.33.34ZM310.91 366.71h6.89v16.73h-6.89zM325.85 366.71h6.89v16.73h-6.89zM310.91 398.45h6.89v16.73h-6.89zM325.85 398.45h6.89v16.73h-6.89zM310.91 430.19h6.89v16.73h-6.89zM325.85 430.19h6.89v16.73h-6.89z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M303.28 354.76v-9.1h7.63v-5h10.33v-4.46h17.95v17.85l-35.91.71z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M302.94 321.38h.63v33.58h-.63zM310.59 321.38h.63v33.58h-.63zM320.92 316.65h.63v33.58h-.63zM338.35 316.65h.63v33.58h-.63zM329.63 316.65h.63v33.58h-.63z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M263.6 344.07h39.68v.63H263.6zM263.69 335.88h57.63v.63h-57.63zM398.66 255.67h.92v49.06h-.92zM383.06 256.42h.92v49.06h-.92zM367.46 255.54h.92v49.06h-.92zM351.86 256.42h.92v49.06h-.92z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M351.29 294.54h111.89V480.3H351.29z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M410.72 294.54h52.46V480.3h-52.46z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M374.75 314.52h10.06v24.44h-10.06zM374.75 410.18h10.06v24.44h-10.06zM410.72 348.55c0 .28-13.31.5-29.72.5s-29.71-.22-29.71-.5 13.3-.49 29.71-.49 29.72.22 29.72.49ZM374.75 362.71h10.06v24.44h-10.06zM410.72 396.75c0 .27-13.31.5-29.72.5s-29.71-.23-29.71-.5 13.3-.5 29.71-.5 29.72.22 29.72.5ZM410.72 447.53c0 .27-13.31.49-29.72.49s-29.71-.22-29.71-.49 13.3-.5 29.71-.5 29.72.22 29.72.5ZM421.86 314.52h10.06v24.44h-10.06zM443.68 314.52h10.06v24.44h-10.06zM421.86 360.9h10.06v24.44h-10.06zM443.68 360.9h10.06v24.44h-10.06zM421.86 407.28h10.06v24.44h-10.06zM443.68 407.28h10.06v24.44h-10.06z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M410.72 297.06v-13.3h11.14v-7.3h15.09v-6.52h26.23v26.08l-52.46 1.04z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M410.22 248.28h.92v49.06h-.92zM421.4 248.28h.92v49.06h-.92zM436.49 241.37h.92v49.06h-.92zM461.95 241.37h.92v49.06h-.92zM449.22 241.37h.92v49.06h-.92z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M352.74 281.45h57.98v.92h-57.98zM352.86 269.48h84.21v.92h-84.21zM65.58 318.71h.79v42.32h-.79zM52.12 319.36h.79v42.32h-.79zM38.67 318.6h.79v42.32h-.79zM25.21 319.36H26v42.32h-.79z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M24.72 352.24h96.52v127.31H24.72z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M75.98 352.24h45.26v127.31H75.98z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M44.95 369.48h8.68v21.08h-8.68zM44.95 452h8.68v21.08h-8.68zM76 398.84c0 .23-11.48.43-25.63.43s-25.63-.2-25.63-.43 11.47-.43 25.63-.43 25.63.19 25.63.43ZM44.95 411.05h8.68v21.08h-8.68zM76 440.41c0 .24-11.48.43-25.63.43s-25.63-.19-25.63-.43 11.45-.41 25.61-.41 25.65.17 25.65.41ZM85.59 369.48h8.68v21.08h-8.68zM104.42 369.48h8.68v21.08h-8.68zM85.59 409.49h8.68v21.08h-8.68zM104.42 409.49h8.68v21.08h-8.68zM85.59 449.49h8.68v21.08h-8.68zM104.42 449.49h8.68v21.08h-8.68z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M75.98 354.42v-11.47h9.61v-6.3h13.02v-5.63h22.63v22.5l-45.26.9z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M75.55 312.34h.79v42.32h-.79zM85.19 312.34h.79v42.32h-.79zM98.21 306.38H99v42.32h-.79zM120.17 306.38h.79v42.32h-.79zM109.19 306.38h.79v42.32h-.79z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M25.97 340.95h50.01v.79H25.97zM26.07 330.63h72.64v.79H26.07z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M345.91 23.69a111.7 111.7 0 0 0-75.5 194l-3.3 19.64 21.05-6.33a111.71 111.71 0 1 0 57.75-207.31Z"
      style={{
        fill: '#ebebeb',
      }}
    />
    <path
      d="M476.15 480.29c0 .15-101.7.26-227.14.26s-227.16-.11-227.16-.26S123.53 480 249 480s227.15.15 227.15.29Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M299.33 369.08h6.71v111.73h-6.71z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M309.46 364.86a6.76 6.76 0 1 1-6.76-6.76 6.76 6.76 0 0 1 6.76 6.76Z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="M432.57 369.08h6.71v111.73h-6.71z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M442.7 364.86a6.76 6.76 0 1 1-6.76-6.76 6.76 6.76 0 0 1 6.76 6.76Z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="M280.19 380.97h171.78v30.73H280.19z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="m299.63 380.97-.3.51-17.65 30.23h-1.49v-30.74h19.44zM343.05 380.97l-17.97 30.74h-23.47l7.14-12.21 10.82-18.53h23.48zM386.45 380.97l-17.97 30.74h-23.47l17.97-30.74h23.47zM429.85 380.98l-17.97 30.73h-23.47l17.97-30.73h23.47zM451.97 380.98v30.73h-20.16l17.97-30.73h2.19z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M280.19 428.45h171.78v30.73H280.19z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="m299.63 428.45-.3.51-17.65 30.23h-1.49v-30.74h19.44zM343.05 428.45l-17.97 30.74h-23.47l7.14-12.21 10.82-18.53h23.48zM386.45 428.45l-17.97 30.74h-23.47l17.97-30.74h23.47zM429.85 428.46l-17.97 30.73h-23.47l17.97-30.73h23.47zM451.97 428.46v30.73h-20.16l17.97-30.73h2.19z"
      style={{
        fill: '#f5f5f5',
      }}
    />
    <path
      d="M452 411.71V409.64c0-1.37 0-3.37-.05-6 0-5.24-.05-12.9-.1-22.7l.18.18-171.77.16.34-.34v30.74l-.34-.34 122.29.15 36.27.1h13.22-3.34l-9.72.05-36.19.09-122.6.27h-.33V380.59h.35l171.77.16h.18v.25c0 9.85-.08 17.54-.1 22.8V411.24c0 .36-.06.47-.06.47ZM452 459.19V457.12c0-1.37 0-3.37-.05-6 0-5.24-.05-12.89-.1-22.7l.18.18-171.77.16.34-.34v30.74l-.34-.34 122.29.15 36.27.1h13.22-13.06l-36.19.09-122.53.15h-.33V427.9h.35l171.77.16h.18v.18c0 9.85-.08 17.55-.1 22.8V458.48c0 .35-.13.71-.13.71Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M97.36 106.89 93 97.53a32.94 32.94 0 1 0-7.21 9.34h11.57Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M97.36 106.89h-2.99l-8.55-.07.09.16a.1.1 0 0 0 0-.14.1.1 0 0 0-.12 0 32.84 32.84 0 0 1-14.36 7.49 31.59 31.59 0 0 1-5 .78A37.92 37.92 0 0 1 61 115a32.41 32.41 0 0 1-11.33-3.12 32.63 32.63 0 0 1-12-48.82A32.33 32.33 0 0 1 49.75 53a33.21 33.21 0 0 1 14.68-3.21 32.22 32.22 0 0 1 13.75 3.33 32.77 32.77 0 0 1 16.4 18.27l.42 1.37a9.42 9.42 0 0 1 .39 1.32c.21.89.41 1.77.61 2.63a46.06 46.06 0 0 1 .51 5.07A32.72 32.72 0 0 1 93 97.48a.14.14 0 0 0 0 .09l3.26 6.91c.37.78.65 1.38.85 1.79s.3.62.3.62l-.26-.59c-.18-.42-.45-1-.8-1.79-.72-1.61-1.79-4-3.17-7v.08a32.72 32.72 0 0 0 3.71-15.79 47 47 0 0 0-.49-5.13c-.2-.87-.4-1.75-.6-2.65a8.93 8.93 0 0 0-.39-1.34l-.45-1.34a33.08 33.08 0 0 0-16.59-18.56 32.41 32.41 0 0 0-13.93-3.39 33.63 33.63 0 0 0-14.88 3.23 33 33 0 0 0-12.31 10.15 15.52 15.52 0 0 0-1.13 1.65l-1.07 1.69-.9 1.77a13.2 13.2 0 0 0-.83 1.79c-.23.61-.47 1.22-.71 1.83s-.38 1.25-.58 1.87-.37 1.25-.5 1.88-.23 1.27-.34 1.91a32.39 32.39 0 0 0 1 14.89A33 33 0 0 0 61 115.35a38.66 38.66 0 0 0 5.48 0 32.08 32.08 0 0 0 5-.81 32.77 32.77 0 0 0 14.42-7.64l-.15-.13a.1.1 0 0 0 0 .14l8.63-.06h2.19Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m48 83.35 4.59-5.08 9.74 7.82L76.8 69.47l4.73 4.61-19.42 21.36L48 83.35z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M375.37 174c.32-.41 28.93-7.38 33.32-8.45s6.86-2.35 10 0 16.46 21.43 18.41 21.11 6.66-1.18 7.09-7.39 1.28-21.32 1.28-21.32l-8.37-5.36s-45.84 6.86-46.16 7-15.54 11.15-15.54 11.15Z"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="m398.79 158.37-11.15 2.21s.36 13.42 7.11 13.32 7.16-15.21 7.16-15.21Zm-3 12.58a2 2 0 1 1 1.64-2 1.81 1.81 0 0 1-1.66 2.05Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="m398.75 158.36-11.14 2.2s.35 13.4 7.1 13.33 7.17-15.21 7.17-15.21Zm-3 12.58a2 2 0 1 1 1.63-2 1.82 1.82 0 0 1-1.64 2Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m400.26 158.36-11.14 2.2s.35 13.4 7.1 13.33 7.17-15.21 7.17-15.21Zm-3 12.58a2 2 0 1 1 1.63-2 1.82 1.82 0 0 1-1.64 2Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M217.48 178.77s-3.42 6.8 18.55 15.46 40.63 15 86 6.4 50.67-25.66 68.74-36.36c0 0 44.94-8.32 47.32-7.61s6.66 11.18 15.22 10.94 11.93-3.6 13.35-8.6-6.66-10.42-6.66-10.42 0-9.75-5.23-11.41l-5.23-1.67s-4-30.67-13.79-47.55-15-27.58-39.94-38.28c0 0-24.73-19.74-59.68-10.7S279.78 74.4 279.78 74.4s-21.29 26.48-25.57 67.38c0 0-6.73-.83-7.53 1.65-.67 2.07-.91 4.36-1.12 8.43l-.26 4.56s-29.14 16.44-27.82 22.35Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M292 70s7.39.49 9.42 3.33c0 0 4.34-15.72-1.62-15.75C295.13 57.6 292 70 292 70Z"
      style={{
        fill: '#fff',
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M395.37 49.05s-.09 0-.25-.11l-.71-.34c-.63-.29-1.56-.72-2.79-1.2a66.57 66.57 0 0 0-10.72-3.1 89.09 89.09 0 0 0-16.43-1.79 69.56 69.56 0 0 0-20 2.92A79.24 79.24 0 0 0 326 53.57a74 74 0 0 0-7.2 5.12c-2.19 1.72-4.18 3.37-6 4.89-3.6 3.06-6.44 5.61-8.38 7.4-1 .9-1.71 1.61-2.21 2.1q-.36.36-.54.57a1.24 1.24 0 0 0-.16.21c-.12-.08.87-1.19 2.77-3s4.71-4.44 8.28-7.54c1.79-1.54 3.77-3.21 6-4.95a72.67 72.67 0 0 1 7.22-5.19 77.87 77.87 0 0 1 18.6-8.23A69 69 0 0 1 364.47 42 87.25 87.25 0 0 1 381 43.94a61.33 61.33 0 0 1 10.71 3.28 29.41 29.41 0 0 1 2.77 1.29l.69.39a1 1 0 0 1 .2.15Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m430.83 79.6-.32-.44-.91-1.32c-.41-.57-.94-1.26-1.55-2.09S426.67 74 425.77 73A61.45 61.45 0 0 0 408 59.32 57.26 57.26 0 0 0 393.36 54a58.35 58.35 0 0 0-17.79-1.05 82.3 82.3 0 0 0-19.28 4.19 66 66 0 0 0-18.51 10.19A84.07 84.07 0 0 0 323 82.49a169.41 169.41 0 0 0-11 16.44 118.21 118.21 0 0 0-8 16 123.52 123.52 0 0 0-5 14.79 161.72 161.72 0 0 0-4.32 22.28c-.19 1.32-.32 2.5-.57 3.51A3.91 3.91 0 0 0 294 158a2.12 2.12 0 0 0 1 1.12 4.68 4.68 0 0 0 .51.19 1.57 1.57 0 0 1-.53-.14 2.12 2.12 0 0 1-1.12-1.13 4 4 0 0 1 0-2.6c.23-1 .35-2.18.52-3.5a154.22 154.22 0 0 1 4.18-22.27 121.88 121.88 0 0 1 4.95-14.86 117.71 117.71 0 0 1 7.95-16.13 166.73 166.73 0 0 1 11.05-16.51 84.39 84.39 0 0 1 14.85-15.3 66.17 66.17 0 0 1 18.65-10.26 83.13 83.13 0 0 1 19.41-4.19 58.53 58.53 0 0 1 17.93 1.11A57 57 0 0 1 408.12 59a65.51 65.51 0 0 1 10.79 7.11 56.9 56.9 0 0 1 7 6.8c.89 1 1.61 1.95 2.25 2.76s1.12 1.54 1.51 2.12l.87 1.35c.21.3.29.46.29.46Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M428 76.08c-6.91-10.87-16.72-17.58-25.15-21.63a57.29 57.29 0 0 0-30.33-5.28c-32.69 3.13-48.75 21.52-63.22 45.46-16.3 27-17.11 54-18.12 57.38s2.63 5.28 2.63 5.28 3.34-20.74 3.73-22.39 5.47-19.42 9.07-26.39 15.25-27.55 24-35.14 17.59-15.21 35.15-19.23 26.27-1.49 32.5.88 17.4 8.29 21 11.54a108 108 0 0 1 8.74 9.52Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M377.73 140.46s0-2.39 3.64-3.08 49.62-7.29 51.44-7.18a5 5 0 0 1 4.11 4c.34 2.05 0 14.71 0 14.71l-.13.6a7.11 7.11 0 0 1-5.73 5.54c-12 2.11-42.69 7.44-44.89 7a14 14 0 0 1-4.8-2.05Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M377.73 140.46s0 .11.08.33.12.57.21 1l.77 3.83c.64 3.37 1.58 8.24 2.75 14.32l-.07-.1a13.79 13.79 0 0 0 2.38 1.26 8.94 8.94 0 0 0 2.73.75c1.92-.05 4-.4 6.15-.68 4.3-.62 8.95-1.37 13.89-2.18l15.59-2.65 8.31-1.46a7 7 0 0 0 3.78-1.65 6.9 6.9 0 0 0 2.18-3.54c.07-.27.12-.57.18-.83v.05q.09-4.05.14-8v-3.9c0-.64 0-1.29-.06-1.92a4.59 4.59 0 0 0-.35-1.78 5 5 0 0 0-2.41-2.55 3 3 0 0 0-1.69-.29c-.58 0-1.21.13-1.81.19l-7.1.9-24.52 3.44-16.51 2.37a9.22 9.22 0 0 0-2.51.62 3.85 3.85 0 0 0-1.5 1.1 2.91 2.91 0 0 0-.61 1.42 1.07 1.07 0 0 1 0-.41 2.89 2.89 0 0 1 .5-1.07 3.73 3.73 0 0 1 1.52-1.18 9.37 9.37 0 0 1 2.54-.68c3.88-.64 9.52-1.5 16.48-2.53s15.27-2.23 24.52-3.49l7.1-.92c.62-.07 1.21-.15 1.85-.2a3.37 3.37 0 0 1 1.92.34 5.46 5.46 0 0 1 2.67 2.81 5.09 5.09 0 0 1 .39 1.94c0 .65.05 1.3.06 1.94v3.91c0 2.63-.07 5.29-.14 8v.03c-.06.26-.11.54-.19.84a7.44 7.44 0 0 1-2.34 3.82 7.64 7.64 0 0 1-4 1.76l-8.31 1.45-15.61 2.61c-4.94.81-9.59 1.54-13.9 2.14-2.17.26-4.2.6-6.24.64a9 9 0 0 1-2.84-.79 14.3 14.3 0 0 1-2.44-1.33V160c-1.11-6.11-2-11-2.61-14.37-.29-1.65-.52-2.94-.67-3.84l-.16-1a1.27 1.27 0 0 1-.1-.33Z"
      style={{
        fill: '#263238',
      }}
    />
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="M381.47 137.6c15-2.4 29.34-4.14 44.31-6.57 2-.32 4.26-.68 6.28-.83a3 3 0 0 1 2.23.48c.46.3 1.17.63 1.15 1.18 0 1.2-1.6 1.38-2.79 1.57-15.37 2.38-31 4.35-46.34 6.73a8.5 8.5 0 0 1-3.24-.27c-1-.16-2.7-.6-4.17-.94a4.81 4.81 0 0 1 2.57-1.35Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M386.46 161.16a3.51 3.51 0 0 1-.2-1c-.11-.78-.23-1.73-.37-2.83-.29-2.53-.66-5.77-1.06-9.36-.22-1.82-.43-3.55-.61-5.15-.06-.39-.11-.78-.17-1.14a1.75 1.75 0 0 0-1.8-1.84c-2.3-.61-3.83-.63-3.81-.73a3.09 3.09 0 0 1 1.05 0 14.72 14.72 0 0 1 2.86.4 4.75 4.75 0 0 1 .92.31 2 2 0 0 1 .91.67 3.27 3.27 0 0 1 .32 1.13c.08.37.13.76.19 1.15.23 1.59.45 3.33.65 5.16.41 3.66.72 7 .92 9.38.09 1.2.16 2.18.2 2.85a4.7 4.7 0 0 1 0 1ZM301.12 158.79h3.94l10.9.26 7.6.2c1.37.05 2.78.05 4.26.16a9 9 0 0 1 2.25.39 2.57 2.57 0 0 1 .56.26 1.21 1.21 0 0 1 .44.52 4 4 0 0 1 .33 1.18 14.79 14.79 0 0 1 .11 2.4v18.04a4.71 4.71 0 0 1-2.86 3.4 5.56 5.56 0 0 1-2.27.47c-.76.05-1.52 0-2.28 0-8.38-.22-16.31-.93-23.54-1.51l-2.67-.23-1.31-.11a4.48 4.48 0 0 1-1.33-.26 3.52 3.52 0 0 1-1.94-1.79A3.33 3.33 0 0 1 293 181v-1.25c.07-3.21.13-6.21.19-8.95s.13-5.21.18-7.39a5.22 5.22 0 0 1 .22-1.59 4 4 0 0 1 .77-1.29l.89-1 .2-.24a.63.63 0 0 1 .29-.2 2.87 2.87 0 0 1 .6-.14 27.33 27.33 0 0 1 3.53-.2h1.23a1.2 1.2 0 0 1-.31 0h-.92a33.63 33.63 0 0 0-3.5.3 2.78 2.78 0 0 0-.55.14.35.35 0 0 0-.2.14l-.2.24-.87 1a3.84 3.84 0 0 0-.7 1.21 4.66 4.66 0 0 0-.19 1.52c0 2.18-.06 4.65-.1 7.39s-.08 5.74-.13 9a4.44 4.44 0 0 0 .3 2.3 3.13 3.13 0 0 0 1.7 1.56 4.19 4.19 0 0 0 1.21.22l1.36.23 2.67.22c7.22.55 15.16 1.24 23.5 1.46h2.25a4.78 4.78 0 0 0 2.09-.43 4.22 4.22 0 0 0 2.56-3V164.2a13.65 13.65 0 0 0-.09-2.32 3.86 3.86 0 0 0-.28-1.05.74.74 0 0 0-.28-.34 2.26 2.26 0 0 0-.47-.22 8.42 8.42 0 0 0-2.13-.38c-1.45-.11-2.88-.13-4.25-.18l-7.59-.27-10.9-.44-2.94-.14h-.76a1.11 1.11 0 0 1-.26-.07Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M328.19 185.36a2.44 2.44 0 0 1-.05-.56v-1.62c0-1.47 0-3.49-.07-5.94s0-5.51 0-8.81v-2.57a2.48 2.48 0 0 0-.13-1.17 1.37 1.37 0 0 0-.94-.69 13.93 13.93 0 0 0-2.67-.28l-2.83-.19c-3.82-.26-7.46-.5-10.77-.61s-6.29-.06-8.81-.09H294.36a2.44 2.44 0 0 1-.56-.05 2.05 2.05 0 0 1 .56-.06h1.62l5.94-.12c2.5 0 5.5-.14 8.82 0s7 .31 10.79.57l2.83.19a14.29 14.29 0 0 1 2.8.31 1.91 1.91 0 0 1 1.16.95 2.71 2.71 0 0 1 .17 1.41v2.53c0 3.3-.06 6.29-.08 8.82s-.08 4.47-.1 5.95c0 .65 0 1.18-.05 1.61a2.05 2.05 0 0 1-.07.42Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m328 164.36 2.58-4.06s.87.72.73 4.06c-.1 2.32.31 17.93 0 18.38s-1.52 2.67-3 2.81c0 0-.19-14.67 0-15.83s.13-5.36-.31-5.36Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M295 178.36a7.55 7.55 0 0 1-.34-2c-.13-1.26-.24-3-.25-4.94s.07-3.69.18-4.95a7.45 7.45 0 0 1 .31-2c.15 0 0 3.14 0 7s.26 6.87.1 6.89Z"
      style={{
        fill: '#fff',
      }}
    />
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="M328.91 160.46c-.45-.82-1.55-.92-2.48-1-9.29-.43-18.35-.78-27.79-.61a7.41 7.41 0 0 0-2.74.29c-.88.37-1.92 2.08-2.23 3l.11.14 29.29 1a19.87 19.87 0 0 0 3.18 0 3.89 3.89 0 0 0 2.15-.79 1.75 1.75 0 0 0 .51-2.03Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M331.42 162.2s13.63-.47 14.61-.86 20.36-15.57 25.06-17.92a45.8 45.8 0 0 1 7.53-3.12s1.62-.07 2 2.38c.35 2 2.58 18.29 2.68 18.48s-27.09 19.37-30.52 19.84-21.48 1.22-21.48 1.22Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M331.42 162.2v20.03l-.18-.18 11.53-.56c2.1-.12 4.29-.24 6.55-.4 1.12-.09 2.29-.16 3.42-.32a15.66 15.66 0 0 0 3.14-1.49c4.17-2.4 8.41-5.23 12.82-8.19s8.94-6.09 13.51-9.41l.6-.46.15-.11.07-.06c.06-.05-.05 0 0 .13s.06.05 0 0V160.84l-.23-1.51-.42-3c-.28-2-.56-3.93-.83-5.86s-.55-3.82-.81-5.68a24 24 0 0 0-.47-2.72 2.52 2.52 0 0 0-.61-1.08 1.6 1.6 0 0 0-1-.48h.05a42.33 42.33 0 0 0-5.31 2.06c-.85.39-1.7.78-2.49 1.21s-1.56.93-2.31 1.41c-3 2-5.76 4-8.38 5.92-5.24 3.82-9.78 7.31-13.67 10.07l-.4.25a4.35 4.35 0 0 1-.46.11l-.86.09c-.57.06-1.11.09-1.64.13l-3 .16-4.75.18-3 .08h-.76a.76.76 0 0 1-.26 0h1.02l3-.15 4.74-.26 3-.2c.53 0 1.07-.08 1.63-.14.28 0 .56-.06.84-.1l.39-.09.35-.22c3.84-2.78 8.36-6.31 13.59-10.16 2.61-1.93 5.38-4 8.37-6 .76-.49 1.52-1 2.35-1.44s1.65-.83 2.51-1.23a42.6 42.6 0 0 1 5.38-2.09h.05a2.41 2.41 0 0 1 2.14 1.9 23 23 0 0 1 .49 2.79c.27 1.87.54 3.76.82 5.69s.55 3.88.83 5.86c.14 1 .29 2 .43 3l.23 1.49v.17a.66.66 0 0 1 0 .18.43.43 0 0 1-.16.27l-.07.06-.16.12-.62.46c-4.58 3.34-9.13 6.45-13.53 9.43s-8.67 5.77-12.87 8.18c-.53.29-1.05.58-1.59.85a7.16 7.16 0 0 1-1.71.67c-1.18.17-2.33.23-3.46.31-2.27.16-4.45.27-6.56.38l-11.54.49h-.18v-.18c.08-6.27.14-11.29.19-14.77 0-1.69.05-3 .06-3.93v-1a1.19 1.19 0 0 1 .04-.32Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M217.31 180.79h.05s-.93 2.7 17.62 11.39a94.14 94.14 0 0 0 20.49 6.33 155.82 155.82 0 0 0 47.21 2.19c5.36-.56 11-1.28 15.64-2.21 12-2.4 21-3.89 32.31-8.33 11.73-4.63 13.6-7.38 20.54-13.26 5.38-4.56 9.65-8.85 17.49-11.52.66-.43 1.33-.85 2-1.26 0 0 40.93-7.58 46.73-7.65a11.58 11.58 0 0 1 1.29 0c5 .3 7.31 6.83 13.89 8.46 7 1.74 11.12-1.29 12.92-3.22a13.37 13.37 0 0 0 1.21-2.93c1.42-5-6.66-10.46-6.66-10.46s-.19-9.62-5.42-11.29l-5.24-1.66s-4-30.67-13.79-47.56-15-27.58-39.94-38.28c0 0-24.73-19.73-59.68-10.7S279.65 74.2 279.65 74.2s-21.28 26.49-25.56 67.38c0 0-6.73-.83-7.54 1.66-.66 2.07-.91 4.35-1.12 8.43l-.25 4.56s-29.18 16.44-27.82 22.35a3.33 3.33 0 0 0-.05 2.21Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      d="M452.6 164.87c-6.58-1.63-8.91-8.16-13.89-8.46a11.58 11.58 0 0 0-1.29 0 2.13 2.13 0 0 1 .58 0c2.38.71 6.66 11.17 15.22 10.94 6.76-.19 10.37-2.39 12.3-5.76-1.8 1.99-5.89 5.02-12.92 3.28ZM350.63 190.12c-11.28 4.44-20.35 5.93-32.31 8.33-4.63.93-10.28 1.65-15.64 2.21a155.82 155.82 0 0 1-47.21-2.19 94.14 94.14 0 0 1-20.47-6.33c-18.55-8.69-17.62-11.39-17.62-11.39h-.05c.69 2.69 4.18 7.57 18.59 13.24 21.87 8.62 40.63 15 86 6.41 43.67-8.23 50.22-24.36 66.72-35.1-7.84 2.67-12.11 7-17.49 11.52-6.92 5.92-8.79 8.67-20.52 13.3Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M433.2 133.15a1.53 1.53 0 0 1-.48.13l-1.39.26-5.12.84c-4.33.7-10.32 1.62-16.94 2.61s-12.62 1.85-17 2.45l-5.15.68-1.4.16a1.72 1.72 0 0 1-.5 0 2.14 2.14 0 0 1 .48-.12l1.39-.26 5.12-.84c4.34-.7 10.32-1.62 16.94-2.61s12.61-1.85 17-2.45l5.15-.68 1.4-.16a2.09 2.09 0 0 1 .5-.01ZM292.69 179.44a10.29 10.29 0 0 1-1.78 0c-1.15 0-2.81-.08-4.85-.25a86.09 86.09 0 0 1-15.76-2.89c-3-.87-5.83-1.85-8.35-2.85s-4.75-2-6.57-3-3.28-1.74-4.26-2.34a12.22 12.22 0 0 1-1.5-1 11.25 11.25 0 0 1 1.6.79c1 .55 2.48 1.3 4.32 2.19s4.07 1.86 6.58 2.85 5.33 1.93 8.33 2.79a96.26 96.26 0 0 0 15.64 3c2 .22 3.68.36 4.82.42a11.79 11.79 0 0 1 1.78.29Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M244.86 155.3a1.73 1.73 0 0 1 0 .5c0 .38-.05.84-.08 1.4-.11 1.29-.24 3-.41 5.14-.09 1.1-.19 2.28-.3 3.55-.05.64-.11 1.29-.17 2s.72.8 1.37.88a11.16 11.16 0 0 0 4.36-.44 3.28 3.28 0 0 0 .52-.2 1 1 0 0 0 .32-.21c.06-.3.12-.76.17-1.14.09-.8.18-1.61.28-2.44.35-3.3.69-6.44 1-9.31l.45-4.07c.19-1.27.42-2.44.61-3.52.38-2.08.7-3.8.93-5.08.11-.55.21-1 .28-1.37a1.55 1.55 0 0 1 .14-.48 3 3 0 0 1 0 .49c-.05.38-.11.84-.19 1.39-.19 1.29-.45 3-.77 5.1-.16 1.08-.37 2.25-.54 3.52s-.26 2.64-.4 4.06c-.3 2.88-.62 6-1 9.33-.1.83-.19 1.64-.29 2.45-.06.42-.09.76-.19 1.25s-.4.38-.59.5a4.71 4.71 0 0 1-.59.22 11.27 11.27 0 0 1-4.58.42 3.34 3.34 0 0 1-1.09-.33 1.16 1.16 0 0 1-.66-1c0-.73.13-1.35.19-2 .13-1.27.25-2.45.37-3.54l.56-5.13c.08-.56.14-1 .19-1.39a2.25 2.25 0 0 1 .11-.55ZM244 159.83a34.66 34.66 0 0 1-3.36 1.85c-2.09 1.11-5 2.67-8.07 4.51s-5.85 3.62-7.82 4.93a33.44 33.44 0 0 1-3.23 2.07s.25-.27.77-.68 1.29-1 2.25-1.69c1.92-1.39 4.65-3.22 7.76-5.07s6-3.37 8.17-4.4c1.07-.52 2-.91 2.56-1.17a3.48 3.48 0 0 1 .97-.35Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M246.08 148.41a13.88 13.88 0 0 1 3.41-.21 11.78 11.78 0 0 1 3.41.25 11 11 0 0 1-3.41.27c-1.88 0-3.42-.14-3.41-.31ZM454.82 160a8.66 8.66 0 0 1-.17-1.51c-.07-1.08-.16-2.47-.26-4.14s-.2-3.86-.32-6.15c-.06-1.16-.12-2.37-.19-3.63a2.41 2.41 0 0 0-.54-1.61 2.11 2.11 0 0 0-1.63-.47c-.53.05-1 .56-1.4 1a2.76 2.76 0 0 1-.82.7.74.74 0 0 1-.37.05.85.85 0 0 1-.32-.17 3.27 3.27 0 0 1-.37-.38c-.82-1-1.51-2-2.26-2.85a21.06 21.06 0 0 0-4.44-4.15c-1.44-1-2.68-1.67-3.53-2.13s-1.36-.67-1.35-.7a4.61 4.61 0 0 1 1.44.52 33.39 33.39 0 0 1 3.65 2 20.69 20.69 0 0 1 4.6 4.14c.76.88 1.49 1.88 2.28 2.83a2.12 2.12 0 0 0 .3.3c.05.08.08 0 .07.1 0-.06.06 0 .11 0a2.61 2.61 0 0 0 .62-.57 2.93 2.93 0 0 1 1.74-1.21 2.56 2.56 0 0 1 2 .63 2.92 2.92 0 0 1 .68 1.94l.15 3.63c.08 2.29.15 4.37.22 6.15s.07 3.08.09 4.16a8.54 8.54 0 0 1 .02 1.52Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M448.55 143.59a16.12 16.12 0 0 0 1-4.11c.15-2.34-.12-4.28.06-4.3a10.06 10.06 0 0 1 .46 4.34 8.14 8.14 0 0 1-.77 3c-.36.75-.7 1.11-.75 1.07ZM459.55 161.07a42.16 42.16 0 0 1-.26-6.56 42 42 0 0 1 .26-6.55 40.55 40.55 0 0 1 .26 6.55 40.73 40.73 0 0 1-.26 6.56Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M463.45 157.66c-.08 0-.14-.36-.35-.89a6.28 6.28 0 0 0-1.19-1.88c-1.18-1.31-2.46-2.1-2.36-2.25s.39.09.91.39a8.11 8.11 0 0 1 1.84 1.51 5 5 0 0 1 1.15 2.12c.14.61.06 1 0 1ZM261 109.88a1.63 1.63 0 0 1 .15-.5l.52-1.4c.23-.62.53-1.36.91-2.2s.78-1.82 1.31-2.86a81.23 81.23 0 0 1 3.91-7.2 85.67 85.67 0 0 1 12.33-15.8 81.64 81.64 0 0 1 6-5.54c.88-.77 1.73-1.39 2.46-2s1.37-1 1.91-1.42l1.23-.85a2.55 2.55 0 0 1 .45-.27s-.12.14-.39.36l-1.17.93c-.52.39-1.15.88-1.85 1.47s-1.54 1.24-2.4 2c-1.77 1.51-3.79 3.4-5.94 5.6s-4.37 4.73-6.56 7.52-4.07 5.59-5.7 8.19-3 5-4 7.11c-.55 1-1 2-1.38 2.82s-.72 1.56-1 2.15l-.62 1.37a2.07 2.07 0 0 1-.17.52Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M244.76 168.87s1.08-20.24 1.89-20.42 6.24-.28 6.25 0c0 1.49-1.84 19.07-2.16 19.48-.88 1.12-5.98.94-5.98.94Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M346.47 43a7.89 7.89 0 0 1-1.4.42c-.9.22-2.2.58-3.8 1.07a68 68 0 0 0-22.82 12.15c-1.3 1-2.32 1.93-3 2.55a7.27 7.27 0 0 1-1.13.93 7.71 7.71 0 0 1 1-1.07c.65-.68 1.64-1.6 2.91-2.69A61.38 61.38 0 0 1 329 49.05a60.48 60.48 0 0 1 12.14-4.87c1.61-.45 2.94-.76 3.86-.92a7.79 7.79 0 0 1 1.47-.26Z"
      style={{
        fill: '#fff',
      }}
    />
    <g
      style={{
        opacity: 0.30000000000000004,
      }}
    >
      <path
        d="M222.45 172.55c-2.08 1.38-2.24 5.26-.79 7.28s2.5 2.48 4.64 3.76a48.21 48.21 0 0 0 10.21 4.25 12.31 12.31 0 0 0 5.13.61c1.88-.29 4.23-2 5.81-3.08 3.78-2.57 7.05-5.72 10.73-8.43.83-.61 1.75-1.42 1.65-2.45-.13-1.32-1.76-1.83-3.07-2l-10.56-1.61a9.34 9.34 0 0 1-3-.83 3.21 3.21 0 0 1-1.8-2.44c-.12-1.73 1.58-3.61.52-5-.92-1.19-2.8-.48-4.07.3-5 3.08-10.49 6.26-15.36 9.66"
        style={{
          fill: '#fff',
        }}
      />
    </g>
    <path
      d="M296.76 159.35c.12.07-.08.6-.45 1.18s-.76 1-.88.9.07-.6.44-1.18.77-.98.89-.9ZM261.05 195.22a1.53 1.53 0 0 1-.47 0l-1.35-.07c-.58 0-1.3-.08-2.12-.19s-1.77-.18-2.8-.36a69.76 69.76 0 0 1-7.2-1.5 77.12 77.12 0 0 1-8.54-2.86 75.64 75.64 0 0 1-8.1-3.93 66.56 66.56 0 0 1-6.2-4c-.86-.59-1.6-1.19-2.25-1.69s-1.21-1-1.65-1.35l-1-.9a1.57 1.57 0 0 1-.33-.33s.15.07.39.25l1.07.82c.46.36 1 .8 1.7 1.28s1.42 1 2.29 1.62c1.71 1.19 3.82 2.5 6.22 3.85a82.36 82.36 0 0 0 8.07 3.87 85 85 0 0 0 8.47 2.88c2.65.71 5.08 1.25 7.13 1.6 1 .2 2 .31 2.78.44s1.52.21 2.1.27l1.34.17a1.5 1.5 0 0 1 .45.13Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M331.42 166.08s12.08.43 15.23-1.18 20.63-14.75 23.71-17.78 10.19-4.47 10.14-4.72a3.45 3.45 0 0 0-1-1.73 1.35 1.35 0 0 0-1.47-.27c-1.95.84-7.2 3.1-8.48 3.93-1.58 1-23.48 17-23.48 17l-14.61.86Z"
      style={{
        fill: '#fff',
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M355.37 52.69a1.13 1.13 0 0 1-.3.13l-.88.32-1.45.51a18.87 18.87 0 0 0-1.95.75 61.83 61.83 0 0 0-11.71 6.24c-1.19.73-2.33 1.67-3.58 2.56s-2.43 2-3.69 3a89.86 89.86 0 0 0-7.48 7.42 113.28 113.28 0 0 0-13.94 19.68 160.16 160.16 0 0 0-15.88 40.8c-1.32 5.46-2.18 9.93-2.72 13-.29 1.55-.47 2.75-.62 3.57-.07.38-.12.69-.16.93a1.11 1.11 0 0 1-.08.31 1.54 1.54 0 0 1 0-.32l.12-.94c.12-.82.26-2 .52-3.58.47-3.12 1.28-7.6 2.56-13.09a155.69 155.69 0 0 1 15.79-41 111 111 0 0 1 14-19.73 87.23 87.23 0 0 1 7.54-7.42c1.28-1.06 2.51-2.09 3.74-3s2.41-1.82 3.62-2.55a59.59 59.59 0 0 1 11.84-6.12 20.38 20.38 0 0 1 2-.71l1.47-.46.9-.27Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m378.87 168.27-.5-1.3s7.12-9 15.34-10.81 39.37-7.82 45.33-7.58 6.73 4.73 6.73 4.73l-42.08 6.75-13.1 2.94Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M217.36 178.58a1.19 1.19 0 0 0 .23.65 6.69 6.69 0 0 0 1.38 1.5 40.89 40.89 0 0 0 6.62 4.36c3 1.67 6.79 3.58 11.26 5.56a93.27 93.27 0 0 0 15.88 4.87 154 154 0 0 0 43 3.29c4.07-.25 8.23-.67 12.46-1.2s8.53-1.22 12.88-2.14c8.7-1.71 17.76-3.44 26.59-6.69a74.65 74.65 0 0 0 12.68-5.78c3.91-2.35 7.13-5.57 10.53-8.43s6-6.4 8.94-9.5a30.72 30.72 0 0 1 10.19-7.34c.93-.43 1.91-.75 2.86-1.08s1.95-.59 2.92-.78c1.94-.46 3.86-.86 5.76-1.25 3.79-.76 7.49-1.43 11.07-2 7.15-1.23 13.84-2.24 20-3 3.07-.38 6-.72 8.78-.85a7.35 7.35 0 0 1 2.09.15 5.68 5.68 0 0 1 1 .39 8.17 8.17 0 0 1 .85.54 17.4 17.4 0 0 1 2.73 2.69c1.57 1.89 2.92 3.73 4.35 5.26a12.86 12.86 0 0 0 4.51 3.4 5.92 5.92 0 0 0 4.4.23 2.73 2.73 0 0 0 1.28-1.2 4.47 4.47 0 0 0 .5-1.4 7.94 7.94 0 0 0 .08-2.05c0-.47-.06-.7-.06-.7a6.26 6.26 0 0 1 .12.69 8 8 0 0 1 0 2.07 4.83 4.83 0 0 1-.49 1.46 2.86 2.86 0 0 1-1.34 1.27 6 6 0 0 1-4.53-.18 12.91 12.91 0 0 1-4.62-3.42c-1.45-1.53-2.82-3.37-4.4-5.24A17.13 17.13 0 0 0 445 150a7 7 0 0 0-.82-.51 5 5 0 0 0-.9-.36 7.23 7.23 0 0 0-2-.13c-2.76.14-5.68.49-8.74.89-6.12.8-12.8 1.83-19.95 3.07-3.57.63-7.26 1.3-11.05 2.07-1.89.39-3.81.8-5.74 1.25-1 .2-1.92.52-2.88.78s-1.9.65-2.82 1.07a30.07 30.07 0 0 0-10 7.29c-2.94 3.06-5.54 6.63-9 9.54s-6.61 6.08-10.59 8.48a74.82 74.82 0 0 1-12.73 5.79c-8.88 3.27-18 5-26.67 6.7-4.35.91-8.67 1.62-12.92 2.13s-8.41 1-12.49 1.19a153.25 153.25 0 0 1-43.15-3.41 93.34 93.34 0 0 1-15.91-5c-4.48-2-8.24-3.95-11.25-5.66a39.07 39.07 0 0 1-6.59-4.45 6.53 6.53 0 0 1-1.36-1.56c-.14-.33-.1-.6-.08-.59Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M414.4 159.75a5.58 5.58 0 0 1-1.08.31c-.7.16-1.71.38-3 .62l-9.85 1.76c-1.91.41-3.81.58-5.46.83a18.2 18.2 0 0 0-4.28 1.16 29.18 29.18 0 0 0-3.59 2s.27-.3.82-.76a14.81 14.81 0 0 1 2.62-1.58 17.07 17.07 0 0 1 4.42-1.29c1.69-.29 3.52-.46 5.43-.87 3.85-.73 7.36-1.22 9.89-1.62l3-.45a4.07 4.07 0 0 1 1.08-.11Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M439.83 119.09s-2.69-24-17.61-41.8L408.4 85.4c11 15.46 8.17 37 8.17 37ZM400.42 61.62c-39.65-14.68-62.24 20.81-62.24 20.81s15.67-16.13 48.09-12c7 .9 11.68 2.47 15.83 6.48l10.19-9a49.23 49.23 0 0 0-11.87-6.29Z"
      style={{
        fill: '#fff',
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M343.8 165.86a19.37 19.37 0 0 1-8.61.35 29.53 29.53 0 0 1 4.3-.21 33.61 33.61 0 0 1 4.31-.14ZM374.17 144.65a22.45 22.45 0 0 1-2.53 1.94c-1.59 1.15-3.79 2.74-6.19 4.54s-4.54 3.46-6.1 4.67a20.32 20.32 0 0 1-2.57 1.88 18.42 18.42 0 0 1 2.34-2.17c1.5-1.28 3.61-3 6-4.8s4.64-3.35 6.29-4.43a18.06 18.06 0 0 1 2.76-1.63ZM247.11 147.13c-.15 0-.12-.83.06-1.79s.43-1.73.57-1.7.12.83-.06 1.79-.43 1.72-.57 1.7ZM264.24 108.54a9.34 9.34 0 0 1-.46 1.49c-.32 1-.78 2.34-1.3 4.06-1.07 3.43-2.38 8.22-3.67 13.56s-2.27 10.2-2.88 13.74c-.32 1.77-.54 3.21-.68 4.21a10.07 10.07 0 0 1-.27 1.54 8.86 8.86 0 0 1 .06-1.57c.09-1 .25-2.45.52-4.24.52-3.56 1.47-8.45 2.75-13.81s2.69-10.13 3.82-13.52c.57-1.71 1.08-3.08 1.46-4a8.08 8.08 0 0 1 .65-1.46Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="M301.62 72.92a4.88 4.88 0 0 1 .08-1.22l.42-3.3a35.44 35.44 0 0 0 .51-4.87 16.17 16.17 0 0 0-.1-2.86 3.79 3.79 0 0 0-1.54-2.4 2.78 2.78 0 0 0-2.71 0 6.39 6.39 0 0 0-2 1.92A17.63 17.63 0 0 0 294 64.5c-.95 2.63-1.37 4.32-1.46 4.3a5.52 5.52 0 0 1 .22-1.21c.17-.76.45-1.87.89-3.21a16.93 16.93 0 0 1 2.21-4.47 6.74 6.74 0 0 1 2.14-2.08 3.61 3.61 0 0 1 1.58-.42 2.68 2.68 0 0 1 1.67.43 4.26 4.26 0 0 1 1.75 2.74 15.67 15.67 0 0 1 .08 3 32.39 32.39 0 0 1-.62 4.91c-.23 1.3-.43 2.4-.59 3.28a6.2 6.2 0 0 1-.25 1.15Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M300.05 73.49c-.1.12-1.44-1-3.41-1.83s-3.7-1-3.69-1.17a8.89 8.89 0 0 1 7.1 3ZM320.5 45.69a26.84 26.84 0 0 1-3.07 1.37c-1.91.82-4.51 2-7.28 3.54s-5.19 3.06-6.91 4.22a28.72 28.72 0 0 1-2.81 1.86s.22-.25.66-.63 1.1-.9 1.94-1.53a60.4 60.4 0 0 1 6.87-4.38 58.69 58.69 0 0 1 7.39-3.42c1-.37 1.77-.65 2.33-.81a3.49 3.49 0 0 1 .88-.22ZM346.57 165.89a40 40 0 0 1-6.49.66 39.48 39.48 0 0 1-6.52.13 42.22 42.22 0 0 1 6.49-.65 41.64 41.64 0 0 1 6.52-.14ZM373 145.53a4.29 4.29 0 0 1-.8.68l-2.27 1.68c-1.93 1.42-4.57 3.39-7.45 5.63s-5.43 4.32-7.28 5.84l-2.2 1.79a3.57 3.57 0 0 1-.85.61 3.92 3.92 0 0 1 .71-.76c.49-.47 1.2-1.12 2.09-1.92 1.79-1.59 4.32-3.72 7.2-6s5.57-4.17 7.55-5.52c1-.67 1.8-1.2 2.37-1.55a4.37 4.37 0 0 1 .93-.48Z"
      style={{
        fill: '#fff',
      }}
    />
    <path
      d="m97 480.81 2.51-54.33 2.77-39.8 122.4 1.32s6.31 26.65 4.21 50.14c-.2 2.24-1.2 18-2.71 42.47l-60.15.21.31-23.66-1.73.32s-1 12.84-2.49 23.34Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M229.12 420.39s-40.34 6.82-66 5.77-63.23-5.77-63.23-5.77l.72-9.21h128.71Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="m314.82 288.51-12.49 5.94S255.73 339.94 251 343c-3 1.93-7.47 3.42-15.26 1.54-9.87-2.38-28.74-51.24-28.74-51.24l28.14-20 15.33 30.86 45.3-30.77Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M312.23 290.28a56.38 56.38 0 0 1 6.84-4.31l3.55-2.33a5 5 0 0 1 .12.85 3.27 3.27 0 0 0 .39 1.55 2.52 2.52 0 0 0 2.79.65 6.06 6.06 0 0 0 2.66-10.1 4.53 4.53 0 0 0-.41-1.71c-1.65-3.64-7.35-4.59-7.35-4.59l1.13-1s4.39-1.46 9.77 4.1a4.72 4.72 0 0 0 3 1.47 2.41 2.41 0 0 0 2-.62c1.3-1.35.69-2.63-2.62-6.15s-7.67-5.81-10.88-5.43a31.44 31.44 0 0 0-4.44.74s9.64-9.52 10.23-10.15c1.7-1.81 2.31-4.09 1.21-5.34-.71-.8-2.18-1.29-4.22.76-1.77 1.77-22.15 17.65-22.15 17.65l-8.61 7.55Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M297.46 274.8c.74 1.28 2.5 1.57 3.93 1.22s2.7-1.17 4.1-1.62c4.19-1.35 9 .77 13.08-.78.58-.21 1.22-.66 1.13-1.27s-.73-.82-1.27-1l-14-4"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M304.41 267.37a5.45 5.45 0 0 1 1.56.27c1 .24 2.41.6 4.17 1.06l6.15 1.66 1.78.5c.55.2 1.36.21 1.91 1.06a1.24 1.24 0 0 1-.18 1.37 2.49 2.49 0 0 1-1 .68 9.34 9.34 0 0 1-2.08.53c-2.87.38-5.5-.21-7.85-.19a11.2 11.2 0 0 0-3.26.41c-1 .3-1.87.76-2.73 1.09a5.1 5.1 0 0 1-4.34.17c-1-.52-1.13-1.19-1.1-1.18a4.31 4.31 0 0 0 1.23.88 4.94 4.94 0 0 0 4-.41c.82-.36 1.69-.85 2.75-1.21a11.35 11.35 0 0 1 3.46-.51c2.45-.06 5.09.49 7.74.13a8.87 8.87 0 0 0 1.89-.48c.56-.22.93-.64.75-.91s-.81-.5-1.41-.67l-1.77-.52-6.1-1.84c-1.74-.53-3.14-1-4.1-1.3a6.15 6.15 0 0 1-1.47-.59ZM320.64 270.22a6 6 0 0 1 2.47-1.22 4.64 4.64 0 0 1 2.88.45 11.94 11.94 0 0 1 3 2.21 18.81 18.81 0 0 0 2.94 2.46 6.12 6.12 0 0 0 3 1 2.38 2.38 0 0 0 1.95-.88 1.42 1.42 0 0 0 .35-.71s-.17.23-.5.57a2.26 2.26 0 0 1-1.79.64 6.07 6.07 0 0 1-2.76-1 21 21 0 0 1-2.84-2.44 11.81 11.81 0 0 0-3.13-2.27 4.85 4.85 0 0 0-3.16-.37 4.23 4.23 0 0 0-1.9 1c-.39.34-.53.54-.51.56ZM329.64 252.56a16.77 16.77 0 0 0-1.93 1.88l-4.52 4.64-4.6 4.58a15.88 15.88 0 0 0-1.86 1.94 11.38 11.38 0 0 0 2.12-1.67c1.25-1.09 2.93-2.66 4.72-4.47s3.34-3.5 4.42-4.76a11.2 11.2 0 0 0 1.65-2.14ZM320.65 274.54a4 4 0 0 0 .77.34 2.51 2.51 0 0 1 1.55 1.39 1.36 1.36 0 0 1-.3 1.3 7.44 7.44 0 0 1-1.33 1.15 3 3 0 0 0-1.26 1.72 2.56 2.56 0 0 0 .7 2.22 2.91 2.91 0 0 0 2.15.85 3.75 3.75 0 0 0 2-.63 11.84 11.84 0 0 0 2.41-2.49 7.12 7.12 0 0 0 1.08-2.07 1.59 1.59 0 0 0 .08-.85c-.05 0-.08.29-.27.78a8.33 8.33 0 0 1-1.2 1.91 12.52 12.52 0 0 1-2.38 2.31 2.89 2.89 0 0 1-3.52-.18 2.07 2.07 0 0 1-.58-1.75 2.59 2.59 0 0 1 1.06-1.44 7 7 0 0 0 1.38-1.27 1.74 1.74 0 0 0 .3-1.68 2.61 2.61 0 0 0-1.84-1.47c-.45-.15-.79-.18-.8-.14Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M323.06 283.06s-.27.13-.49.59a2.37 2.37 0 0 0 .14 2.1 3.1 3.1 0 0 0 2.74 1.44 5.11 5.11 0 0 0 3.46-1.59 6.16 6.16 0 0 0 1.73-3.38 5.9 5.9 0 0 0-.34-3.07 5.58 5.58 0 0 0-1.1-1.77c-.33-.36-.56-.5-.59-.48a9.47 9.47 0 0 1 1.33 2.37 5.83 5.83 0 0 1 .21 2.87 5.94 5.94 0 0 1-1.62 3.08 4.76 4.76 0 0 1-3.09 1.48 2.78 2.78 0 0 1-2.41-1.15 2.23 2.23 0 0 1-.26-1.82c.14-.44.33-.64.29-.67ZM310.06 282.89s-.23-.45-.92-.91a5.17 5.17 0 0 0-3.2-.78l.22.42a16.32 16.32 0 0 0 1.07-1.49 11.58 11.58 0 0 0 2-6 29 29 0 0 1-2.47 5.69 17.67 17.67 0 0 1-1 1.46l-.37.48.6-.07a5.43 5.43 0 0 1 3 .55c.68.37 1.01.7 1.07.65ZM326.52 266.74c-.14 0-.45 1 0 2.21s1.43 1.76 1.5 1.63-.59-.77-1-1.83-.35-1.99-.5-2.01ZM248 316.8c-.22 0 .13-2.9.8-6.39s1.37-6.29 1.59-6.25-.13 2.91-.79 6.4-1.34 6.28-1.6 6.24Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M129.52 177.85s-15.77-8.94-15.76-26.75c0-8.1 3-10.75 3-10.75s3.3-23.58 7.15-28.64 5.74-6.51 5.74-6.51 1.24-4.7 6-4.68c0 0 1.7-4.68 8.22-3.22a15.57 15.57 0 0 1 7-3.77c4.16-.92 13.55-.69 15.92 1.39 0 0 7.54 2 8.72 3.22 0 0 4.29-.3 6.4 4.67a45 45 0 0 1 11.28 13.56l3.39 7.76a13.47 13.47 0 0 1 6.15 11.69c-.29 7.94-4.49 18.45-9.44 23-3.08 2.81-42.17 26.3-63.77 19.03Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M193.75 127.39c-3.65-2.83-8.55-3.29-13.16-3.64a214.82 214.82 0 0 0-23.38-.92c-7.08.24-14.24 1.21-20.75 4s-12.38 7.53-15.49 13.9a27.73 27.73 0 0 0-1 20.5 38.62 38.62 0 0 0 11.83 17.09l59.36-19.18c3.14-4.94 6.34-10 7.49-15.78s-.27-12.36-4.9-15.97Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M130 172.41c-2.7-7.37-5.36-14.8-6.75-22.52s-1.44-15.83 1-23.27c.37-1.13 3.63-8.74 4.82-8.66.85.05-1.08 7.1-.62 7.82 4.33 6.86 5.74 15.25 5.38 23.35a106.34 106.34 0 0 1-3.83 23.28"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M132.52 214.19 129.65 143c-1.35-19.64 7.44-37.25 27.11-38.06h2.13a34.85 34.85 0 0 1 34.17 32.64c1.08 17.1 2.06 36.1 1.79 45.27-.56 18.87-19.49 21.48-19.49 21.48s0 7.63.16 13.17c0 0-2.52 8.78-18.8 8.13-17.59-.63-24.2-11.44-24.2-11.44Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M183.9 156.73a2.69 2.69 0 0 1-2.64 2.67 2.57 2.57 0 0 1-2.74-2.48 2.69 2.69 0 0 1 2.64-2.67 2.57 2.57 0 0 1 2.74 2.48ZM188.3 148c-.34.35-2.36-1.2-5.26-1.24s-5.06 1.37-5.36 1c-.15-.16.2-.79 1.14-1.45a7.41 7.41 0 0 1 4.29-1.24 7.07 7.07 0 0 1 4.18 1.43c.88.72 1.17 1.36 1.01 1.5ZM159.13 157.26a2.69 2.69 0 0 1-2.64 2.68 2.57 2.57 0 0 1-2.74-2.48 2.7 2.7 0 0 1 2.64-2.68 2.58 2.58 0 0 1 2.74 2.48ZM161.78 148.45c-.34.34-2.36-1.21-5.26-1.25s-5.05 1.37-5.36 1c-.15-.16.19-.79 1.14-1.45a7.42 7.42 0 0 1 4.3-1.24 7.06 7.06 0 0 1 4.17 1.43c.88.71 1.23 1.35 1.01 1.51ZM170.61 170.87c0-.16 1.8-.44 4.73-.77.74-.06 1.44-.19 1.58-.7a3.76 3.76 0 0 0-.46-2.2l-2.09-5.68c-2.9-8.09-5-14.73-4.66-14.84s2.94 6.34 5.84 14.43q1 3 2 5.71a4.3 4.3 0 0 1 .34 2.91 1.87 1.87 0 0 1-1.24 1.07 5.63 5.63 0 0 1-1.26.15c-2.95.11-4.77.05-4.78-.08ZM162.33 136.8c-.32.78-3.2.3-6.62.59s-6.2 1.11-6.62.39c-.19-.35.31-1.07 1.45-1.8a11.58 11.58 0 0 1 10.09-.74c1.23.55 1.83 1.19 1.7 1.56ZM189.32 138.2c-.57.68-2.92.08-5.71.14s-5.14.62-5.71-.05c-.25-.32.1-1 1.11-1.66a9 9 0 0 1 4.6-1.24 8.76 8.76 0 0 1 4.6 1.17c1.01.64 1.36 1.31 1.11 1.64Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M184.23 116.72c2.06-1.2-4.37-7.82-9.56-10-7.84-3.34-19.75-5.23-26.85-3-8.56 2.65-15.62 7.1-19.76 15s-5.56 17.12-5.1 26.07c.14 2.73.49 5.57 2 7.84 1.87 2.81 5.38 4.53 5.32 8.47a5.36 5.36 0 0 0 1.43 3.91c2.42 2.46 5.25 0 6.53-2.36 2.72-5.13 2.89-11.17 3-17 .08-4.13.15-8.34-1.1-12.26-.69-2.14-1.75-4.15-2.31-6.32s-.56-4.66.76-6.47a8 8 0 0 1 1-1.09 13.45 13.45 0 0 1 13.3-2.85c7.33 2.4 19.83 6 23 3.41 4.58-3.66 5.65-6 5.65-6ZM171.33 178.81c.07.2-.59.69-1.86 1a9.26 9.26 0 0 1-5-.38 9.38 9.38 0 0 1-4.13-2.88c-.8-1-1-1.83-.82-1.95.41-.26 2.22 2.11 5.52 3.23s6.15.51 6.29.98Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m121.78 143.65 2.07 8.77s9.22-9.94 17.79-15.42c7.41-4.75 33.56-9.34 44.59-5.73a15.69 15.69 0 0 1 6.56 3.7 21.93 21.93 0 0 0-2.55-10.56L133.4 129 121 140.72l.53 3.43"
      style={{
        fill: '#455a64',
      }}
    />
    <path
      d="M193.91 150.09a54.93 54.93 0 0 0 4.15-8c.92-2.76 1-8.25-.57-10.71-2.14-3.4-6.24-4.9-10.05-6.18h-.65c-33.43 3.22-50.47 4.88-50.47 4.88-6.52 3.61-12.57 9.91-14.82 14.1s-3.25 11.61-1.76 16.13a13.78 13.78 0 0 0 10 9.21l-1.43 1.88s-6.29-3.87-9.89-9.05-1.11-19.35-1.11-19.35l3.22-8.91S127.12 98 154 97.36s36.37 19.56 36.37 19.56 7.79 9 9.15 12.47a26.05 26.05 0 0 1 1.32 11.48c-.42 3.29-6.75 13.65-6.75 13.65Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="M130.94 156.85c-.32-.14-12.9-4.06-12.72 8.86s13.12 10.06 13.14 9.69-.42-18.55-.42-18.55Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M127.13 170.61c-.06 0-.23.16-.61.34a2.18 2.18 0 0 1-1.68.06c-1.37-.48-2.55-2.63-2.64-4.95a7.31 7.31 0 0 1 .58-3.23 2.62 2.62 0 0 1 1.67-1.76 1.15 1.15 0 0 1 1.33.6c.18.36.1.62.17.64s.27-.22.16-.75a1.36 1.36 0 0 0-.52-.8 1.68 1.68 0 0 0-1.25-.31 3.17 3.17 0 0 0-2.32 2 7.68 7.68 0 0 0-.72 3.61c.12 2.6 1.48 5 3.36 5.53a2.39 2.39 0 0 0 2-.37c.45-.29.51-.58.47-.61Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M175.46 98.14a4.69 4.69 0 0 1 .58.74c.35.5.83 1.23 1.36 2.17a32.39 32.39 0 0 1 3.21 7.82c.78 3.21 1.36 6.12 1.8 8.23a23.81 23.81 0 0 1 .58 3.45 23.64 23.64 0 0 1-.94-3.36l-1.94-8.19a37.94 37.94 0 0 0-3-7.77c-1.02-1.91-1.72-3.04-1.65-3.09ZM139 126.32a3.81 3.81 0 0 1-.26-1c-.13-.66-.29-1.61-.48-2.8-.38-2.37-.83-5.66-1.17-9.31s-.47-7-.51-9.37v-2.85a3.94 3.94 0 0 1 .08-1 3.7 3.7 0 0 1 .12 1c0 .78.09 1.73.15 2.83.13 2.4.33 5.7.66 9.34s.73 6.93 1 9.31c.13 1.1.23 2 .32 2.82a4.62 4.62 0 0 1 .09 1.03ZM132.53 127.66a3.81 3.81 0 0 1-.29-.89c-.16-.59-.36-1.44-.58-2.5a73.17 73.17 0 0 1-1.17-8.35 72.26 72.26 0 0 1-.18-8.44c.05-1.08.11-2 .18-2.56a3 3 0 0 1 .15-.93 33.91 33.91 0 0 1 0 3.49c0 2.15.07 5.12.33 8.39s.67 6.22 1 8.34a27 27 0 0 1 .56 3.45Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m93.79 140.91 4-4.65S99 124 101.13 121.58s15-5.16 18.56-2.85c0 0 6.69 1.13 8.42 4.85 0 0 4.29 1.54 4.4 4s0 7.91-2.34 8.14-3.68-2.12-3.68-2.12l-6.25 3.23-.89 10.81-14.49 14.16-17.53-16.28Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M114.11 138.16c.34 0 6.58-2.23 11.82 0l3.9-4 2.21-3.56 6.71-1s-1.78 9.93-3.23 12.27-4.46 10.47-6.69 11.48-17.71 2.36-17.71 2.36Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="M124.48 137.84c0 .07-1-.39-2.61-.66a28.21 28.21 0 0 0-6.67-.28h-.27v-.28c0-.66 0-1.35-.11-2.06a22.36 22.36 0 0 0-2.26-7.71c-1-1.85-1.74-2.88-1.67-2.93s.25.21.62.67a18.1 18.1 0 0 1 1.37 2.09 20.29 20.29 0 0 1 2.46 7.83 19.07 19.07 0 0 1 .1 2.11l-.27-.25a24.65 24.65 0 0 1 6.78.46 10.69 10.69 0 0 1 1.91.62c.42.23.64.36.62.39Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M115.38 121.86a10.17 10.17 0 0 1-.65 8.72c-.15-.06.62-1.91.79-4.32s-.29-4.36-.14-4.4Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M119.37 118.63a2.66 2.66 0 0 1 1 .42 8 8 0 0 1 2.13 1.9c1.6 1.87 2.3 5.59.9 9.08a6.51 6.51 0 0 1-.82 1.4 6.06 6.06 0 0 1-.55.58 1.46 1.46 0 0 1-.74.44 3.58 3.58 0 0 1-1.54-.08 3.62 3.62 0 0 1-.7-.24 1.36 1.36 0 0 1-.6-.51 3.4 3.4 0 0 1-.45-1.28 8.7 8.7 0 0 1-.12-1.2 8.06 8.06 0 0 1 .18-2l.15.19a2.42 2.42 0 0 1-1.81-1.09 1.1 1.1 0 0 1-.23-.72 6.78 6.78 0 0 0 .37.62 2.32 2.32 0 0 0 1.69.87h.17v.18a8.81 8.81 0 0 0-.09 2 8.12 8.12 0 0 0 .15 1.14 2.9 2.9 0 0 0 .4 1.1.94.94 0 0 0 .42.34 3.63 3.63 0 0 0 .61.19 3.07 3.07 0 0 0 1.31.06.87.87 0 0 0 .51-.32 4.85 4.85 0 0 0 .49-.52 6.59 6.59 0 0 0 .75-1.29 10.32 10.32 0 0 0 .68-4.89 7.45 7.45 0 0 0-1.4-3.76 10.66 10.66 0 0 0-2.86-2.61Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M128.32 123.58a3.82 3.82 0 0 1 .81 2.32 8.56 8.56 0 0 1-.23 2.67 25 25 0 0 1-1 3.11 3.19 3.19 0 0 1-1 1.47 1.9 1.9 0 0 1-1.7.23 2.88 2.88 0 0 1-1.85-2 5.9 5.9 0 0 1-.19-1.81c0-.42 0-.65.09-.65a1.89 1.89 0 0 1 .11.65 7.81 7.81 0 0 0 .34 1.69 2.53 2.53 0 0 0 1.64 1.66 1.44 1.44 0 0 0 1.27-.19 2.76 2.76 0 0 0 .84-1.24 15.41 15.41 0 0 0 1.32-5.57 10.89 10.89 0 0 0-.45-2.34ZM138.75 129.57a8.18 8.18 0 0 1-1.86.37c-1.19.19-2.88.43-4.88.69l.17-.12c-.68 1.23-1.43 2.57-2.22 4l-.56.57-3.25 3.22v-.3a19.05 19.05 0 0 1 2.07 5.1 8.44 8.44 0 0 1 .35 2.11 18.52 18.52 0 0 1-.64-2 21 21 0 0 0-2.2-4.93l-.1-.16.14-.13 3.22-3.25.57-.56h-.05l2.28-3.95.06-.1h.15c2-.23 3.71-.4 4.9-.51a10.06 10.06 0 0 1 1.85-.05Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M132.53 127.66a3 3 0 0 1 .6-.21l1.74-.5 6.4-1.75a173.38 173.38 0 0 1 21.47-4.49 88.32 88.32 0 0 1 12-.86l2.72.05c.88 0 1.73.12 2.56.17 1.65.09 3.17.34 4.57.55a34.28 34.28 0 0 1 3.71.77c.54.13 1.06.23 1.51.37l1.24.41 1.71.59a3.92 3.92 0 0 1 .59.24 2.54 2.54 0 0 1-.62-.14l-1.73-.49-1.2-.37c-.46-.13-1-.21-1.51-.33-1.1-.21-2.32-.55-3.71-.69s-2.9-.41-4.54-.48c-.82 0-1.67-.13-2.55-.15h-2.7a94.73 94.73 0 0 0-12 .89 201.25 201.25 0 0 0-21.43 4.33l-6.45 1.6-1.76.4a2.65 2.65 0 0 1-.62.09ZM197.73 142.47a18.92 18.92 0 0 0 .68-3 12.2 12.2 0 0 0-5.75-11.93 13.62 13.62 0 0 0-6.19-1.91 41.91 41.91 0 0 0-7.29.2l-7.86.67c-10.77.89-20.52 1.69-27.57 2.36l-8.35.81-2.26.22a3.71 3.71 0 0 1-.8 0 7.54 7.54 0 0 1 .78-.14l2.26-.32c2-.25 4.81-.6 8.33-1 7-.76 16.8-1.62 27.57-2.51l7.86-.65a40.83 40.83 0 0 1 7.38-.18 14.16 14.16 0 0 1 6.37 2 12.74 12.74 0 0 1 4.09 4.16 12.36 12.36 0 0 1 1.63 8.16 9.21 9.21 0 0 1-.88 3.06Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M207.46 217.84s13.38 2.21 20.33 13.06c4.51 7 25.62 50 25.62 50 .17-1.12-34 28.13-32.95 27.32 1.21-.94-16.61-22.77-16.61-22.77Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M175.36 204.36s-11.92.49-24.46-7.69c0 0 5.61 14.61 24.48 13.24Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M97.76 136.26s-73.94 62.18-70.23 79.25 53.66 40 65.53 49.59 9.66 19.22 14.36 16.25 3.8-55.16-3.8-60c-2.26-1.44-32.33-16.55-32.33-16.55l37.53-48.62Z"
      style={{
        fill: '#ffbf9d',
      }}
    />
    <path
      d="m232.54 416.63-14.33-150.07-10.41-48.69s-16.57-1.52-17.42-1.52-12.82-1.27-14.65-1.43c0 0-.37 8.11-14 9.46-22.68 2.22-29.37-11.12-29.37-11.12l-6.49 1.24-33.8 6.5 7.4 59 2.1 107-3.39 30.14c21.71 2.37 86.72 3.91 134.36-.51Z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="m105.21 218.98-27.36-12.65L53 248.32l42.41 21.82 4.43 20.14 15.89-9.11 2.49-63.35-13.01 1.16z"
      style={{
        fill: '#e0e0e0',
      }}
    />
    <path
      d="M103.09 233a10.35 10.35 0 0 1-.36-2.25c-.15-1.4-.28-3.35-.29-5.49 0-4.28-.3-7.74-.13-7.75a8.91 8.91 0 0 1 .36 2.25 52.86 52.86 0 0 1 .29 5.49c.04 4.22.3 7.68.13 7.75ZM89.87 259.23a9.64 9.64 0 0 1 .94 1.33c.59.88 1.37 2.18 2.25 3.84a53.55 53.55 0 0 1 2.72 6 59 59 0 0 1 2.22 7.81 90.06 90.06 0 0 1 1.74 14.58c.07 1.87.11 3.39.11 4.44a10.36 10.36 0 0 1 0 1.63 9.44 9.44 0 0 1-.16-1.62c-.07-1.14-.17-2.63-.28-4.43a104.6 104.6 0 0 0-1.88-14.49 61.39 61.39 0 0 0-2.19-7.72 58.63 58.63 0 0 0-2.6-6c-.83-1.67-1.56-3-2.09-3.9a9.23 9.23 0 0 1-.78-1.47ZM221.19 245.88a2.9 2.9 0 0 1 0 .54v7.22c0 4.86-.07 11.45-.11 18.73s0 13.94 0 18.73c0 2.34 0 4.26.05 5.68v1.54a2.46 2.46 0 0 1 0 .54 2 2 0 0 1-.07-.53c0-.41-.06-.92-.1-1.55-.08-1.33-.15-3.27-.22-5.67-.14-4.8-.22-11.42-.19-18.74s.14-13.94.27-18.73c.07-2.34.13-4.26.17-5.68 0-.62.06-1.13.08-1.54a2 2 0 0 1 .12-.54Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M103.07 217.73s22.73 67.25-3.6 88.85v96h133.59c.46-.72-13.65-123.26-13.65-123.26l-17.15-62.17a127.22 127.22 0 0 0-22.26-1.89s-10.56 57.4-12 57.4S128.55 214 128.55 214c-7.55 1-18.08 1.76-25.48 3.73Z"
      style={{
        fill: '#ff725e',
      }}
    />
    <path
      d="m99.47 368.16 129.9-.92 2.05 18.91H99.47v-17.99z"
      style={{
        fill: '#fff',
        opacity: 0.30000000000000004,
      }}
    />
    <path
      d="M172.63 402.61c-.15 0-1.33-28.93-2.65-64.63s-2.26-64.66-2.12-64.66 1.33 28.93 2.64 64.64 2.27 64.65 2.13 64.65Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="m116.25 326.53.25.08.7.25 2.66 1.05c2.31.92 5.67 2.22 9.83 3.77s9.15 3.32 14.73 5.17 11.75 3.73 18.28 5.55 12.81 3.36 18.54 4.68 10.91 2.36 15.28 3.17 7.91 1.44 10.37 1.84l2.82.48.73.14.25.06h-.26l-.74-.09-2.84-.38c-2.46-.34-6-.9-10.4-1.68s-9.57-1.8-15.32-3.06-12-2.84-18.57-4.65-12.71-3.74-18.29-5.6-10.56-3.67-14.71-5.27-7.49-2.95-9.77-3.93l-2.63-1.11-.68-.31a1.09 1.09 0 0 1-.23-.16Z"
      style={{
        fill: '#263238',
      }}
    />
    <path
      d="M56.21 213.26a28 28 0 0 0 8.8-2.94 20.52 20.52 0 0 0 5.13-4.13c1.13-1.26 1.71-2.15 1.78-2.11a8.47 8.47 0 0 1-1.5 2.35 18 18 0 0 1-5.15 4.34c-4.64 2.65-9.2 2.64-9.06 2.49Z"
      style={{
        fill: '#ff9a6c',
      }}
    />
    <path
      d="M143.68 97.28c-.21.41.2 6.52.74 9.56a15.25 15.25 0 0 0 .92-6.12c0-1.64-.06-2.85 0-4.49a10.46 10.46 0 0 0-1.66 1.05ZM166.54 94.87s1.2 2.68 1.74 4.23c.83 2.4 1.84 5 3.72 5.83a46.2 46.2 0 0 0-3.14-9.42ZM190.61 122.24s-7.24-5.92-26.18-4.68-29.11 8.77-29.11 8.77 22.27-5.36 28.59-6.06c15.67-1.75 26.7 1.97 26.7 1.97Z"
      style={{
        opacity: 0.30000000000000004,
      }}
    />
  </svg>
);
export default React.memo(ConstructionIllustration);
